import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import ModalCreateBrand from "./ModalCreateBrand";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import toast from "react-hot-toast";

import { usePromiseTracker } from "react-promise-tracker";
import ContentLoader from "react-content-loader";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import { AppHelper } from "../../utils/Helper";

export default function Brand(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const [brand_data, SetBrandData] = useState([]);
  const [brand_filter_data, SetBrandFilterData] = useState([]);

  const [modal_brand, SetModalBrand] = useState({
    is_open: false,
    data: {},
    type: "create",
  });
  const [page_from, SetPageFrom] = useState("");
  const context = useContext(DataContext);
  const [img_base_url, SetImageBaseUrl] = useState("");
  const [img_size, SetImageSize] = useState([]);
  const [search_data, SetSearchData] = useState("");

  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const { promiseInProgress } = usePromiseTracker();

  const [modal_image, SetModalImage] = useState({
    is_open: false,
    type: "",
    data: {},
    selected_images: [],
  });

  useEffect(() => {
    console.log("Brand On mount :", props);
    console.log("Brand On mount context :", context);
    console.log("id_value :", id_value);
    get_brands();
  }, []);

  useEffect(() => {
    if (search_data != "") {
      const filter_array = brand_data.filter((ele) =>
        ele.name.toLowerCase().match(search_data.toLowerCase())
      );
      console.log("filter_array :", filter_array);
      SetBrandFilterData(filter_array);
    } else {
      SetBrandFilterData(brand_data);
    }
  }, [search_data]);

  async function get_brands() {
    let pass_data = {
      get: {},
    };
    console.log("context :", context);
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let res_data = await api("/brand/all", pass_data);
    console.log("/brand/all response :", res_data);
    if (res_data.response.hasOwnProperty("data")) {
      SetBrandData(res_data.response.data);
      SetBrandFilterData(res_data.response.data);
    }
    if (res_data.response.hasOwnProperty("image_sizes")) {
      SetImageSize(res_data.response.image_sizes);
    }
    if (res_data.response.hasOwnProperty("image_base_url")) {
      SetImageBaseUrl(res_data.response.image_base_url);
    }
  }

  async function delete_brand(pass_value) {
    let pass_data = {
      get: {},
      post: {
        product_brand_id: pass_value.data.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    console.log("delete_brand pass_data :", pass_data);
    let res_data = await api("/brand/delete", pass_data);
    console.log("/brand/delete response :", res_data);
    if (res_data.status_code == 200) {
      toast.success("Brand Deleted");
      if (res_data.response.hasOwnProperty("data")) {
        SetBrandData((prevValue: any) => {
          prevValue = res_data.response.data;
          return [...prevValue];
        });
        SetBrandFilterData((prevValue: any) => {
          prevValue = res_data.response.data;
          return [...prevValue];
        });
      }
    } else {
      get_brands();
    }
  }

  async function update_logo(image_value, brand_value) {
    let pass_data = {
      get: {},
      post: {
        product_brand_id: brand_value.id,
        logo: image_value[0].id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    console.log("update_logo pass_data :", pass_data);
    let res_data = await api("/brand/update_logo", pass_data);
    console.log("/brand/update_logo response :", res_data);
    if (res_data.status_code == 200) {
      toast.success("Logo Updated");
      if (res_data.response.status) {
        if (res_data.response.hasOwnProperty("data")) {
          SetBrandData((prevValue: any) => {
            prevValue = res_data.response.data;
            return [...prevValue];
          });
          SetBrandFilterData((prevValue: any) => {
            prevValue = res_data.response.data;
            return [...prevValue];
          });
        }
      } else {
        toast.success("Logo Not Updated");
        get_brands();
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar">
        <div className="col-md-10 col-7 sm-mb-2">
          <h5 className="mb-0">Brand</h5>
        </div>

        <div className="col-5 col-md-2 ps-0 text-end sm-mb-2">
          <Link
            to={
              // context.app_data.app_from == 'seller'
              id_value.hasOwnProperty("brand_id")
                ? "/seller/" + id_value.brand_id + "/create_brand"
                : "/manufacturer/" + id_value.m_id + "/create_brand"
            }
          >
            <button
              className="btn btn-primary btn-sm px-3 btn-radius fw-bold"
            // onClick={() => {
            //   SetModalBrand((prevValue: any) => {
            //     prevValue.is_open = true
            //     prevValue.type = 'create'
            //     return { ...prevValue }
            //   })
            // }}
            >
              Create New
            </button>
          </Link>
        </div>
      </div>

      <div className=" fixed-sub-header">
        <div className=" row align-items-center head-bar">
          <div className="col-md-6 col-9 d-flex">
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
                onChange={(e) => {
                  SetSearchData(e.target.value);
                }}
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={18} color="#ccc" />
              </div>
            </div>
          </div>

          {/* <div className="text-center col-md-1 col-3">
            <select className="form-select px-2" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div> */}
        </div>
      </div>
      {/* Body */}

      {promiseInProgress === true ? (
        <div className="px-1">
          {/* <div className="p-3 mb-3"> */}
          <ContentLoader
            width={1200}
            height={900}
            viewBox="0 0 1200 900"
            backgroundColor="#eaeced"
            foregroundColor="#ffffff"
            {...props}
          >
            {/* <div className="p-3 mb-3"> */}
            <rect x="30" y="30" rx="100" ry="0" width="298" height="129" />
            {/* </div> */}
          </ContentLoader>
          {/* </div> */}
        </div>
      ) : (
        <div className="px-1">
          <div className="p-3 mb-3">
            <div className="row">
              {brand_filter_data.length > 0 ? (
                brand_filter_data.map((brand_ele: any, brand_index: any) => (
                  <div
                    className="col-md-3 col-sm-6 col-12 mb-3"
                    key={brand_index}
                  >
                    <div className="card p-3">
                      <div className="d-flex mb-2">
                        <h6 className="mb-0">{brand_ele.name}</h6>

                        <div className="dropdown cursor ms-auto px-1 ">
                          <div
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={require("../../assets/icon/svg/more-horizontal.svg")}
                              className="action_img cursor"
                            />
                          </div>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {/* <li>
                              <div
                                className="cursor dropdown-item"
                                onClick={() => {
                                  console.log('view')
                                  SetModalBrand((prevValue: any) => {
                                    prevValue.is_open = true
                                    prevValue.type = 'view'
                                    prevValue.data = brand_ele
                                    return { ...prevValue }
                                  })
                                }}
                                style={{ marginRight: '5px' }}
                              >
                                <img
                                  src={require('../../assets/icon/svg/eye.svg')}
                                  className="action_menu_img cursor"
                                />
                                &nbsp; View
                              </div>
                            </li> */}

                            <li>
                              {/* delete */}
                              <div
                                className="cursor dropdown-item py-2"
                                onClick={() => {
                                  console.log("delete");
                                  SetModalDelete((prevValue: any) => {
                                    prevValue.is_open = true;
                                    prevValue.delete_type = "single";
                                    prevValue.data = {
                                      title: "Delete Brand",
                                      body: "Are you sure? want to delete brand",
                                      data: brand_ele,
                                    };
                                    prevValue.have_option = false;
                                    prevValue.options = {};
                                    return { ...prevValue };
                                  });
                                }}
                                style={{ marginRight: "5px" }}
                              >
                                <MaterialIcons
                                  name="delete-outline"
                                  size={17}
                                  color="gray"
                                />
                                &nbsp; Delete
                              </div>
                            </li>
                            {/* Update Logo */}
                            <li>
                              <div
                                className="cursor dropdown-item py-2"
                                onClick={() => {
                                  console.log("Update Logo");
                                  SetModalImage((prevValue: any) => {
                                    prevValue.is_open = !prevValue.is_open;
                                    prevValue.type = "update_logo";
                                    prevValue.data = brand_ele;
                                    prevValue.selected_images = [];
                                    return { ...prevValue };
                                  });
                                }}
                                style={{ marginRight: "5px" }}
                              >
                                <SimpleLineIcons name="refresh" size={16} color="#666" />
                                &nbsp; Update Logo
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {brand_ele.hasOwnProperty("logo") ? (
                        <img
                          src={AppHelper.imageSrc(
                            img_base_url,
                            brand_ele.logo,
                            {
                              format: "webp",
                              width: 320,
                            }
                          )}
                          // src={
                          //   img_base_url + img_size[0] + '/' + brand_ele.logo
                          // }
                          className="brand_img"
                        />
                      ) : (
                        <img
                          src={require("../../assets/img/no-img.jpg")}
                          className="brand_img"
                        />
                      )}

                      <div className="d-flex align-items-center my-2">
                        <Octicons name="dot-fill" size={18} color={brand_ele.status == "Active" ? "#03bb03" : "gray"} />
                        <p className={brand_ele.status == "Active" ? "ms-2 text-green mb-0" : "ms-2 text-gray mb-0"}>{brand_ele.status}</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <small>{brand_ele.create_at}</small>
                        <div className="ms-auto">
                          {brand_ele.centerlized ? (
                            <small className="text-green">Centerlized</small>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
               <div className="bg-fff w-100">
                 <div className="center h-170 p-3">
                  <div>
                    <img
                      src={require("../../assets/img/empty.png")}
                      className="product-img"
                    />
                    <p className="mt-3 fw-bold text-center">No Data Found!</p>
                  </div>
                </div>
               </div>
              )}
            </div>
          </div>
        </div>
      )}

      <ModalCreateBrand
        open={modal_brand.is_open}
        type={modal_brand.type}
        data={modal_brand.data}
        close={(data: any) => {
          console.log("ModalCreateBrand on close :", data);
          SetModalBrand((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_brands();
          }
        }}
      />

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data) => {
          console.log("on close delete modal data:", data);
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            delete_brand(data);
          }
        }}
      />

      {modal_image.is_open ? (
        <ModalAddGallery
          open={modal_image.is_open}
          type={modal_image.type}
          data={modal_image.data}
          selected={modal_image.selected_images}
          from={"brand"}
          close={(data: any) => {
            console.log("ModalAddGallery on close :", data);
            SetModalImage((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // SetSaveData((prevValue: any) => {
              //   prevValue.logo = data.value[0]
              //   return { ...prevValue }
              // })
              update_logo(data.value, data.pass_value);
            }
          }}
        />
      ) : null}
    </div>
  );
}
