import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  Ionicons,
  AntDesign,
} from '@expo/vector-icons'
import DataContext from '../../utils/Context/DataContext'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import toast from 'react-hot-toast'

const animatedComponents = makeAnimated()
let state_data_value = [];
let myModal = {}

const OwnAccordion = (props) => {
  const [state, SetState] = useState({
    expanded: false,
    show_add_fields: false,
  })
  const [field_data, SetFieldData] = useState([
    {
      selected_country: {
        id: "99",
        iso: "IN",
        iso3: "IND",
        label: "India",
        name: "INDIA",
        nicename: "India",
        numcode: "356",
        phonecode: "91",
        value: "99",
      },
      country: '99',
      state_data: state_data_value,
      selected_state: {},
      state: '',
      selected_currency: {
        code: "INR",
        id: "1",
        label: "₹",
        name: "Indian Rupees",
        symbol: "₹",
        value: "1",
      },
      currency: "1",
      price: '',
      selected_date: '',
      activation_date: '',
    },
  ])
  const [id_value, SetIdValue] = useState(useParams())
  const context = useContext(DataContext)

  async function get_state(c_data: any, index: any) {
    console.log('get_state')
    let pass_data = {
      get: {
        country: 99,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)

    SetFieldData((prevValue: any) => {
      prevValue[index].selected_country = c_data
      prevValue[index].country = c_data.id
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty('data')) {
          prevValue[index].state_data = response.response.data
        }
      }
      return [...prevValue]
    })
  }

  // set_save_value

  useEffect(() => {
    SetState((prevValue: any) => {
      prevValue.show_add_fields = props.edit_data
      return { ...prevValue }
    })
    if (field_data.length == 0) {
      SetFieldData((prevValue: any) => {
        prevValue.push({
          selected_country: {
            id: "99",
            iso: "IN",
            iso3: "IND",
            label: "India",
            name: "INDIA",
            nicename: "India",
            numcode: "356",
            phonecode: "91",
            value: "99",
          },
          country: '99',
          state_data: state_data_value,
          selected_state: {},
          state: '',
          selected_currency: {
            code: "INR",
            id: "1",
            label: "₹",
            name: "Indian Rupees",
            symbol: "₹",
            value: "1",
          },
          currency: "1",
          price: '',
          selected_date: '',
          activation_date: '',
        })
        return [...prevValue]
      })
    }
  }, [props.edit_data])

  useEffect(() => {
    if (props.is_save) {
      // pass_data = {
      //   i
      //   data: field_data,
      // };
      SetFieldData([
        {
          selected_country: {},
          country: '',
          state_data: [],
          selected_state: {},
          state: '',
          selected_currency: {},
          currency: '',
          price: '',
          selected_date: '',
          activation_date: '',
        },
      ])
      props.set_save_value(field_data)
    }
  }, [props.is_save])

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return [
    <div className="">
      <div
        className={
          state.expanded
            ? 'bg-lightblue border-top-radius d-flex align-items-center border-bottom cursor py-2'
            : 'd-flex align-items-center border-bottom cursor py-2'
        }
        onClick={() => {
          SetState((prevValue: any) => {
            prevValue.expanded = !prevValue.expanded
            return { ...prevValue }
          })
        }}
      >
        <p className="mb-0 text-dark ps-2">{props.field_data.name}</p>
        <div className="ms-auto">
          <div className="d-flex align-items-center">
            <button className="btn btn-sm cursor">
              {state.expanded ? (
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={20}
                  color="black"
                />
              ) : (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={20}
                  color="black"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>,
    state.expanded && (
      <div className="p-2 border mb-2 border-bottom-radius">
        {state.show_add_fields ? (
          field_data.map((field: any, field_index: any) => (
            <div className="row mb-2 py-2 border-bottom" key={field_index}>
              {/* Country */}
              <div className="col-6 col-md-3 col-lg-3 mb-2">
                <label className="form-label">Country</label>
                <Select
                  components={animatedComponents}
                  placeholder="Select Country"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.countries}
                  value={field.selected_country}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    console.log(' Country :', e)
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_country = e
                      prevValue[field_index].country = e.id
                      prevValue[field_index].selected_state = {}
                      prevValue[field_index].state = ""
                      // if (e.id == '99') {
                      //   get_state(e, field_index)
                      // }
                      return [...prevValue]
                    })
                  }}
                />
              </div>
              {/* State */}
              {field.selected_country.id == '99' ? (
                <div className="col-6 col-md-3 col-lg-3 mb-2">
                  <label className="form-label">State</label>
                  <Select
                    components={animatedComponents}
                    placeholder="Select State"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={field.state_data}
                    value={field.selected_state}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                      console.log(' state :', e)
                      SetFieldData((prevValue: any) => {
                        prevValue[field_index].selected_state = e
                        prevValue[field_index].state = e.id
                        return [...prevValue]
                      })
                    }}
                  />
                </div>
              ) : null}
              {/* Currency */}
              {/* <div className="col-md-3 col-lg-3 mb-2">
                <label className="form-label">Currency</label>
                <Select
                  components={animatedComponents}
                  placeholder="Select Currency"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.currencies}
                  value={field.selected_currency}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    console.log(" Currency :", e);

                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_currency = e;
                      prevValue[field_index].currency = e.id;
                      return [...prevValue];
                    });
                  }}
                />
              </div> */}
              {/* Price */}
              <div className="col-6 col-md-3 mb-2">
                <label className="form-label ">Price</label>
                <div className="input-group">
                  <div className="input-group-text p-0">
                    <Select
                      className="no-radius border-none"
                      components={animatedComponents}
                      placeholder="Select Currency"
                      isSearchable={ false }
                      classNamePrefix="select"
                      options={context.app_data.currencies}
                      value={field.selected_currency}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (base) => ({ ...base, border: 'none' }),
                        indicatorSeparator: (base) => ({
                          ...base,
                          backgroundColor: 'none',
                        }),
                      }}
                      onChange={(e) => {
                        console.log(' Currency :', e)

                        SetFieldData((prevValue: any) => {
                          prevValue[field_index].selected_currency = e
                          prevValue[field_index].currency = e.id
                          return [...prevValue]
                        })
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Price"
                    value={field.price}
                    //   disabled={read_only}
                    onChange={(e) => {
                      console.log(' Price :', e.target.value)
                      SetFieldData((prevValue: any) => {
                        prevValue[field_index].price = e.target.value
                        return [...prevValue]
                      })
                    }}
                  />
                </div>
              </div>
              {/* Activation Date */}
              <div className="col-6 col-md-3 col-lg-2 mb-2 pe-md-0 sm-pe-2">
                <label className="form-label">Activation Date</label>
                <DatePicker
                  selected={field.selected_date}
                  dateFormat="dd/MM/yyyy"
                  onSelect={(date) => {
                    let date_value = moment(date).format('DD-MM-yyyy')
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_date = date
                      prevValue[field_index].activation_date = date_value
                      return [...prevValue]
                    })
                  }}
                  onChange={(date) => {
                    let date_value = moment(date).format('DD-MM-yyyy')
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_date = date
                      prevValue[field_index].activation_date = date_value
                      return [...prevValue]
                    })
                  }}
                  customInput={<DatepickerInput />}

                />
              </div>
              <div className="col-md-1 ms-auto center">
                <button
                  className="btn btn-sm center"
                  onClick={() => {
                    console.log('Remove')
                    SetFieldData((prevValue: any) => {
                      prevValue.splice(field_index, 1)
                      return [...prevValue]
                    })
                  }}
                >
                  <MaterialCommunityIcons
                    name="delete-outline"
                    size={16}
                    color="black"
                  />
                  <span className="d-sm-block d-md-none ps-1">Remove</span>
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="mb-2 scrollX">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="text-darkgray">
                    #
                  </th>
                  <th scope="col" className="text-darkgray">
                    Country
                  </th>
                  <th scope="col" className="text-darkgray">
                    State
                  </th>
                  <th scope="col" className="text-darkgray">
                    Currency
                  </th>
                  <th scope="col" className="text-darkgray">
                    Price
                  </th>
                  <th scope="col" className="text-darkgray">
                    Activation Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {!props.is_dependent ? (
                  props.field_data.hasOwnProperty('addon_group_mrp') ? (
                    props.field_data.addon_group_mrp.map(
                      (field_ele: any, field_index: any) => (
                        <tr key={field_index}>
                          <th scope="row text-gary">{field_index + 1}</th>
                          <td className="text-gray">{field_ele.country}</td>
                          {field_ele.hasOwnProperty('state') ? (
                            <td className="text-gray">{field_ele.state}</td>
                          ) : (
                            <td className="text-gray">-</td>
                          )}
                          <td className="text-gray">
                            {field_ele.currency} - {field_ele.currency_symbol}
                          </td>
                          <td className="text-gray">{field_ele.price}</td>
                          <td className="text-gray">
                            {field_ele.activation_date}
                          </td>
                        </tr>
                      ),
                    )
                  ) : (
                    <tr className="center w-100">No data</tr>
                  )
                ) : (
                  props.field_data.specification.map(
                    (spec_ele: any, spec_index: any) =>
                      props.selected_specification.id == spec_ele.id
                        ? spec_ele.items.map((item: any, item_index: any) =>
                            props.selected_child_item.id == item.id ? (
                              item.hasOwnProperty('addon_group_mrp') ? (
                                item.addon_group_mrp.map(
                                  (field_ele: any, field_index: any) => (
                                    <tr key={field_index}>
                                      <th scope="row text-gary">
                                        {field_index + 1}
                                      </th>
                                      <td className="text-gray">
                                        {field_ele.country}
                                      </td>
                                      {field_ele.hasOwnProperty('state') ? (
                                        <td className="text-gray">
                                          {field_ele.state}
                                        </td>
                                      ) : (
                                        <td className="text-gray">-</td>
                                      )}
                                      <td className="text-gray">
                                        {field_ele.currency} -{' '}
                                        {field_ele.currency_symbol}
                                      </td>
                                      <td className="text-gray">
                                        {field_ele.price}
                                      </td>
                                      <td className="text-gray">
                                        {field_ele.activation_date}
                                      </td>
                                    </tr>
                                  ),
                                )
                              ) : (
                                <tr className="center w-100">
                                  {/* No Mrp Data :{props.selected_child_item.name} */}
                                  No Mrp Data
                                </tr>
                              )
                            ) : null,
                          )
                        : null,
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

        {state.show_add_fields ? (
          <div className="center mt-3 mb-4">
            <button
              className="btn d-flex align-items-center btn-page"
              onClick={() => {
                let push_data = {
                  selected_country: {
                    id: "99",
                    iso: "IN",
                    iso3: "IND",
                    label: "India",
                    name: "INDIA",
                    nicename: "India",
                    numcode: "356",
                    phonecode: "91",
                    value: "99",
                  },
                  country: '99',
                  state_data: state_data_value,
                  selected_state: {},
                  state: '',
                  selected_currency: {
                    code: "INR",
                    id: "1",
                    label: "₹",
                    name: "Indian Rupees",
                    symbol: "₹",
                    value: "1",
                  },
                  currency: "1",
                  price: '',
                  selected_date: '',
                  activation_date: '',
                };
                SetFieldData((prevValue: any) => {
                  prevValue.push(push_data)
                  return [...prevValue]
                })
              }}
            >
              <span className="pe-1">
                <Ionicons name="add-sharp" size={16} color="black" />
              </span>
              Add more
            </button>
          </div>
        ) : null}
      </div>
    ),
  ]
}

export default function ModalAddonGroupUpdatePrice(props: any) {
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [view_type, SetViewType] = useState('')
  const context = useContext(DataContext)
  const [is_dependent, SetIsDependent] = useState(false)
  const [is_edit, SetIsEdit] = useState(false)
  const [specification_data, SetSpecificationData] = useState([])
  const [selected_specification, SetSelectedSpecification] = useState({})
  const [is_save, SetIsSave] = useState(false)
  const [save_data, SetSaveData] = useState([])
  const [selected_child_item, SetSelectedChildItem] = useState({})
  const [save_data_dependent, SetSaveDataDependent] = useState([])
  const [props_data, SetPropsData] = useState({})

  useEffect(() => {
    if (props.open) {
      console.log('AddonGroupUpdatePriceModal On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('AddonGroupUpdatePriceModal'),
        {},
      )
      myModal.show()
      if (props.from == 'addon_group') {
        SetViewType('main')
      }
      SetPropsData(props.data)
      get_state_value();
    }
  }, [props.open])

  useEffect(() => {
    console.log('AddonGroupUpdatePriceModal On mount :', props)
    let myModal1 = document.getElementById('AddonGroupUpdatePriceModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetIsEdit(false)
      SetIsSave(false)
      SetSpecificationData([])
      SetSelectedSpecification({})
      SetIsDependent(false)
      // SetPropsData({})
      SetViewType('')
      SetSelectedChildItem({})
      SetSaveData([])
      SetSaveDataDependent([])
      props.close(close_data)
    })
  }, [])

  async function get_state_value() {
    console.log('get_state_value')
    let pass_data = {
      get: {
        country: "99",
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)
    if (response.status_code == 200) {
      if(response.response.hasOwnProperty("data")){
        state_data_value = response.response.data
      }
    }
  }

  const Switch = ({ isOn, handleToggle, onColor, id_value, is_disabled }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={id_value}
          disabled={is_disabled}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={id_value}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    )
  }

  async function get_specifications() {
    let pass_data = {
      get: {
        // category_id: props_data.category_id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/get_specifications', pass_data)
    console.log('/product/get_specifications response  :', response)
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty('data')) {
        let spec_data = []
        response.response.data.map((ele) => {
          if (ele.hasOwnProperty('children')) {
            ele.label = ele.name
            ele.value = ele.id
            spec_data.push(ele)
          }
        })
        SetSpecificationData(spec_data)
      }
    }
  }

  async function final_save(data) {
    console.log('final save :', data)
    // console.log("props_data.children :", props_data.children);
    let addon_item_data = props_data.children
    let save_value = []
    if (!is_dependent) {
      data.map((ele, index) => {
        let push_value = {
          id: addon_item_data[ele.child_index].addon_items_id,
          addon_group: [],
        }
        let push1 = {
          id: props_data.id,
          mrp: ele.data,
        }
        push_value.addon_group.push(push1)
        save_value.push(push_value)
      })
      console.log('save_value not dependent :', save_value)
    } else {
      // data.map((ele, index) => {
      //   let push_value = {
      //     id: addon_item_data[ele.child_index].addon_items_id,
      //     specification: [],
      //   }
      //   let push1 = {
      //     id: selected_child_item.id,
      //     mrp: ele.data,
      //   }
      //   push_value.specification.push(push1)
      //   save_value.push(push_value)
      // })
      data.map((ele, index) => {
        let push_value = {
          id: addon_item_data[ele.child_index].addon_items_id,
          addon_group: [],
        }
        let push1 = {
          id: props_data.id,
          specification: [
            {
              id: selected_child_item.id,
              mrp: ele.data,
            },
          ],
        }
        push_value.addon_group.push(push1)
        save_value.push(push_value)
      })
      console.log('save_value dependent :', save_value)
    }
    console.log('selected_child_item : ', selected_child_item)
    console.log('save_value : ', save_value)

    let pass_data = {
      get: {},
      post: {
        addons: save_value,
        return: 'addon_group',
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/add_addon_pricing', pass_data)
    console.log('/product/add_addon_pricing response :', response)
    SetIsSave(false)

    if (response.status_code == 201) {
      if (response.response.hasOwnProperty('addon')) {
        SetPropsData(response.response.addon[0])
        SetIsEdit(false)
        SetIsSave(false)
        SetSaveData([])
        SetSaveDataDependent([])
      }
    }
  }

  async function get_specifiaction_price(e) {
    console.log('get_specifiaction_price :', e)
    let grpup_data = props_data

    //   let addon_value = [props_data.id];
    //   let pass_data = {
    //     get: {},
    //     post: {
    //       addon_group: {
    //         addon: addon_value,
    //         specification: [e.id],
    //       },
    //     },
    //   };
    //   // if (context.app_data.app_from == "seller") {
    //   if (id_value.hasOwnProperty("brand_id")) {
    //     pass_data.get.brand = id_value.brand_id;
    //   } else {
    //     pass_data.get.m_id = id_value.m_id;
    //   }
    //   console.log("pass_data :", pass_data);
    //   let response = await api("/product/get_addon_group", pass_data);
    //   console.log("/product/get_addon_group response  :", response);
  }

  async function get_addon_with_spec(spec_data: any) {
    let pass_data = {
      get: {
        page: 1,
        spec: encodeURI(JSON.stringify([spec_data.id])),
        addon_groups: encodeURI(JSON.stringify([props_data.id])),
        mrp: encodeURI(JSON.stringify([2,4])),
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/get_all_addon_groups', pass_data)
    console.log('/product/get_all_addon_groups response :', response)
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty('addon')) {
        console.log('setpropsdata : ', response.response.addon[0])
        SetPropsData(response.response.addon[0])
      }
    }
  }

  return (
    <div
      className="modal"
      id="AddonGroupUpdatePriceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* modal header */}
          <div className="modal-header">
            <div className="d-flex align-items-center">
              {/* <i
                className="fas fa-arrow-left cursor me-3"
                onClick={() => {}}
              ></i> */}
              <h6 className="mb-0  modal-title" id="ModalLabel">
                {props_data.name} Pricing
              </h6>
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* modal body */}
          <div className="modal-body">
            <div className="">
              {view_type == 'main' ? (
                <div>
                  <div className="row align-items-center mb-3">
                    {/* <h5 className="col-md-2">{props_data.name}</h5> */}
                    {/* {is_edit ? ( */}
                    <div className="col-8 mb-2">
                      <label className="form-label mb-0 d-flex align-items-center cursor">
                        <span className="pe-2">Is Dependent</span>
                        <Switch
                          id_value={'is_dependent'}
                          is_disabled={is_edit}
                          isOn={is_dependent}
                          onColor="#0053ad"
                          handleToggle={(e) => {
                            console.log('e :', e.target.checked)
                            SetIsDependent((prevValue) => {
                              prevValue = !prevValue
                              return prevValue
                            })
                            if (e.target.checked) {
                              get_specifications()
                            }
                            SetSelectedSpecification({})
                            SetIsEdit(false)
                            SetIsSave(false)
                          }}
                        />
                      </label>
                    </div>

                    <div className="col-4 end mb-2">
                      {!is_edit ? (
                        <button
                          className="btn btn-primary radius btn-sm"
                          onClick={() => {
                            SetIsEdit((prevValue: any) => {
                              prevValue = true
                              // if (prevValue) {
                              //   SetIsSave(false);
                              // } else {
                              //   SetIsSave(false);
                              // }
                              SetIsSave(false)
                              return prevValue
                            })
                          }}
                        >
                          <span className="pe-2">
                            <AntDesign name="edit" size={16} color="#fff" />
                          </span>
                          Edit
                          {/* {is_edit ? 'Cancel' : 'Edit'} */}
                        </button>
                      ) : null}
                    </div>
                    {/* ) : null} */}
                    {is_dependent ? (
                      <div className="col-md-6 col-lg-4 pt-3">
                        <label className="form-label">
                          Select Specification
                        </label>
                        <Select
                          components={animatedComponents}
                          placeholder="Select..."
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={specification_data}
                          value={selected_specification}
                          onChange={(e) => {
                            console.log(' selected_specification :', e)
                            SetSelectedSpecification(e)
                            get_addon_with_spec(e)
                            e.children.map((ele: any, index: any) => {
                              if (index == 0) {
                                SetSelectedChildItem((prevValue: any)=>{
                                  prevValue = ele
                                  return prevValue
                                })
                              }
                            })
                            SetIsEdit(false)
                            SetIsSave(false)
                          }}
                          isDisabled={is_edit}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {!is_dependent ? (
                    <div className="mt-2">
                      {props_data.children.map(
                        (child_ele: any, child_index) => (
                          <div className="my-2" key={child_index}>
                            <OwnAccordion
                              field_data={child_ele}
                              edit_data={is_edit}
                              is_dependent={is_dependent}
                              selected_specification={selected_specification}
                              selected_child_item={selected_child_item}
                              is_save={is_save}
                              set_save_value={(data) => {
                                console.log('On save data not dependent:', data)
                                SetSaveData((prevValue) => {
                                  let push_data = {
                                    child_index: child_index,
                                    data: data,
                                  }
                                  prevValue.push(push_data)
                                  if (
                                    props_data.children.length - 1 ==
                                    child_index
                                  ) {
                                    final_save(prevValue)
                                  }
                                  return [...prevValue]
                                })
                              }}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <div className="mt-3 px-2">
                      <nav className=" scroll-nav">
                        <div
                          className="nav nav-pills"
                          id="nav-tab"
                          role="tablist"
                        >
                          {Object.keys(selected_specification).length > 0
                            ? selected_specification.children.map(
                                (child_ele: any, child_index) => (

                                  !is_edit ? (
                                    <button
                                      className={
                                        child_ele.id == selected_child_item.id
                                          ? 'nav-link active'
                                          : 'nav-link'
                                      }
                                      id={'nav-' + child_index + '-tab'}
                                      data-bs-toggle="tab"
                                      data-bs-target={'#nav-' + child_index}
                                      type="button"
                                      role="tab"
                                      aria-controls={'nav-' + child_index}
                                      aria-selected="true"
                                      key={child_index}
                                      onClick={() => {
                                        if(!is_edit){
                                          console.log('is_edit is false')
                                          SetSelectedChildItem(child_ele)
                                          SetIsEdit(false)
                                          SetIsSave(false)
                                          SetSaveData([])
                                          SetSaveDataDependent([])
                                        }else{
                                          console.log('is_edit is true')
                                        }
                                    
                                      }}
                                    >
                                      {child_ele.label}
                                    </button>
                                  ) : (
                                    child_ele.id == selected_child_item.id ? (
                                      <button
                                        className={
                                          child_ele.id == selected_child_item.id
                                            ? 'nav-link active'
                                            : 'nav-link'
                                        }
                                        id={'nav-' + child_index + '-tab'}
                                        data-bs-toggle="tab"
                                        data-bs-target={'#nav-' + child_index}
                                        type="button"
                                        role="tab"
                                        aria-controls={'nav-' + child_index}
                                        aria-selected="true"
                                        key={child_index}
                                        onClick={() => {
                                          if(!is_edit){
                                            console.log('is_edit is false')
                                            SetSelectedChildItem(child_ele)
                                            SetIsEdit(false)
                                            SetIsSave(false)
                                            SetSaveData([])
                                            SetSaveDataDependent([])
                                          }else{
                                            console.log('is_edit is true')
                                          }
                                      
                                        }}
                                      >
                                        {child_ele.label}
                                      </button>
                                    ) : null
                                  )
                                  
                                ),
                              )
                            : null}
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        {Object.keys(selected_specification).length > 0 ? (
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            {props_data.children.map(
                              (child_ele: any, child_index) => (
                                <div className="my-3" key={child_index}>
                                  <OwnAccordion
                                    field_data={child_ele}
                                    edit_data={is_edit}
                                    is_dependent={is_dependent}
                                    selected_specification={
                                      selected_specification
                                    }
                                    selected_child_item={selected_child_item}
                                    is_save={is_save}
                                    set_save_value={(data) => {
                                      console.log(
                                        'On save data on dependent:',
                                        data,
                                      )
                                      // SetSaveDataDependent((prevValue) => {
                                      //   let push_data = {};
                                      //   if (prevValue.length > 0) {
                                      //     prevValue.map(
                                      //       (ele: any, ele_index: any) => {
                                      //         if (
                                      //           ele.child_index != child_index
                                      //         ) {
                                      //           push_data = {
                                      //             child_index: child_index,
                                      //             data: data,
                                      //           };
                                      //           prevValue.push(push_data);
                                      //         } else {
                                      //           data.map((ele1) => {
                                      //             prevValue[
                                      //               ele_index
                                      //             ].data.push(ele1);
                                      //           });
                                      //         }
                                      //       }
                                      //     );
                                      //   } else {
                                      //     push_data = {
                                      //       child_index: child_index,
                                      //       data: data,
                                      //     };
                                      //     prevValue.push(push_data);
                                      //   }

                                      //   console.log("prevValue :", prevValue);

                                      //   if (
                                      //     props_data.children.length - 1 ==
                                      //     child_index
                                      //   ) {
                                      //     final_save(prevValue);
                                      //   }
                                      //   return [...prevValue];
                                      // });

                                      SetSaveDataDependent((prevValue) => {
                                        let push_data = {
                                          child_index: child_index,
                                          data: data,
                                        }
                                        prevValue.push(push_data)
                                        if (
                                          props_data.children.length - 1 ==
                                          child_index
                                        ) {
                                          final_save(prevValue)
                                        }
                                        return [...prevValue]
                                      })
                                    }}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {/* modal footer */}
          <div className="modal-footer shadow ">
            {is_edit ? (
              <div className="d-flex align-items-center w-100">
                <button
                  type="button"
                  className="btn btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    SetIsEdit((prevValue: any) => {
                      prevValue = false
                      SetIsSave(false)
                      return prevValue
                    })
                  }}
                >
                  Cancel
                </button>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-primary btn-radius px-3 "
                    onClick={() => {
                      // let item = close_data;
                      // item.action = "close";
                      // SetCloseData(item);
                      // myModal.hide();
                      if (props.from == 'addon_group') {
                        // update_price();
                        SetIsSave(true)
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius px-3"
                onClick={() => {
                  let item = close_data
                  item.action = 'close'
                  SetCloseData(item)
                  SetIsSave(false)
                  myModal.hide()
                }}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
