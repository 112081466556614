import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";

export default function Home(props: any) {
  const context = useContext(DataContext);

  const [account_data, SetAccountData] = useState([]);

  useEffect(() => {
    console.log("Home On mount :", props);
    console.log("Home On mount context :", context);
    // window.location.href = "https://sellers.1superapp.com/"
  }, []);

  return (
    <div className="row">
      <div className="col-6">
        <Link to="/manufacturer/43/">
          <button type="button" className="btn btn-primary">
            Manufacturer
          </button>
        </Link>

        <Link to="/seller/2/">
          <button type="button" className="btn btn-primary">
            Seller
          </button>
        </Link>
      </div>
    </div>
    // <div className="container">
    //   <div className="row">
    //     <div className="col-md-4 mb-3 text-center">
    //       <div className="card p-3">
    //         <p className="text-dark fw-bold">Manufacturer</p>
    //         <div className="container">
    //           <img src={require("../../assets/img/svg/factory.svg")} className="main-select" />
    //         </div>
    //         <div className="mt-3">
    //           <Link to="/manufacturer/43/">
    //             <button type="button" className="btn btn-primary">
    //               Login
    //             </button>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="col-md-4 mb-3 text-center">
    //       <div className="card p-3">
    //         <p className="text-dark fw-bold">Seller</p>
    //         <div className="container">
    //           <img src={require("../../assets/img/svg/seller.svg")} className="main-select" />
    //         </div>
    //         <div className="mt-3">
    //           <Link to="/seller/12/">
    //             <button type="button" className="btn btn-primary">
    //               Login
    //             </button>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
