import React, { useState, useEffect, useContext, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DataContext from "../../utils/Context/DataContext";
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";

let myModal: any = {};

const initial_value = {
  name: "",
};

export default function ModalCategory(props: any) {
  const context = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());
  const [save_data, SetSaveData] = useState(initial_value);
  const [category_data, SetCategoryData] = useState([]);
  const [selected_category, SetSelectedCategory]: any = useState({});
  const [selected_category_group, SetSelectedCategoryGroup] = useState([]);
  const [search_data, SetSearchData] = useState("");
  const [filter_data, SetFilterData] = useState([]);
  const [initial_render, SetInitialRender] = useState(true);
  const [sector_data, SetSectorData] = useState([]);
  const [lines, SetLines]: any = useState([{ index: "", id: "" }]);
  const [c_data, SetCData]: any = useState([]);
  const [org_c_data,SetOrgCData] : any = useState([])
  const [selected_sector, SetSelectedScetor] = useState({ id: 0, name: "All" });

  useEffect(() => {
    if (props.open) {
      console.log("ModalCategory On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("CategoryModal"),
        {}
      );
      get_category();
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    console.log("ModalCategory On mount :", props);
    let myModal1: any = document.getElementById("CategoryModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      SetSelectedCategory({});
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (initial_render) {
      SetInitialRender(false);
    } else {
      if (search_data == "") {
        SetFilterData(category_data);
        // SetCData([category_data]);
        SetSelectedScetor((prevalue)=>{
          if(prevalue.id == 0){
            SetCData([org_c_data])
          }else{
            let push_data : any = []
            org_c_data.map((ele:any)=>{
              if(ele.sector_name == prevalue.name){
                push_data.push(ele);
              }
            })
            SetCData([push_data])
          }
          return prevalue;
        })
        SetLines([{ index: "", id: "" }]);
      } else {
        const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [search_data]);

  function triggerItemChange(value: any) {
    console.log("triggerItemChange value :", value);
    const str2 = value.charAt(0).toUpperCase() + value.slice(1);
    const find = ({ name, children }: any) =>
      name.includes(str2) || (children && children.some(find));
    let searchResults : any;
    SetLines([{ index: "", id: "" }]);
    SetSelectedScetor((prevalue)=>{
      if(prevalue.id == 0){
        // SetCData([org_c_data])
        searchResults = org_c_data.filter(find)
      }else{
        let push_data : any = []
        org_c_data.map((ele:any)=>{
          if(ele.sector_name == prevalue.name){
            push_data.push(ele);
          }
        })
        // SetCData([push_data])
        searchResults = push_data.filter(find)
      }
      return prevalue;
    })
    SetCData([searchResults]);
    // let searchResults = filter_data.filter(find);
    // SetLines([{ index: "", id: "" }]);
    // SetCData([searchResults]);
    console.log("searchResults :", searchResults);

    // const res = recursivelyFindKeyValue("name", str2, filter_data);
    // console.log("res :", res);
  }

  function recursivelyFindKeyValue(key: any, keyValue: any, list: any) {
    console.log("Searching list: ", list);
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      // for (const key of Object.keys(item)) {
      //check if its array of more options, search it
      if (Array.isArray(item[key])) {
        console.log("child array found, searching", item);
        const res: any = recursivelyFindKeyValue(key, keyValue, item[key]);
        if (res.found === true) return res;
      }
      //Test the keyValue
      else if (item[key] == keyValue) {
        //found, return the list
        console.log("found ", keyValue);
        return { found: true, containingArray: list };
      }
      // }
    }
    return { found: false, containingArray: [] };
  }

  async function get_category() {
    let pass_data: any = {
      get: {},
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data: any = await api("/product/get_all_categories", pass_data);
    console.log("/product/get_all_categories :", res_data);
    if (res_data.response.hasOwnProperty("data")) {
      SetCategoryData(res_data.response.data);
      SetFilterData(res_data.response.data);
      SetCData((prevalue: any) => {
        prevalue.push(res_data.response.data);
        return [...prevalue];
      });
      SetOrgCData(res_data.response.data)
    }
    if (res_data.response.hasOwnProperty("sectors")) {
      let s_data = res_data.response.sectors;
      let push_data: any = [{ id: 0, name: "All" }];
      s_data.map((ele: any) => {
        push_data.push(ele);
      });
      SetSectorData(push_data);
    }
  }

  // function ColumnNode(props: any) {
  //   const [lines, setLines] = useState([{ index: "", id: "" }]);
  //   const [c_data, set_c_data] = useState([props.data]);
  //   const [initial_render, SetInitialRender] = useState(true);

  //   useEffect(() => {
  //     console.log("ColumnNode props : ", props);
  //     // if (initial_render) {
  //     //   SetInitialRender(false);
  //     // } else {
  //     //   set_c_data([props.data]);
  //     // }
  //     // set_c_data([props.data]);
  //   }, [props.data]);

  //   return lines.map((m, index) => (
  //     <ColumnChild
  //       key={index}
  //       col_index={index}
  //       data={c_data[index]}
  //       pstate={{ lines, setLines }}
  //       cstate={{ c_data, set_c_data }}
  //       onChildSelect={(data: any) => {
  //         props.onSelect(data);
  //       }}
  //     />
  //   ));
  // }

  // function ColumnChild(props: any) {
  //   const { lines, setLines } = props.pstate;
  //   const { c_data, set_c_data } = props.cstate;
  //   const count = props.col_index + 1;
  //   const [active, SetActive] = useState(null);

  //   function capitalizeFirstLetter(string: any) {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  //   }

  //   useEffect(() => {
  //     console.log("ColumnChild props : ", props);
  //   }, [props]);

  //   return (
  //     <div className="scroll-row border-right">
  //       {console.log("props.data in html :", props.data)}
  //       {props.data.map((element: any, index: any) => (
  //         <div key={index}>
  //           <div
  //             className="catbut "
  //             onClick={(event) => {
  //               event.preventDefault();
  //               props.onChildSelect(element);
  //               console.log("event :", event);
  //               console.log("element :", element);
  //               console.log("count :", count);
  //               console.log("lines :", lines);
  //               console.log("c_data :", c_data);
  //               if (element.hasOwnProperty("children")) {
  //                 console.log("1");
  //                 if (count < lines.length) {
  //                   console.log("2");
  //                   let line_data = {
  //                     index: index,
  //                     id: element.id,
  //                     name: element.name,
  //                   };
  //                   let c_value_data = element.children;
  //                   let data_a = [...lines];
  //                   let data_b = [...c_data];
  //                   console.log("data_a :", data_a);
  //                   console.log("data_b :", data_b);
  //                   console.log("line_data :", line_data);
  //                   console.log("c_value_data :", c_value_data);
  //                   data_a.splice(count);
  //                   data_b.splice(count);
  //                   data_a.push(line_data);
  //                   data_b.push(c_value_data);
  //                   setLines(data_a);
  //                   set_c_data(data_b);
  //                 } else if (count == lines.length) {
  //                   console.log("3");
  //                   // setLines((prevalue) => {
  //                   //   prevalue.push({
  //                   //     index: index,
  //                   //     id: element.id,
  //                   //     name: element.name,
  //                   //   });
  //                   //   console.log("setLines prevalue :", prevalue);
  //                   //   return [...prevalue];
  //                   // });
  //                   // set_c_data((prevalue) => {
  //                   //   prevalue.push(element.children);
  //                   //   console.log("set_c_data prevalue :", prevalue);
  //                   //   return [...prevalue];
  //                   // });

  //                   setLines([
  //                     ...lines,
  //                     { index: index, id: element.id, name: element.name },
  //                   ]);
  //                   set_c_data([...c_data, element.children]);
  //                 }
  //                 SetActive(element.id);
  //               } else {
  //                 console.log("4");
  //                 if (count < lines.length) {
  //                   let data_a = [...lines];
  //                   let data_b = [...c_data];
  //                   data_a.splice(count);
  //                   data_b.splice(count);
  //                   setLines(data_a);
  //                   set_c_data(data_b);
  //                 }
  //                 SetActive(element.id);
  //               }

  //               console.log("clicked c_data :", c_data);
  //               console.log("clicked element :", element);
  //               console.log("clicked lines :", lines);
  //             }}
  //           >
  //             <div
  //               className={
  //                 element.id == active
  //                   ? "cursor px-2 py-3 active_cat"
  //                   : "cursor px-2 py-3 cat_hover"
  //               }
  //             >
  //               <div className="row align-items-center">
  //                 <div className="col-2 pe-0">
  //                   {element.id == active ? (
  //                     <img
  //                       src={require("../../assets/icon/menu.png")}
  //                       className="cat_img"
  //                     />
  //                   ) : (
  //                     <img
  //                       src={require("../../assets/icon/menu1.png")}
  //                       className="cat_img"
  //                     />
  //                   )}
  //                 </div>
  //                 <p className="col-8 mb-0 ps-2 ">
  //                   {capitalizeFirstLetter(element.name)}
  //                 </p>
  //                 <span className="col-2 ps-0 ms-auto">
  //                   {element.hasOwnProperty("children") ? (
  //                     <MaterialIcons
  //                       name="arrow-forward-ios"
  //                       size={11}
  //                       color={element.id == active ? "#000" : "#777"}
  //                     />
  //                   ) : null}
  //                 </span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  const ParentNode = (props: any) => {
    const [active, SetActive] = useState(null);

    // function capitalizeFirstLetter(string: any) {
    //   if (string != undefined) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    //   } else {
    //     return "";
    //   }
    // }

    return lines.map((m: any, index: any) => (
      <div className="scroll-row border-right">
        {c_data[index]?.map((element: any, c_index: any) => (
          <div key={c_index}>
            <div
              className="catbut "
              onClick={(event) => {
                let send_data = {
                  element: element,
                  c_index: c_index,
                  l_index: index,
                };
                props.onSelect(send_data);
                SetActive(element.id);
              }}
            >
              <div
                className={
                  element.id == active
                    ? "cursor px-2 py-3 active_cat"
                    : "cursor px-2 py-3 cat_hover"
                }
              >
                <div className="row align-items-center">
                  <div className="col-2 pe-0">
                    {element.id == active ? (
                      <img
                        src={require("../../assets/icon/menu.png")}
                        className="cat_img"
                      />
                    ) : (
                      <img
                        src={require("../../assets/icon/menu1.png")}
                        className="cat_img"
                      />
                    )}
                  </div>
                  <div className="d-flex col-8">
                    {/* <p className=" mb-0 ps-2 col-10">
                      {capitalizeFirstLetter(element?.name)}
                    </p> */}
                    <span className=" mb-0 ps-2 col-10">
                      {capitalizeFirstLetter(element?.name)}
                    </span>
                    <span className="col-2 ps-0 ms-auto">
                      {element.hasOwnProperty("children") ? (
                        <MaterialIcons
                          name="arrow-forward-ios"
                          size={11}
                          color={element.id == active ? "#000" : "#777"}
                        />
                      ) : null}
                    </span>
                  </div>

                  {/* <span>{element?.sector_name}</span> */}
                  <p>{element?.sector_name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const ChildNode = (props: any) => {
    const [active, SetActive] = useState(null);
    return (
      <div className="">
        {props.child.map((c_ele: any) => (
          <div className="cat-child">
            {/* scroll-row border-right */}
            <div
              className="catbut d-flex"
              onClick={() => {
                SetSelectedCategory(c_ele);
                SetActive(c_ele.id);
                if (search_data == "") {
                  SetSelectedCategoryGroup((prevValue: any) => {
                    console.log("c_ele :", c_ele);
                    console.log("prevValue :", prevValue);
                    if (c_ele.parent_id == 0) {
                      prevValue = [c_ele];
                      return [...prevValue];
                    }
                    if (c_ele.parent_id != 0) {
                      let pre_length = prevValue.length;
                      console.log("pre_length :", pre_length);
                      if (prevValue[pre_length - 1].id == c_ele.parent_id) {
                        prevValue.push(c_ele);
                        return [...prevValue];
                      } else {
                        let copy_value = prevValue;
                        copy_value.map((ele: any, index: any) => {
                          if (c_ele.parent_id == ele.id) {
                            prevValue.splice(index + 1);
                            prevValue.push(c_ele);
                          }
                        });
                        console.log("latest prevValue :", prevValue);
                        return [...prevValue];
                      }
                    }
                  });
                } else {
                  // SetSelectedCategoryGroup(c_ele);
                  SetSelectedCategoryGroup((prevalue: any) => {
                    prevalue.push(c_ele);
                    return [...prevalue];
                  });
                }
              }}
            >
              {/* <div className="cursor px-2 py-3 cat_hover">
                <div className="row align-items-center">
                  <div className="col-2 pe-0">
                    <img
                      src={require("../../assets/icon/menu.png")}
                      className="cat_img"
                    />
                  </div>
                  <p className="col-8 mb-0 ps-2 ">
                    {capitalizeFirstLetter(c_ele?.name)}
                  </p>
                  <span className="col-2 ps-0 ms-auto">
                    {c_ele.hasOwnProperty("children") ? (
                      <MaterialIcons
                        name="arrow-forward-ios"
                        size={11}
                        color={"#000"}
                      />
                    ) : null}
                  </span>
                </div>
              </div> */}
              <div
                className={
                  c_ele.id == active
                    ? "cursor px-2 py-3 active_cat"
                    : "cursor px-2 py-3 cat_hover"
                }
              >
                <div className="row align-items-center">
                  <div className="col-2 pe-0">
                    {c_ele.id == active ? (
                      <img
                        src={require("../../assets/icon/menu.png")}
                        className="cat_img"
                      />
                    ) : (
                      <img
                        src={require("../../assets/icon/menu1.png")}
                        className="cat_img"
                      />
                    )}
                  </div>
                  <div className="d-flex col-8">
                    <span className=" mb-0 ps-2 col-10">
                      {capitalizeFirstLetter(c_ele?.name)}
                    </span>
                    <span className="col-2 ps-0 ms-auto">
                      {c_ele.hasOwnProperty("children") ? (
                        <MaterialIcons
                          name="arrow-forward-ios"
                          size={11}
                          color={c_ele.id == active ? "#000" : "#777"}
                        />
                      ) : null}
                    </span>
                  </div>

                  <p>{c_ele?.sector_name}</p>
                </div>
              </div>
            </div>
            {props.child.hasOwnProperty("children") ? (
              <ChildNode child={props.child.children} level={props.level + 1} />
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  function capitalizeFirstLetter(string: any) {
    if (string != undefined) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  }

  return (
    <div
      className="modal"
      id="CategoryModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg  modal-dialog-centered">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Select Category
            </h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="d-flex p-3 align-items-center">
            <p className="pe-2 mb-0">Selected Category</p>
            <FontAwesome5 name="angle-double-right" size={11} color="gray" />
            {selected_category.name != "" ? (
              <p className="text-dark fw-bold mb-0 text-capitalize ps-2">
                {selected_category.name}
              </p>
            ) : null}
          </div>
          <div className="col-12 m-2">
            <input
              type="text"
              className="p-2"
              value={search_data}
              placeholder="Search"
              onChange={(e) => {
                SetSearchData(e.target.value);
              }}
            />
          </div>

          {/* body */}
          <div className="modal-body p-2 border-top">
            <div className="">
              <ul className="nav justify-content-center">
                {sector_data.map((s_ele: any, s_index: any) => (
                  <li
                    className="nav-item px-2 py-2 cursor"
                    onClick={() => {
                      SetSelectedScetor(s_ele);
                      SetSearchData("");
                      if(s_ele.id == 0){
                        SetCData([org_c_data])
                      }else{
                        let push_data : any = []
                        org_c_data.map((ele:any)=>{
                          if(ele.sector_name == s_ele.name){
                            push_data.push(ele);
                          }
                        })
                        console.log("s_ele :",s_ele)
                        console.log("org_c_data :",org_c_data)
                        console.log("push_data :",push_data)
                        SetCData([push_data])
                      }
                    }}
                  >
                    <p
                      className={
                        selected_sector.id == s_ele.id ? "catbut" : ""
                      }
                    >
                      {s_ele.name}
                    </p>
                  </li>
                  // <li className="nav-item">
                  //   <a className="nav-link" href="#">
                  //     Link
                  //   </a>
                  // </li>
                  // <li className="nav-item">
                  //   <a className="nav-link" href="#">
                  //     Link
                  //   </a>
                  // </li>
                  // <li className="nav-item">
                  //   <a className="nav-link disabled" href="#" aria-disabled="true">
                  //     Disabled
                  //   </a>
                  // </li>
                ))}
              </ul>
            </div>

            <div className="scroll-x">
              <div className="scroll-flex mt-1">
                {filter_data.length > 0 ? (
                  // <ColumnNode
                  //   data={filter_data}
                  //   onSelect={(data: any) => {
                  //     console.log("category on select :", data);
                  //     SetSelectedCategory(data);
                  //     SetSelectedCategoryGroup((prevValue) => {
                  //       if (data.parent_id == 0) {
                  //         prevValue = [data];
                  //         return [...prevValue];
                  //       }
                  //       if (data.parent_id != 0) {
                  //         let pre_length = prevValue.length;
                  //         if (prevValue[pre_length - 1].id == data.parent_id) {
                  //           prevValue.push(data);
                  //           return [...prevValue];
                  //         } else {
                  //           let copy_value = prevValue;
                  //           copy_value.map((ele: any, index: any) => {
                  //             if (data.parent_id == ele.id) {
                  //               prevValue.splice(index + 1);
                  //               prevValue.push(data);
                  //             }
                  //           });
                  //           console.log("latest prevValue :", prevValue);
                  //           return [...prevValue];
                  //         }
                  //       }
                  //     });
                  //   }}
                  // />
                  search_data == "" ? (
                    <ParentNode
                      // data={filter_data}
                      onSelect={(data: any) => {
                        console.log("category on select :", data);
                        SetSelectedCategory(data.element);
                        SetSelectedCategoryGroup((prevValue: any) => {
                          if (data.element.parent_id == 0) {
                            prevValue = [data.element];
                            // return [...prevValue];
                          }
                          if (data.element.parent_id != 0) {
                            let pre_length = prevValue.length;
                            if (
                              prevValue[pre_length - 1].id ==
                              data.element.parent_id
                            ) {
                              prevValue.push(data.element);
                              // return [...prevValue];
                            } else {
                              let copy_value = prevValue;
                              copy_value.map((ele: any, index: any) => {
                                if (data.element.parent_id == ele.id) {
                                  prevValue.splice(index + 1);
                                  prevValue.push(data.element);
                                }
                              });
                              console.log("latest prevValue :", prevValue);
                              // return [...prevValue];
                            }
                          }
                          console.log(
                            "SetSelectedCategoryGroup prevValue :",
                            prevValue
                          );
                          return [...prevValue];
                        });
                        let count = data.l_index + 1;
                        if (data.element.hasOwnProperty("children")) {
                          console.log("1");
                          if (count < lines.length) {
                            console.log("2");
                            let line_data = {
                              index: data.c_index,
                              id: data.element.id,
                              name: data.element.name,
                            };
                            let c_value_data = data.element.children;
                            let data_a = [...lines];
                            let data_b = [...c_data];
                            data_a.splice(count);
                            data_b.splice(count);
                            data_a.push(line_data);
                            data_b.push(c_value_data);
                            SetLines(data_a);
                            SetCData(data_b);
                          } else if (count == lines.length) {
                            console.log("3");
                            SetLines((prevalue: any) => {
                              prevalue.push({
                                index: data.c_index,
                                id: data.element.id,
                                name: data.element.name,
                              });
                              return [...prevalue];
                            });
                            SetCData((prevalue: any) => {
                              prevalue.push(data.element.children);
                              return [...prevalue];
                            });
                          }
                        } else {
                          console.log("4");
                          if (count < lines.length) {
                            let data_a = [...lines];
                            let data_b = [...c_data];
                            data_a.splice(count);
                            data_b.splice(count);
                            SetLines(data_a);
                            SetCData(data_b);
                          }
                        }
                      }}
                    />
                  ) : (
                    <div className="mb-5">
                      {c_data[0].map((c_ele: any, c_index: any) => (
                        <div className="d-flex border-bottom">
                          <div className="cat-child">
                            <div
                              className="catbut d-flex"
                              onClick={() => {
                                SetSelectedCategory(c_ele);
                                if (search_data == "") {
                                  SetSelectedCategoryGroup((prevValue: any) => {
                                    if (c_ele.parent_id == 0) {
                                      prevValue = [c_ele];
                                      return [...prevValue];
                                    }
                                    if (c_ele.parent_id != 0) {
                                      let pre_length = prevValue.length;
                                      if (
                                        prevValue[pre_length - 1].id ==
                                        c_ele.parent_id
                                      ) {
                                        prevValue.push(c_ele);
                                        return [...prevValue];
                                      } else {
                                        let copy_value = prevValue;
                                        copy_value.map(
                                          (ele: any, index: any) => {
                                            if (c_ele.parent_id == ele.id) {
                                              prevValue.splice(index + 1);
                                              prevValue.push(c_ele);
                                            }
                                          }
                                        );
                                        console.log(
                                          "latest prevValue :",
                                          prevValue
                                        );
                                        return [...prevValue];
                                      }
                                    }
                                  });
                                } else {
                                  SetSelectedCategoryGroup((prevalue: any) => {
                                    prevalue.push(c_ele);
                                    return [...prevalue];
                                  });
                                }
                              }}
                            >
                              {/* <div className="cursor px-2 py-3 cat_hover">
                                <div className="row align-items-center">
                                  <div className="col-2 pe-0">
                                    <img
                                      src={require("../../assets/icon/menu.png")}
                                      className="cat_img"
                                    />
                                  </div>
                                  <p className="col-8 mb-0 ps-2 ">
                                    {capitalizeFirstLetter(c_ele?.name)}
                                  </p>
                                  <span className="col-2 ps-0 ms-auto">
                                    {c_ele.hasOwnProperty("children") ? (
                                      <MaterialIcons
                                        name="arrow-forward-ios"
                                        size={11}
                                        color={"#000"}
                                      />
                                    ) : null}
                                  </span>
                                </div>
                              </div> */}
                              <div
                                className="cursor px-2 py-3 cat_hover"
                                // className={
                                //   c_ele.id == active
                                //     ? "cursor px-2 py-3 active_cat"
                                //     : "cursor px-2 py-3 cat_hover"
                                // }
                              >
                                <div className="row align-items-center">
                                  <div className="col-2 pe-0">
                                    {/* {c_ele.id == active ? (
                                      <img
                                        src={require("../../assets/icon/menu.png")}
                                        className="cat_img"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../assets/icon/menu1.png")}
                                        className="cat_img"
                                      />
                                    )} */}
                                    <img
                                      src={require("../../assets/icon/menu.png")}
                                      className="cat_img"
                                    />
                                  </div>
                                  <div className="d-flex col-8">
                                    <span className=" mb-0 ps-2 col-10">
                                      {capitalizeFirstLetter(c_ele?.name)}
                                    </span>
                                    <span className="col-2 ps-0 ms-auto">
                                      {c_ele.hasOwnProperty("children") ? (
                                        <MaterialIcons
                                          name="arrow-forward-ios"
                                          size={11}
                                          color={"#000"}
                                        />
                                      ) : null}
                                    </span>
                                  </div>

                                  <p>{c_ele?.sector_name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {c_ele.hasOwnProperty("children") ? (
                            <ChildNode child={c_ele.children} level={0} />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-4 btn-sm"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary btn-radius px-4 btn-sm"
                onClick={() => {
                  console.log("Ok");
                  console.log("selected group :", selected_category_group);
                  console.log("selected_category :", selected_category);
                  let item: any = close_data;
                  item.action = "save";
                  item.value = selected_category;
                  item.group_value = selected_category_group;
                  SetCloseData(item);
                  myModal.hide();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
