import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import Select from 'react-select'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import { Feather } from '@expo/vector-icons'

import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
  Ionicons,
} from '@expo/vector-icons'
import { SignalCellularNullSharp } from '@material-ui/icons'

let myModal = {}

const initial_value = {
  category: {},
  name: '',
}

function ColumnNode(props) {
  const [lines, setLines] = useState([{ index: '', id: '' }])
  const [c_data, set_c_data] = useState([props.data])
  console.log('props :', props)
  console.log('ColumnNode lines :', lines)
  console.log('ColumnNode c_data :', c_data)

  return lines.map((m, index) => (
    // <div>
    //   <p>hai</p>
    <ColumnChild
      key={index}
      col_index={index}
      data={c_data[index]}
      pstate={{ lines, setLines }}
      cstate={{ c_data, set_c_data }}
      onChildSelect={(data) => {
        props.onSelect(data)
      }}
    />
    // </div>
  ))
}

function ColumnChild(props) {
  const { lines, setLines } = props.pstate
  const { c_data, set_c_data } = props.cstate
  const [active, SetActive] = useState(null)
  const count = props.col_index + 1
  return (
    <div className="scroll-row border-right">
      {props.data.map((element, index) => (
        <div key={index}>
          <div
            className="catbut"
            onClick={(event) => {
              event.preventDefault()
              props.onChildSelect(element)

              if (element.hasOwnProperty('children')) {
                console.log('Have Child')
                console.log('count :', count)
                console.log('lines length :', lines.length)
                if (count < lines.length) {
                  console.log('<')
                  let line_data = {
                    index: index,
                    id: element.id,
                    name: element.name,
                  }
                  let c_value_data = element.children
                  let data_a = [...lines]
                  let data_b = [...c_data]
                  data_a.splice(count)
                  data_b.splice(count)
                  data_a.push(line_data)
                  data_b.push(c_value_data)
                  setLines(data_a)
                  set_c_data(data_b)
                } else if (count == lines.length) {
                  console.log('==')
                  setLines([
                    ...lines,
                    { index: index, id: element.id, name: element.name },
                  ])
                  set_c_data([...c_data, element.children])
                  SetActive(element.id)
                }
              } else {
                console.log("Don't Have Child")
                if (count < lines.length) {
                  console.log('remove')
                  let data_a = [...lines]
                  let data_b = [...c_data]
                  data_a.splice(count)
                  data_b.splice(count)
                  setLines(data_a)
                  set_c_data(data_b)
                }
                SetActive(element.id)
              }
            }}
          >
            <div className="p-2 border-bottom  cursor">
              <div className="row align-center w-100">
                <p
                  className={
                    element.id == active
                      ? 'col-11 mb-0 text-primary fw-bold'
                      : 'col-11 mb-0'
                  }
                >
                  {element.name}
                </p>
                <span className="col-1 p-0 ms-auto">
                  {element.hasOwnProperty('children') ? (
                    <MaterialIcons
                      name="arrow-forward-ios"
                      size={11}
                      color={element.id == active ? '#297ad0' : '#777'}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default function ModalCreateSpecification(props: any) {
  const [id_value, SetIdValue] = useState(useParams())
  const [save_data, SetSaveData] = useState(initial_value)
  const [specification_type_option, SetSpecificationTypeOptions] = useState([])
  const [selected_sepc_type, SetSelectedSpecType] = useState({})
  const [show_add_child, SetShowAddChild] = useState(false)
  const [child_name, SetChildName] = useState('')
  const [child_list, SetChildList] = useState([
    {
      id: '',
      name: '',
      error: '',
    },
  ])
  const [spec_template_data, SetSpecTemplateData] = useState([])
  const [selected_category, SetSelectedCategory] = useState({})
  const [error_data, SetErrorData] = useState('')
  const [selected_template, SetSelectedTemplate] = useState({})
  const [all_spec_data, SetAllSpecData] = useState([])
  const [timer, SetTimer] = useState(null)
  const [view_data, SetViewData] = useState('main')
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [modal_type, SetModalType] = useState('')
  const [read_only, SetReadOnly] = useState(false)
  const [category_data, SetCategoryData] = useState([])
  const context = useContext(DataContext)

  const WAIT_INTERVAL = 300

  // from add_product
  const [spec_data_list, SetSpecDataList] = useState([])

  //
  const [error_state, SetErrorState] = useState({})

  useEffect(() => {
    if (props.open) {
      console.log('SpecificationModal On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('SpecificationModal'),
        {},
      )
      myModal.show()
      SetModalType(props.type)
      SetAllSpecData(props.all_data)

      if (props.from == 'specification') {
        if (props.type == 'create') {
          get_category()
        }

        if (props.type == 'view') {
          SetReadOnly(true)
          process_data()
        }
      } else if (props.from == 'add_product') {
        console.log('From Add Product')
        // SetSpecificationTypeOptions(props.spec_type_options);
        SetSaveData((prevValue: any) => {
          prevValue.category = props.category
          return { ...prevValue }
        })
      }
      SetSpecificationTypeOptions(props.spec_type_options)
    }
  }, [props.open])

  useEffect(() => {
    console.log('SpecificationModal On mount :', props)
    let myModal1 = document.getElementById('SpecificationModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData({
        category: {},
        name: '',
      })
      SetReadOnly(false)
      SetViewData('main')
      SetChildList([])
      SetSelectedSpecType({})
      SetErrorState({})
      SetShowAddChild(false)
      props.close(close_data)
    })
  }, [])

  function process_data() {
    SetSaveData((prevValue) => {
      prevValue.name = props.data.name
      let category_data = {
        id: props.data.category_id,
        name: props.data.category_name,
      }
      prevValue.category = category_data
      return { ...prevValue }
    })

    SetSelectedSpecType({ label: props.data.type, value: props.data.type })
    if (props.data.type == 'Select' || props.data.type == 'Multi Select') {
      SetShowAddChild(true)
    } else {
      SetShowAddChild(false)
    }
    if (props.data.hasOwnProperty('children')) {
      SetChildList(props.data.children)
    } else {
      SetChildList([])
    }
  }

  async function get_category() {
    let pass_data = {
      get: {},
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data = await api('/product/get_all_categories', pass_data)
    console.log('/product/get_all_categories :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      SetCategoryData(res_data.response.data)
    }
  }

  async function get_template() {
    let pass_data = {
      get: {},
      post: {
        category_id: selected_category.id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data = await api('/product/get_specification_templates', pass_data)
    console.log('/product/get_specification_templates :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      SetSpecTemplateData(res_data.response.data)
    }
  }

  function process_selected_template(temp_data: any) {
    SetSaveData((prevValue) => {
      prevValue.name = temp_data.name
      return { ...prevValue }
    })
    let spec_type = {
      label: temp_data.type,
      value: temp_data.type,
    }
    SetSelectedSpecType(spec_type)
    if (temp_data.type == 'Select' || temp_data.type == 'Multi Select') {
      SetShowAddChild(true)
    } else {
      SetShowAddChild(false)
    }
    if (temp_data.hasOwnProperty('children')) {
      SetChildList(temp_data.children)
    } else {
      SetChildList([])
    }
  }

  function input_on_change(label: any, e: any) {
    clearTimeout(timer)
    SetSaveData((prevValue) => {
      if (label == 'name') {
        prevValue.name = e.target.value
      }
      // setTimeout(triggerItemChange(prevValue.name), WAIT_INTERVAL)

      return { ...prevValue }
    })
  }

  function triggerItemChange(name_value) {
    let addon_item_data = all_spec_data
    var item_name = name_value
    let selected_category_value = save_data.category
    let spec_temp_data_value = spec_template_data

    let name_presented_index = spec_template_data.findIndex(
      (element) => element.name == item_name,
    )

    console.log('name_presented_index :', name_presented_index)

    if (name_presented_index > -1) {
      spec_temp_data_value.splice(name_presented_index, 1)
    }

    let selected_addon_data = addon_item_data.filter(
      (a_data) => a_data.category_name === selected_category_value.name,
    )
    console.log('spec_temp_data :', spec_template_data)

    let available_data = selected_addon_data.filter(
      (a_data) =>
        a_data.name.toString().toLowerCase() ===
        item_name.toString().toLowerCase(),
    )

    let available_data1 = spec_temp_data_value.filter(
      (a_data) =>
        a_data.name.toString().toLowerCase() ===
          item_name.toString().toLowerCase() && a_data.strict,
    )

    console.log('available_data :', available_data)
    console.log('available_data1 :', available_data1)

    if (available_data.length > 0) {
      SetErrorData('* Specification name is already available in this category')
    } else if (available_data1.length > 0) {
      SetErrorData('* Specification name is already available in template')
    } else {
      SetErrorData('')
    }
  }

  function validate() {
    let nameError = ''
    let typeError = ''
    let childError = ''
    if (!save_data.name) {
      nameError = 'Name field is required'
    }
    if (Object.keys(selected_sepc_type).length == 0) {
      // if (selected_sepc_type == {}) {
      typeError = 'Type Field is required '
    }
    if (selected_sepc_type.value == 2 || selected_sepc_type.value == 3) {
      child_list.map((ele) => {
        if (ele.name == '') {
          ele.error = 'Child Name Required'
        } else {
          ele.error = ''
        }
      })
      // if (child_list.every((ele) => !ele.name)) {
      //   childError = 'Child field is required'
      // }
      if (child_list.some((ele) => ele.error != '')) {
        childError = 'Child field is required'
      }
    }

    if (nameError || typeError || childError) {
      SetErrorState({ nameError, typeError, childError })
      return false
    }
    return true
  }

  async function create_specification() {
    if (validate()) {
      console.log('validate ok')
      let spec_data_value = {
        name: save_data.name,
        type: selected_sepc_type.value,
      }

      if (spec_data_value.type == '2' || spec_data_value.type == '3') {
        if (child_list.length > 0) {
          spec_data_value.items = child_list
        }
      }

      let pass_data = {
        get: {},
        post: {
          specification: [spec_data_value],
        },
      }

      // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id
      } else {
        pass_data.get.m_id = id_value.m_id
      }

      console.log('create_specification pass_data :', pass_data)
      let res_data = await api('/product/create_specification', pass_data)
      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success('Specifications Created')
          let item = close_data
          item.action = 'save'
          SetCloseData(item)
          myModal.hide()
        } else {
          toast.error(res_data.response.message)
        }
      }
    }
  }

  function add_spec_list() {
    if (validate()) {
      let spec_data_value = {
        name: save_data.name,
        type: selected_sepc_type.value,
        type_name: selected_sepc_type.label,
      }

      if (spec_data_value.type == '2' || spec_data_value.type == '3') {
        if (child_list.length > 0) {
          spec_data_value.items = child_list
        }
      }
      console.log('Add spec_data_value :', spec_data_value)

      SetSpecDataList((prevValue: any) => {
        prevValue.push(spec_data_value)
        console.log('add SetSpecDataList prevValue :', prevValue)
        return [...prevValue]
      })

      SetViewData('list')
      SetSelectedSpecType({})
      SetSaveData((prevValue: any) => {
        prevValue.name = ''
        return { ...prevValue }
      })
      SetChildList([
        {
          id: '',
          name: '',
          error: '',
        },
      ])
      SetShowAddChild(false)
    }
  }

  async function submit_spec_list() {
    if (validate()) {
      let spec_data_value = {
        name: save_data.name,
        type: selected_sepc_type.value,
        type_name: selected_sepc_type.label,
      }

      if (spec_data_value.type == '2' || spec_data_value.type == '3') {
        if (child_list.length > 0) {
          spec_data_value.items = child_list
        }
      }

      let pass_data = {
        get: {},
        post: {
          // category_id: props.category.id,
          // specification_data: spec_data_list,
          // location: "create_product",
          // specification: spec_data_list,
          specification: [spec_data_value],
        },
      }
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id
      } else {
        pass_data.get.m_id = id_value.m_id
      }
      console.log('submit_spec_list pass_data :', pass_data)
      let res_data = await api('/product/create_specification', pass_data)

      // if (res_data.status_code == 201) {
      //   toast.success("Specifications Created");

      //   if (res_data.response.hasOwnProperty("data")) {
      //     let item = close_data;
      //     item.action = "save";
      //     SetCloseData(item);
      //     myModal.hide();
      //   }
      // }

      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success('Specifications Created')
          let item = close_data
          item.action = 'save'
          SetCloseData(item)
          myModal.hide()
        } else {
          toast.error(res_data.response.message)
        }
      }
    }
  }

  return (
    <div
      className="modal"
      id="SpecificationModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg  modal-dialog-centered">
        <div className="modal-content">
          {/* modal header */}
          <div className="modal-header">
            {props.from == 'specification' ? (
              <>
                {view_data != 'main' ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      if (view_data == 'category') {
                        SetSelectedCategory({})
                      }
                      SetViewData('main')
                    }}
                  ></i>
                ) : null}
                <h5 className="  modal-title" id="ModalLabel">
                  {view_data == 'category'
                    ? 'Select Category'
                    : view_data == 'main'
                    ? modal_type == 'create'
                      ? 'Create Specification'
                      : modal_type == 'view'
                      ? 'View Specification'
                      : null
                    : view_data == 'template'
                    ? 'Select Template'
                    : null}
                </h5>
              </>
            ) : null}
            {props.from == 'add_product' ? (
              <>
                {view_data != 'main' ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      SetViewData('main')
                    }}
                  ></i>
                ) : null}
                <h5 className="  modal-title" id="ModalLabel">
                  {view_data == 'main'
                    ? 'Add Specification'
                    : 'Specification List'}
                </h5>
              </>
            ) : null}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* modal body */}
          <div className="modal-body">
            {props.from == 'specification' ? (
              view_data == 'category' ? (
                <div className="">
                  <div className="d-flex px-3 align-items-center mb-3">
                    <p className="pe-2 mb-0">Selected Category</p>
                    <MaterialIcons name="double-arrow" size={16} color="gray" />
                    <span className="ps-2 text-dark">
                      {selected_category.name}
                    </span>
                  </div>
                  <div className="scroll mt-2 p-0 border-top">
                    <div className="scroll-flex mt-1">
                      <ColumnNode
                        data={category_data}
                        onSelect={(data) => {
                          console.log('category on select :', data)
                          SetSelectedCategory(data)
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : view_data == 'main' ? (
                <div className="p-2">
                  {/* category */}
                  {/* <div
                    className="col-md-12 mb-3"
                    onClick={() => {
                      console.log("Open Category");
                      if (props.type == "create") {
                        SetViewData("category");
                        SetSelectedCategory({});
                      }
                    }}
                  >
                    <label className="form-label">Category</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Category"
                      value={
                        Object.keys(save_data.category).length > 0
                          ? save_data.category.name
                          : ""
                      }
                      disabled={read_only}
                      onChange={(e) => {}}
                    />
                  </div> */}
                  {/* {Object.keys(save_data.category).length > 0 ? ( */}
                  {/* Specification Details */}
                  {/* <div className="border-bottom d-flex">
                    <label className="form-label">
                      Specification Details
                    </label> */}
                  {/* {props.type != "view" ? (
                        <label
                          className="ms-auto cursor text-primary"
                          onClick={() => {
                            SetViewData("template");
                          }}
                        >
                          <i className=" fas fa-plus "></i> Choose From Template
                        </label>
                      ) : null} */}
                  {/* </div> */}
                  <div className="row mt-2">
                    {/* specification name */}
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Specification Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Specification Name"
                        value={save_data.name}
                        disabled={read_only}
                        onChange={(e) => {
                          input_on_change('name', e)
                          SetSaveData((prevValue) => {
                            prevValue.name = e.target.value
                            return { ...prevValue }
                          })
                          SetErrorState((prevValue: any) => {
                            if (e.target.value == '') {
                              prevValue.nameError = 'Name field is required'
                            } else {
                              prevValue.nameError = ''
                            }

                            return { ...prevValue }
                          })
                        }}
                      />
                      <span className="text-danger">
                        {error_state.nameError}
                      </span>
                    </div>
                    {/* specification Type */}
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Specification Type *</label>
                      <Select
                        placeholder="Select Specification Type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isDisabled={read_only}
                        options={specification_type_option}
                        value={selected_sepc_type}
                        onChange={(e) => {
                          console.log('Header Select :', e)
                          if (e.value == '2' || e.value == '3') {
                            SetShowAddChild(true)
                          } else {
                            SetShowAddChild(false)
                          }
                          SetSelectedSpecType(e)
                          SetErrorState((prevValue: any) => {
                            prevValue.typeError = ''
                            return { ...prevValue }
                          })
                        }}
                      />
                      <span className="text-danger">
                        {error_state.typeError}
                      </span>
                    </div>

                    {show_add_child ? (
                      <div className="">
                        <div className="d-flex py-2 align-items-center border-bottom ">
                          <p className="text-dark fw-bold mb-0">
                            Specification Values *
                          </p>

                          <div className="ms-auto">
                            {modal_type == 'create' ? (
                              <div className="ms-auto">
                                {/* <div className="col-md-6 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Child Name"
                                    value={child_name}
                                    onChange={(e) => {
                                      SetChildName(e.target.value);
                                    }}
                                  />
                                </div> */}
                                <button
                                  className="btn text-primary btn-sm"
                                  onClick={() => {
                                    console.log('Add Child')
                                    let push_value = {
                                      id: '',
                                      name: '',
                                      error: '',
                                    }
                                    SetChildList((prevValue) => {
                                      prevValue.push(push_value)
                                      return [...prevValue]
                                    })
                                  }}
                                >
                                  <i className=" fas fa-plus "></i> Add
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row pt-2">
                          {child_list.length > 0 ? (
                            <>
                              {child_list.map((ele: any, index: any) => (
                                <div className="col-md-6 mb-3" key={index}>
                                  <div className="border radius ">
                                    <label className="d-flex align-items-center px-1">
                                      {/* <div className=""> */}
                                      {/* <p className="mb-0"> {ele.name}</p> */}
                                      <input
                                        type="text"
                                        className="form-control custom-input"
                                        placeholder="Enter child name"
                                        value={ele.name}
                                        onChange={(e) => {
                                          // SetChildName(e.target.value);
                                          SetChildList((prevValue: any) => {
                                            prevValue[index].id = e.target.value
                                            prevValue[index].name =
                                              e.target.value
                                            if (e.target.value == '') {
                                              prevValue[index].error =
                                                'Child Name Required'
                                            } else {
                                              prevValue[index].error = ''
                                            }
                                            return [...prevValue]
                                          })
                                        }}
                                      />

                                      {/* </div> */}
                                      {modal_type == 'create' ? (
                                        index > 0 ? (
                                          <div
                                            className="end border-left px-2 cursor"
                                            onClick={() => {
                                              console.log('remove')
                                              SetChildList((prevValue) => {
                                                prevValue.splice(index, 1)
                                                return [...prevValue]
                                              })
                                            }}
                                          >
                                            <Ionicons
                                              name="close"
                                              size={16}
                                              color="#ccc"
                                            />
                                            {/* <p className="mb-0 ps-1">Remove</p> */}
                                          </div>
                                        ) : null
                                      ) : null}
                                    </label>
                                  </div>
                                  <span className="text-danger">
                                    {ele.error}
                                  </span>
                                </div>
                              ))}
                            </>
                          ) : (
                            <p>No Child Data</p>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* ) : null} */}
                </div>
              ) : view_data == 'template' ? (
                <div className="p-3">
                  <div className="row">
                    {spec_template_data.length > 0 ? (
                      spec_template_data.map((a_ele, a_index) => (
                        <div className="col-md-6 mb-3" key={a_index}>
                          <div className="card p-2">
                            <label
                              className="cursor"
                              onClick={() => {
                                console.log('temp data :', a_ele)
                                SetViewData('main')
                                SetSelectedTemplate(a_ele)
                                process_selected_template(a_ele)
                              }}
                            >
                              <div className="d-flex mb-2 w-100">
                                <p className="mb-0 text-dark oneline">
                                  {a_ele.name}
                                </p>
                                <small className="ms-auto note_hide">
                                  {a_ele.type}
                                </small>
                              </div>
                              <small className="mb-0">
                                No Of Child's : {a_ele.children.length}
                              </small>
                            </label>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Data.</p>
                    )}
                  </div>
                </div>
              ) : null
            ) : null}
            {props.from == 'add_product' ? (
              <>
                {view_data == 'main' ? (
                  <div className="col-md-12 mb-3">
                    <div className="pb-2 d-flex">
                      {/* border-bottom  */}
                      <p className="mb-0 text-dark">Specification Details</p>
                      {spec_data_list.length > 0 ? (
                        <label
                          className="ms-auto cursor"
                          onClick={() => {
                            SetViewData('list')
                          }}
                        >
                          <i className=" fas fa-eye "></i> View List
                        </label>
                      ) : null}
                    </div>
                    <div className="row p-3">
                      {/* specification name */}
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Specification Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Specification Name"
                          value={save_data.name}
                          disabled={read_only}
                          onChange={(e) => {
                            input_on_change('name', e)
                            SetSaveData((prevValue) => {
                              prevValue.name = e.target.value
                              return { ...prevValue }
                            })
                            SetErrorState((prevValue: any) => {
                              if (e.target.value == '') {
                                prevValue.nameError = 'Name field is required'
                              } else {
                                prevValue.nameError = ''
                              }

                              return { ...prevValue }
                            })
                          }}
                        />
                        <span className="text-danger">
                          {error_state.nameError}
                        </span>
                      </div>
                      {/* specification Type */}
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Specification Type</label>
                        <Select
                          placeholder="Select Specification Type"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={read_only}
                          options={specification_type_option}
                          value={selected_sepc_type}
                          onChange={(e) => {
                            console.log('Header Select :', e)
                            if (e.value == '2' || e.value == '3') {
                              SetShowAddChild(true)
                            } else {
                              SetShowAddChild(false)
                            }
                            SetSelectedSpecType(e)
                            SetErrorState((prevValue: any) => {
                              prevValue.typeError = ''
                              return { ...prevValue }
                            })
                          }}
                        />
                        <span className="text-danger">
                          {error_state.typeError}
                        </span>
                      </div>

                      {show_add_child ? (
                        <div className="">
                          <div className="d-flex align-items-center py-2 mb-2">
                            <p className="text-black mb-0">
                              Specification Values
                            </p>

                            <div className="ms-auto">
                              {modal_type == 'create' ? (
                                <div>
                                  {/* <div className="col-md-6 col-9 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Child Name"
                                  value={child_name}
                                  onChange={(e) => {
                                    SetChildName(e.target.value);
                                  }}
                                />
                                </div> */}
                                  {/* <div className="col-3 col-md-2 offset-md-4 text-end p-0"> */}
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                      console.log('Add Child')
                                      let push_value = {
                                        id: '',
                                        name: '',
                                      }
                                      SetChildList((prevValue) => {
                                        prevValue.push(push_value)
                                        return [...prevValue]
                                      })
                                    }}
                                  >
                                    <i className=" fas fa-plus "></i> Add
                                  </button>
                                  {/* </div> */}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            {child_list.length > 0 ? (
                              <div className="row">
                                {child_list.map((ele: any, index: any) => (
                                  <div className="col-md-6 mb-3" key={index}>
                                    <div className="border radius ">
                                      <label className="d-flex align-items-center px-1">
                                        {/* <div className=""> */}
                                        {/* <p className="mb-0"> {ele.name}</p> */}
                                        <input
                                          type="text"
                                          className="form-control custom-input"
                                          placeholder="Enter child name"
                                          value={ele.name}
                                          onChange={(e) => {
                                            // SetChildName(e.target.value);
                                            SetChildList((prevValue: any) => {
                                              prevValue[index].id =
                                                e.target.value
                                              prevValue[index].name =
                                                e.target.value
                                              if (e.target.value == '') {
                                                prevValue[index].error =
                                                  'Child Name Required'
                                              } else {
                                                prevValue[index].error = ''
                                              }
                                              return [...prevValue]
                                            })
                                          }}
                                        />
                                        {/* </div> */}
                                        {modal_type == 'create' ? (
                                          index > 0 ? (
                                            <div
                                              className="end border-left px-2 cursor"
                                              onClick={() => {
                                                console.log('remove')
                                                SetChildList((prevValue) => {
                                                  prevValue.splice(index, 1)
                                                  return [...prevValue]
                                                })
                                              }}
                                            >
                                              <Ionicons
                                                name="close"
                                                size={16}
                                                color="#ccc"
                                              />
                                              {/* <p className="mb-0 ps-1">Remove</p> */}
                                            </div>
                                          ) : null
                                        ) : null}
                                      </label>
                                    </div>
                                    <span className="text-danger">
                                      {ele.error}
                                    </span>
                                  </div>
                                ))}
                                {/* </div> */}
                              </div>
                            ) : (
                              <div className="center">
                                <p>No Child Data</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {view_data == 'list' ? (
                  <div className="row">
                    <div
                      className="text-end mb-3 cursor"
                      onClick={() => {
                        SetViewData('main')
                      }}
                    >
                      <p className="mb-0 text-primary"> + Add More</p>
                    </div>
                    {spec_data_list.map((ele: any, index: any) => (
                      <div className="col-md-6 col-lg-4" key={index}>
                        <div className="card p-2">
                          <p className="mb-1">{ele.name}</p>
                          <div className="d-flex align-items-center">
                            <p className="mb-0 text-black">{ele.type_name}</p>
                            <div className="ms-auto">
                              {ele.hasOwnProperty('items') ? (
                                <small className="mb-0">have child</small>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          {/* modal footer */}
          <div className="modal-footer shadow">
            {modal_type != 'create' ? (
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius px-3 ms-3"
                onClick={() => {
                  let item = close_data
                  item.action = 'close'
                  SetCloseData(item)
                  // SetSaveData(initial_value);
                  myModal.hide()
                }}
              >
                Close
              </button>
            ) : null}

            {modal_type == 'create' ? (
              <>
                {props.from == 'specification' ? (
                  view_data == 'category' ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-radius px-3 ms-3"
                      onClick={() => {
                        console.log('Category Ok')
                        SetViewData('main')
                        SetSaveData((prevValue) => {
                          prevValue.category = selected_category
                          return { ...prevValue }
                        })
                        // input_on_change("category", null);
                        get_template()
                      }}
                    >
                      OK
                    </button>
                  ) : view_data == 'main' ? (
                    <div className="d-flex align-items-center w-100">
                      <p className="error_message me-3">{error_data}</p>
                      <button
                        type="button"
                        className="btn btn-secondary-outline btn-radius px-3 ms-3"
                        onClick={() => {
                          let item = close_data
                          item.action = 'close'
                          SetCloseData(item)
                          // SetSaveData(initial_value);
                          myModal.hide()
                        }}
                      >
                        Close
                      </button>
                      {error_data == '' ? (
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn btn-primary btn-radius px-3 ms-3"
                            onClick={() => {
                              console.log('save')
                              create_specification()
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                ) : null}
                {props.from == 'add_product' ? (
                  <>
                    {view_data == 'main' ? (
                      <div className="d-flex align-items-center w-100">
                        {error_data ? (
                          <p className="text-red me-3 fw-bold extra-small mb-0">
                            {error_data}
                          </p>
                        ) : // error_message
                        null}
                        <div className={error_data ? 'ms-auto' : ''}>
                          <button
                            type="button"
                            className="btn btn-secondary-outline btn-radius px-3"
                            onClick={() => {
                              let item = close_data
                              item.action = 'close'
                              SetCloseData(item)
                              // SetSaveData(initial_value);
                              myModal.hide()
                            }}
                          >
                            Close
                          </button>
                        </div>
                        {error_data == '' ? (
                          <div className="ms-auto">
                            {/* <button
                              type="button"
                              className="btn btn-primary-outline ms-3"
                              onClick={() => {
                                console.log('save')
                                // create_specification();
                                //
                                // if (save_data.name == '') {
                                //   // console.log('enter name')
                                //   toast.error('Enter specification name')
                                // } else if (
                                //   Object.keys(selected_sepc_type).length == 0
                                // ) {
                                //   console.log('select type')
                                //   toast.error('Select specification type')
                                // } else {
                                //   if (
                                //     selected_sepc_type.value == '2' ||
                                //     selected_sepc_type.value == '3'
                                //   ) {
                                //     if (child_list.length == 0) {
                                //       console.log('Add Child')
                                //       toast.error(
                                //         'Specification value Required',
                                //       )
                                //     } else {
                                //       add_spec_list()
                                //     }
                                //   } else {
                                //     add_spec_list()
                                //   }
                                // }
                                add_spec_list()
                                //
                                // const [child_list, SetChildList] = useState([
                                //   {
                                //     id: "",
                                //     name: "",
                                //   },
                                // ]);
                              }}
                            >
                              Add
                            </button> */}
                            <button
                              type="button"
                              className="btn btn-primary btn-radius px-3"
                              onClick={() => {
                                console.log('submit ')
                                submit_spec_list()
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {view_data == 'list' ? (
                      <div className="d-flex ms-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-radius px-3"
                          onClick={() => {
                            console.log('submit :', spec_data_list)
                            submit_spec_list()
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
