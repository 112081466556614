import React, { useState, useEffect } from "react";

const DataContext = React.createContext();

function DataProvider(props) {
  const [app_data, SetAppData] = useState({
    user_details: {},
    languages: [],
    selected_language: {},
    countries: [],
    states:[],
    selected_country: {},
    currencies: [],
    selected_currency: {},
    image_sizes: [],
    image_base_url: "",
    app_from: "",
  });

  useEffect(() => {
    console.log("DataProvider On mount :");
  }, []);

  function t(value) {
    if (app_data.language_data.hasOwnProperty(value)) {
      return app_data.language_data[value];
    } else {
      return value;
    }
  }

  return (
    <DataContext.Provider
      value={{
        app_data,
        SetAppData,
        t,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export { DataProvider };

// export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
