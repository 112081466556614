import React, { useState, useEffect, useContext, useRef } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome5,
} from '@expo/vector-icons'

let myModal = {}

export default function ModalAddonConfirm(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())

  useEffect(() => {
    if (props.open) {
      console.log('ModalAddonConfirm On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('AddonConfirmModal'),
        {},
      )
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalAddonConfirm On mount :', props)
    let myModal1 = document.getElementById('AddonConfirmModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      props.close(close_data)
    })
  }, [])

  return (
    <div
      className="modal"
      id="AddonConfirmModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Notice
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-2 border-top overflowY">
            <div className="">
              <p>
                Addon is selected but not added to any variants, continue anyway
              </p>
            </div>
          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            >
              Cancel
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary-outline "
                onClick={() => {
                  console.log('Ok')
                  let item = close_data
                  item.action = 'save'
                  SetCloseData(item)
                  myModal.hide()
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
