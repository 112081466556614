import { AntDesign, Ionicons } from "@expo/vector-icons";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";

export default function ManufacturerFooter(props) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ManufacturerFooter On mount :", props);
    console.log("ManufacturerFooter context :", context);
  }, []);

  return (
    <div
      className="bottom-tabs d-lg-none d-flex justify-content-center align-items-center text-center border-top"
      //   style="position: fixed; width: 100%; left:0; bottom: 0; background: #ffffff; z-index: 1250;"
    >
      <Link className="flex-fill p-2 link-loader" to="/">
        {/* <img src={require("../../assets/icon/svg/home1.svg")} alt="" /> */}
        <AntDesign name="home" size={20} color="black" />
      </Link>
      {props.from == "outlet" ? (
        <Link
          className="flex-fill p-2 link-loader"
          to={
            "/outlet/" +
            id_value.brand_id +
            "/" +
            id_value.outlet_id +
            "/profile"
          }
        >
          <img src={require("../../assets/icon/svg/user1.svg")} alt="" />
        </Link>
      ) : (
        <Link
          className="flex-fill p-2 link-loader"
          to={"/brand/" + id_value.brand_id + "/account"}
        >
          {/* <img
            src={require("../../assets/icon/svg/settings1.svg")}
            className="action_img"
          /> */}
          <Ionicons name="settings-outline" size={20} color="#555" />
        </Link>
      )}
      <a
        className="flex-fill p-2 link-loader"
        href="https://1superapp.com/dashboard"
      >
        <img
          src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
          width="25"
        />
      </a>
      <a
        className="flex-fill p-2 link-loader"
        href="https://1studio.1superapp.com/notification/view"
      >
        <img src={require("../../assets/icon/svg/bell1.svg")} alt="" />
      </a>
      {/* <!-- <a className="flex-fill p-2" href="#"><i className="material-icons fs-24 text-dark">search</i></a> --> */}
      <div className="flex-fill p-2">
        <button
          className="sidebar-toggler navbar-toggler  px-2"
          onClick={() => {
            // console.log("hai");
            props.menu_click(true);
          }}
        >
          <img src={require("../../assets/icon/svg/menu1.svg")} alt="" />
        </button>
      </div>
    </div>
  );
}
