import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
// import Footer from "../Footer/Footer";
import DataContext from "../../utils/Context/DataContext";
import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import Header from "../Header/Header";

export default function HomeLayout(props) {
  const context = useContext(DataContext);
  const location = useLocation();
  const [is_menu_open, SetIsMenuOpen] = useState(false);

  useEffect(() => {
    console.log("HomeLayout onmount context :", context);
  }, []);

  return (
    <>
      <div className="page-body">
        <div className="page-content-home">
          <Header />
          <Outlet />
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              className: "",
              style: {
                border: "1px solid #171546",
                padding: "10px",
              },
              duration: 2500,
            }}
          />
        </div>
      </div>
    </>
  );
}
