import React, { useState, useEffect, useContext, useRef } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
} from '@expo/vector-icons'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-checkbox-tree/src/less/react-checkbox-tree.less'

let myModal = {}

const initial_value = {
  checked: [],
  addonexpanded: [],
  addon: {},
  original_addon_data: [],
  choosed_addon_data: [],
  sample_nodes: [
    {
      value: 'mars',
      label: 'Mars',
      children: [
        { value: 'phobos', label: 'Phobos' },
        { value: 'deimos', label: 'Deimos' },
      ],
    },
  ],
}

export default function AddonModal(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [addon_group_data, SetAddonGroupData] = useState([])
  const [save_data, SetSaveData] = useState(initial_value)
  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: '1',
    next_page: false,
    count: { label: 'All', value: 'all' },
    count_option: [
      { label: 'All', value: 'all' },
      { label: '10', value: '10' },
      { label: '20', value: '20' },
      { label: '30', value: '30' },
    ],
    sort: { label: 'asc', value: 'asc' },
    sort_option: [
      { label: 'asc', value: 'asc' },
      { label: 'desc', value: 'desc' },
    ],
    search: '',
    spec: [],
    mrp: [1, 2, 3, 4],
    addon_groups: [],
    category_id: '',
  })

  useEffect(() => {
    if (props.open) {
      console.log('AddonModal On Open :', props)
      myModal = new bootstrap.Modal(document.getElementById('ModalAddon'), {})
      if (props.from == 'product_details') {
        // get_addons();
        if (props.product_data.hasOwnProperty('addon_group')) {
          let checked_value : any = []
          props.selected_addon.map((s_ele: any, s_index: any) => {
            s_ele.items.map((i_ele: any, i_index: any) => {
              console.log('i_ele :', i_ele)
              checked_value.push(i_ele.addon_group_item_id)
            })
          })
          console.log('checked_value :', checked_value)
          SetSaveData((prevValue: any) => {
            prevValue.checked = checked_value
            return { ...prevValue }
          })
          let addon_group_value = props.product_data.addon_group
          addon_group_value.map((ele: any, index: any) => {
            ele.checked = false
            ele.label = ele.name
            ele.value = ele.id
            ele.children.map((child_ele: any, child_index: any) => {
              child_ele.checked = false
              child_ele.label = child_ele.name
              child_ele.value = child_ele.addon_group_item_id
            })
          })
          // props.selected_addon.map((s_ele: any, s_index: any) => {
          //   addon_group_value.map((ele: any, index: any) => {

          //     if(s_ele.group_id == ele.id){
          //       ele.checked = true
          //     }
          //     // ele.checked = false
          //     // ele.label = ele.name
          //     // ele.value = ele.id
          //     // ele.children.map((child_ele: any, child_index: any) => {
          //     //   child_ele.checked = false
          //     //   child_ele.label = child_ele.name
          //     //   child_ele.value = child_ele.addon_group_item_id
          //     // })
          //   })

          //   // s_ele.items.map((i_ele: any, i_index: any) => {
          //   //   console.log('i_ele :', i_ele)
          //   //   checked_value.push(i_ele.addon_group_item_id)
          //   // })
          // })

          SetAddonGroupData(addon_group_value)
        }
      }
      if (props.from == 'product_edit_variant') {
        let checked_value = []
        props.selected_addon.map((s_ele: any, s_index: any) => {
          s_ele.children.map((i_ele: any, i_index: any) => {
            console.log('i_ele :', i_ele)
            if (i_ele.checked) {
              checked_value.push(i_ele.addon_group_item_id)
            }
          })
        })
        console.log('checked_value :', checked_value)
        SetSaveData((prevValue: any) => {
          prevValue.checked = checked_value
          return { ...prevValue }
        })
        let addon_group_value = props.addon_group
        addon_group_value.map((ele: any, index: any) => {
          // ele.checked = false
          ele.label = ele.name
          ele.value = "children_" + ele.id
          ele.children.map((child_ele: any, child_index: any) => {
            // child_ele.checked = false
            child_ele.label = child_ele.name
            child_ele.value = child_ele.addon_group_item_id
          })
        })
        SetAddonGroupData(addon_group_value)
      }
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('AddonModal On mount :', props)
    let myModal1 = document.getElementById('ModalAddon')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData(initial_value)
      props.close(close_data)
    })
  }, [])

  async function get_addons(filter_value) {
    // let pass_data = {
    //   get: {
    //     category_id: props.category_id,
    //   },
    // };
  //   // if (context.app_data.app_from == "seller") {
      // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id;
    // } else {
    //   pass_data.get.m_id = id_value.m_id;
    // }
    // let res_data = await api("/product/get_addon_groups", pass_data);
    // console.log("/product/get_addon_groups :", res_data);
    // if (res_data.status_code == 200) {
    //   if (res_data.response.hasOwnProperty("data")) {
    //     let addon_data = res_data.response.data;
    //     addon_data.map((addon_ele: any, addon_index: any) => {
    //       console.log(
    //         "props.selected_addon.includes((s_ele) => s_ele.id == addon_ele.id) :",
    //         props.selected_addon.some((s_ele) => s_ele.id == addon_ele.id)
    //       );
    //       if (props.selected_addon.some((s_ele) => s_ele.id == addon_ele.id)) {
    //         addon_ele.checked = true;
    //         addon_ele.disabled = true;
    //       } else {
    //         addon_ele.checked = false;
    //         addon_ele.disabled = false;
    //       }
    //     });
    //     SetAddonGroupData(addon_data);
    //   }
    // }

    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        spec: filter_value.spec,
        mrp: encodeURI(JSON.stringify(filter_value.mrp)),
        addon_groups: filter_value.addon_groups,
        category_id: props.category_id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data = await api('/product/get_all_addon_groups', pass_data)
    console.log('/product/get_all_addon_groups :', res_data)
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty('addon')) {
        let addon_data = res_data.response.addon
        addon_data.map((addon_ele: any, addon_index: any) => {
          console.log(
            'props.selected_addon.includes((s_ele) => s_ele.id == addon_ele.id) :',
            props.selected_addon.some((s_ele) => s_ele.id == addon_ele.id),
          )
          if (props.selected_addon.some((s_ele) => s_ele.id == addon_ele.id)) {
            addon_ele.checked = true
            addon_ele.disabled = true
          } else {
            addon_ele.checked = false
            addon_ele.disabled = false
          }
        })
        SetAddonGroupData(addon_data)
      }
    }
  }

  function onAddonChange(checked, value_data) {
    SetSaveData((prevValue: any) => {
      prevValue.checked = checked
      return { ...prevValue }
    })
  }

  return (
    <div
      className="modal"
      id="ModalAddon"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Select Addon
            </h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-2 border-top">
            <div className="row">
              {/* {props.from == 'product_details' ? ( */}
              <CheckboxTree
                expandOnClick
                // nodes={save_data.sample_nodes}
                nodes={addon_group_data}
                checked={save_data.checked}
                expanded={save_data.addonexpanded}
                onCheck={(checked_value, value_data) => {
                  console.log('on check checked_value :', checked_value)
                  console.log('on check value_data :', value_data)
                  console.log('addon_group_data :', addon_group_data)
                  onAddonChange(checked_value, value_data)
                  // if (value_data.isParent) {
                  //   console.log('it is parent')
                  // }
                }}
                onExpand={(addonexpanded) => {
                  SetSaveData((prevValue: any) => {
                    prevValue.addonexpanded = addonexpanded
                    return { ...prevValue }
                  })
                }}
                icons={{
                  check: <i className="fas fa-check-square" />,
                  uncheck: <span className="fas fa-square" />,
                  halfCheck: <span className="fas fa-minus-square" />,
                  expandClose: <span className="fas fa-plus" />,
                  expandOpen: <span className="fas fa-minus" />,
                  expandAll: <span className="fas fa-minus-square" />,
                  collapseAll: <span className="fas fa-minus-square" />,
                  parentClose: <span className="fas fa-folder" />,
                  parentOpen: <span className="fas fa-folder-open" />,
                  leaf: <span className="fas fa-file" />,
                }}
              />
              {/* ) : null} */}

              {/* {props.from == 'product_details' ? (
                <CheckboxTree
                  expandOnClick
                  nodes={addon_group_data}
                  checked={save_data.checked}
                  expanded={save_data.addonexpanded}
                  onCheck={(checked_value, value_data) => {
                    console.log('on check checked_value :', checked_value)
                    console.log('on check value_data :', value_data)
                    console.log('addon_group_data :', addon_group_data)
                    onAddonChange(checked_value, value_data)
                  }}
                  onExpand={(addonexpanded) => {
                    SetSaveData((prevValue: any) => {
                      prevValue.addonexpanded = addonexpanded
                      return { ...prevValue }
                    })
                  }}
                  icons={{
                    check: <i className="fas fa-check-square" />,
                    uncheck: <span className="fas fa-square" />,
                    halfCheck: <span className="fas fa-minus-square" />,
                    expandClose: <span className="fas fa-plus" />,
                    expandOpen: <span className="fas fa-minus" />,
                    expandAll: <span className="fas fa-minus-square" />,
                    collapseAll: <span className="fas fa-minus-square" />,
                    parentClose: <span className="fas fa-folder" />,
                    parentOpen: <span className="fas fa-folder-open" />,
                    leaf: <span className="fas fa-file" />,
                  }}
                />
              ) : null} */}
            </div>
          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary btn-radius px-3"
                onClick={() => {
                  console.log('Ok')
                  let item = close_data

                  if (props.from == 'product_details') {
                    item.action = 'save'
                    item.checked_value = save_data.checked
                    item.product_data = props.product_data
                    item.variant_data = props.variant_data
                    SetCloseData(item)
                    myModal.hide()
                  }

                  if (props.from == 'product_edit_variant') {
                    item.action = 'save'
                    item.checked_value = save_data.checked
                    item.addon_group = props.addon_group
                    // item.variant_data = props.variant_data
                    item.combination_index = props.combination_index
                    SetCloseData(item)
                    myModal.hide()
                  }
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
