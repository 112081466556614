import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DataContext from "../../utils/Context/DataContext";
import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppHelper } from "../../utils/Helper";

let myModal = {};

export default function VarriantModal(props: any) {
  const context = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());

  const [product_data, SetProductData] = useState([]);
  const [size_data, SetSizeData] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const [is_checked, SetIsChecked] = useState(false);
  const [search_data, SetSearchData] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [sort_by, SetSortBy] = useState("desc");
  const [next_page, SetNextPage] = useState(false);
  const [timer, SetTimer] = useState(null);

  useEffect(() => {
    if (props.open) {
      console.log("VarriantModal On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ModalVarriant"),
        {}
      );
      myModal.show();

      let pass_value = {
        search_data: search_data,
        page_no: page_no,
        sort: sort_by,
        fetch_more: false,
      };
      get_variants(pass_value);
    }
  }, [props.open]);

  useEffect(() => {
    console.log("VarriantModal On mount :", props);
    let myModal1 = document.getElementById("ModalVarriant");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search_data]);

  function triggerItemChange(value) {
    SetPageNo((prevValue: any) => {
      prevValue = 1;
      let pass_value = {
        search_data: value,
        page_no: prevValue,
        sort: sort_by,
        fetch_more: false,
      };
      get_variants(pass_value);
      return prevValue;
    });
  }

  function onCancel() {
    let item = close_data;
    item.action = "close";
    SetCloseData(item);
    myModal.hide();
  }

  async function get_variants(pass_value) {
    let pass_data = {
      get: {
        sort: pass_value.sort,
        page: pass_value.page_no,
      },
    };
    if (pass_value.search_data != "") {
      pass_data.get.search = pass_value.search_data;
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/all_variants", pass_data);
    console.log("/product/all_variants :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("image_sizes")) {
        SetSizeData(res_data.response.image_sizes);
      }
      if (res_data.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(res_data.response.image_base_url);
      }
      if (res_data.response.hasOwnProperty("data")) {
        let product_data_value = res_data.response.data;

        let all_selected_product = props.all_selected_product;
        let selected_product = props.selected_product;
        let without_selected_product = [];
        all_selected_product.map((ele: any) => {
          if (
            selected_product != undefined &&
            Object.keys(selected_product).length > 0
          ) {
            if (ele != selected_product.id) {
              without_selected_product.push(ele);
            }
          } else {
            without_selected_product.push(ele);
          }
        });

        product_data_value.map((ele: any) => {
          if (
            props.selected_product != undefined &&
            Object.keys(props.selected_product).length > 0
          ) {
            if (props.selected_product.id == ele.id) {
              ele.checked = true;
            } else {
              ele.checked = false;
            }
          } else {
            ele.checked = false;
          }

          if (without_selected_product.some((s_ele) => s_ele == ele.id)) {
            ele.checked = true;
            ele.disabled = true;
          } else {
            ele.disabled = false;
          }
        });

        if (pass_value.fetch_more) {
          SetProductData((prevValue: any) => {
            product_data_value.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetProductData(product_data_value);
        }
      }
      if (res_data.response.hasOwnProperty("next_page")) {
        SetNextPage(res_data.response.next_page);
      }
    }
  }

  return (
    <div
      className="modal"
      id="ModalVarriant"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Products list
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body p-sm-0">
            <div className="col-md-6 col-12 d-flex p-sm-2">
              <div className="input-icons form-group p-2 px-3">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  onChange={(e) => {
                    SetSearchData((prevValue: any) => {
                      prevValue = e.target.value;
                      return prevValue;
                    });
                  }}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={18} color="#ccc" />
                </div>
              </div>
            </div>
            <div className="mt-3">
              {product_data.length > 0 ? (
                <InfiniteScroll
                  dataLength={product_data.length}
                  next={() => {
                    SetPageNo((prevValue: any) => {
                      prevValue = prevValue + 1;
                      let pass_value = {
                        search_data: search_data,
                        page_no: prevValue,
                        sort: sort_by,
                        fetch_more: true,
                      };
                      get_variants(pass_value);
                      return prevValue;
                    });
                  }}
                  hasMore={next_page}
                  height={450}
                  loader={
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  }
                  endMessage={
                    <div className="center pt-3 pb-5">
                      <hr className="w-25" />
                    </div>
                  }
                >
                  <div className="row p-3">
                    {product_data.map((ele: any, index: any) => (
                      <div className="col-md-6 mb-2 px-2" key={index}>
                        <div
                          className="card p-3 cursor"
                          onClick={() => {
                            console.log("Product Detail :", ele);
                            if (!ele.disabled) {
                              SetProductData((prevValue: any) => {
                                prevValue.map(
                                  (pre_ele: any, pre_index: any) => {
                                    if (pre_index == index) {
                                      prevValue[pre_index].checked = true;
                                    } else {
                                      prevValue[pre_index].checked = false;
                                    }
                                  }
                                );
                                return [...prevValue];
                              });
                            }
                          }}
                        >
                          <div className="row">
                            <div className="col-3 p-0">
                              {/* <img
                                src={require('../../assets/img/mob.png')}
                                className="product_img"
                              /> */}

                              {ele.hasOwnProperty("images") ? (
                                <img
                                  src={AppHelper.imageSrc(
                                    image_base_url,
                                    ele.images[0].url,
                                    {
                                      format: "webp",
                                      width: 320,
                                    }
                                  )}
                                  // src={
                                  //   image_base_url +
                                  //   size_data[0] +
                                  //   "/" +
                                  //   ele.images[0].url
                                  // }
                                  className="product_img"
                                />
                              ) : (
                                <img
                                  src={require("../../assets/img/no-image.jpg")}
                                  className="product_img"
                                />
                              )}
                              <div className="position-check-left ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={ele.disabled ? true : ele.checked}
                                  // checked={ele.checked}
                                  disabled={ele.disabled}
                                  onChange={() => {}}
                                />
                              </div>
                            </div>
                            <div className="col-9">
                              <div className="d-flex">
                                <p className="mb-1 double text-dark">
                                  {ele.name}
                                </p>
                                <div className="ms-auto">
                                  {/* <small
                                    className={
                                      ele.status == "Active"
                                        ? "text-green mb-1"
                                        : "mb-1"
                                    }
                                  >
                                    {ele.status}
                                  </small> */}
                                  {ele.status == "Active" ? (
                                    <FontAwesome
                                      name="circle"
                                      size={14}
                                      color="#18b901"
                                    />
                                  ) : (
                                    <FontAwesome
                                      name="circle"
                                      size={14}
                                      color="red"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="d-flex align-items-center">
                                <MaterialIcons
                                  name="category"
                                  size={13}
                                  color="#bbb"
                                />
                                <p className="my-1 ps-1">
                                  <small className="oneline text-gray fw-500">
                                    {ele.category}
                                  </small>
                                </p>
                              </div>
                              <small className="v-small fw-500 text-darkgray">
                                {ele.description}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="center h-170 w-100">
                  <div>
                    <img
                      src={require("../../assets/img/empty.png")}
                      className="product-img"
                    />
                    <p className="mt-3 fw-bold text-center">No Data Found!</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* footer */}
          <div className="modal-footer shadow">
            <div className="d-flex align-items-center w-100">
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius btn-sm px-3"
                onClick={() => {
                  let item = close_data;
                  item.action = "close";
                  SetCloseData(item);
                  myModal.hide();
                }}
              >
                Cancel
              </button>
              <div className="ms-auto">
                <button
                  type="button"
                  className="btn btn-primary  btn-radius btn-sm px-3"
                  onClick={() => {
                    // onSubmit();
                    let selected_data = {};
                    product_data.map((ele: any) => {
                      if (ele.checked && !ele.disabled) {
                        selected_data = ele;
                      }
                    });
                    let item = close_data;
                    item.action = "save";
                    item.value = selected_data;
                    item.index = props.index;
                    SetCloseData(item);
                    myModal.hide();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
