import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import Select from 'react-select'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import { Feather } from '@expo/vector-icons'

import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
  Ionicons,
} from '@expo/vector-icons'
import { SignalCellularNullSharp } from '@material-ui/icons'

let myModal = {}

const initial_value = {
  name: '',
}

export default function ModalCreateSpecificationItem(props: any) {
  const [id_value, SetIdValue] = useState(useParams())
  const [save_data, SetSaveData] = useState(initial_value)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const context = useContext(DataContext)

  useEffect(() => {
    console.log('SpecificationModalItem On mount :', props)
    let myModal1 = document.getElementById('SpecificationModalItem')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData({
        category: {},
        name: '',
      })
      props.close(close_data)
    })
  }, [])

  useEffect(() => {
    if (props.open) {
      console.log('ModalCreateSpecificationItem On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('SpecificationModalItem'),
        {},
      )
      myModal.show()
    }
  }, [props.open])

  async function create_specification(){
    let pass_data = {
        get: {},
        post: {
            specification: [{
                id: props.all_data.value.id,
                items:[{
                    name:save_data.name,
                },]
            }],
            // specification_item : save_data.name
        },
    };
    if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
    } else {
        pass_data.get.m_id = id_value.m_id;
    }
    console.log("create_specification pass_data :", pass_data);
    let response = await api("/product/create_specification", pass_data);
    console.log("/product/create_specification response :", response);
    if (response.status_code == 200) {
        if (response.response.status) {
          toast.success('Specifications Created')
          let item = close_data
          item.action = 'save'
          item.all_data = props.all_data
          item.from = props.from;
          SetCloseData(item)
          myModal.hide()
        } else {
          toast.error(response.response.message)
        }
      }
  }

  return (
    <div
      className="modal"
      id="SpecificationModalItem"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg  modal-dialog-centered">
        <div className="modal-content">
          {/* modal header */}
          <div className="modal-header">                
            <h5 className="modal-title" id="ModalLabel">Create Specification Item</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* modal body */}
          <div className="modal-body">
            <div className="col-md-12 mb-3">
                <p>Spectfication Item For {props?.all_data?.value?.name}</p>
                <div className="row p-3">
                    {/* specification item name */}
                    <div className="col-md-6 mb-3">
                    <label className="form-label">Specification Item Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Specification item Name"
                        value={save_data.name}
                        onChange={(e) => {
                            SetSaveData((prevValue) => {
                                prevValue.name = e.target.value
                                return { ...prevValue }
                            })
                        }}
                    />
                    </div>
                </div>
            </div>
          </div>
          {/* modal footer */}
          <div className="modal-footer shadow">
            <div className="d-flex align-items-center w-100">
                <div>
                    <button
                    type="button"
                    className="btn btn-secondary-outline"
                    onClick={() => {
                        let item = close_data
                        item.action = 'close'
                        SetCloseData(item)
                        SetSaveData(initial_value);
                        myModal.hide()
                    }}
                    >
                    Close
                    </button>
                </div>
                <div className="ms-auto">
                
                    <button
                        type="button"
                        className="btn btn-primary-outline"
                        onClick={() => {
                            console.log('submit ')
                            if(save_data.name != ""){
                                create_specification()
                            }else{
                                toast.error("Specification item name empty")
                            }
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
