import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Entypo } from "@expo/vector-icons";


export default function Details(props: any) {
  const context = useContext(DataContext);

  useEffect(() => {
    console.log("ProductDetail On mount :", props);
    console.log("ProductDetail On mount context :", context);
  });

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h6>Product group name</h6>
          <div className="d-flex align-items-center mb-2">
            <Entypo name="language" size={24} color="black" />
            <p className="mb-0 ps-2"><small>English</small></p>
          </div>
          <p className="text-dark fw-bold">Cutlet</p>
          <p>CreateGroupProduct Descriptionx</p>
        </div>
        <div className="col-6">
          <p className="text-dark fw-bold">Images</p>
        </div>
      </div>
    </div>
  );
}


{/* <div className="bg-fff shadow col-md-12 mb-3" key={v_index}>
              <div
                className="d-flex cursor p-3"
                onClick={() => {
                  SetVariantData((prevValue: any) => {
                    prevValue.expand = !prevValue.expand;
                    return { ...prevValue };
                  });
                }}
              >
                <div>
                  <h6 className="mb-0">{v_ele.name}</h6>
                  {/* <p className="mb-0 pt-2 v-small">{v_ele.description}</p> 
                  <p className="pt-2 mb-0"><small className="fw-500">{v_ele.description}</small></p>
                </div>
                <div className="ms-auto px-2">
                  {v_ele.expand ? (
                    <MaterialIcons name="keyboard-arrow-up" size={20} color="#333" />
                  ) : (
                    <MaterialIcons name="keyboard-arrow-down" size={20} color="#333" />
                  )}
                </div>
              </div>
              {v_ele.expand ? (
                <div className="p-3">
                  <div className="row pb-3">

                    <div className="col-md-5">
                      <AliceCarousel
                        mouseTracking
                        touchMoveDefaultEvents={false}
                        // items={productsRestraunt}
                        // paddingLeft={50}
                        paddingRight={50}
                        disableDotsControls
                        responsive={responsive}
                        disableButtonsControls
                        controlsStrategy="alternate"
                      >
                        {img.map((data, index) => (
                          <div className=""
                            key={index}
                            onDragStart={handleDragStart}>
                            <div className="px-2">
                              <img src={data.src} className="pre_img" />
                            </div>
                          </div>
                        ))}
                      </AliceCarousel>
                    </div>

                    <div className="col-md-7 pt-sm-2 pt-md-0">
                      {/* <h6 className="mb-0">{v_ele.name}</h6> 
                      {/* {v_ele.status == "Active" ? "Available": "Un-Available"} 
                      <p className="mb-0 text-gray v-small">Brand : <b className="text-dark">{v_ele.product_brand}</b></p>
                      <p className="mb-0 text-gray v-small pt-2">Category : <b className="text-dark">{v_ele.category}</b></p>
                      <div className="d-flex align-items-center pt-2">
                        <p className=" mb-0"><small className="fw-500">Status :<span className="text-dark ps-1">{v_ele.status}</span></small></p>
                        <div className="end">
                          <Entypo name="language" size={13} color="#777" />
                          <small className="text-gray fw-500 ps-1">{v_ele.orgin_language}</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top pt-3">
                    <h6 className="">Specifications Details</h6>
                    <div className="row p-3">
                      <div className="col-5 bg-light p-0 border-right">
                        {v_ele.weight != "" ? (
                          <p className="mb-0 text-dark border-top p-2">
                            Weight
                          </p>
                        ) : (null)}
                        {v_ele.product_brand != "" ? (
                          <p className="mb-0 text-dark border-top p-2">
                            Brand
                          </p>
                        ) : (null)}
                        {v_ele.package_length != "" && v_ele.package_breadth !== "" ? (
                          <p className="mb-0 text-dark border-top p-2">
                            Length & Breath
                          </p>
                        ) : (null)}
                        {v_ele.orgin_country != "" ? (
                          <p className="mb-0 text-dark border-top p-2">
                            Country of Orgin
                          </p>
                        ) : (null)}
                      </div>
                      <div className="col-7 p-0">
                        {v_ele.weight != "" ? (
                          <p className="text-gray border-top mb-0 p-2">
                            {v_ele.weight + " " + v_ele.weight_unit_name}
                          </p>
                        ) : (null)}
                        {v_ele.product_brand != "" ? (
                          <p className="text-gray border-top mb-0 p-2">
                            {v_ele.product_brand}
                          </p>
                        ) : (null)}
                        {v_ele.package_length != "" && v_ele.package_breadth !== "" ? (
                          <p className="mb-0 text-dark border-top p-2">
                            {v_ele.package_length + v_ele.packagev_ele.package_length + v_ele.package_length_unit & v_ele.package_breadth + v_ele.package_breadth_unit_length_unit & v_ele.package_breadth + v_ele.package_breadth_unit}
                          </p>
                        ) : (null)}
                        {v_ele.orgin_country != "" ? (
                          <p className="text-gray border-top mb-0 p-2">
                            {v_ele.orgin_country}
                          </p>
                        ) : (null)}
                      </div>
                    </div>
                  </div>

                  <div className="row">

                    {Object.entries(v_ele).map(([key, value]) =>
                      v_ele[key] != null ? (
                        isArray(v_ele[key]) ? (
                          <div className="col-md-12" key={key}>
                            {/* <hr></hr> */}
{/* <h6>{key} :</h6> */ }
{/* {key == "images" ? (
                <div className="row">
                  {v_ele[key].map(
                    (img_ele: any, img_index) => (
                      <div className="col-4">
                        <img
                          src={
                            image_base_url +
                            image_sizes[0] +
                            "/" +
                            img_ele.url
                          }
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  )}
                </div>
              ) : null} 



                            {key == "mrp" ? (
                              <div className="table-responsive p-md-2 p-sm-0">
                                <h6 className="my-3">MRP Details</h6>
                                <table className="table teble-hover pad-table">
                                  <thead className="bg-light p-2 border-bottom">
                                    {/* <th>#</th> 
                                    <th>Country</th>
                                    <th>State</th>
                                    <th>Currency</th>
                                    <th>Mrp</th>
                                  </thead>
                                  <tbody>
                                    {v_ele[key].map(
                                      (mrp_ele: any, mrp_index) => (
                                        <tr key={mrp_index}>
                                          {/* <td>{mrp_index + 1}</td> 
                                          <td>{mrp_ele.country_name}</td>
                                          <td>{mrp_ele.state_name}</td>
                                          <td>
                                            {mrp_ele.currency} -{" "}
                                            {mrp_ele.currency_name}
                                          </td>
                                          <td>{mrp_ele.mrp}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}

                            {key == "group_variant_specification" ? (
                              <div className="">
                                <table>
                                  <thead>
                                    <th>#</th>
                                    <th>Spec Id</th>
                                    <th>Name</th>
                                  </thead>
                                  <tbody>
                                    {v_ele[key].map(
                                      (spec_ele: any, spec_index) => (
                                        <tr key={spec_index}>
                                          <td>{spec_index + 1}</td>
                                          <td>{spec_ele.spec_id}</td>
                                          <td>{spec_ele.name}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}

                            {key == "specifications" ? (
                              <div className="">
                                {v_ele[key].map(
                                  (spec1_ele: any, spec1_index: any) => (
                                    <div className="" key={spec1_index}>
                                      <div className="d-flex">
                                        <p className="me-3">ID : {spec1_ele.id}</p>
                                        <p className="me-3">
                                          Name : {spec1_ele.name}
                                        </p>
                                        <p className="me-3">
                                          Type : {spec1_ele.type}
                                        </p>
                                      </div>

                                      <table>
                                        <thead>
                                          <th>#</th>
                                          <th>Spec Id</th>
                                          <th>Name</th>
                                        </thead>
                                        <tbody>
                                          {isArray(spec1_ele.value) ? (
                                            spec1_ele.value.map(
                                              (v_ele: any, v_index) => (
                                                <tr>
                                                  <td>{v_index + 1}</td>
                                                  <td>{v_ele.id}</td>
                                                  <td>{v_ele.name}</td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td>{1}</td>
                                              <td>{spec1_ele.value.id}</td>
                                              <td>{spec1_ele.value.name}</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : null}

                            {key == "addon_group" ? (
                              <div className="">
                                {v_ele[key].map(
                                  (a_ele: any, a_index: any) => (
                                    <div className="" key={a_index}>
                                      <div className="d-flex">
                                        <p className="me-3">ID : {a_ele.id}</p>
                                        <p className="me-3">
                                          Group ID : {a_ele.group_id}
                                        </p>
                                        <p className="me-3">Name : {a_ele.name}</p>
                                        <p className="me-3">Note : {a_ele.notes}</p>
                                        <div className="ms-auto">
                                          <button
                                            onClick={() => {
                                              let pass_value = {
                                                a_ele: a_ele,
                                                a_index: a_index,
                                                key: key,
                                                v_ele: v_ele,
                                                v_index: v_index,
                                              };
                                              remove_addon_group(pass_value);
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>

                                      <table>
                                        <thead>
                                          <th>#</th>
                                          <th>Id</th>
                                          <th>Addon Id</th>
                                          <th>Name</th>
                                          <th>Notes</th>
                                          <th>Action</th>
                                        </thead>
                                        <tbody>
                                          {a_ele.items.map(
                                            (i_ele: any, i_index) => (
                                              <tr key={i_index}>
                                                <td>{i_index + 1}</td>
                                                <td>{i_ele.id}</td>
                                                <td>{i_ele.addon_id}</td>
                                                <td>{i_ele.name}</td>
                                                <td>{i_ele.notes}</td>
                                                <td>
                                                  <button
                                                    onClick={() => {
                                                      let pass_value = {
                                                        a_ele: a_ele,
                                                        a_index: a_index,
                                                        key: key,
                                                        v_ele: v_ele,
                                                        v_index: v_index,
                                                        i_ele: i_ele,
                                                        i_index: i_index,
                                                      };
                                                      remove_addon_item(pass_value);
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : null}
                            {/* <hr></hr> 
                          </div>
                        ) : typeof v_ele[key] == "object" ? (
                          // <div className="col-md-12" key={key}>
                          //   <p>OBJECT {key} :</p>
                          // </div>
                          null
                        ) : (
                          // <div className="col-md-6 col-lg-4" key={key}>
                          //   <p>
                          //     {key} : {v_ele[key]}
                          //   </p>
                          // </div>
                          null
                        )
                      ) : null
                    )}
                  </div>
                </div>
              ) : null}
            </div> */}