const imageSrc = (base_url: any, img_url: any, data = {}) => {
  let pass_string = base_url + img_url;
  if (Object.keys(data).length > 0) {
    Object.entries(data).forEach(([key, value], index) => {
      if (index == 0) {
        let join_data;
        join_data = "?" + key + "=" + value;
        pass_string = pass_string + join_data;
      } else {
        let join_data = "&" + key + "=" + value;
        pass_string = pass_string + join_data;
      }
    });
  }
  return pass_string;
};
export const AppHelper = {
  imageSrc,
};
