import { AppConfig } from "../../config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

let origin = window.location.origin;
let Environment = AppConfig.environment;
let token = AppConfig.token;

if (origin == "http://localhost:19006") {
  origin = "https://products.1superapp.com";
}

export async function api(
  url : any,
  pass_data = {},
  blob_value = false,
  image = false
) {
  let request_method = "GET";
  let formData;

  if (pass_data.hasOwnProperty("get")) {
    url = url + append_url(pass_data.get, "all");
  }
  if (pass_data.hasOwnProperty("post")) {
    request_method = "POST";
    if (!pass_data.hasOwnProperty("get")) {
      // url = url + append_url(pass_data.get, "all");
      url = url + append_url(pass_data.post, "token_only");
    }

    formData = append_data(pass_data.post, image);
  }

  if (Object.keys(pass_data).length == 0) {
    url = url + append_url(pass_data, "all");
  }

  url = AppConfig.base_url.api + url;

  const dataPromise = await trackPromise(
    make_request(request_method, url, formData, blob_value)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  );

  return dataPromise;
}

function make_request(request_method : any, url : any, formData : any, blob_value : any) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    xhr.open(request_method, url);
    if (blob_value) {
      // xhr.responseType("blob");
    }
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({
          status: true,
          status_code: xhr.status,
          status_text: xhr.statusText,
          // response: JSON.parse(xhr.response),
          response: xhr.response ? JSON.parse(xhr.response) : "No Data",
        });
      } else {
        reject({
          status: false,
          status_code: xhr.status,
          statusText: xhr.statusText,
          response: error_handler(xhr),
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: false,
        status_code: xhr.status,
        statusText: xhr.statusText,
        response: error_handler(xhr),
      });
    };
    if (formData) {
      xhr.send(formData);
    } else {
      xhr.send();
    }
  });
}

function append_url(data : any, type : any) {
  if (type == "all") {
    let pass_string = "";
    if (Object.keys(data).length > 0) {
      Object.entries(data).forEach(([key, value], index) => {
        if (index == 0) {
          let join_data;
          if (Environment == "Development") {
            join_data = "?" + key + "=" + value + "&1id_token=" + token; //Development
          } else {
            join_data = "?" + key + "=" + value; //Live
          }
          pass_string = pass_string + join_data;
        } else {
          let join_data = "&" + key + "=" + value;
          pass_string = pass_string + join_data;
        }
      });
    } else {
      let join_data;
      if (Environment == "Development") {
        join_data = "?1id_token=" + token; //Development
      } else {
        join_data = ""; //Live
      }

      pass_string = pass_string + join_data;
    }
    return pass_string;
  } else {
    let pass_string = "";
    let join_data;
    if (Environment == "Development") {
      join_data = "?1id_token=" + token; //Development
    } else {
      join_data = ""; //Live
    }
    pass_string = pass_string + join_data;

    return pass_string;
  }
}

function append_data(data : any, image : any) {
  // console.log("append_data data :", data);
  const postdata = new FormData();
  for (const items in data) {
    // console.log("append_data items :", items);
    // console.log("append_data data[items] :", data[items]);

    if (image) {
      postdata.append(items, data[items]);
    } else {
      if (data[items].isArray || isObject(data[items])) {
        postdata.append(items, JSON.stringify(data[items]));
      } else {
        postdata.append(items, data[items]);
      }
    }
  }
  return postdata;
}

function isObject(data : any) {
  return data instanceof Object;
}

function error_handler(error : any) {
  if (error.response == undefined) {
    // console.log("Network Error");
    return "Network Error";
  } else if (error.status === 401) {
    // console.log("Unauthorized Error");
    let redirect_value;
    if (Environment == "Development") {
      redirect_value = origin + "/app_dev/login?r=" + window.location.href;
    } else {
      redirect_value = origin + "/app/login?r=" + window.location.href;
    }
    console.log("redirect_value :", redirect_value);
    window.location = redirect_value;
    return "Unauthorized Error";
  }
}
