import React, { useState, useEffect, useContext, useRef } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome5,
} from '@expo/vector-icons'

import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

let myModal = {}
const animatedComponents = makeAnimated()
let state_data_value = [];
export default function ModalMrp(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [mrp_data, SetMRPData] = useState([
    {
      selected_country: {
        id: "99",
        iso: "IN",
        iso3: "IND",
        label: "India",
        name: "INDIA",
        nicename: "India",
        numcode: "356",
        phonecode: "91",
        value: "99",
      },
      country: '99',
      state_data: state_data_value,
      selected_state: {},
      state: '',
      selected_currency: {
        code: "INR",
        id: "1",
        label: "₹",
        name: "Indian Rupees",
        symbol: "₹",
        value: "1",
      },
      currency: "1",
      price: '',
      selected_date: '',
      activation_date: '',
    },
  ])

  async function get_state(c_data: any, index: any) {
    console.log('get_state')
    let pass_data = {
      get: {
        country: c_data.id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)
    SetMRPData((prevValue: any) => {
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty('data')) {
          prevValue[index].state_data = response.response.data
        }
      }
      return [...prevValue]
    })
  }
  useEffect(() => {
    if (props.open) {
      console.log('ModalMrp On Open :', props)
      myModal = new bootstrap.Modal(document.getElementById('MrpModal'), {})
      myModal.show()
      get_state_value();
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalMrp On mount :', props)
    let myModal1 = document.getElementById('MrpModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetMRPData([
        {
          selected_country: {
            id: "99",
            iso: "IN",
            iso3: "IND",
            label: "India",
            name: "INDIA",
            nicename: "India",
            numcode: "356",
            phonecode: "91",
            value: "99",
          },
          country: '99',
          state_data: state_data_value,
          selected_state: {},
          state: '',
          selected_currency: {
            code: "INR",
            id: "1",
            label: "₹",
            name: "Indian Rupees",
            symbol: "₹",
            value: "1",
          },
          currency: "1",
          price: '',
          selected_date: '',
          activation_date: '',
        },
      ])
      props.close(close_data)
    })
  }, [])

  async function get_state_value() {
    console.log('get_state_value')
    let pass_data = {
      get: {
        country: "99",
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty('data')) {
        state_data_value = response.response.data
        SetMRPData((prevalue:any)=>{
          prevalue.map((ele:any)=>{
            ele.state_data = response.response.data
          })
          return [...prevalue]
        })
      }
    }
  }


  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return (
    <div
      className="modal"
      id="MrpModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Add Mrp
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-3 pb-5 border-top overflowY-modal">
              <div className="mb-4">
                <button className="btn btn-sm text-primary btn-page d-flex align-items-center ms-auto" 
                  onClick={() => {
                    // onEditAddon()
                    SetMRPData((prevValue: any) => {
                      prevValue.push({
                        selected_country: {
                          id: "99",
                          iso: "IN",
                          iso3: "IND",
                          label: "India",
                          name: "INDIA",
                          nicename: "India",
                          numcode: "356",
                          phonecode: "91",
                          value: "99",
                        },
                        country: '99',
                        state_data: state_data_value,
                        selected_state: {},
                        state: '',
                        selected_currency: {
                          code: "INR",
                          id: "1",
                          label: "₹",
                          name: "Indian Rupees",
                          symbol: "₹",
                          value: "1",
                        },
                        currency: "1",
                        price: '',
                        selected_date: '',
                        activation_date: '',
                      })
                      return [...prevValue]
                    })
                  }}
                >
                <MaterialIcons name="add-task" size={18} color="#0053ad" />
                  <span className="text-primary ps-1 fw-bold">Add more</span>
                </button>
              </div>

              {mrp_data.map((field: any, field_index: any) => (
                <div className="row mb-2 align-items-end border-bottom pb-2" key={field_index}>
                  {/* Country */}
                  <div className="col-md-3  col-6 mb-2">
                    <label className="form-label">Country</label>
                    <Select
                      components={animatedComponents}
                      placeholder="Select Country"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={context.app_data.countries}
                      value={field.selected_country}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(e) => {
                        console.log(' Country :', e)
                        SetMRPData((prevValue: any) => {
                          prevValue[field_index].selected_country = e
                          prevValue[field_index].country = e.id
                          prevValue[field_index].selected_state = {}
                          prevValue[field_index].state = ""
                          // if (e.id == '99') {
                          //   get_state(e, field_index)
                          // }
                          return [...prevValue]
                        })
                      }}
                    />
                  </div>
                  {/* State */}
                  {field.selected_country.id == '99' ? (
                    <div className="col-md-3 col-6 mb-2">
                      <label className="form-label">State</label>
                      <Select
                        components={animatedComponents}
                        placeholder="Select State"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={field.state_data}
                        value={field.selected_state}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onChange={(e) => {
                          console.log(' state :', e)

                          SetMRPData((prevValue: any) => {
                            prevValue[field_index].selected_state = e
                            prevValue[field_index].state = e.id
                            return [...prevValue]
                          })
                        }}
                      />
                    </div>
                  ) : null}
                  {/* Price */}
                  <div className="col-md-3 col-6 mb-2">
                    <label className="form-label">Price</label>
                    <div className="input-group">
                      <div className="input-group-text p-0">
                        <Select
                          className="no-radius border-none"
                          components={animatedComponents}
                          placeholder="Select Currency"
                          isSearchable={ false }
                          classNamePrefix="select"
                          options={context.app_data.currencies}
                          value={field.selected_currency}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({ ...base, border: 'none' }),
                            indicatorSeparator: (base) => ({
                              ...base,
                              backgroundColor: 'none',
                            }),
                          }}
                          onChange={(e) => {
                            console.log(' Currency :', e)
                            SetMRPData((prevValue: any) => {
                              prevValue[field_index].selected_currency = e
                              prevValue[field_index].currency = e.id
                              return [...prevValue]
                            })
                          }}
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Price"
                        value={field.price}
                        //   disabled={read_only}
                        onChange={(e) => {
                          console.log(' Price :', e.target.value)
                          SetMRPData((prevValue: any) => {
                            prevValue[field_index].price = e.target.value
                            return [...prevValue]
                          })
                        }}
                      />
                    </div>
                  </div>
                  {/* Activation Date */}
                  <div className="col-md-2 col-6 mb-2">
                    <label className="form-label">Activation Date</label>
                    <DatePicker
                      selected={field.selected_date}
                      dateFormat="dd/MM/yyyy"
                      onSelect={(date) => {
                        let date_value = moment(date).format('DD-MM-yyyy')
                        SetMRPData((prevValue: any) => {
                          prevValue[field_index].selected_date = date
                          prevValue[field_index].activation_date = date_value
                          return [...prevValue]
                        })
                      }}
                      onChange={(date) => {
                        let date_value = moment(date).format('DD-MM-yyyy')
                        SetMRPData((prevValue: any) => {
                          prevValue[field_index].selected_date = date
                          prevValue[field_index].activation_date = date_value
                          return [...prevValue]
                        })
                      }}
                      customInput={<DatepickerInput />}

                    />
                  </div>
                  <div className="col-md-1 col-6 mb-3 text-end">
                    {field_index > 0 ? (
                     <div>
                      {/* <label className="form-label">Action</label> */}
                      <button
                        className="btn btn-secondary-outline border-none btn-sm d-flex align-items-center"
                        onClick={() => {
                          console.log('Remove')
                          SetMRPData((prevValue: any) => {
                            prevValue.splice(field_index, 1)
                            return [...prevValue]
                          })
                        }}
                      >
                        <MaterialCommunityIcons
                          name="delete-outline"
                          size={16}
                          color="#222"
                        />
                        <span className='d-sm-block d-md-none ps-2 text-dark'>Remove</span>
                      </button>
                     </div>
                    ) : null}
                  </div>
                </div>
              ))}

          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline btn-sm btn-radius px-3"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary  btn-radius px-3 "
                onClick={() => {
                  console.log('Ok')
                  let item = close_data
                  item.action = 'save'
                  item.value = mrp_data
                  item.variant_index = props.variant_index
                  item.variant_data = props.variant_data
                  SetCloseData(item)
                  myModal.hide()
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
