import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
} from "@expo/vector-icons";
import DataContext from "../../utils/Context/DataContext";

import { Ionicons, AntDesign, Feather } from "@expo/vector-icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";

const animatedComponents = makeAnimated();

let myModal = {};

const initial_value = {
  category: {},
  name: "",
  notes: "",
  is_compulsory: false,
  is_multiple: true,
  min_value: null,
  max_value: null,
};

const item_initial_value = {
  name: "",
  notes: "",
  mrp: [],
  custom_quantity: true,
};

function capitalizeFirstLetter(string : any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function ColumnNode(props : any) {
  const [lines, setLines] = useState([{ index: "", id: "" }]);
  const [c_data, set_c_data] = useState([props.data]);
  console.log("props :", props);
  console.log("ColumnNode lines :", lines);
  console.log("ColumnNode c_data :", c_data);

  return lines.map((m, index) => (
    // <div>
    //   <p>hai</p>
    <ColumnChild
      key={index}
      col_index={index}
      data={c_data[index]}
      pstate={{ lines, setLines }}
      cstate={{ c_data, set_c_data }}
      onChildSelect={(data) => {
        props.onSelect(data);
      }}
    />
    // </div>
  ));
}

function ColumnChild(props : any) {
  const { lines, setLines } = props.pstate;
  const { c_data, set_c_data } = props.cstate;
  const count = props.col_index + 1;
  const [active, SetActive] = useState(null);
  return (
    <div className="scroll-row border-right">
      {props.data.map((element, index) => (
        <div key={index}>
          <div
            className="catbut"
            onClick={(event) => {
              event.preventDefault();
              props.onChildSelect(element);

              if (element.hasOwnProperty("children")) {
                console.log("Have Child");
                console.log("count :", count);
                console.log("lines length :", lines.length);
                if (count < lines.length) {
                  console.log("<");
                  let line_data = {
                    index: index,
                    id: element.id,
                    name: element.name,
                  };
                  let c_value_data = element.children;
                  let data_a = [...lines];
                  let data_b = [...c_data];
                  data_a.splice(count);
                  data_b.splice(count);
                  data_a.push(line_data);
                  data_b.push(c_value_data);
                  setLines(data_a);
                  set_c_data(data_b);
                } else if (count == lines.length) {
                  console.log("==");
                  setLines([
                    ...lines,
                    { index: index, id: element.id, name: element.name },
                  ]);
                  set_c_data([...c_data, element.children]);
                }
                SetActive(element.id);
              } else {
                console.log("Don't Have Child");
                if (count < lines.length) {
                  console.log("remove");
                  let data_a = [...lines];
                  let data_b = [...c_data];
                  data_a.splice(count);
                  data_b.splice(count);
                  setLines(data_a);
                  set_c_data(data_b);
                }
                SetActive(element.id);
              }
            }}
          >
            <div
              className={
                element.id == active
                  ? "cursor px-2 py-3 active_cat"
                  : "cursor px-2 py-3 cat_hover"
              }
            >
              <div className="row align-items-center">
                <div className="col-2 pe-0">
                  <img
                    src={require("../../assets/icon/cookie.png")}
                    className="cat_img"
                  />
                </div>
                <p className="col-8 mb-0 ps-2 ">
                  {capitalizeFirstLetter(element.name)}
                </p>
                <span className="col-2 ps-0 ms-auto">
                  {element.hasOwnProperty("children") ? (
                    <MaterialIcons
                      name="arrow-forward-ios"
                      size={11}
                      color={element.id == active ? "#000" : "#777"}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

let state_data_value = {};

let today_date = new Date();


const OwnAccordion = (props : any) => {
  const initial_field_value = {
    selected_country: {
      id: "99",
      iso: "IN",
      iso3: "IND",
      label: "India",
      name: "INDIA",
      nicename: "India",
      numcode: "356",
      phonecode: "91",
      value: "99",
    },
    country: "99",
    state_data: state_data_value,
    selected_state: {},
    state: "",
    selected_currency: {
      code: "INR",
      id: "1",
      label: "₹",
      name: "Indian Rupees",
      symbol: "₹",
      value: "1",
    },
    currency: "1",
    price: "",
    // selected_date: "",
    // activation_date: "",
    selected_date: today_date,
    activation_date: moment(today_date).format('DD-MM-yyyy'),
  };
  const [state, SetState] = useState({
    expanded: false,
    show_add_fields: false,
  });
  const [field_data, SetFieldData] = useState([
    {
      selected_country: {
        id: "99",
        iso: "IN",
        iso3: "IND",
        label: "India",
        name: "INDIA",
        nicename: "India",
        numcode: "356",
        phonecode: "91",
        value: "99",
      },
      country: "99",
      state_data: state_data_value,
      selected_state: {},
      state: "",
      selected_currency: {
        code: "INR",
        id: "1",
        label: "₹",
        name: "Indian Rupees",
        symbol: "₹",
        value: "1",
      },
      currency: "1",
      price: "",
      // selected_date: "",
      // activation_date: "",
      selected_date: today_date,
      activation_date: moment(today_date).format('DD-MM-yyyy'),
    }
  ]);
  const [id_value, SetIdValue] = useState(useParams());
  const context = useContext(DataContext);

  // async function get_state(c_data: any, index: any) {
  //   console.log("get_state");
  //   let pass_data = {
  //     get: {
  //       country: c_data.id,
  //     },
  //   };
  //   // if (context.app_data.app_from == 'seller') {
  //   if (id_value.hasOwnProperty("brand_id")) {
  //     pass_data.get.brand = id_value.brand_id;
  //   } else {
  //     pass_data.get.m_id = id_value.m_id;
  //   }
  //   console.log("pass_data :", pass_data);
  //   let response = await api("/init/get_state", pass_data);
  //   console.log("/init/get_state response :", response);

  //   SetFieldData((prevValue: any) => {
  //     prevValue[index].selected_country = c_data;
  //     prevValue[index].country = c_data.id;
  //     if (response.status_code == 200) {
  //       if (response.response.hasOwnProperty("data")) {
  //         prevValue[index].state_data = response.response.data;
  //       }
  //     }
  //     return [...prevValue];
  //   });
  // }

  // set_save_value

  useEffect(() => {
    SetState((prevValue: any) => {
      prevValue.show_add_fields = props.edit_data;
      return { ...prevValue };
    });
    if (field_data.length == 0) {
      SetFieldData((prevValue: any) => {
        prevValue.push({
          selected_country: {
            id: "99",
            iso: "IN",
            iso3: "IND",
            label: "India",
            name: "INDIA",
            nicename: "India",
            numcode: "356",
            phonecode: "91",
            value: "99",
          },
          country: "99",
          state_data: state_data_value,
          selected_state: {},
          state: "",
          selected_currency: {
            code: "INR",
            id: "1",
            label: "₹",
            name: "Indian Rupees",
            symbol: "₹",
            value: "1",
          },
          currency: "1",
          price: "",
          // selected_date: "",
          // activation_date: "",
          selected_date: today_date,
          activation_date: moment(today_date).format('DD-MM-yyyy'),
        });
        return [...prevValue];
      });
    }
  }, [props.edit_data]);

  useEffect(() => {
    if (props.is_save) {
      // pass_data = {
      //   i
      //   data: field_data,
      // };

      // SetFieldData([
      //   {
      //     selected_country: {
      //       id: "99",
      //       iso: "IN",
      //       iso3: "IND",
      //       label: "India",
      //       name: "INDIA",
      //       nicename: "India",
      //       numcode: "356",
      //       phonecode: "91",
      //       value: "99",
      //     },
      //     country: "99",
      //     state_data: state_data_value,
      //     selected_state: {},
      //     state: "",
      //     selected_currency: {
      //       code: "INR",
      //       id: "1",
      //       label: "₹",
      //       name: "Indian Rupees",
      //       symbol: "₹",
      //       value: "1",
      //     },
      //     currency: "1",
      //     price: "",
      //     selected_date: "",
      //     activation_date: "",
      //   },
      // ]);
      props.set_save_value(field_data);
    }
  }, [props.is_save]);

  useEffect(() => {
    if (props.child_id != "") {
      SetFieldData([initial_field_value]);
    }
  }, [props.child_id]);

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return [
    <div className="">
      <div
        className={
          state.expanded
            ? "bg-lightblue border-top-radius d-flex align-items-center border-bottom cursor p-2"
            : "d-flex align-items-center border-bottom cursor p-2"
        }
        onClick={() => {
          SetState((prevValue: any) => {
            prevValue.expanded = !prevValue.expanded;
            return { ...prevValue };
          });
        }}
      >
        <p className="mb-0 text-dark">{props.field_data.name}</p>
        <div className="ms-auto"></div>
        <button
          className="btn btn-white py-0"
        // onClick={() => {
        //   SetState((prevValue: any) => {
        //     prevValue.expanded = !prevValue.expanded;
        //     return { ...prevValue };
        //   });
        // }}
        >
          {state.expanded ? (
            <MaterialIcons name="keyboard-arrow-down" size={20} color="#333" />
          ) : (
            <MaterialIcons name="keyboard-arrow-up" size={20} color="#333" />
          )}
        </button>
      </div>
    </div>,
    state.expanded && (
      <div className="p-2 border mb-2 border-bottom-radius">
        {state.show_add_fields ? (
          field_data.map((field: any, field_index: any) => (
            <div
              className="row py-2 px-1 align-items-center border-bottom"
              key={field_index}
            >
              {/* Country */}
              <div className="col-6 col-md-3 col-lg-3 mb-2">
                <label className="form-label">Country</label>
                <Select
                  components={animatedComponents}
                  placeholder="Select Country"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.countries}
                  value={field.selected_country}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    console.log(" Country :", e);
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_country = e;
                      prevValue[field_index].country = e.id;
                      prevValue[field_index].selected_state = {};
                      prevValue[field_index].state = "";
                      if (e.id = "99") {
                        // get_state(e, field_index);
                      }
                      return [...prevValue];
                    });
                  }}
                />
              </div>
              {/* State */}
              {field.selected_country.id == "99" ? (
                <div className="col-6 col-md-3 mb-2">
                  <label className="form-label">State</label>
                  <Select
                    components={animatedComponents}
                    placeholder="Select State"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={field.state_data}
                    value={field.selected_state}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                      console.log(" state :", e);
                      SetFieldData((prevValue: any) => {
                        prevValue[field_index].selected_state = e;
                        prevValue[field_index].state = e.id;
                        return [...prevValue];
                      });
                    }}
                  />
                </div>
              ) : null}
              {/* Currency */}
              {/* <div className="col-md-3 col-lg-2 mb-2">
                <label className="form-label">Currency</label>
                <Select
                  components={animatedComponents}
                  placeholder="Select Currency"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.currencies}
                  value={field.selected_currency}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    console.log(" Currency :", e);

                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_currency = e;
                      prevValue[field_index].currency = e.id;
                      return [...prevValue];
                    });
                  }}
                />
              </div> */}
              {/* Price */}
              <div className="col-6 col-md-3 mb-2">
                <label className="form-label ">Price</label>
                <div className="input-group">
                  <div className="input-group-text p-0">
                    <Select
                      className="no-radius border-none"
                      components={animatedComponents}
                      placeholder="Select Currency"
                      classNamePrefix="select"
                      isSearchable={false}
                      options={context.app_data.currencies}
                      value={field.selected_currency}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (base) => ({ ...base, border: "none" }),
                        indicatorSeparator: (base) => ({
                          ...base,
                          backgroundColor: "none",
                        }),
                      }}
                      onChange={(e) => {
                        console.log(" Currency :", e);

                        SetFieldData((prevValue: any) => {
                          prevValue[field_index].selected_currency = e;
                          prevValue[field_index].currency = e.id;
                          return [...prevValue];
                        });
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Price"
                    value={field.price}
                    //   disabled={read_only}
                    onChange={(e) => {
                      console.log(" Price :", e.target.value);
                      SetFieldData((prevValue: any) => {
                        prevValue[field_index].price = e.target.value;
                        return [...prevValue];
                      });
                    }}
                  />
                </div>
              </div>
              {/* Activation Date */}
              <div className="col-6 col-md-3 col-lg-2 mb-2 pe-0">
                <label className="form-label">Activation Date</label>
                <DatePicker
                  selected={field.selected_date}
                  dateFormat="dd/MM/yyyy"
                  customInput={<DatepickerInput />}
                  className="py-2"
                  onSelect={(date) => {
                    let date_value = moment(date).format("DD-MM-yyyy");
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_date = date;
                      prevValue[field_index].activation_date = date_value;
                      return [...prevValue];
                    });
                  }}
                  onChange={(date) => {
                    let date_value = moment(date).format("DD-MM-yyyy");
                    SetFieldData((prevValue: any) => {
                      prevValue[field_index].selected_date = date;
                      prevValue[field_index].activation_date = date_value;
                      return [...prevValue];
                    });
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="col-12 col-md-1 end">
                <button
                  className="btn btn-sm end"
                  onClick={() => {
                    console.log("Remove");
                    SetFieldData((prevValue: any) => {
                      prevValue.splice(field_index, 1);
                      return [...prevValue];
                    });
                  }}
                >
                  {/* <span className="pe-1"> */}
                  <MaterialCommunityIcons
                    name="delete-outline"
                    size={16}
                    color="black"
                  />
                  <span className="d-sm-block d-md-none ps-1">Remove</span>
                </button>
                {/* <button
                  onClick={() => {
                    console.log("field_data :", field_data);
                  }}
                >
                  Check
                </button> */}
              </div>
            </div>
          ))
        ) : (
          <div className="mb-2 scrollX">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="text-darkgray">
                    #
                  </th>
                  <th scope="col" className="text-darkgray">
                    Country
                  </th>
                  <th scope="col" className="text-darkgray">
                    State
                  </th>
                  <th scope="col" className="text-darkgray">
                    Currency
                  </th>
                  <th scope="col" className="text-darkgray">
                    Price
                  </th>
                  <th scope="col" className="text-darkgray">
                    Activation Date
                  </th>
                </tr>
              </thead>
              <tbody className="border-bottom">
                {!props.is_dependent ? (
                  props.field_data.hasOwnProperty("addon_group_mrp") ? (
                    props.field_data.addon_group_mrp.map(
                      (field_ele: any, field_index: any) => (
                        <tr key={field_index}>
                          <th scope="row text-gary">{field_index + 1}</th>
                          <td className="text-gray">{field_ele.country}</td>
                          {field_ele.hasOwnProperty("state") ? (
                            <td className="text-gray">{field_ele.state}</td>
                          ) : (
                            <td className="text-gray">-</td>
                          )}
                          <td className="text-gray">
                            {field_ele.currency} - {field_ele.currency_symbol}
                          </td>
                          <td className="text-gray">{field_ele.price}</td>
                          <td className="text-gray">
                            {field_ele.activation_date}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr className="center w-100">No data</tr>
                  )
                ) : (
                  props.field_data.varriant.map(
                    (spec_ele: any, spec_index: any) =>
                      props.selected_child_item.value == spec_ele.id ? (
                        spec_ele.hasOwnProperty("addon_group_mrp") &&
                          spec_ele.addon_group_mrp.length > 0 ? (
                          spec_ele.addon_group_mrp.map(
                            (field_ele: any, field_index: any) => (
                              <tr key={field_index}>
                                <th scope="row text-gray">{field_index + 1}</th>
                                <td className="text-gray">
                                  {field_ele.country}
                                </td>
                                {field_ele.hasOwnProperty("state") ? (
                                  <td className="text-gray">
                                    {field_ele.state}
                                  </td>
                                ) : (
                                  <td className="text-gray">-</td>
                                )}
                                <td className="text-gray">
                                  {field_ele.currency} -{" "}
                                  {field_ele.currency_symbol}
                                </td>
                                <td className="text-gray">{field_ele.price}</td>
                                <td className="text-gray">
                                  {field_ele.activation_date}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <div className="center w-100">
                            <p>No Mrp Data</p>
                          </div>
                        )
                      ) : null
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="center mt-3 mb-4">
          {state.show_add_fields ? (
            <button
              className="btn btn-page d-flex align-items-center"
              onClick={() => {
                let push_data = {
                  selected_country: {
                    id: "99",
                    iso: "IN",
                    iso3: "IND",
                    label: "India",
                    name: "INDIA",
                    nicename: "India",
                    numcode: "356",
                    phonecode: "91",
                    value: "99",
                  },
                  country: "99",
                  state_data: state_data_value,
                  selected_state: {},
                  state: "",
                  selected_currency: {
                    code: "INR",
                    id: "1",
                    label: "₹",
                    name: "Indian Rupees",
                    symbol: "₹",
                    value: "1",
                  },
                  currency: "1",
                  price: "",
                  // selected_date: "",
                  // activation_date: "",
                  selected_date: today_date,
                  activation_date: moment(today_date).format('DD-MM-yyyy'),
                };
                SetFieldData((prevValue: any) => {
                  prevValue.push(push_data);
                  return [...prevValue];
                });
              }}
            >
              <Ionicons name="ios-add-outline" size={16} color="black" />
              <span className="ps-1 ">Add More</span>
            </button>
          ) : null}
        </div>
      </div>
    ),
  ];
};

// const OwnAccordianNew = (props : any) => {
//   const initial_field_value = {
//     selected_country: {
//       id: "99",
//       iso: "IN",
//       iso3: "IND",
//       label: "India",
//       name: "INDIA",
//       nicename: "India",
//       numcode: "356",
//       phonecode: "91",
//       value: "99",
//     },
//     country: "99",
//     state_data: state_data_value,
//     selected_state: {},
//     state: "",
//     selected_currency: {
//       code: "INR",
//       id: "1",
//       label: "₹",
//       name: "Indian Rupees",
//       symbol: "₹",
//       value: "1",
//     },
//     currency: "1",
//     price: "",
//     selected_date: "",
//     activation_date: "",
//   };
//   const [state, SetState] = useState({
//     expanded: false,
//     show_add_fields: false,
//   });
//   const [field_data, SetFieldData] = useState([
//     {
//       selected_country: {
//         id: "99",
//         iso: "IN",
//         iso3: "IND",
//         label: "India",
//         name: "INDIA",
//         nicename: "India",
//         numcode: "356",
//         phonecode: "91",
//         value: "99",
//       },
//       country: "99",
//       state_data: state_data_value,
//       selected_state: {},
//       state: "",
//       selected_currency: {
//         code: "INR",
//         id: "1",
//         label: "₹",
//         name: "Indian Rupees",
//         symbol: "₹",
//         value: "1",
//       },
//       currency: "1",
//       price: "",
//       selected_date: "",
//       activation_date: "",
//     }
//   ]);
//   const [id_value, SetIdValue] = useState(useParams());
//   const context = useContext(DataContext);

//   useEffect(() => {
//     SetState((prevValue: any) => {
//       prevValue.show_add_fields = props.edit_data;
//       return { ...prevValue };
//     });
//     if (field_data.length == 0) {
//       SetFieldData((prevValue: any) => {
//         prevValue.push({
//           selected_country: {
//             id: "99",
//             iso: "IN",
//             iso3: "IND",
//             label: "India",
//             name: "INDIA",
//             nicename: "India",
//             numcode: "356",
//             phonecode: "91",
//             value: "99",
//           },
//           country: "99",
//           state_data: state_data_value,
//           selected_state: {},
//           state: "",
//           selected_currency: {
//             code: "INR",
//             id: "1",
//             label: "₹",
//             name: "Indian Rupees",
//             symbol: "₹",
//             value: "1",
//           },
//           currency: "1",
//           price: "",
//           selected_date: "",
//           activation_date: "",
//         });
//         return [...prevValue];
//       });
//     }
//   }, [props.edit_data]);

//   useEffect(() => {
//     if (props.is_save) {
//       props.set_save_value(field_data);
//     }
//   }, [props.is_save]);

//   useEffect(() => {
//     if (props.child_id != "") {
//       SetFieldData([initial_field_value]);
//     }
//   }, [props.child_id]);

//   const DatepickerInput = ({ ...props }) => (
//     <input type="text" {...props} readOnly />
//   );

//   return (
//     <>
//       <div className="">
//         <div
//           className={
//             state.expanded
//               ? "bg-lightblue border-top-radius d-flex align-items-center border-bottom cursor p-2"
//               : "d-flex align-items-center border-bottom cursor p-2"
//           }
//           onClick={() => {
//             SetState((prevValue: any) => {
//               prevValue.expanded = !prevValue.expanded;
//               return { ...prevValue };
//             });
//           }}
//         >
//           <p className="mb-0 text-dark">{props.field_data.name}</p>
//           <div className="ms-auto"></div>
//           <button
//             className="btn btn-white py-0"
//           >
//             {state.expanded ? (
//               <MaterialIcons name="keyboard-arrow-down" size={20} color="#333" />
//             ) : (
//               <MaterialIcons name="keyboard-arrow-up" size={20} color="#333" />
//             )}
//           </button>
//         </div>
//       </div>
//       {state.expanded && (
//         <div className="p-2 border mb-2 border-bottom-radius">
//           {!props.is_dependent ? (
//             field_data.map((field: any, field_index: any) => (
//               <div
//                 className="row py-2 px-1 align-items-center border-bottom"
//                 key={field_index}
//               >
//                 {/* Country */}
//                 <div className="col-6 col-md-3 col-lg-3 mb-2">
//                   <label className="form-label">Country</label>
//                   <Select
//                     components={animatedComponents}
//                     placeholder="Select Country"
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     options={context.app_data.countries}
//                     value={field.selected_country}
//                     menuPortalTarget={document.body}
//                     styles={{
//                       menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                     }}
//                     onChange={(e) => {
//                       console.log(" Country :", e);
//                       SetFieldData((prevValue: any) => {
//                         prevValue[field_index].selected_country = e;
//                         prevValue[field_index].country = e.id;
//                         prevValue[field_index].selected_state = {};
//                         prevValue[field_index].state = "";
//                         if ((e.id = "99")) {
//                           // get_state(e, field_index);
//                         }
//                         return [...prevValue];
//                       });
//                     }}
//                   />
//                 </div>
//                 {/* State */}
//                 {field.selected_country.id == "99" ? (
//                   <div className="col-6 col-md-3 mb-2">
//                     <label className="form-label">State</label>
//                     <Select
//                       components={animatedComponents}
//                       placeholder="Select State"
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       options={field.state_data}
//                       value={field.selected_state}
//                       menuPortalTarget={document.body}
//                       styles={{
//                         menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                       }}
//                       onChange={(e) => {
//                         console.log(" state :", e);
//                         SetFieldData((prevValue: any) => {
//                           prevValue[field_index].selected_state = e;
//                           prevValue[field_index].state = e.id;
//                           return [...prevValue];
//                         });
//                       }}
//                     />
//                   </div>
//                 ) : null}
//                 {/* Currency */}
//                 {/* <div className="col-md-3 col-lg-2 mb-2">
//                           <label className="form-label">Currency</label>
//                           <Select
//                             components={animatedComponents}
//                             placeholder="Select Currency"
//                             className="basic-multi-select"
//                             classNamePrefix="select"
//                             options={context.app_data.currencies}
//                             value={field.selected_currency}
//                             menuPortalTarget={document.body}
//                             styles={{
//                               menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                             }}
//                             onChange={(e) => {
//                               console.log(" Currency :", e);

//                               SetFieldData((prevValue: any) => {
//                                 prevValue[field_index].selected_currency = e;
//                                 prevValue[field_index].currency = e.id;
//                                 return [...prevValue];
//                               });
//                             }}
//                           />
//                         </div> */}
//                 {/* Price */}
//                 <div className="col-6 col-md-3 mb-2">
//                   <label className="form-label ">Price</label>
//                   <div className="input-group">
//                     <div className="input-group-text p-0">
//                       <Select
//                         className="no-radius border-none"
//                         components={animatedComponents}
//                         placeholder="Select Currency"
//                         classNamePrefix="select"
//                         isSearchable={false}
//                         options={context.app_data.currencies}
//                         value={field.selected_currency}
//                         menuPortalTarget={document.body}
//                         styles={{
//                           menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                           control: (base) => ({ ...base, border: "none" }),
//                           indicatorSeparator: (base) => ({
//                             ...base,
//                             backgroundColor: "none",
//                           }),
//                         }}
//                         onChange={(e) => {
//                           console.log(" Currency :", e);

//                           SetFieldData((prevValue: any) => {
//                             prevValue[field_index].selected_currency = e;
//                             prevValue[field_index].currency = e.id;
//                             return [...prevValue];
//                           });
//                         }}
//                       />
//                     </div>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Price"
//                       value={field.price}
//                       //   disabled={read_only}
//                       onChange={(e) => {
//                         console.log(" Price :", e.target.value);
//                         SetFieldData((prevValue: any) => {
//                           prevValue[field_index].price = e.target.value;
//                           return [...prevValue];
//                         });
//                       }}
//                     />
//                   </div>
//                 </div>
//                 {/* Activation Date */}
//                 <div className="col-6 col-md-3 col-lg-2 mb-2 pe-0">
//                   <label className="form-label">Activation Date</label>
//                   <DatePicker
//                     selected={field.selected_date}
//                     dateFormat="dd/MM/yyyy"
//                     customInput={<DatepickerInput />}
//                     className="py-2"
//                     onSelect={(date) => {
//                       let date_value = moment(date).format("DD-MM-yyyy");
//                       SetFieldData((prevValue: any) => {
//                         prevValue[field_index].selected_date = date;
//                         prevValue[field_index].activation_date = date_value;
//                         return [...prevValue];
//                       });
//                     }}
//                     onChange={(date) => {
//                       let date_value = moment(date).format("DD-MM-yyyy");
//                       SetFieldData((prevValue: any) => {
//                         prevValue[field_index].selected_date = date;
//                         prevValue[field_index].activation_date = date_value;
//                         return [...prevValue];
//                       });
//                     }}
//                     onKeyDown={(e) => {
//                       e.preventDefault();
//                     }}
//                   />
//                 </div>
//                 <div className="col-12 col-md-1 end">
//                   <button
//                     className="btn btn-sm end"
//                     onClick={() => {
//                       console.log("Remove");
//                       SetFieldData((prevValue: any) => {
//                         prevValue.splice(field_index, 1);
//                         return [...prevValue];
//                       });
//                     }}
//                   >
//                     {/* <span className="pe-1"> */}
//                     <MaterialCommunityIcons
//                       name="delete-outline"
//                       size={16}
//                       color="black"
//                     />
//                     <span className="d-sm-block d-md-none ps-1">Remove</span>
//                   </button>
//                   {/* <button
//                             onClick={() => {
//                               console.log("field_data :", field_data);
//                             }}
//                           >
//                             Check
//                           </button> */}
//                 </div>
//               </div>
//             ))
//           ) : null}

//         </div>
//       )}
//     </>
//   )
// }

export default function ModalCreateAddonGroup(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());
  const [country_option, SetCountryOption] = useState([]);
  const [save_data, SetSaveData] = useState(initial_value);
  const [modal_type, SetModalType] = useState("");
  const [read_only, SetReadOnly] = useState(false);
  const [all_addon_group_data, SetAllAddonGroupData] = useState([]);
  const [timer, SetTimer] = useState(null);
  const [category_data, SetCategoryData] = useState([]);
  const [addon_item_data, SetAddonItemData] = useState([]);
  const [filter_addon_item_data, SetFilterAddonItemData] = useState([]);
  const [view_data, SetViewData] = useState("main");
  const [addon_item_search, SetAddonItemSearch] = useState("");
  const [selected_category, SetSelectedCategory] = useState({});
  const [error_data, SetErrorData] = useState("");
  const [child_items, SetChildItems] = useState([]);

  //
  const [selected_addon_data, SetSelectedAddonData] = useState([]);
  const [available_addon_data, SetAvailableAddonData] = useState([]);
  const [addon_group_selected, SetAddonGroupSelected] = useState({
    is_open: false,
    data: {},
  });
  const [seleceted_addon_group_form, SetSelecetedAddonGroupForm] = useState({
    is_dependent: false,
    dependent_id: "",
    varriant_data: [],
    addon_child_varriant_data: [],
  });

  const [edit_addon_price, SetEditAddonPrice] = useState(false);
  const [edit_independent_addon_price, SetEditInDependentAddonPrice] =
    useState(false);
  // const [edit_independent_addon_price, SetEditInDependentAddonPrice] =
  //   useState(true);
  const [item_save_data, SetItemSaveData] = useState(item_initial_value);
  const [mrp_data, SetMrpData] = useState([
    {
      selected_country: {
        id: "99",
        iso: "IN",
        iso3: "IND",
        label: "India",
        name: "INDIA",
        nicename: "India",
        numcode: "356",
        phonecode: "91",
        value: "99",
      },
      country: "99",
      state_data: [],
      selected_state: {},
      state: "",
      selected_currency: {
        code: "INR",
        id: "1",
        label: "₹",
        name: "Indian Rupees",
        symbol: "₹",
        value: "1",
      },
      currency: "1",
      price: "",
      // selected_date: "",
      // activation_date: "",
      selected_date: today_date,
      activation_date: moment(today_date).format('DD-MM-yyyy'),
    },
  ]);
  const [is_edit, SetIsEdit] = useState(false);
  // const [is_edit, SetIsEdit] = useState(true);
  const [is_save, SetIsSave] = useState(false);
  const [save_data_in_dependent, SetSaveDataInDependent] = useState([]);
  const [save_data_dependent, SetSaveDataDependent] = useState([]);
  const [selected_specification, SetSelectedSpecification] = useState({});
  const [selected_child_item, SetSelectedChildItem] = useState({});

  const context = useContext(DataContext);

  const WAIT_INTERVAL = 300;

  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: "1",
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
    spec: [],
    mrp: [1, 2, 3, 4],
    addon_groups: [],
    category_id: "",
  });

  const [remove_selected_item, SetRemoveSelectedItem] = useState([]);
  const [error_state, SetErrorState] = useState({});

  const [item_filter_data, SetItemFilterData] = useState({
    page_no: 1,
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
  });

  const [selected_variant, SetSelectedVariant] = useState({});

  const [spec_data,SetSpecData] = useState([])

  useEffect(() => {
    if (props.open) {
      console.log("AddonGroupModal On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("AddonGroupModal"),
        {}
      );
      myModal.show();
      SetModalType(props.type);
      SetAllAddonGroupData(props.all_data);
      // SetCloseData({
      //   action: 'close',
      // })
      get_state_value();
      if (props.from == "addon_group") {
        if (props.type == "create") {
          get_category();
          get_all_addon_item(item_filter_data, false);
          SetChildItems([])
          SetAddonItemData([])
        }

        if (props.type == "view") {
          SetReadOnly(true);
          process_data();
        }

        if (props.type == "edit") {
          console.log("props on edit :", props);
          SetReadOnly(false);
          if (props.data.hasOwnProperty("update_request")) {
            if (Object.keys(props.data.update_request).length > 0) {
              edit_propcess_data();
            } else {
              process_data();
            }
          } else {
            process_data();
          }

          // get_all_addon_item()
          get_all_addon_item(item_filter_data, false);
        }
      }

      if (props.from == "add_product") {
        // get_all_addon_item()
        get_all_addon_item(item_filter_data, false);
        console.log("From Add Product :", props);
        SetViewData("list");
        SetSelectedAddonData(props.addon_data);
        let available_addon_data_value = [];
        if (props.addon_data.length > 0) {
          if (props.all_data != undefined && props.all_data.length > 0) {
            available_addon_data_value = all_addon_group_data.filter((el) => {
              return !selected_addon_data.find((element) => {
                return element.value === el.id;
              });
            });
          } else {
            available_addon_data_value = [];
          }
        } else {
          available_addon_data_value = props.all_data;
        }
        console.log("available_addon_data_value :", available_addon_data_value);

        SetAvailableAddonData(available_addon_data_value);
        SetSaveData((prevValue: any) => {
          prevValue.category = props.category;
          return { ...prevValue };
        });

        console.log("props.variant_data :", props.variant_data);
        SetSelecetedAddonGroupForm((prevValue: any) => {
          prevValue.varriant_data = props.variant_data;
          return { ...prevValue };
        });
      }

      if (props.from == "product_details") {
        get_addon_by_category(filter_data);
        SetViewData("list");
      }

      get_specification();
    }
  }, [props.open]);

  async function get_state_value() {
    console.log("get_state");
    let pass_data = {
      get: {
        country: "99",
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let response = await api("/init/get_state", pass_data);
    console.log("/init/get_state response :", response);
    if (response.status_code == 200) {
      // SetFieldData((prevValue: any) => {
      //     if (response.response.hasOwnProperty("data")) {
      //       prevValue[0].state_data = response.response.data;
      //     }
      //   return [...prevValue];
      // });
      state_data_value = response.response.data
    }

  }

  useEffect(() => {
    console.log("AddonGroupModal On mount :", props);
    let myModal1 = document.getElementById("AddonGroupModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("even listen on close")
      // SetIdValue(useParams())
      // SetCountryOption([])
      // SetModalType('')
      // SetAllAddonGroupData([])
      // SetTimer(null)
      // SetCategoryData([])
      SetAddonItemData([])
      // SetFilterAddonItemData([])
      // SetAddonItemSearch('')
      // SetSelectedCategory({})
      SetErrorData("");
      SetSaveData({
        category: {},
        name: "",
        notes: "",
        is_compulsory: false,
        is_multiple: true,
        min_value: 0,
        max_value: 0,
      });
      SetReadOnly(false);
      SetViewData("main");
      SetMrpData([
        {
          selected_country: {},
          country: "",
          state_data: [],
          selected_state: {},
          state: "",
          selected_currency: {},
          currency: "",
          price: "",
          selected_date: "",
          activation_date: "",
        },
      ]);
      SetItemSaveData({
        name: "",
        notes: "",
        mrp: [],
        custom_quantity: true,
      });
      SetSelecetedAddonGroupForm({
        is_dependent: false,
        dependent_id: "",
        varriant_data: [],
        addon_child_varriant_data: [],
      });
      SetAddonGroupSelected({
        is_open: false,
        data: {},
      });
      SetSaveDataInDependent([]);
      SetSaveDataDependent([]);
      SetSelectedSpecification({});
      SetSelectedChildItem({});
      SetRemoveSelectedItem([]);
      SetErrorState({});
      SetSelectedVariant({});
      SetIsEdit(false);
      SetIsSave(false);
      SetEditInDependentAddonPrice(false);
      SetEditAddonPrice(false);
      // SetAvailableAddonData([])
      // SetSelectedAddonData([])
      SetChildItems([])
      SetItemFilterData({
        page_no: 1,
        next_page: false,
        count: { label: "All", value: "all" },
        count_option: [
          { label: "All", value: "all" },
          { label: "10", value: "10" },
          { label: "20", value: "20" },
          { label: "30", value: "30" },
        ],
        sort: { label: "asc", value: "asc" },
        sort_option: [
          { label: "asc", value: "asc" },
          { label: "desc", value: "desc" },
        ],
        search: "",
      })
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (item_filter_data.search == "") {
      get_all_addon_item(item_filter_data, false);
    } else {
      const timeOutId = setTimeout(
        () => triggerItemChange1(item_filter_data.search),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [item_filter_data.search]);

  function triggerItemChange1(value) {
    SetItemFilterData((prevValue: any) => {
      // prevValue.page_no = prevValue.page_no + 1
      prevValue.page_no = 1;
      get_all_addon_item(prevValue, false);
      return { ...prevValue };
    });
  }

  function process_data() {
    SetSaveData((prevValue) => {
      prevValue.name = props.data.name;
      prevValue.notes = props.data.notes;
      let category_data = {
        id: props.data.category_id,
        name: props.data.category_name,
      };
      prevValue.category = category_data;
      prevValue.is_compulsory = props.data.compulsory;

      if (props.data.type == "multiple") {
        prevValue.is_multiple = true;
        prevValue.min_value = props.data.min_select;
        prevValue.max_value = props.data.max_select;
      } else {
        prevValue.is_multiple = false;
      }
      return { ...prevValue };
    });
    if (props.data.hasOwnProperty("children")) {
      SetChildItems(props.data.children);
    } else {
      SetChildItems([]);
    }
  }

  function edit_propcess_data() {
    SetSaveData((prevValue) => {
      prevValue.name = props.data.update_request.name;
      prevValue.notes = props.data.update_request.notes;
      let category_data = {
        id: props.data.category_id,
        name: props.data.category_name,
      };
      prevValue.category = category_data;
      prevValue.is_compulsory = props.data.update_request.compulsory;

      if (props.data.update_request.type == "multiple") {
        prevValue.is_multiple = true;
        prevValue.min_value = props.data.update_request.min_select;
        prevValue.max_value = props.data.update_request.max_select;
      } else {
        prevValue.is_multiple = false;
      }
      return { ...prevValue };
    });
    if (props.data.hasOwnProperty("children")) {
      SetChildItems(props.data.children);
    } else {
      SetChildItems([]);
    }
  }

  async function get_category() {
    let pass_data = {
      get: {},
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/get_all_categories", pass_data);
    console.log("/product/get_all_categories :", res_data);
    if (res_data.response.hasOwnProperty("data")) {
      SetCategoryData(res_data.response.data);
    }
  }

  async function get_addon_by_category(filter_value : any) {
    // let pass_data = {
    //   get: {
    //     category_id: props.category_id,
    //   },
    // };
    // // if (context.app_data.app_from == "seller") {
    // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id;
    // } else {
    //   pass_data.get.m_id = id_value.m_id;
    // }
    // let res_data = await api("/product/get_addon_groups", pass_data);
    // console.log("/product/get_addon_groups :", res_data);
    // if (res_data.status_code == 200) {
    //   if (res_data.response.hasOwnProperty("data")) {
    //     let addon_data = res_data.response.data;
    //     SetAddonItemData(res_data.response.data);
    //     SetFilterAddonItemData(res_data.response.data);
    //     let available_addon = [];
    //     addon_data.map((addon_ele: any, addon_index: any)=>{
    //       if (!props.added_addon.some((s_ele) => s_ele.id == addon_ele.id)) {
    //           available_addon.push(addon_ele);
    //           }
    //     })

    //     SetSelecetedAddonGroupForm((prevValue: any) =>{
    //       prevValue.varriant_data = props.group_spec_data
    //       return { ...prevValue}
    //     })

    //     SetAvailableAddonData(available_addon);
    //   }
    // }

    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        spec: filter_value.spec,
        mrp: filter_value.mrp,
        addon_groups: filter_value.addon_groups,
        category_id: props.category_id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let res_data = await api("/product/get_all_addon_groups", pass_data);
    console.log("/product/get_all_addon_groups response :", res_data);
    if (res_data.status_code == 200) {
      //     let addon_data = res_data.response.data;
      //     SetAddonItemData(res_data.response.data);
      //     SetFilterAddonItemData(res_data.response.data);
      //     let available_addon = [];
      //     addon_data.map((addon_ele: any, addon_index: any)=>{
      //       if (!props.added_addon.some((s_ele) => s_ele.id == addon_ele.id)) {
      //           available_addon.push(addon_ele);
      //           }
      //     })
      //     SetSelecetedAddonGroupForm((prevValue: any) =>{
      //       prevValue.varriant_data = props.group_spec_data
      //       return { ...prevValue}
      //     })
      //     SetAvailableAddonData(available_addon);

      if (res_data.response.hasOwnProperty("addon")) {
        let addon_data = res_data.response.addon;
        SetAddonItemData(res_data.response.addon);
        SetFilterAddonItemData(res_data.response.addon);
        let available_addon = [];
        addon_data.map((addon_ele: any, addon_index: any) => {
          if (!props.added_addon.some((s_ele) => s_ele.id == addon_ele.id)) {
            available_addon.push(addon_ele);
          }
        });

        SetSelecetedAddonGroupForm((prevValue: any) => {
          prevValue.varriant_data = props.group_spec_data;
          return { ...prevValue };
        });

        SetAvailableAddonData(available_addon);
      }
    }
  }

  async function get_all_addon_item(filter_value : any , fetch_more : any) {
    console.log("get_all_addon_item filter_value :", filter_value);
    console.log("get_all_addon_item fetch_more :", fetch_more);
    let pass_data = {
      get: {
        page: filter_value.page_no,
        search: filter_value.search,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data : any = await api("/product/get_all_addon_items", pass_data);
    console.log("/product/get_all_addon_items :", res_data);
    if (res_data.response.hasOwnProperty("data")) {
      if (fetch_more) {
        if (props.type != "edit") {
          SetAddonItemData((prevValue: any) => {
            // res_data.response.data
            let old_data = prevValue,
              resp_data = res_data.response.data,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;
            return [...prevValue];
          });
          SetFilterAddonItemData((prevValue: any) => {
            // res_data.response.data
            let old_data = prevValue,
              resp_data = res_data.response.data,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;
            return [...prevValue];
          });
        } else {
          let available_addon_item = props.data.children;
          let item_value = res_data.response.data;
          item_value.map((ele: any) => {
            if (
              available_addon_item.some(
                (s_ele) => s_ele.addon_items_id == ele.id
              )
            ) {
              ele.checked = true;
              ele.already_checked = true;
            } else {
              ele.checked = false;
              ele.already_checked = false;
            }
          });
          SetAddonItemData((prevValue: any) => {
            // res_data.response.data
            let old_data = prevValue,
              resp_data = item_value,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;
            return [...prevValue];
          });
          SetFilterAddonItemData((prevValue: any) => {
            // res_data.response.data
            let old_data = prevValue,
              resp_data = item_value,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;
            return [...prevValue];
          });
        }
      } else {
        if (props.type != "edit") {
          // SetAddonItemData(res_data.response.data);
          // SetFilterAddonItemData(res_data.response.data);

          let res_data_value = res_data.response.data;
          SetAddonItemData((prevalue1)=>{

            if(res_data_value.length > 0){
              if(filter_value.search != ""){
                prevalue1 = prevalue1
              }else{
                res_data_value.map((ele:any)=>{
                  let aray_value : any = prevalue1.find((f_ele:any) => f_ele.id == ele.id);
                  if(aray_value != undefined && aray_value.checked){
                    ele.checked = true;
                  }
                })
                prevalue1 = res_data_value;
              }
            }else{
              // prevalue1 = res_data_value
              prevalue1 = prevalue1
            }

            
            
            SetFilterAddonItemData((prevalue)=>{
              if(filter_value.search != ""){
                let new_value = res_data.response.data;
                if(new_value.length > 0){
                  new_value.map((ele:any)=>{
                    let aray_value : any = prevalue1.find((f_ele:any) => f_ele.id == ele.id);
                    if(aray_value != undefined && aray_value.checked){
                      ele.checked = true;
                    }
                  })
                  prevalue = new_value;
                }else{
                  prevalue = res_data.response.data
                }
                
              }else{
                prevalue = res_data.response.data
              }
              return [...prevalue]
            });
            return [...prevalue1]
          });

        } else {
          let available_addon_item = props.data.children;
          let item_value = res_data.response.data;
          item_value.map((ele: any) => {
            if (
              available_addon_item.some(
                (s_ele) => s_ele.addon_items_id == ele.id
              )
            ) {
              ele.checked = true;
              ele.already_checked = true;
            } else {
              ele.checked = false;
              ele.already_checked = false;
            }
          });
          // SetAddonItemData(item_value);
          SetAddonItemData((prevalue)=>{
            let available_addon_item = props.data.children;
            // let item_value = prevalue;
            prevalue.map((ele: any) => {
              if (
                available_addon_item.some(
                  (s_ele) => s_ele.addon_items_id == ele.id
                )
              ) {
                ele.checked = true;
                ele.already_checked = true;
              } else {
                ele.checked = false;
                ele.already_checked = false;
              }
            });
            return [...prevalue]
          });
          SetFilterAddonItemData(item_value);
        }
      }

      // if (props.type != 'edit') {
      //   SetAddonItemData(res_data.response.data)
      //   SetFilterAddonItemData(res_data.response.data)
      // } else {
      //   let available_addon_item = props.data.children
      //   let item_value = res_data.response.data
      //   item_value.map((ele: any) => {
      //     if (
      //       available_addon_item.some((s_ele) => s_ele.addon_items_id == ele.id)
      //     ) {
      //       ele.checked = true
      //       ele.already_checked = true
      //     } else {
      //       ele.checked = false
      //       ele.already_checked = false
      //     }
      //   })
      //   SetAddonItemData(item_value)
      //   SetFilterAddonItemData(item_value)
      // }
    }
    SetItemFilterData((prevValue: any) => {
      prevValue.next_page = res_data.response.next_page;
      return { ...prevValue };
    });
  }

  function addon_group_validate() {
    let nameError = "";
    let catError = "";
    let noteError = "";
    let childError = "";
    let MinError = "";
    let MaxError = "";


    if (Object.keys(save_data.category).length == 0) {
      catError = "Category is required ";
    }

    if (!save_data.name) {
      nameError = "Name field is required";
    }

    if (save_data.is_multiple) {
      if(save_data.min_value && save_data.max_value != null){
        if (save_data.min_value >= save_data.max_value) {
          MinError = "Min value must be less than max value"
        } else {
          MinError = "";
        }
        if (save_data.max_value <= save_data.min_value) {
          MaxError = "Max value must be greater than min value"
        } else {
          MaxError = "";
        }
      }

    }

    // if (!save_data.notes) {
    //   noteError = 'Note field is required'
    // }

    if (!addon_item_data.some((ele) => ele.checked)) {
      childError = "Addon Item is required";
    }

    // if (nameError || catError || noteError || childError) {
    //   SetErrorState({ nameError, catError, noteError, childError })
    //   return false
    // }

    if (nameError || catError || childError || MinError || MaxError) {
      SetErrorState({ nameError, catError, childError, MinError, MaxError });
      return false;
    }

    return true;
  }

  async function create_addon_group() {
    // if (Object.keys(save_data.category).length == 0) {
    //   toast.error('Choose Category')
    // } else if (save_data.name == '') {
    //   toast.error('Enter Addon Group Name')
    // } else {
    if (addon_group_validate()) {
      console.log("validate ok");
      let addon_value = [];
      let push_data = {
        name: save_data.name,
        notes: save_data.notes,
        category_id: save_data.category.id,
        addons: [],
      };
      if (save_data.is_compulsory) {
        push_data.compulsory = true;
      }
      if (save_data.is_multiple) {
        if (save_data.min_value != "") {
          push_data.min = save_data.min_value;
        }
        if (save_data.max_value != "") {
          push_data.max = save_data.max_value;
        }
      } else {
        push_data.single = true;
      }
      let addons_push_data = [];
      addon_item_data.map((ele: any) => {
        if (ele.checked) {
          addons_push_data.push({ addon_id: ele.id });
        }
      });
      push_data.addons = addons_push_data;
      addon_value.push(push_data);
      let pass_data = {
        get: {},
        post: {
          addons: addon_value,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("Pass_data", pass_data);
      let res_data = await api("/product/create_addon_group", pass_data);
      console.log("/product/create_addon_group :", res_data);
      if (res_data.status_code == 201) {
        toast.success("Addon Group Created");
        if (res_data.response.hasOwnProperty("data")) {
          let item = close_data;
          item.action = "save";
          SetCloseData(item);
          SetSaveData({
            category: {},
            name: "",
            notes: "",
            is_compulsory: false,
            is_multiple: true,
            min_value: 0,
            max_value: 0,
          });
          SetAddonItemData((prevalue: any) => {
            prevalue.map((ele: any) => {
              ele.checked = false
            })
            return [...prevalue]
          })
          myModal.hide();
        }
      }
    } else {
      console.log("Validation not ok");
    }
    // }
  }

  const Switch = ({ isOn, handleToggle, onColor, id_value }  : any) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={id_value}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={id_value}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

  function addon_search_on_change(event : any) {
    const keyword = event.target.value;
    if (keyword !== "") {
      const results = addon_item_data.filter((data) => {
        return data.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      SetFilterAddonItemData(results);
    } else {
      SetFilterAddonItemData(addon_item_data);
    }
  }

  function input_on_change(label: any, e: any) {
    clearTimeout(timer);
    SetSaveData((prevValue) => {
      if (label == "name") {
        prevValue.name = e.target.value;
      } else if (label == "notes") {
        prevValue.notes = e.target.value;
      }
      setTimeout(
        triggerItemChange(prevValue.name, prevValue.notes),
        WAIT_INTERVAL
      );

      return { ...prevValue };
    });
  }

  function triggerItemChange(name: any, notes: any) {
    let addon_item_data = all_addon_group_data;
    var item_name = name;
    var item_note = notes;
    let selected_category_value = selected_category;

    let selected_addon_data = addon_item_data.filter(
      (a_data) => a_data.category_name === selected_category_value.name
    );

    let available_data = selected_addon_data.filter(
      (a_data) =>
        a_data.name.toString().toLowerCase() ===
        item_name.toString().toLowerCase() &&
        a_data.notes.toString().toLowerCase() ===
        item_note.toString().toLowerCase()
    );
    if (available_data.length > 0) {
      SetErrorData(
        "Group Name / Group Note is already available in this Category"
      );
    } else {
      SetErrorData("");
    }
  }

  async function on_varriant_select(data: any, filter_value: any) {
    let addon_group = addon_group_selected.data;
    let varriant_data = seleceted_addon_group_form.varriant_data;
    console.log("data :", data);
    console.log("addon_group :", addon_group);
    console.log("varriant_data :", varriant_data);

    let varriant_child_data_value = varriant_data.filter((v_data) => {
      if (v_data.id == data.id) {
        console.log("v_data value :", v_data.value);
        let data = v_data.value;
        return data;
      }
    });

    console.log("varriant_child_data_value value :", varriant_child_data_value);

    let addon_sample_value = [];
    let specification_sample_value = [];
    addon_sample_value.push(addon_group.id);
    if (props.from == "product_details") {
      specification_sample_value.push(data.specifications_id);
    } else {
      specification_sample_value.push(data.id);
    }
    // specification_sample_value.push(data.id);

    let sample_addon_group = {
      addon: addon_sample_value,
      specification: specification_sample_value,
    };

    // let pass_data = {
    //   get: {},
    //   post: {
    //     addon_group: sample_addon_group,
    //   },
    // }
    // // if (context.app_data.app_from == 'seller') {
    // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id
    // } else {
    //   pass_data.get.m_id = id_value.m_id
    // }
    // console.log('pass_data :', pass_data)
    // let res_data = await api('/product/get_addon_group', pass_data)
    // console.log('/product/get_addon_group :', res_data)
    // if (res_data.status_code == 200) {
    //   let sample_data = []
    //   if (res_data.response.hasOwnProperty('data')) {
    //     let res_data_value = res_data.response.data
    //     res_data_value[0].children.map(
    //       (res_data_ele: any, res_data_index: any) => {
    //         console.log('res_data_ele', res_data_ele)
    //         if (res_data_ele.hasOwnProperty('specification')) {
    //           let items_value = res_data_ele.specification[0].items
    //           let selected_varriant_value = varriant_child_data_value[0].value
    //           console.log('items_value', items_value)
    //           console.log('selected_varriant_value', selected_varriant_value)
    //           let sa_data = []
    //           sa_data = items_value.filter((ele, index) => {
    //             // ele.value = ele.id + "_" + res_data_ele.id;
    //             ele.value = ele.id
    //             ele.label = ele.name
    //             ele.checked = false

    //             if (!ele.hasOwnProperty('mrp')) {
    //               ele.mrp = ''
    //             }
    //             return selected_varriant_value.find((f) => {
    //               return f.value === ele.id
    //               // return f.value === ele.value;
    //             })
    //           })
    //           console.log('sa_data', sa_data)
    //           let varriant_value = {}

    //           varriant_value.id = res_data_ele.id
    //           varriant_value.name = res_data_ele.name
    //           varriant_value.value = res_data_ele.id
    //           varriant_value.label = res_data_ele.name

    //           varriant_value.addon_items_id = res_data_ele.addon_items_id
    //           varriant_value.custom_quantity = res_data_ele.custom_quantity
    //           varriant_value.mrp = res_data_ele.mrp
    //           varriant_value.notes = res_data_ele.notes
    //           varriant_value.varriant = sa_data

    //           // varriant_value.children = sa_data;
    //           varriant_value.checked = false

    //           sample_data.push(varriant_value)
    //         } else {
    //           // let items_value = res_data_ele.specification[0].items;
    //           // let selected_varriant_value = varriant_child_data_value[0].value;
    //           // console.log("items_value", items_value);
    //           // console.log("selected_varriant_value", selected_varriant_value);
    //           // let sa_data = [];
    //           // sa_data = items_value.filter((ele, index) => {
    //           //   // ele.value = ele.id + "_" + res_data_ele.id;
    //           //   ele.value = ele.id;
    //           //   ele.label = ele.name;
    //           //   ele.checked = false;

    //           //   if (!ele.hasOwnProperty("mrp")) {
    //           //     ele.mrp = "";
    //           //   }
    //           //   return selected_varriant_value.find((f) => {
    //           //     return f.value === ele.id;
    //           //     // return f.value === ele.value;
    //           //   });
    //           // });
    //           // console.log("sa_data", sa_data);
    //           let varriant_value = {}

    //           varriant_value.id = res_data_ele.id
    //           varriant_value.name = res_data_ele.name
    //           varriant_value.value = res_data_ele.id
    //           varriant_value.label = res_data_ele.name

    //           varriant_value.addon_items_id = res_data_ele.addon_items_id
    //           varriant_value.custom_quantity = res_data_ele.custom_quantity
    //           varriant_value.mrp = res_data_ele.mrp
    //           varriant_value.notes = res_data_ele.notes
    //           varriant_value.varriant = []

    //           // varriant_value.children = sa_data;
    //           varriant_value.checked = false

    //           sample_data.push(varriant_value)
    //         }
    //       },
    //     )
    //     console.log('on_varriant_select data :', data)

    //     console.log('on_varriant_select sample_data :', sample_data)
    //     SetSelecetedAddonGroupForm((prevValue: any) => {
    //       console.log('SetSelecetedAddonGroupForm prevValue :', prevValue)

    //       prevValue.dependent_id = data.id
    //       prevValue.addon_child_varriant_data = sample_data
    //       return { ...prevValue }
    //     })

    //     data.children.map((ele: any, index: any) => {
    //       if (index == 0) {
    //         SetSelectedChildItem(ele)
    //       }
    //     })
    //     SetIsEdit(false)
    //     SetIsSave(false)
    //     SetSelectedSpecification(data)

    //     // const [selected_specification, SetSelectedSpecification] = useState({});
    //     // const [selected_child_item, SetSelectedChildItem] = useState({});
    //   }
    // }

    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        // spec: encodeURI(JSON.stringify(filter_value.spec)),
        spec: encodeURI(JSON.stringify(specification_sample_value)),
        mrp: encodeURI(JSON.stringify(filter_value.mrp)),
        // addon_groups: encodeURI(JSON.stringify(filter_value.addon_groups)),
        addon_groups: encodeURI(JSON.stringify(addon_sample_value)),
      },
      // post: {
      //   addon_group: sample_addon_group,
      // },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("get_all_addon_groups pass_data :", pass_data);
    let res_data = await api("/product/get_all_addon_groups", pass_data);
    console.log("/product/get_all_addon_groups :", res_data);
    if (res_data.status_code == 200) {
      let sample_data = [];
      if (res_data.response.hasOwnProperty("addon")) {
        let res_data_value = res_data.response.addon;
        if (res_data_value[0].hasOwnProperty("children")) {
          res_data_value[0].children.map(
            (res_data_ele: any, res_data_index: any) => {
              console.log("res_data_ele", res_data_ele);
              if (res_data_ele.hasOwnProperty("specification")) {
                let items_value = res_data_ele.specification[0].items;
                let selected_varriant_value = varriant_child_data_value[0].value;
                console.log("items_value", items_value);
                console.log("selected_varriant_value", selected_varriant_value);
                let sa_data = [];
                sa_data = items_value.filter((ele, index) => {
                  ele.value = ele.id;
                  ele.label = ele.name;
                  ele.checked = false;

                  if (!ele.hasOwnProperty("mrp")) {
                    ele.mrp = "";
                  }
                  return selected_varriant_value.find((f) => {
                    return f.value === ele.id;
                  });
                });
                console.log("sa_data", sa_data);
                let varriant_value = {};

                varriant_value.id = res_data_ele.id;
                varriant_value.name = res_data_ele.name;
                varriant_value.value = res_data_ele.id;
                varriant_value.label = res_data_ele.name;

                varriant_value.addon_items_id = res_data_ele.addon_items_id;
                varriant_value.custom_quantity = res_data_ele.custom_quantity;
                varriant_value.mrp = res_data_ele.mrp;
                varriant_value.notes = res_data_ele.notes;
                varriant_value.varriant = sa_data;
                varriant_value.checked = false;

                sample_data.push(varriant_value);
              } else {
                let varriant_value = {};

                varriant_value.id = res_data_ele.id;
                varriant_value.name = res_data_ele.name;
                varriant_value.value = res_data_ele.id;
                varriant_value.label = res_data_ele.name;

                varriant_value.addon_items_id = res_data_ele.addon_items_id;
                varriant_value.custom_quantity = res_data_ele.custom_quantity;
                varriant_value.mrp = res_data_ele.mrp;
                varriant_value.notes = res_data_ele.notes;
                varriant_value.varriant = [];
                varriant_value.checked = false;

                sample_data.push(varriant_value);
              }
            }
          );
        }
        console.log("on_varriant_select data :", data);
        console.log("on_varriant_select sample_data :", sample_data);

        if (props.from == "product_details") {
          SetSelecetedAddonGroupForm((prevValue: any) => {
            console.log("SetSelecetedAddonGroupForm prevValue :", prevValue);
            prevValue.dependent_id = data.specifications_id;
            prevValue.addon_child_varriant_data = sample_data;
            return { ...prevValue };
          });
        } else {
          SetSelecetedAddonGroupForm((prevValue: any) => {
            console.log("SetSelecetedAddonGroupForm prevValue :", prevValue);
            prevValue.dependent_id = data.id;
            prevValue.addon_child_varriant_data = sample_data;
            return { ...prevValue };
          });
        }

        data.children.map((ele: any, index: any) => {
          if (index == 0) {
            SetSelectedChildItem(ele);
          }
        });
        SetIsEdit(false);
        SetIsSave(false);
        SetSelectedSpecification(data);
      }
    }
  }

  async function get_state(c_data: any, index: any) {
    console.log("get_state");
    let pass_data = {
      get: {
        country: c_data.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let response = await api("/init/get_state", pass_data);
    console.log("/init/get_state response :", response);

    SetMrpData((prevValue: any) => {
      prevValue[index].selected_country = c_data;
      prevValue[index].country = c_data.id;
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty("data")) {
          prevValue[index].state_data = response.response.data;
        }
      }
      return [...prevValue];
    });
  }

  async function product_create_addon_group() {
    if (Object.keys(save_data.category).length == 0) {
      toast.error("Choose Category");
    } else if (save_data.name == "") {
      toast.error("Enter Addon Group Name");
    } else if (addon_item_data.length == 0) {
      toast.error("Choose Child Addon Items");
    } else {
      if (addon_group_validate()) {
        let addon_value = [];
        let push_data = {
          name: save_data.name,
          notes: save_data.notes,
          category_id: save_data.category.id,
          addons: [],
        };
        if (save_data.is_compulsory) {
          push_data.compulsory = true;
        }

        if (save_data.is_multiple) {
          if (save_data.min_value != "") {
            push_data.min = save_data.min_value;
          }
          if (save_data.max_value != "") {
            push_data.max = save_data.max_value;
          }
        } else {
          push_data.single = true;
        }
        let addons_push_data = [];

        addon_item_data.map((ele: any) => {
          if (ele.checked) {
            addons_push_data.push({ addon_id: ele.id });
          }
        });
        push_data.addons = addons_push_data;
        addon_value.push(push_data);
        let pass_data = {
          get: {},
          post: {
            addons: addon_value,
          },
        };

        // if (context.app_data.app_from == 'seller') {
        if (id_value.hasOwnProperty("brand_id")) {
          pass_data.get.brand = id_value.brand_id;
        } else {
          pass_data.get.m_id = id_value.m_id;
        }
        console.log("Pass_data", pass_data);
        let res_data = await api("/product/create_addon_group", pass_data);
        console.log("/product/create_addon_group :", res_data);

        if (res_data.status_code == 201) {
          toast.success("Addon Group Created");
          if (res_data.response.hasOwnProperty("data")) {
            // let item = close_data;
            // item.action = "save";
            // SetCloseData(item);
            // myModal.hide();
            SetAvailableAddonData((prevValue: any) => {
              prevValue.push(res_data.response.data[0]);
              return [...prevValue];
            });
            // SetSaveData({
            //   category: {},
            //   name: "",
            //   notes: "",
            //   is_compulsory: false,
            //   is_multiple: true,
            //   min_value: 0,
            //   max_value: 0,
            // });
            SetSaveData((prevalue:any)=>{
              prevalue.name = "";
              prevalue.notes = "";
              prevalue.is_compulsory = "";
              prevalue.is_multiple = "";
              prevalue.min_value = "";
              prevalue.max_value = "";
              return {...prevalue}
            })
            SetAddonItemData((prevalue: any) => {
              prevalue.map((ele: any) => {
                ele.checked = false
              })
              return [...prevalue]
            })
            SetViewData("list");
          }
        }
      } else {
        console.log("Validation not ok")
      }
    }
  }

  async function create_addon_item() {
    if (item_save_data.name == "") {
      toast.error("Enter Addon Item Name");
    } else if (item_save_data.notes == "") {
      toast.error("Enter Addon Item notes");
    } else {
      let addon_value = [];
      let mrp_value = [];
      let spec_value = [];
      mrp_data.map((ele: any, index: any) => {
        let push_data = {
          country: ele.country,
          state: ele.state,
          currency: ele.currency,
          price: ele.price,
          activation_date: ele.activation_date,
        };
        mrp_value.push(push_data);
      });
      spec_data.map((ele:any,index:any)=>{
        let push_data : any = {}
        if(ele.type_id == 2){
          if(Object.keys(ele.value).length > 0 ){
            push_data.id = ele.id;
            push_data.value = ele.value.value;
            spec_value.push(push_data);
          }
        }
      })
      addon_value.push({
        name: item_save_data.name,
        notes: item_save_data.notes,
        custom_quantity: item_save_data.custom_quantity,
        mrp: mrp_value,
        specification_data : spec_value
      });
      let pass_data = {
        get: {},
        post: {
          addons: addon_value,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("Pass_data", pass_data);
      let res_data = await api("/product/add_addon_items", pass_data);
      console.log("/product/add_addon_items :", res_data);

      if (res_data.status_code == 201) {
        toast.success("Addon Item Created");
        if (res_data.response.hasOwnProperty("data")) {
          // get_all_addon_item()

          SetItemFilterData((prevValue: any) => {
            prevValue.page_no = 1;
            get_all_addon_item(prevValue, false);

            return { ...prevValue };
          });
          SetItemSaveData({
            name: "",
            notes: "",
            mrp: [],
            custom_quantity: true,
          });
          SetViewData("child");
        }
      }
    }
  }

  function close_modal() {
    myModal.hide();
  }

  function submit_addon() {
    if (seleceted_addon_group_form.is_dependent) {

      if (seleceted_addon_group_form.dependent_id != "") {
        let data = {
          value: addon_group_selected.data.id,
          label: addon_group_selected.data.name,
          is_dependent: seleceted_addon_group_form.is_dependent,
          dependent_id: seleceted_addon_group_form.dependent_id,
          children: seleceted_addon_group_form.addon_child_varriant_data,
          seleceted_addon_group_form: seleceted_addon_group_form,
        };
        console.log("is_dependent submit value :", data);
        // let item = close_data
        // item.action = 'save'
        // item.data = data
        // SetCloseData(item)
        // myModal.hide()
        SetCloseData((prevValue: any) => {
          prevValue.action = "save";
          prevValue.data = data;
          // myModal.hide()
          close_modal();
          return prevValue;
        });
      } else {
        toast.error("Select vatiant")
      }
    } else {
      let children_value = [];
      if (addon_group_selected.data.hasOwnProperty("children")) {
        addon_group_selected.data.children.map((ele: any, index: any) => {
          ele.value = ele.id;
          ele.label = ele.name;
          ele.chceked = false;
          children_value.push(ele);
        });
      }
      addon_group_selected.data.children = children_value;
      let data = {
        value: addon_group_selected.data.id,
        label: addon_group_selected.data.name,
        is_dependent: seleceted_addon_group_form.is_dependent,
        dependent_id: seleceted_addon_group_form.dependent_id,
        children: addon_group_selected.data.children,
        seleceted_addon_group_form: seleceted_addon_group_form,
      };
      console.log("is not dependent submit value :", data);
      // let item = close_data
      // item.action = 'save'
      // item.data = data
      // SetCloseData(item)
      // myModal.hide()

      SetCloseData((prevValue: any) => {
        prevValue.action = "save";
        prevValue.data = data;
        // myModal.hide()
        close_modal();
        return prevValue;
      });
    }
  }

  // async function update_price(addon_value) {
  //   let pass_data = {
  //     get: {},
  //     post: {
  //       addons: addon_value,
  //     },
  //   }
  //   // if (context.app_data.app_from == 'seller') {
  // if (id_value.hasOwnProperty("brand_id")) {

  //     pass_data.get.brand = id_value.brand_id
  //   } else {
  //     pass_data.get.m_id = id_value.m_id
  //   }
  //   console.log('pass data :', pass_data)
  //   let res_data = await api('/product/add_addon_pricing', pass_data)
  //   console.log('/product/add_addon_pricing res data :', res_data)
  //   if (res_data.status_code == 200) {
  //     if (res_data.response.hasOwnProperty('data')) {
  //       SetEditInDependentAddonPrice(false)
  //       SetEditAddonPrice(false)
  //     }
  //   }
  // }

  async function save_addon_pricing(data: any) {
    let addon_item_data = addon_group_selected.data.children;
    let save_value = [];
    if (!seleceted_addon_group_form.is_dependent) {
      data.map((ele, index) => {
        let push_value = {
          id: addon_item_data[ele.child_index].addon_items_id,
          addon_group: [],
        };
        let push1 = {
          id: addon_group_selected.data.id,
          mrp: ele.data,
        };
        push_value.addon_group.push(push1);
        save_value.push(push_value);
      });
      console.log("save_value not dependent :", save_value);
    } else {
      data.map((ele, index) => {
        let push_value = {
          id: addon_item_data[ele.child_index].addon_items_id,
          addon_group: [
            {
              id: addon_group_selected.data.id,
              specification: [],
            },
          ],
        };
        let push1 = {
          id: selected_child_item.value,
          mrp: ele.data,
        };
        push_value.addon_group[0].specification.push(push1);
        save_value.push(push_value);
      });
      console.log("save_value dependent :", save_value);
    }

    console.log("save_value : ", save_value);

    let pass_data = {
      get: {},
      post: {
        addons: save_value,
        return: "addon_group",
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let response = await api("/product/add_addon_pricing", pass_data);
    console.log("/product/add_addon_pricing response :", response);
    if (response.status_code == 201) {
      if (response.response.hasOwnProperty("addon")) {
        // SetPropsData(response.response.data[0]);
        SetIsEdit(false);
        SetIsSave(false);
        SetSaveDataInDependent([]);
        SetSaveDataDependent([]);
        if (seleceted_addon_group_form.is_dependent) {
          // seleceted_addon_group_form.addon_child_varriant_data
          console.log(
            "is_dependent seleceted_addon_group_form.addon_child_varriant_data :",
            seleceted_addon_group_form.addon_child_varriant_data
          );
          console.log("selected_specification :", selected_specification);

          SetSelecetedAddonGroupForm((prevValue: any) => {
            let res_data = response.response.addon;
            res_data.map((addon_group: any, group_index: any) => {
              if (addon_group.id == addon_group_selected.data.id) {
                console.log("addon_group :", addon_group);
                addon_group.children.map((addon_item: any, item_index) => {
                  prevValue.addon_child_varriant_data.map(
                    (varriant_item: any, varriant__item_index: any) => {
                      if (varriant_item.id == addon_item.id) {
                        if (addon_item.hasOwnProperty("specification")) {
                          addon_item.specification.map(
                            (spec_ele: any, spec_index: any) => {
                              if (spec_ele.id == selected_specification.id) {
                                // seleceted_addon_group_form.addon_child_varriant_data
                                varriant_item.varriant = spec_ele.items;
                                console.log(
                                  "v == varriant_item :",
                                  varriant_item
                                );
                                console.log(
                                  "v == spec_ele.items :",
                                  spec_ele.items
                                );
                              } else {
                                console.log(
                                  "spec_ele.id != selected_specification.id"
                                );
                              }
                            }
                          );
                        } else {
                          console.log("no specification");
                        }
                      } else {
                        console.log("varriant_item.id != addon_item.id");
                      }
                    }
                  );
                });
              }
            });
            // prevValue.addon_child_varriant_data;
            return { ...prevValue };
          });
        } else {
          // addon_group_selected.data.children
          console.log(
            "not dependent addon_group_selected.data :",
            addon_group_selected.data
          );
          SetAddonGroupSelected((prevValue: any) => {
            prevValue.data = response.response.addon[0];
            return { ...prevValue };
          });
        }
      }
    }
  }

  async function update_addon_item() {
    let addons_push_data = [];
    console.log("addon_item_data :", addon_item_data);
    addon_item_data.map((ele: any) => {
      if (ele.checked) {
        addons_push_data.push({ addon_id: ele.id });
      }
    });
    console.log("addons_push_data :", addons_push_data);
    let pass_data = {
      get: {},
      post: {
        group_id: props.data.id,
        addons: addons_push_data,
      },
    };

    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data", pass_data);
    let res_data = await api("/product/add_addon_group_items", pass_data);
    console.log("/product/add_addon_group_items :", res_data);

    if (res_data.status_code == 201) {
      if (res_data.response.hasOwnProperty("data")) {
        let child_items_value = [];
        SetAddonItemData((prevValue: any) => {
          prevValue.map((ele) => {
            if (ele.checked) {
              child_items_value.push(ele);
              ele.already_checked = true;
            }
          });
          return [...prevValue];
        });
        res_data.response.data.map((ele: any) => {
          if (ele.id != null) {
            SetChildItems((prevValue: any) => {
              prevValue.push(ele);
              return [...prevValue];
            });
          }
        });

        SetFilterAddonItemData((prevValue: any) => {
          prevValue.map((ele) => {
            if (ele.checked) {
              ele.already_checked = true;
            }
          });
          return [...prevValue];
        });
        SetViewData("main");
      } else if (res_data.response.hasOwnProperty("message")) {
        toast.success(res_data.response.message);
      }
    }
  }

  async function remove_addon_item() {
    let addons_push_data = [];

    remove_selected_item.map((ele: any) => {
      addons_push_data.push(ele.id);
    });
    let pass_data = {
      get: {},
      post: {
        group_items: addons_push_data,
      },
    };

    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data", pass_data);
    let res_data = await api("/product/delete_addon_group_item", pass_data);
    console.log("/product/delete_addon_group_item :", res_data);

    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("status")) {
        if (res_data.response.status) {
          toast.success("Addon Item Removed Successfully");
          console.log("remove_selected_item :", remove_selected_item);
          SetChildItems((prevValue) => {
            let set_value = [];
            prevValue.map((ele: any, e_index: any) => {
              if (!remove_selected_item.some((s_ele) => s_ele.id == ele.id)) {
                set_value.push(ele);
              }
            });
            prevValue = set_value;
            return [...prevValue];
          });

          SetRemoveSelectedItem([]);

          SetAddonItemData((prevValue: any) => {
            console.log("SetAddonItemData before prevValue :", prevValue);
            console.log(
              "SetAddonItemData remove_selected_item :",
              remove_selected_item
            );

            prevValue.map((ele: any, e_index: any) => {
              if (
                remove_selected_item.some(
                  (s_ele) => s_ele.addon_items_id == ele.id
                )
              ) {
                // set_value.push(ele)
                ele.checked = false;
                ele.already_checked = false;
              }
            });
            console.log("SetAddonItemData after prevValue :", prevValue);

            return [...prevValue];
          });
          SetFilterAddonItemData((prevValue: any) => {
            console.log("SetFilterAddonItemData before prevValue :", prevValue);
            console.log(
              "SetFilterAddonItemData remove_selected_item :",
              remove_selected_item
            );
            prevValue.map((ele: any, e_index: any) => {
              if (
                remove_selected_item.some(
                  (s_ele) => s_ele.addon_items_id == ele.id
                )
              ) {
                // set_value.push(ele)
                ele.checked = false;
                ele.already_checked = false;
              }
            });
            console.log("SetFilterAddonItemData after prevValue :", prevValue);
            return [...prevValue];
          });
        } else {
          toast.success(res_data.response.message);
        }
        SetViewData("main");
      }
    }
  }

  async function update_addon_group_details() {
    console.log("save_data :", save_data);

    if (addon_group_validate()) {
      console.log("Validation Not Ok");

      let addon_value = {
        id: props.data.id,
        name: save_data.name,
        notes: save_data.name,
        compulsory: save_data.name,
        single: !save_data.is_multiple,
      };

      if (save_data.is_multiple) {
        addon_value.min = save_data.min_value;
        addon_value.max = save_data.max_value;
      }

      let pass_data = {
        get: {},
        post: {
          addon: [addon_value],
        },
      };

      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("pass_data", pass_data);
      let res_data = await api(
        "/product/update_addon_group_details",
        pass_data
      );
      console.log("/product/update_addon_group_details :", res_data);

      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          let item = close_data;
          item.action = "save";
          // item.data = data
          SetCloseData(item);
          myModal.hide();
          toast.success("Addon Group Updated");
        } else {
          toast.error(res_data.response.message);
        }
      }
    } else {
      console.log("Validation Not Ok");
    }
  }

  async function get_specification(){
    let pass_data : any = {
      get: {
        specifications_only : 1
      },
      post: {
        specification: [
          {
              "id": "115",
              "source_type": "specification",
              "data_type": "varying"
          }
        ],
      },
    }
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data : any = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications :', res_data)
    if(res_data.status_code == 200){
      if(res_data.response.hasOwnProperty("data")){
        SetSpecData(res_data.response.data)
      }
    }
  }

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return (
    <div
      className="modal"
      id="AddonGroupModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    // data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* modal header */}
          <div className="modal-header">
            {props.from == "addon_group" ? (
              <div className="d-flex align-items-center">
                {/* {view_data == 'category' || view_data == 'child' ? ( */}
                {view_data == "category" ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      if (view_data == "category") {
                        SetSelectedCategory({});
                      }
                      SetViewData("main");
                    }}
                  ></i>
                ) : null}
                <h5 className="mb-0  modal-title" id="ModalLabel">
                  {view_data == "category" ? (
                    "Select Category"
                  ) : view_data == "main" ? (
                    modal_type == "create" ? (
                      "Create Addon Group"
                    ) : modal_type == "view" ? (
                      "View Addon Group"
                    ) : modal_type == "edit" ? (
                      "Edit Addon Group"
                    ) : null
                  ) : view_data == "child" ? (
                    <p>
                      <i
                        className="fas fa-arrow-left cursor me-3"
                        onClick={() => {
                          if (view_data == "category") {
                            SetSelectedCategory({});
                          }
                          SetViewData("main");
                          // SetItemFilterData("")
                          console.log("on back addon_item_data :",addon_item_data);
                          SetFilterAddonItemData((prevalue)=>{
                            prevalue = addon_item_data;
                            return [...prevalue]
                          })
                          // SetItemFilterData((prevValue: any) => {
                          //   prevValue.page_no =  1;
                          //   prevValue.search =  "";
                          //   // get_gallery(prevValue, true)
                          //   get_all_addon_item(prevValue, false);
                          //   return { ...prevValue };
                          // });
                        }}
                      ></i>
                      Select Addon Item
                    </p>
                  ) : null}
                </h5>
              </div>
            ) : null}

            {props.from == "add_product" ? (
              <div className="d-flex align-items-center">
                {view_data == "main" ||
                  view_data == "child" ||
                  view_data == "add_child" ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      if (view_data == "main") {
                        SetViewData("list");
                      }
                      if (view_data == "child") {
                        SetViewData("main");
                      }
                      if (view_data == "add_child") {
                        SetViewData("child");
                      }
                      SetItemSaveData({
                        name: "",
                        notes: "",
                        mrp: [],
                        custom_quantity: true,
                      });
                      SetSaveData({
                        category: props.category,
                        name: "",
                        notes: "",
                        is_compulsory: false,
                        is_multiple: true,
                        min_value: 0,
                        max_value: 0,
                      });
                    }}
                  ></i>
                ) : null}
                {addon_group_selected.is_open && view_data == "list" ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      console.log("SetAddonGroupSelected false")

                      SetAddonGroupSelected((prevValue: any) => {
                        prevValue.is_open = false;
                        prevValue.data = {};
                        return { ...prevValue };
                      });

                      SetIsEdit(false);

                      SetEditInDependentAddonPrice(false);
                      SetEditAddonPrice(false);

                      SetSelecetedAddonGroupForm((prevalue: any) => {
                        prevalue.is_dependent = false;
                        prevalue.dependent_id = "";
                        prevalue.varriant_data = [];
                        prevalue.addon_child_varriant_data = [];

                        return { ...prevalue };
                      })
                      // const [seleceted_addon_group_form, SetSelecetedAddonGroupForm] = useState({
                      //   is_dependent: false,
                      //   dependent_id: "",
                      //   varriant_data: [],
                      //   addon_child_varriant_data: [],
                      // });

                    }}
                  ></i>
                ) : null}
                <h5 className=" modal-title" id="ModalLabel">
                  {view_data == "list"
                    ? addon_group_selected.is_open
                      ? "Selected Addon"
                      : "Addon Group List"
                    : view_data == "main"
                      ? "Create Addon Group"
                      : view_data == "child"
                        ? "Select Addon Item"
                        : view_data == "add_child"
                          ? "Create Addon Item"
                          : null}
                </h5>
              </div>
            ) : null}

            {props.from == "product_details" ? (
              <div className="d-flex align-items-center">
                {view_data == "main" ||
                  view_data == "child" ||
                  view_data == "add_child" ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      if (view_data == "main") {
                        SetViewData("list");
                      }
                      if (view_data == "child") {
                        SetViewData("main");
                      }
                      if (view_data == "add_child") {
                        SetViewData("child");
                      }
                    }}
                  ></i>
                ) : null}
                {addon_group_selected.is_open && view_data == "list" ? (
                  <i
                    className="fas fa-arrow-left cursor me-3"
                    onClick={() => {
                      SetAddonGroupSelected((prevValue: any) => {
                        prevValue.is_open = false;
                        prevValue.data = {};
                        return { ...prevValue };
                      });
                    }}
                  ></i>
                ) : null}
                <h5 className=" modal-title" id="ModalLabel">
                  {view_data == "list"
                    ? addon_group_selected.is_open
                      ? "Selected Addon"
                      : "Addon Group List"
                    : view_data == "main"
                      ? "Create Addon Group"
                      : view_data == "child"
                        ? "Select Addon Item"
                        : view_data == "add_child"
                          ? "Create Addon Item"
                          : null}
                </h5>
              </div>
            ) : null}

            <button
              type="button"
              className="btn-close"
              // aria-label="Close"
              onClick={() => {
                console.log("modal X button on click");
                let item = close_data;
                item.action = "close";
                // SetCloseData(item)
                // myModal.hide()

                SetCloseData((prevValue: any) => {
                  prevValue.action = "close";
                  myModal.hide();
                  return prevValue;
                });
              }}
            ></button>
          </div>
          {/* modal body */}
          <div className="modal-body">
            <div className="">
              {props.from == "addon_group" ? (
                view_data == "category" ? (
                  <div className="">
                    <div className="d-flex px-3 align-items-center mb-3">
                      <p className="pe-2 mb-0">Selected Category </p>

                      <p className="text-capitalize text-dark fw-bold mb-0">
                        {selected_category.name}
                      </p>
                    </div>
                    <div className="scroll mt-2 p-0 border-top">
                      <div className="scroll-flex mt-1">
                        <ColumnNode
                          data={category_data}
                          onSelect={(data) => {
                            console.log("category on select :", data);
                            SetSelectedCategory(data);
                            SetErrorState((prevValue: any) => {
                              prevValue.catError = "";
                              return { ...prevValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : view_data == "main" ? (
                  <div className="row py-3">
                    {/* category */}
                    <div
                      className="col-md-6 mb-3"
                      onClick={() => {
                        console.log("Open Category");
                        if (props.type == "create") {
                          SetViewData("category");
                          SetSelectedCategory({});
                        }
                      }}
                    >
                      <label className="form-label">Category *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category"
                        value={
                          Object.keys(save_data.category).length > 0
                            ? save_data.category.name
                            : ""
                        }
                        disabled={props.type == "edit" ? !read_only : read_only}
                        onChange={(e) => { }}
                      />
                      <span className="text-danger">
                        {error_state.catError}
                      </span>
                    </div>
                    {/* group name */}
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Addon Group Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Addon Group Name"
                        value={save_data.name}
                        disabled={read_only}
                        onChange={(e) => {
                          input_on_change("name", e);
                          SetErrorState((prevValue: any) => {
                            if (e.target.value == "") {
                              prevValue.nameError = "Name field is required";
                            } else {
                              prevValue.nameError = "";
                            }
                            return { ...prevValue };
                          });
                          // SetSaveData((prevValue) => {
                          //   prevValue.name = e.target.value;
                          //   return { ...prevValue };
                          // });
                        }}
                      />
                      <span className="text-danger">
                        {error_state.nameError}
                      </span>
                    </div>
                    {/* group note */}
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Addon Group Notes *</label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Addon Group Notes"
                        value={save_data.notes}
                        disabled={read_only}
                        onChange={(e) => {
                          input_on_change("notes", e);
                          SetErrorState((prevValue: any) => {
                            if (e.target.value == "") {
                              prevValue.noteError = "Note field is required";
                            } else {
                              prevValue.noteError = "";
                            }
                            return { ...prevValue };
                          });
                          // SetSaveData((prevValue) => {
                          //   prevValue.notes = e.target.value;
                          //   return { ...prevValue };
                          // });
                        }}
                      />
                      <span className="text-danger">
                        {error_state.noteError}
                      </span>
                    </div>
                    {/* Is Compulsory /Is Multiple */}
                    <div className="col-md-6 mb-3 ">
                      <div className="row pb-2">
                        <div className="col-md-6 d-flex align-items-center pb-2">
                          <label className="form-label pe-3 mb-0">
                            Is Compulsory
                          </label>
                          <div className="ms-auto">
                            <Switch
                              id_value={"is_compulsory"}
                              isOn={save_data.is_compulsory}
                              onColor="#0053AD"
                              handleToggle={(e) => {
                                console.log(
                                  "is_compulsory Switch on change :",
                                  e
                                );
                                if (!read_only) {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_compulsory =
                                      !prevValue.is_compulsory;
                                    return { ...prevValue };
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        {/* <div className="col-md-6 d-flex align-items-center pb-2">
                          <label className="form-label pe-3 mb-0">
                            Is Multiple
                          </label>
                          <div className="ms-auto">
                            <Switch
                              id_value={'is_multiple'}
                              isOn={save_data.is_multiple}
                              onColor="#0053ad"
                              handleToggle={(e) => {
                                console.log('is_multiple Switch on change :', e)
                                if (!read_only) {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_multiple = !prevValue.is_multiple
                                    return { ...prevValue }
                                  })
                                }
                              }}
                            />
                          </div>
                        </div> */}

                        {/* Single / Multiple */}
                        <div className="end">
                          <div className="pe-2">
                            <label className="cursor">
                              <input
                                type="radio"
                                className="form-check-input me-1"
                                checked={!save_data.is_multiple}
                                onChange={() => {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_multiple =
                                      !prevValue.is_multiple;
                                    return { ...prevValue };
                                  });
                                }}
                              />
                              Single
                            </label>
                          </div>
                          <div className="pe-2">
                            <label className="cursor">
                              <input
                                type="radio"
                                className="form-check-input me-1"
                                checked={save_data.is_multiple}
                                onChange={() => {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_multiple =
                                      !prevValue.is_multiple;
                                    return { ...prevValue };
                                  });
                                }}
                              />
                              Multiple
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* min/max */}
                    {save_data.is_multiple ? (
                      <>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Min Count</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Addon Item Min Count"
                            value={save_data.min_value}
                            disabled={read_only}
                            onChange={(e) => {
                              SetSaveData((prevValue) => {
                                // if(prevValue.max_value != ""){
                                //   if(prevValue.max_value <= e.target.value){
                                //     toast.error("Minimum count must be less than maximium count")
                                //   }else{
                                //     prevValue.min_value = e.target.value;
                                //   }
                                // }else{
                                //   prevValue.min_value = e.target.value;
                                // }
                                // prevValue.min_value = e.target.value;
                                return { ...prevValue };
                              });
                            }}
                          />
                          <span className="text-danger">
                            {error_state.MinError}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Max Count</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Addon Item Max Count"
                            value={save_data.max_value}
                            disabled={read_only}
                            onChange={(e) => {
                              SetSaveData((prevValue) => {
                                // if(prevValue.min_value != ""){
                                //   if(prevValue.min_value >= e.target.value){
                                //     toast.error("Maximium count must be greater than minimum count")
                                //   }else{
                                //     prevValue.max_value = e.target.value;
                                //   }
                                // }else{
                                //   prevValue.max_value = e.target.value;
                                // }
                                prevValue.max_value = e.target.value;
                                return { ...prevValue };
                              });
                            }}
                          />
                          <span className="text-danger">
                            {error_state.MaxError}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {/* Child Items */}
                    <div className="py-3">
                      <div className="border-bottom row pb-2 align-items-center">
                        <div className="col-md-9 col-6">
                          <p className="text-dark fw-bold mb-0 fs-7">Addon Items</p>
                          <span className="text-danger">
                            {error_state.childError}
                          </span>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="row align-items-center">
                            {props.type != "view" ? (
                              <div className="col-4 end px-1">
                                <button
                                  className="btn btn-sm btn-text d-flex align-items-center "
                                  onClick={() => {
                                    SetViewData("child");
                                    console.log("add");
                                  }}
                                >
                                  <Ionicons name="add" size={18} color="#0053ad" />
                                  <span className="ps-1 text-primary fw-bold">Add</span>
                                </button>
                              </div>
                            ) : null}

                            {props.type == "edit" ? (
                              remove_selected_item.length > 0 ? (
                                <div className="col-8 end p-0">
                                  <button
                                    className="btn btn-sm border text-darkgray fw-bold d-flex align-items-center"
                                    onClick={() => {
                                      // SetViewData('child')
                                      console.log("remove");
                                      remove_addon_item();
                                    }}
                                  >
                                    <MaterialCommunityIcons
                                      name="delete-outline"
                                      size={18}
                                      color="#555"
                                    />
                                    <span className="ps-1">Remove</span>
                                  </button>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 px-0">
                        {props.type != "view" ? (
                          props.type == "edit" ? (
                            child_items.length > 0 ? (
                              child_items.map((ele: any, index: any) => (
                                <div className="col-md-6 mb-3 px-2" key={index}>
                                  <div
                                    className={
                                      remove_selected_item.some(
                                        (s_ele) => s_ele.id == ele.id
                                      )
                                        ? "card p-2 card-border"
                                        : "card p-2"
                                    }
                                  >
                                    <label className="row cursor">
                                      <div className="col-11 pe-1">
                                        <p className="mb-2 text-dark">
                                          {ele.name}
                                        </p>
                                        <p className="mb-0 note_hide v-small">
                                          {ele.notes}
                                        </p>
                                        {/* <p className="mb-2">MRP : ₹</p> */}
                                      </div>
                                      <div className="col-1 p-0">
                                        <input
                                          className="form-check-input-custom"
                                          type="checkbox"
                                          checked={
                                            remove_selected_item.some(
                                              (s_ele) => s_ele.id == ele.id
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            SetRemoveSelectedItem(
                                              (prevValue: any) => {
                                                if (e.target.checked) {
                                                  prevValue.push(ele);
                                                } else {
                                                  let index_value =
                                                    prevValue.findIndex(
                                                      (f_ele) =>
                                                        f_ele.id == ele.id
                                                    );
                                                  prevValue.splice(
                                                    index_value,
                                                    1
                                                  );
                                                }
                                                console.log(
                                                  "Remove selected Value :",
                                                  prevValue
                                                );
                                                return [...prevValue];
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No Child Addon Items</p>
                            )
                          ) : addon_item_data.length > 0 ? (
                            addon_item_data.map((a_ele, a_index) =>
                              a_ele.checked ? (
                                <div className="col-md-6 mb-3" key={a_index}>
                                  <div className="card p-2">
                                    <label className=" d-flex cursor">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={a_ele.checked}
                                          disabled={false}
                                          onChange={(e) => {
                                            SetAddonItemData((prevValue) => {
                                              prevValue[a_index].checked =
                                                e.target.checked;
                                              return [...prevValue];
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="ms-3">
                                        <p className="mb-2 text-black">
                                          {a_ele.name}
                                        </p>
                                        <div className=" mb-2">
                                          <p className="note_hide mb-0">
                                            Notes : {a_ele.notes}
                                          </p>
                                          {/* <p className="mb-2">MRP : ₹ {a_ele.mrp}</p> */}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              ) : null
                            )
                          ) : (
                            <p>No Child Addon Items, Please Add Some.</p>
                          )
                        ) : child_items.length > 0 ? (
                          child_items.map((ele: any, index: any) => (
                            <div className="col-md-6 mb-3" key={index}>
                              <div className="card p-2">
                                <label className=" d-flex cursor">
                                  {/* <div>
                                <input
                                  type="checkbox"
                                  checked={true}
                                  disabled={true}
                                  onChange={() => {}}
                                />
                              </div> */}
                                  <div className="ms-3">
                                    <p className="mb-2 text-black">
                                      {ele.name}
                                    </p>
                                    <div className=" mb-2">
                                      <p className="note_hide mb-0">
                                        Notes : {ele.notes}
                                      </p>
                                      <p className="mb-2">MRP : ₹</p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No Child Addon Items</p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : view_data == "child" ? (
                  <div className="">
                    <div className="px-3 pb-4 pt-0">
                      {/* search */}
                      <div className="input-icons form-group p-2 px-3">
                        {/* <input
                          type="text"
                          className="form-control search-bar p-0"
                          placeholder="Search Addon Item"
                          value={addon_item_search}
                          onChange={(e) => {
                            SetAddonItemSearch(e.target.value)
                            addon_search_on_change(e)
                          }}
                        /> */}
                        <input
                          type="text"
                          className="form-control search-bar p-0"
                          placeholder="Search here..."
                          onChange={(e) => {
                            // SetSearchData((prevValue: any) => {
                            //   prevValue = e.target.value
                            //   return prevValue
                            // })

                            SetItemFilterData((prevValue1: any) => {
                              prevValue1.search = e.target.value;
                              return { ...prevValue1 };
                            });
                          }}
                        />
                        <div className="cursor px-2 border-left">
                          <Ionicons name="md-search" size={18} color="#ccc" />
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      {filter_addon_item_data.length > 0 ? (
                        <InfiniteScroll
                          dataLength={filter_addon_item_data.length}
                          next={() => {
                            console.log("on next :");
                            SetItemFilterData((prevValue: any) => {
                              prevValue.page_no = prevValue.page_no + 1;
                              // get_gallery(prevValue, true)
                              get_all_addon_item(prevValue, true);
                              return { ...prevValue };
                            });
                          }}
                          hasMore={item_filter_data.next_page}
                          className=""
                          height={450}
                          loader={
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                padding: "10px",
                              }}
                            >
                              <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          }
                          endMessage={
                            <div className="center pt-5 pb-3">
                              <hr className="w-25" />
                            </div>
                          }
                        >
                          <div className="row">
                            {filter_addon_item_data.map((a_ele, a_index) =>
                              !a_ele.already_checked ? (
                                <div className="col-md-6 mb-3" key={a_index}>
                                  <div
                                    className={
                                      a_ele.checked
                                        ? "card card-border p-2 mb-3"
                                        : "card p-2 mb-3"
                                    }
                                    key={a_index}
                                  >
                                    <label className="row cursor">
                                      <div className="col-1 pe-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input-custom"
                                          checked={a_ele.checked ? true : false}
                                          onChange={(e) => {
                                            console.log(
                                              "filter_addon_item_data.length :",
                                              filter_addon_item_data.length
                                            );
                                            let org_index =
                                              addon_item_data.findIndex(
                                                (p) => p.id == a_ele.id
                                              );
                                            console.log(
                                              "org_index :",
                                              org_index
                                            );
                                            SetAddonItemData(
                                              (prevValue1: any) => {
                                                if(prevValue1[org_index].hasOwnProperty("checked")){
                                                  prevValue1[org_index].checked =
                                                  !prevValue1[org_index]
                                                    .checked;
                                                }else{
                                                  prevValue1[org_index].checked = true
                                                }
                                               
                                                console.log(
                                                  "SetAddonItemData prevValue :",
                                                  prevValue1
                                                );
                                                return [...prevValue1];
                                              }
                                            );
                                            SetFilterAddonItemData((prevalue)=>{
                                              // if(prevalue[a_index].hasOwnProperty("checked")){
                                                prevalue[a_index].checked = e.target.checked;
                                              // }else{
                                              //   prevalue[a_index].checked = true
                                              // }
                                              return [...prevalue]
                                            })
                                            
                                          }}
                                        />
                                      </div>
                                      <div className="col-11">
                                        <p className="mb-0 text-black">
                                          {a_ele.name}
                                        </p>
                                      </div>
                                      <small className="pt-2 fw-500 note_hide v-small">
                                        {a_ele.notes}
                                      </small>
                                      <div className="pt-2">
                                        {a_ele.hasOwnProperty("mrp")
                                          ? a_ele.mrp.map(
                                            (mrp_ele: any, mrp_index: any) =>
                                              mrp_index == 0 ? (
                                                <div
                                                  className="row align-items-center"
                                                  key={mrp_index}
                                                >
                                                  <p className="mb-2 text-black">
                                                    {mrp_ele.country},{" "}
                                                    {mrp_ele.state}
                                                  </p>
                                                  {/* {mrp_ele.hasOwnProperty("state") ? (
                                                <p className="">
                                                  State : {mrp_ele.state}
                                                </p>
                                              ) : null} */}
                                                  <div className="col-6">
                                                    <p className="text-darkgray mb-0 fw-bold">
                                                      {
                                                        mrp_ele.currency_symbol
                                                      }{" "}
                                                      {mrp_ele.price}
                                                    </p>
                                                  </div>

                                                  <div className="col-6 end">
                                                    <Feather
                                                      name="calendar"
                                                      size={14}
                                                      color="lightgray"
                                                    />
                                                    <small className="ps-1">
                                                      {
                                                        mrp_ele.activation_date
                                                      }
                                                    </small>
                                                  </div>
                                                </div>
                                              ) : null
                                          )
                                          : null
                                          // <div className="center w-100 p-3">
                                          //   <img
                                          //     src={require('../../assets/img/svg/no_data_2.svg')}
                                          //     className="product-img"
                                          //   />
                                          //   <p className="my-2">No Data</p>
                                          // </div>
                                        }
                                        {/* {a_ele.mrp.map(
                                          (mrp_ele: any, mrp_index: any) => (
                                            <div
                                              className="col-md-6"
                                              key={mrp_index}
                                            >
                                              <p className="">
                                                Country : {mrp_ele.country}
                                              </p>
                                              {mrp_ele.hasOwnProperty("state") ? (
                                                <p className="">
                                                  State : {mrp_ele.state}
                                                </p>
                                              ) : null}
                                              <p className="">
                                                price :{mrp_ele.currency_symbol}{" "}
                                                {mrp_ele.price}
                                              </p>
                                              <p className="">
                                                Activation Date :{" "}
                                                {mrp_ele.activation_date}
                                              </p>
                                            </div>
                                          )
                                        )} */}
                                      </div>
                                      {/* <p className="mb-2">MRP : ₹ {a_ele.mrp}</p> */}
                                    </label>
                                  </div>
                                </div>
                              ) : null
                            )}
                          </div>
                        </InfiniteScroll>
                      ) : (
                        <div className="center w-100 p-3">
                          <img
                            src={require("../../assets/img/svg/no_data_2.svg")}
                            className="product-img"
                          />
                          <p className="my-2">No Data</p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null
              ) : null}

              {props.from == "add_product" ? (
                <div className="">
                  {view_data == "list" ? (
                    <div className="">
                      {addon_group_selected.is_open == false ? (
                        <div className="d-flex align-items-center mb-3">
                          <div
                            className="text-primary ms-auto fw-bold d-flex align-items cursor"
                            onClick={() => {
                              SetViewData("main");
                            }}
                          >
                            <div className="me-2">
                              <Entypo name="plus" size={16} color="#0053ad" />
                            </div>
                            Create New
                          </div>
                        </div>
                      ) : null}

                      <div className="">
                        {addon_group_selected.is_open == false ? (
                          <div className="">
                            {available_addon_data.length > 0 ? (
                              <div className="row">
                                {available_addon_data.map(
                                  (ele: any, index: any) => (
                                    <div
                                      key={index}
                                      className="col-md-6 px-2 "
                                      onClick={() => {
                                        console.log("on group click");
                                        SetAddonGroupSelected(
                                          (prevValue: any) => {
                                            prevValue.is_open = true;
                                            prevValue.data = ele;
                                            return { ...prevValue };
                                          }
                                        );
                                      }}
                                    >
                                      <div className="border radius mb-3 py-2 px-3 cursor">
                                        <div className="d-flex">
                                          <p className="text-dark mb-2 fw-bold">
                                            {ele.name}
                                          </p>
                                          <div className="ms-auto">
                                            {/* <MaterialIcons name="category" size={14} color="#959595" /> */}
                                            <p className="mb-2">
                                              <small className="ps-2 text-darkgray">
                                                {ele.type}
                                              </small>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center pe-2">
                                          <MaterialIcons
                                            name="notes"
                                            size={14}
                                            color="#959595"
                                          />
                                          <small className="ps-2 text-gray">
                                            {ele.notes}
                                          </small>
                                        </div>
                                      </div>
                                      {/* <div className="d-flex align-items-center pe-2">
                                        <MaterialIcons
                                          name="notes"
                                          size={14}
                                          color="#959595"
                                        />
                                        <small className="ps-2 text-gray">
                                          {ele.notes}
                                        </small>
                                      </div> */}
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div className="text-center w-100 p-3">
                                <img
                                  src={require("../../assets/img/empty.png")}
                                  className="product-img"
                                />
                                <p className="my-2">No Addon Group</p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="">
                            <div className="row">
                              <div className="col-md-6">
                                <p className="text-dark mb-2 fw-bold">
                                  {addon_group_selected.data.name}
                                </p>
                                <div className="d-flex align-items-center pe-2">
                                  <MaterialIcons
                                    name="notes"
                                    size={14}
                                    color="#959595"
                                  />
                                  <small className="ps-2">
                                    {addon_group_selected.data.notes}
                                  </small>
                                </div>
                                <div className="d-flex align-items-center pe-2">
                                  <MaterialIcons
                                    name="category"
                                    size={14}
                                    color="#959595"
                                  />
                                  <small className="ps-2">
                                    {addon_group_selected.data.type}
                                  </small>
                                </div>
                                {/* <p className="text-dark mb-1">
                                  {addon_group_selected.data.name}
                                </p>
                                <p>
                                  <small>
                                    {addon_group_selected.data.notes}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    {addon_group_selected.data.notes}
                                  </small>
                                </p> */}
                              </div>
                              {/* Is Dependent */}
                              <div className="end col-md-6 md-text-end">
                                <label className="form-label pe-3 mb-0">
                                  Is Dependent
                                </label>
                                <Switch
                                  id_value={"is_dependent"}
                                  isOn={seleceted_addon_group_form.is_dependent}
                                  onColor="#0053ad  "
                                  handleToggle={(e) => {
                                    if (
                                      seleceted_addon_group_form.varriant_data
                                        .length > 0
                                    ) {
                                      SetSelecetedAddonGroupForm(
                                        (prevValue: any) => {
                                          prevValue.is_dependent =
                                            !prevValue.is_dependent;
                                          console.log("prevValue :", prevValue);
                                          return { ...prevValue };
                                        }
                                      );
                                    } else {
                                      toast.error("No Variants");
                                    }
                                    SetEditAddonPrice(false);
                                    SetEditInDependentAddonPrice(false);
                                    SetSelectedSpecification({});
                                    SetSelectedVariant({});
                                    SetIsEdit(false);
                                  }}
                                />
                              </div>
                            </div>

                            {seleceted_addon_group_form.is_dependent ? (
                              <div className="py-3 px-1">
                                <p className="mb-0 text-dark">Variants</p>
                                {seleceted_addon_group_form.varriant_data.map(
                                  (v_ele: any, v_index: any) => (
                                    <div className="py-2" key={v_index}>
                                      <label className="d-flex cursor">
                                        <input
                                          type="radio"
                                          name="type"
                                          value={v_ele.id}
                                          id={v_ele.id}
                                          disabled={is_edit}
                                          checked={
                                            selected_variant.id == v_ele.id
                                          }
                                          onChange={() => {
                                            console.log(
                                              "seleceted_addon_group_form.varriant_data :",
                                              seleceted_addon_group_form.varriant_data
                                            );
                                            if (!is_edit) {
                                              on_varriant_select(
                                                v_ele,
                                                filter_data
                                              );
                                              SetSaveDataInDependent([]);
                                              SetSaveDataDependent([]);
                                              SetSelectedVariant(v_ele);
                                            }
                                          }}
                                          className="me-2"
                                        />
                                        <p className="m-0 ml-2">{v_ele.name}</p>
                                      </label>
                                    </div>
                                  )
                                )}
                                <div className="">
                                  {seleceted_addon_group_form.dependent_id !=
                                    null ? (
                                    Object.keys(selected_specification).length >
                                      0 ? (
                                      <div className="py-3">
                                        <div className="d-flex align-items-center">
                                          <p className="mb-0 text-black">
                                            Variants Items
                                          </p>
                                          {Object.keys(selected_specification)
                                            .length > 0 ? (
                                            <div className="ms-auto">
                                              {edit_addon_price ? (
                                                <div className="d-flex">
                                                  {/* <button
                                                    className="btn btn-primary fw-bold btn-sm me-3 px-3"
                                                    onClick={() => {
                                                      console.log(
                                                        'on Edit seleceted_addon_group_form:',
                                                        seleceted_addon_group_form,
                                                      )
                                                      let latest_data = []
                                                      let v_data =
                                                        seleceted_addon_group_form.addon_child_varriant_data
                                                      v_data.map(
                                                        (v_ele, v_index) => {
                                                          let data = {}
                                                          let data1 = []

                                                          v_ele.varriant.map(
                                                            (vv_ele, vv_index) => {
                                                              data1.push({
                                                                id: vv_ele.id,
                                                                price: vv_ele.mrp,
                                                              })
                                                            },
                                                          )
                                                          data.id =
                                                            v_ele.addon_items_id
                                                          data.specification = data1
                                                          latest_data.push(data)
                                                        },
                                                      )
                                                      // update_price(latest_data);
                                                      SetEditAddonPrice(false)
                                                      SetIsEdit(false)
                                                      SetIsSave(true)
                                                    }}
                                                    >
                                                      <span className="pe-1">
                                                        <MaterialCommunityIcons
                                                          name="check-underline"
                                                          size={16}
                                                          color="#fff"
                                                        />
                                                      </span>
                                                      Save
                                                    </button> */}
                                                  {/* <button
                                                      className="btn btn-secondary fw-bold btn-sm me-3 px-2"
                                                      onClick={() => {
                                                        SetEditAddonPrice(false)
                                                        SetIsEdit(false)
                                                      }}
                                                    >
                                                      <span className="pe-1">
                                                        <Ionicons
                                                          name="md-close"
                                                          size={18}
                                                          color="#fff"
                                                        />
                                                      </span>
                                                      Cancel
                                                    </button> */}
                                                </div>
                                              ) : (
                                                seleceted_addon_group_form.addon_child_varriant_data.length > 0 ? (
                                                  <button
                                                    className="btn text-primary btn-sm px-3"
                                                    onClick={() => {
                                                      console.log(
                                                        "on Edit seleceted_addon_group_form:",
                                                        seleceted_addon_group_form
                                                      );
                                                      console.log(
                                                        "selected_child_item :",
                                                        selected_child_item
                                                      );
                                                      SetEditAddonPrice(true);
                                                      SetIsEdit(true);
                                                    }}
                                                  >
                                                    <span className="pe-1">
                                                      <Feather
                                                        name="edit-3"
                                                        size={16}
                                                        color="#0053ad"
                                                      />
                                                    </span>
                                                    Edit
                                                  </button>
                                                ) : null
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="pt-3">
                                          <nav className=" scroll-nav">
                                            <div
                                              className="nav nav-pills"
                                              id="nav-tab"
                                              role="tablist"
                                            >
                                              {Object.keys(
                                                selected_specification
                                              ).length > 0
                                                ? selected_specification.children.map(
                                                  (
                                                    child_ele: any,
                                                    child_index
                                                  ) =>
                                                    // selected_child_item.id
                                                    is_edit ? (
                                                      selected_child_item.value ==
                                                        child_ele.value ? (
                                                        <button
                                                          className={
                                                            // child_index == 0
                                                            selected_child_item.value ==
                                                              child_ele.value
                                                              ? "nav-link active"
                                                              : "nav-link"
                                                          }
                                                          id={
                                                            "nav-" +
                                                            child_index +
                                                            "-tab"
                                                          }
                                                          data-bs-toggle="tab"
                                                          data-bs-target={
                                                            "#nav-" +
                                                            child_index
                                                          }
                                                          type="button"
                                                          role="tab"
                                                          aria-controls={
                                                            "nav-" +
                                                            child_index
                                                          }
                                                          aria-selected="true"
                                                          key={child_index}
                                                          onClick={() => {
                                                            console.log(
                                                              "child_ele :",
                                                              child_ele
                                                            );
                                                            console.log(
                                                              "selected_specification :",
                                                              selected_specification
                                                            );

                                                            SetSelectedChildItem(
                                                              child_ele
                                                            );
                                                            SetIsEdit(false);
                                                            SetIsSave(false);
                                                            SetEditAddonPrice(
                                                              false
                                                            );

                                                            SetSaveDataInDependent(
                                                              []
                                                            );
                                                            SetSaveDataDependent(
                                                              []
                                                            );
                                                          }}
                                                        >
                                                          {child_ele.label}
                                                        </button>
                                                      ) : null
                                                    ) : (
                                                      <button
                                                        className={
                                                          // child_index == 0
                                                          selected_child_item.value ==
                                                            child_ele.value
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                        }
                                                        id={
                                                          "nav-" +
                                                          child_index +
                                                          "-tab"
                                                        }
                                                        data-bs-toggle="tab"
                                                        data-bs-target={
                                                          "#nav-" +
                                                          child_index
                                                        }
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={
                                                          "nav-" + child_index
                                                        }
                                                        aria-selected="true"
                                                        key={child_index}
                                                        onClick={() => {
                                                          console.log(
                                                            "child_ele :",
                                                            child_ele
                                                          );
                                                          console.log(
                                                            "selected_specification :",
                                                            selected_specification
                                                          );

                                                          SetSelectedChildItem(
                                                            child_ele
                                                          );
                                                          SetIsEdit(false);
                                                          SetIsSave(false);
                                                          SetEditAddonPrice(
                                                            false
                                                          );

                                                          SetSaveDataInDependent(
                                                            []
                                                          );
                                                          SetSaveDataDependent(
                                                            []
                                                          );
                                                        }}
                                                      >
                                                        {child_ele.label}
                                                      </button>
                                                    )
                                                )
                                                : null}
                                            </div>
                                          </nav>
                                          <div
                                            className="tab-content"
                                            id="nav-tabContent"
                                          >
                                            {Object.keys(selected_specification)
                                              .length > 0 ? (
                                              <div
                                                className="tab-pane fade show active"
                                                id="nav-home"
                                                role="tabpanel"
                                                aria-labelledby="nav-home-tab"
                                              >
                                                {seleceted_addon_group_form.addon_child_varriant_data.map(
                                                  (element, index) => (
                                                    <div
                                                      className="m-3"
                                                      key={index}
                                                    >
                                                      <OwnAccordion
                                                        field_data={element}
                                                        edit_data={is_edit}
                                                        is_dependent={true}
                                                        child_id={
                                                          selected_child_item.label
                                                        }
                                                        selected_specification={
                                                          selected_specification
                                                        }
                                                        selected_child_item={
                                                          selected_child_item
                                                        }
                                                        is_save={is_save}
                                                        set_save_value={(
                                                          data
                                                        ) => {
                                                          console.log(
                                                            "On save data in dependent:",
                                                            data
                                                          );
                                                          SetSaveDataDependent(
                                                            (prevValue) => {
                                                              let push_data = {
                                                                child_index:
                                                                  index,
                                                                data: data,
                                                              };
                                                              prevValue.push(
                                                                push_data
                                                              );
                                                              if (
                                                                addon_group_selected
                                                                  .data.children
                                                                  .length -
                                                                1 ==
                                                                index
                                                              ) {
                                                                save_addon_pricing(
                                                                  prevValue
                                                                );
                                                              }
                                                              return [
                                                                ...prevValue,
                                                              ];
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div className="w-100 pt-2">
                                <div className="text-end">
                                  {edit_independent_addon_price ? (
                                    <div className="py-2">
                                      {/* <button
                                        className="btn btn-secondary fw-bold btn-sm me-3 px-2"
                                        onClick={() => {
                                          console.log(
                                            'Item on edit addon_group_selected :',
                                            addon_group_selected,
                                          )
                                          SetEditInDependentAddonPrice(false)
                                          SetIsEdit(false)
                                          // let latest_data = [];
                                          // addon_group_selected.data.children.map(
                                          //   (c_elem: any, c_index: any) => {
                                          //     latest_data.push({
                                          //       id: c_elem.addon_items_id,
                                          //       price: c_elem.mrp,
                                          //     });
                                          //   }
                                          // );
                                          // update_price(latest_data);
                                        }}
                                      >
                                        <span className="pe-1">
                                          <Ionicons
                                            name="md-close"
                                            size={18}
                                            color="#fff"
                                          />
                                        </span>
                                        Cancel
                                      </button> */}

                                      {/* <button
                                        className="btn btn-primary fw-bold btn-sm me-3 px-3"
                                        onClick={() => {
                                          SetIsSave(true)
                                        }}
                                      >
                                        <span className="pe-1">
                                          <MaterialCommunityIcons
                                            name="check-underline"
                                            size={16}
                                            color="#fff"
                                          />
                                        </span>
                                        Save
                                      </button> */}
                                    </div>
                                  ) : (
                                    addon_group_selected.data.hasOwnProperty(
                                      "children"
                                    ) ? (
                                      <button
                                        className="btn text-primary radius btn-sm"
                                        onClick={() => {
                                          console.log(
                                            "Item on edit addon_group_selected :",
                                            addon_group_selected
                                          );
                                          SetEditInDependentAddonPrice(true);
                                          SetIsEdit(true);
                                        }}
                                      >
                                        <span className="pe-1">
                                          {/* <AntDesign
                                            name="edit"
                                            size={16} */}
                                          <Feather
                                            name="edit-3"
                                            size={16}
                                            color="#0053ad"
                                          />
                                        </span>
                                        Edit
                                      </button>
                                    ) : null
                                  )}
                                </div>

                                <div className="row p-2">
                                  {addon_group_selected.data.hasOwnProperty(
                                    "children"
                                  )
                                    ? addon_group_selected.data.children.map(
                                      (element, child_index) => (
                                        <div key={child_index}>
                                          <OwnAccordion
                                            field_data={element}
                                            edit_data={is_edit}
                                            is_dependent={false}
                                            child_id={""}
                                            selected_specification={{}}
                                            selected_child_item={{}}
                                            is_save={is_save}
                                            set_save_value={(data) => {
                                              console.log(
                                                "On save data not dependent:",
                                                data
                                              );
                                              SetSaveDataInDependent(
                                                (prevValue) => {
                                                  let push_data = {
                                                    child_index: child_index,
                                                    data: data,
                                                  };
                                                  prevValue.push(push_data);
                                                  if (
                                                    addon_group_selected.data
                                                      .children.length -
                                                    1 ==
                                                    child_index
                                                  ) {
                                                    save_addon_pricing(
                                                      prevValue
                                                    );
                                                  }
                                                  return [...prevValue];
                                                }
                                              );
                                            }}
                                          />
                                          {/* <OwnAccordianNew
                                            field_data={element}
                                            edit_data={is_edit}
                                            is_dependent={false}
                                            child_id={""}
                                            selected_specification={{}}
                                            selected_child_item={{}}
                                            is_save={is_save} 
                                          /> */}
                                        </div>
                                      )
                                    )
                                    : null}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : view_data == "main" ? (
                    <div className="row">
                      {/* group name */}
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Addon Group Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Addon Group Name"
                          value={save_data.name}
                          disabled={read_only}
                          onChange={(e) => {
                            input_on_change("name", e);
                            // SetSaveData((prevValue) => {
                            //   prevValue.name = e.target.value;
                            //   return { ...prevValue };
                            // });
                          }}
                        />
                        <span className="text-danger">
                          {error_state.nameError}
                        </span>
                      </div>
                      {/* group note */}
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Addon Group Notes</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Addon Group Notes"
                          value={save_data.notes}
                          disabled={read_only}
                          onChange={(e) => {
                            input_on_change("notes", e);

                            // SetSaveData((prevValue) => {
                            //   prevValue.notes = e.target.value;
                            //   return { ...prevValue };
                            // });
                          }}
                        />
                        <span className="text-danger">
                          {error_state.noteError}
                        </span>
                      </div>
                      {/* Is Compulsory /Is Multiple */}
                      <div className="mb-3 d-flex">
                        {/* Compulsory */}
                        <div className="d-flex mb-0 align-items-center">
                          <label className="form-label pe-3 mb-0">
                            Is Compulsory
                          </label>
                          <div className="ms-auto">
                            <Switch
                              id_value={"is_compulsory"}
                              isOn={save_data.is_compulsory}
                              onColor="#0053ad"
                              handleToggle={(e) => {
                                console.log(
                                  "is_compulsory Switch on change :",
                                  e
                                );
                                if (!read_only) {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_compulsory =
                                      !prevValue.is_compulsory;
                                    return { ...prevValue };
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* Single / Multiple */}
                        <div className="end">
                          {/* <label className="form-label pe-3 mb-0">
                            Is Multiple
                          </label>
                          <Switch
                            id_value={'is_multiple'}
                            isOn={save_data.is_multiple}
                            onColor="#0053ad"
                            handleToggle={(e) => {
                              console.log('is_multiple Switch on change :', e)
                              if (!read_only) {
                                SetSaveData((prevValue) => {
                                  prevValue.is_multiple = !prevValue.is_multiple
                                  return { ...prevValue }
                                })
                              }
                            }}
                          /> */}
                          <div className="pe-2">
                            <label className="cursor">
                              <input
                                type="radio"
                                className="me-1 text-primary form-check-input"
                                checked={!save_data.is_multiple}
                                onChange={() => {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_multiple =
                                      !prevValue.is_multiple;
                                    return { ...prevValue };
                                  });
                                }}
                              />
                              Single
                            </label>
                          </div>
                          <div className="pe-2">
                            <label className="cursor">
                              <input
                                type="radio"
                                className="me-1 text-primary"
                                checked={save_data.is_multiple}
                                onChange={() => {
                                  SetSaveData((prevValue) => {
                                    prevValue.is_multiple =
                                      !prevValue.is_multiple;
                                    return { ...prevValue };
                                  });
                                }}
                              />
                              Multiple
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* min/max */}
                      {save_data.is_multiple ? (
                        <>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">Min Count</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Addon Item Min Count"
                              value={save_data.min_value}
                              disabled={read_only}
                              onChange={(e) => {
                                SetSaveData((prevValue) => {
                                  // if(prevValue.max_value != ""){
                                  //   if(prevValue.max_value < e.target.value){
                                  //     toast.error("Minimum count must be less than maximium count")
                                  //   }else{
                                  //     prevValue.min_value = e.target.value;
                                  //   }
                                  // }else{
                                  //   prevValue.min_value = e.target.value;
                                  // }
                                  prevValue.min_value = e.target.value;
                                  return { ...prevValue };
                                });
                              }}
                            />
                            <span className="text-danger">
                              {error_state.MinError}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">Max Count</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Addon Item Max Count"
                              value={save_data.max_value}
                              disabled={read_only}
                              onChange={(e) => {
                                SetSaveData((prevValue) => {
                                  // if(prevValue.min_value != ""){
                                  //   if(prevValue.min_value > e.target.value){
                                  //     toast.error("Maximium count must be greater than minimum count")
                                  //   }else{
                                  //     prevValue.max_value = e.target.value;
                                  //   }
                                  // }else{
                                  //   prevValue.max_value = e.target.value;
                                  // }
                                  prevValue.max_value = e.target.value;
                                  return { ...prevValue };
                                });
                              }}
                            />
                            <span className="text-danger">
                              {error_state.MaxError}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {/* Child Items */}
                      <div className="border-top mt-2 py-3">
                        <div className=" d-flex align-items-center">
                          <h6 className="text-dark fw-bold mb-0">
                            Addon Items
                          </h6>
                          <span className="text-danger">
                            {error_state.childError}
                          </span>
                          {/* {props.type != "view" ? ( */}
                          <label
                            className="ms-auto cursor text-primary"
                            onClick={() => {
                              SetViewData("child");
                              console.log("add");
                            }}
                          >
                            <i className=" fas fa-plus "></i> Add
                          </label>
                          {/* ) : null} */}
                        </div>
                        <div className="row mt-3">
                          {addon_item_data.length > 0 ? (
                            addon_item_data.map((a_ele, a_index) =>
                              a_ele.checked ? (
                                <div className="col-md-6 mb-3" key={a_index}>
                                  <div className="card p-2">
                                    <label className=" d-flex cursor">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={a_ele.checked}
                                          onChange={(e) => {
                                            SetAddonItemData((prevalue: any) => {
                                              prevalue[a_index].checked = e.target.checked;
                                              return [...prevalue]
                                            })
                                            // SetAddonItemData(
                                            //   (prevValue1: any) => {
                                            //     prevValue1[org_index].checked =
                                            //       !prevValue1[org_index]
                                            //         .checked;
                                            //     console.log(
                                            //       "SetAddonItemData prevValue :",
                                            //       prevValue1
                                            //     );
                                            //     return [...prevValue1];
                                            //   }
                                            // );
                                          }}
                                        />
                                      </div>
                                      <div className="ms-3">
                                        <p className="mb-2 text-black">
                                          {a_ele.name}
                                        </p>
                                        <div className=" mb-2">
                                          <p className="note_hide mb-0">
                                            Notes : {a_ele.notes}
                                          </p>
                                        </div>

                                        {/* <p className="mb-2">MRP : ₹ {a_ele.mrp}</p> */}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              ) : null
                            )
                          ) : (
                            <p className="text-center">
                              No Child Addon Items, Please Add Some.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : view_data == "child" ? (
                    <div className="">
                      {/* header */}
                      <div className="row align-items-center pb-2">
                        <div className="col-8 col-md-9 pe-0 pb-3">
                          {/* search */}
                          <div className="input-icons form-group p-2 px-3">
                            {/* <input
                              type="text"
                              className="form-control search-bar p-0"
                              placeholder="Search Addon Item"
                              value={addon_item_search}
                              onChange={(e) => {
                                SetAddonItemSearch(e.target.value)
                                addon_search_on_change(e)
                              }}
                            /> */}
                            <input
                              type="text"
                              className="form-control search-bar p-0"
                              placeholder="Search here..."
                              onChange={(e) => {
                                // SetSearchData((prevValue: any) => {
                                //   prevValue = e.target.value
                                //   return prevValue
                                // })
                                SetItemFilterData((prevValue1: any) => {
                                  prevValue1.search = e.target.value;
                                  return { ...prevValue1 };
                                });
                              }}
                            />
                            <div className="cursor px-2 border-left">
                              <Ionicons
                                name="md-search"
                                size={18}
                                color="#ccc"
                              />
                            </div>
                          </div>
                        </div>
                        {/* add new */}
                        <div className="col-4 col-md-3 pb-3">
                          <button
                            className="btn btn-text fw-bold text-primary d-flex align-items-center btn-sm ms-auto"
                            onClick={() => {
                              SetViewData("add_child");
                            }}
                          >
                            <Feather name="plus" size={16} color="#0053ad" />
                            <span className="ps-2">Add New</span>
                          </button>
                        </div>
                      </div>
                      {/* body */}
                      <div className="row p-2">
                        {filter_addon_item_data.length > 0 ? (
                          <InfiniteScroll
                            dataLength={filter_addon_item_data.length}
                            next={() => {
                              SetItemFilterData((prevValue: any) => {
                                prevValue.page_no = prevValue.page_no + 1;
                                // get_gallery(prevValue, true)
                                get_all_addon_item(prevValue, true);
                                return { ...prevValue };
                              });
                            }}
                            hasMore={item_filter_data.next_page}
                            className=""
                            height={450}
                            loader={
                              <div
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                  padding: "10px",
                                }}
                              >
                                <div className="lds-ellipsis">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            }
                            endMessage={
                              <div className="center pt-5 pb-3">
                                <hr className="w-25" />
                              </div>
                            }
                          >
                            <div className="row">
                              {filter_addon_item_data.map((a_ele, a_index) => (
                                <div className="col-md-6  mb-3">
                                  <div className="card p-2 mb-3" key={a_index}>
                                    <label className="row cursor">
                                      <div className="col-1 pe-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={a_ele.checked}
                                          onChange={() => {
                                            let org_index =
                                              addon_item_data.findIndex(
                                                (p) => p.id == a_ele.id
                                              );
                                            console.log(
                                              "org_index :",
                                              org_index
                                            );
                                            SetAddonItemData(
                                              (prevValue1: any) => {
                                                prevValue1[org_index].checked =
                                                  !prevValue1[org_index]
                                                    .checked;
                                                console.log(
                                                  "SetAddonItemData prevValue :",
                                                  prevValue1
                                                );
                                                return [...prevValue1];
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-11">
                                        <p className="mb-1 text-black">
                                          {a_ele.name}
                                        </p>
                                        <small className="note_hide v-small">
                                          {a_ele.notes}
                                        </small>
                                      </div>
                                      <div className="pt-2">
                                        {a_ele.mrp?.map(
                                          (mrp_ele: any, mrp_index: any) =>
                                            mrp_index == 0 ? (
                                              <div
                                                className="row align-items-center"
                                                key={mrp_index}
                                              >
                                                <p className="mb-2 text-black">
                                                  {mrp_ele.country},{" "}
                                                  {mrp_ele.state}
                                                </p>
                                                {/* {mrp_ele.hasOwnProperty("state") ? (
                                              <p className="">
                                                State : {mrp_ele.state}
                                              </p>
                                            ) : null} */}
                                                <div className="col-6">
                                                  <p className="text-darkgray mb-0 fw-bold">
                                                    {mrp_ele.currency_symbol}{" "}
                                                    {mrp_ele.price}
                                                  </p>
                                                </div>

                                                <div className="col-6 end">
                                                  <Feather
                                                    name="calendar"
                                                    size={14}
                                                    color="lightgray"
                                                  />
                                                  <small className="ps-1">
                                                    {mrp_ele.activation_date}
                                                  </small>
                                                </div>
                                              </div>
                                            ) : null
                                        )}
                                        {/* {a_ele.mrp.map(
                                        (mrp_ele: any, mrp_index: any) => (
                                          <div
                                            className="col-md-6"
                                            key={mrp_index}
                                          >
                                            <p className="">
                                              Country : {mrp_ele.country}
                                            </p>
                                            {mrp_ele.hasOwnProperty("state") ? (
                                              <p className="">
                                                State : {mrp_ele.state}
                                              </p>
                                            ) : null}
                                            <p className="">
                                              price :{mrp_ele.currency_symbol}{" "}
                                              {mrp_ele.price}
                                            </p>
                                            <p className="">
                                              Activation Date :{" "}
                                              {mrp_ele.activation_date}
                                            </p>
                                          </div>
                                        )
                                      )} */}
                                      </div>
                                      {/* <p className="mb-2">MRP : ₹ {a_ele.mrp}</p> */}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </InfiniteScroll>
                        ) : (
                          <p>No Data.</p>
                        )}
                      </div>
                    </div>
                  ) : view_data == "add_child" ? (
                    <div className="">
                      {/* <h6>Add Child</h6> */}
                      <div className="row pt-2">
                        {/* Addon Item Name */}
                        <div className="col-md-4 mb-3">
                          <label className="form-label">Addon Item Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Addon Item Name"
                            value={item_save_data.name}
                            disabled={read_only}
                            onChange={(e) => {
                              SetItemSaveData((prevValue) => {
                                prevValue.name = e.target.value;
                                return { ...prevValue };
                              });
                            }}
                          />
                        </div>
                        {/* Addon Item Notes */}
                        <div className="col-md-4 mb-3">
                          <label className="form-label">Addon Item Notes</label>

                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Addon Item Notes"
                            value={item_save_data.notes}
                            disabled={read_only}
                            onChange={(e) => {
                              SetItemSaveData((prevValue) => {
                                prevValue.notes = e.target.value;
                                return { ...prevValue };
                              });
                            }}
                          />
                        </div>
                        {/* custom quantity */}
                        <div className="col-md-4 mb-3">
                          <label className="form-label mb-0 d-flex">
                            <p className="mb-0 pe-3">Custom Quantity</p>
                            <Switch
                              isOn={item_save_data.custom_quantity}
                              onColor="#0053ad"
                              id_value="custom_quantity"
                              handleToggle={(e) => {
                                console.log("Switch on change :", e);
                                if (!read_only) {
                                  SetItemSaveData((prevValue) => {
                                    prevValue.custom_quantity =
                                      !prevValue.custom_quantity;
                                    return { ...prevValue };
                                  });
                                }
                              }}
                            />
                          </label>
                        </div>
                        {spec_data.map((spec_ele:any,spec_index:any)=>(
                          <div className="col-md-6 mb-2">
                            <label className="form-label d-flex">
                              <p>{spec_ele.name}</p>
                              {spec_ele.type_id == 2 ? (
                                // select
                                <Select
                                  components={animatedComponents}
                                  placeholder="Select Option"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={spec_ele.children}
                                  value={spec_ele.value}
                                  onChange={(e) => {
                                    SetSpecData((prevalue)=>{
                                      prevalue.map((ele,index)=>{
                                        if(index == spec_index){
                                          ele.value = e
                                        }
                                      })
                                      console.log("prevalue :",prevalue)
                                      return [...prevalue]
                                    })
                                  }}
                                />
                              ) : null}
                            </label>
                          </div>
                        ))}
                      </div>
                      {/* <div className="">
                        <div className="d-flex align-items-center pb-2 border-bottom">
                          <p className="text-dark fw-bold mb-0">MRP Details</p>
                          <div className="ms-auto">
                            <p
                              className="mb-0 text-primary px-2 cursor"
                              onClick={() => {
                                SetMrpData((prevValue: any) => {
                                  let push_value = {
                                    selected_country: {
                                      id: "99",
                                      iso: "IN",
                                      iso3: "IND",
                                      label: "India",
                                      name: "INDIA",
                                      nicename: "India",
                                      numcode: "356",
                                      phonecode: "91",
                                      value: "99",
                                    },
                                    country: "99",
                                    state_data: [],
                                    selected_state: {},
                                    state: "",
                                    selected_currency: {
                                      code: "INR",
                                      id: "1",
                                      label: "₹",
                                      name: "Indian Rupees",
                                      symbol: "₹",
                                      value: "1",
                                    },
                                    currency: "1",
                                    price: "",
                                    selected_date: "",
                                    activation_date: "",
                                  };
                                  prevValue.push(push_value);
                                  return [...prevValue];
                                });
                              }}
                            >
                              + Add MRP
                            </p>
                          </div>
                        </div>
                        <div className="mb-2">
                          {mrp_data.map((ele: any, index: any) => (
                            <div
                              className="row align-items-center p-2 cursor-hover"
                              key={index}
                            >
                              Country
                              <div className="col-md-3 p-1">
                                <label className="form-label">Country</label>
                                <Select
                                  components={animatedComponents}
                                  placeholder="Select Country"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={context.app_data.countries}
                                  value={ele.selected_country}
                                  onChange={(e) => {
                                    console.log(" Country :", e);
                                    SetMrpData((prevValue: any) => {
                                      prevValue[index].selected_country = e;
                                      prevValue[index].country = e.id;
                                      prevValue[index].selected_state = {};
                                      prevValue[index].state = "";
                                      return [...prevValue];
                                    });
                                  }}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                              State
                              {ele.selected_country.id == "99" ? (
                                <div className="col-md-3 p-1">
                                  <label className="form-label">State</label>
                                  <Select
                                    components={animatedComponents}
                                    placeholder="Select State"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={ele.state_data}
                                    value={ele.selected_state}
                                    onChange={(e) => {
                                      console.log(" state :", e);
                                      SetMrpData((prevValue: any) => {
                                        prevValue[index].selected_state = e;
                                        prevValue[index].state = e.id;
                                        return [...prevValue];
                                      });
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                </div>
                              ) : null}
                              Price
                              <div className="col-md-3 p-1">
                                <label className="form-label ">Price</label>
                                <div className="input-group">
                                  <div className="input-group-text p-0">
                                    <Select
                                      className="no-radius border-none"
                                      placeholder="Select Currency"
                                      classNamePrefix="select"
                                      options={context.app_data.currencies}
                                      value={ele.selected_currency}
                                      isSearchable={false}
                                      onChange={(e) => {
                                        console.log(" Currency :", e);

                                        SetMrpData((prevValue: any) => {
                                          prevValue[index].selected_currency =
                                            e;
                                          prevValue[index].currency = e.id;
                                          return [...prevValue];
                                        });
                                      }}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          border: "none",
                                        }),
                                        indicatorSeparator: (base) => ({
                                          ...base,
                                          backgroundColor: "none",
                                        }),
                                      }}
                                    />
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Price"
                                    value={ele.price}
                                    disabled={read_only}
                                    onChange={(e) => {
                                      console.log(" Price :", e.target.value);
                                      SetMrpData((prevValue: any) => {
                                        prevValue[index].price = e.target.value;
                                        return [...prevValue];
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              Activation Date
                              <div className="col-md-2 p-1">
                                <label className="form-label">
                                  Activation Date
                                </label>
                                <DatePicker
                                  selected={ele.selected_date}
                                  dateFormat="dd/MM/yyyy"
                                  customInput={<DatepickerInput />}
                                  onSelect={(date) => {
                                    let date_value =
                                      moment(date).format("DD-MM-yyyy");
                                    SetMrpData((prevValue: any) => {
                                      prevValue[index].selected_date = date;
                                      prevValue[index].activation_date =
                                        date_value;
                                      return [...prevValue];
                                    });
                                  }}
                                  onChange={(date) => {
                                    let date_value =
                                      moment(date).format("DD-MM-yyyy");
                                    SetMrpData((prevValue: any) => {
                                      prevValue[index].selected_date = date;
                                      prevValue[index].activation_date =
                                        date_value;
                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                              <div className="col-1 mt-2 p-0 center ms-auto">
                                <button
                                  className="btn btn-secondary-outline border-none btn-sm p-2"
                                  onClick={() => {
                                    SetMrpData((prevValue: any) => {
                                      prevValue.splice(index, 1);
                                      return [...prevValue];
                                    });
                                  }}
                                >
                                  <MaterialCommunityIcons
                                    name="delete-outline"
                                    size={16}
                                    color="black"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {props.from == "product_details" ? (
                <div className="">
                  {view_data == "list" ? (
                    <div className="">
                      {/* {addon_group_selected.is_open == false ? (
                        <div className="d-flex mb-2">
                          <div
                            className="text-primary ms-auto fw-bold d-flex align-items cursor"
                            onClick={() => {
                              SetViewData("main");
                            }}
                          >
                            <div className="me-2">
                              <Entypo name="plus" size={16} color="#0053ad" />
                            </div>
                            Create New
                          </div>
                        </div>
                      ) : null} */}

                      <div className="">
                        {addon_group_selected.is_open == false ? (
                          <div className="">
                            {available_addon_data.length > 0 ? (
                              <div className="row">
                                {available_addon_data.map(
                                  (ele: any, index: any) => (
                                    <div
                                      key={index}
                                      className="col-md-4 col-6 p-2 "
                                      onClick={() => {
                                        console.log("on group click");
                                        SetAddonGroupSelected(
                                          (prevValue: any) => {
                                            prevValue.is_open = true;
                                            prevValue.data = ele;
                                            return { ...prevValue };
                                          }
                                        );
                                        
                                      }}
                                    >
                                      <div className="card mb-3 p-2 cursor">
                                        <p className="text-dark mb-2">
                                          {ele.name}
                                        </p>
                                        <div className="d-flex align-items-center pe-2">
                                          <MaterialIcons
                                            name="notes"
                                            size={14}
                                            color="#959595"
                                          />
                                          <small className="ps-2">
                                            {ele.notes}
                                          </small>
                                        </div>
                                        <div className="d-flex align-items-center pe-2">
                                          <MaterialIcons
                                            name="category"
                                            size={14}
                                            color="#959595"
                                          />
                                          <small className="ps-2">
                                            {ele.type}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div className="">No Addon Group</div>
                            )}
                          </div>
                        ) : (
                          <div className="">
                            <div className="row">
                              <div className="col-md-6">
                                <p className="text-dark mb-2 fw-bold">
                                  {addon_group_selected.data.name}
                                </p>
                                <div className="d-flex align-items-center pe-2">
                                  <MaterialIcons
                                    name="notes"
                                    size={14}
                                    color="#959595"
                                  />
                                  <small className="ps-2">
                                    {addon_group_selected.data.notes}
                                  </small>
                                </div>
                                <div className="d-flex align-items-center pe-2">
                                  <MaterialIcons
                                    name="category"
                                    size={14}
                                    color="#959595"
                                  />
                                  <small className="ps-2">
                                    {addon_group_selected.data.type}
                                  </small>
                                </div>
                                {/* <p className="text-dark mb-1">
                                {addon_group_selected.data.name}
                                </p>
                                <p>
                                  <small>
                                    {addon_group_selected.data.notes}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    {addon_group_selected.data.notes}
                                  </small>
                                </p> */}
                              </div>
                              {addon_group_selected.data.hasOwnProperty(
                                "children"
                              ) ? (
                                <div className="end col-md-6 md-text-end">
                                  <label className="form-label pe-3 mb-0">
                                    Is Dependent
                                  </label>
                                  <Switch
                                    id_value={"is_dependent"}
                                    isOn={
                                      seleceted_addon_group_form.is_dependent
                                    }
                                    onColor="#0053ad"
                                    handleToggle={(e) => {
                                      console.log(
                                        "dependent on change :",
                                        seleceted_addon_group_form
                                      );
                                      if (
                                        seleceted_addon_group_form.varriant_data
                                          .length > 0
                                      ) {
                                        SetSelecetedAddonGroupForm(
                                          (prevValue: any) => {
                                            prevValue.is_dependent =
                                              !prevValue.is_dependent;
                                            console.log(
                                              "prevValue :",
                                              prevValue
                                            );
                                            return { ...prevValue };
                                          }
                                        );
                                      } else {
                                        toast.error("No Variants");
                                      }
                                      SetEditAddonPrice(false);
                                      SetEditInDependentAddonPrice(false);
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>

                            {seleceted_addon_group_form.is_dependent ? (
                              <div className="py-3 ">
                                <p className="mb-0 text-dark">Variants</p>
                                {seleceted_addon_group_form.varriant_data.map(
                                  (v_ele: any, v_index: any) => (
                                    <div className="py-2" key={v_index}>
                                      <label className="d-flex cursor">
                                        <input
                                          type="radio"
                                          name="type"
                                          value={v_ele.id}
                                          id={v_ele.id}
                                          onChange={() => {
                                            on_varriant_select(
                                              v_ele,
                                              filter_data
                                            );
                                            SetSaveDataInDependent([]);
                                            SetSaveDataDependent([]);
                                          }}
                                          className="me-2 form-check-input"
                                        />
                                        <p className="m-0 ml-2">{v_ele.name}</p>
                                      </label>
                                    </div>
                                  )
                                )}
                                <div className="">
                                  {seleceted_addon_group_form.dependent_id !=
                                    null ? (
                                    <div className="py-3">
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 text-black">
                                          Variants Items
                                        </p>
                                        {Object.keys(selected_specification)
                                          .length > 0 ? (
                                          <div className="ms-auto">
                                            {edit_addon_price ? (
                                              <div className="d-flex">
                                                {/* <button
                                                className="btn btn-primary fw-bold btn-sm me-3 px-3"
                                                onClick={() => {
                                                  console.log(
                                                    'on Edit seleceted_addon_group_form:',
                                                    seleceted_addon_group_form,
                                                  )
                                                  let latest_data = []
                                                  let v_data =
                                                    seleceted_addon_group_form.addon_child_varriant_data
                                                  v_data.map(
                                                    (v_ele, v_index) => {
                                                      let data = {}
                                                      let data1 = []

                                                      v_ele.varriant.map(
                                                        (vv_ele, vv_index) => {
                                                          data1.push({
                                                            id: vv_ele.id,
                                                            price: vv_ele.mrp,
                                                          })
                                                        },
                                                      )
                                                      data.id =
                                                        v_ele.addon_items_id
                                                      data.specification = data1
                                                      latest_data.push(data)
                                                    },
                                                  )
                                                  // update_price(latest_data);
                                                  SetEditAddonPrice(false)
                                                  SetIsEdit(false)
                                                  SetIsSave(true)
                                                }}
                                              >
                                                <span className="pe-1">
                                                  <MaterialCommunityIcons
                                                    name="check-underline"
                                                    size={16}
                                                    color="#fff"
                                                  />
                                                </span>
                                                Save
                                              </button> */}
                                                {/* <button
                                                className="btn btn-secondary fw-bold btn-sm me-3 px-2"
                                                onClick={() => {
                                                  SetEditAddonPrice(false)
                                                  SetIsEdit(false)
                                                }}
                                              >
                                                <span className="pe-1">
                                                  <Ionicons
                                                    name="md-close"
                                                    size={18}
                                                    color="#fff"
                                                  />
                                                </span>
                                                Cancel
                                              </button> */}
                                              </div>
                                            ) : (
                                             <div className="my-2">
                                               <button
                                                className="btn btn-text text-primary btn-sm px-3 d-flex align-items-center"
                                                onClick={() => {
                                                  console.log(
                                                    "on Edit seleceted_addon_group_form:",
                                                    seleceted_addon_group_form
                                                  );
                                                  SetEditAddonPrice(true);
                                                  SetIsEdit(true);
                                                }}
                                              >
                                                <Feather
                                                  name="edit-3"
                                                  size={16}
                                                  color="#0053ad"
                                                />
                                                <div className="ps-2 text-primary">
                                                  Edit
                                                </div>
                                              </button>
                                             </div>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="pt-3">
                                        <nav className=" scroll-nav">
                                          <div
                                            className="nav nav-pills"
                                            id="nav-tab"
                                            role="tablist"
                                          >
                                            {Object.keys(selected_specification)
                                              .length > 0
                                              ? selected_specification.children.map(
                                                (
                                                  child_ele: any,
                                                  child_index
                                                ) => (
                                                  <button
                                                    className={
                                                      child_index == 0
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                    }
                                                    id={
                                                      "nav-" +
                                                      child_index +
                                                      "-tab"
                                                    }
                                                    data-bs-toggle="tab"
                                                    data-bs-target={
                                                      "#nav-" + child_index
                                                    }
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={
                                                      "nav-" + child_index
                                                    }
                                                    aria-selected="true"
                                                    key={child_index}
                                                    onClick={() => {
                                                      console.log(
                                                        "child_ele :",
                                                        child_ele
                                                      );
                                                      console.log(
                                                        "selected_specification :",
                                                        selected_specification
                                                      );

                                                      SetSelectedChildItem(
                                                        child_ele
                                                      );
                                                      SetIsEdit(false);
                                                      SetIsSave(false);
                                                      SetEditAddonPrice(
                                                        false
                                                      );

                                                      SetSaveDataInDependent(
                                                        []
                                                      );
                                                      SetSaveDataDependent(
                                                        []
                                                      );
                                                    }}
                                                  >
                                                    {child_ele.label}
                                                  </button>
                                                )
                                              )
                                              : null}
                                          </div>
                                        </nav>
                                        <div
                                          className="tab-content"
                                          id="nav-tabContent"
                                        >
                                          {Object.keys(selected_specification)
                                            .length > 0 ? (
                                            <div
                                              className="tab-pane fade show active"
                                              id="nav-home"
                                              role="tabpanel"
                                              aria-labelledby="nav-home-tab"
                                            >
                                              {seleceted_addon_group_form.addon_child_varriant_data.map(
                                                (element, index) => (
                                                  <div
                                                    className="m-3"
                                                    key={index}
                                                  >
                                                    <OwnAccordion
                                                      field_data={element}
                                                      edit_data={is_edit}
                                                      is_dependent={true}
                                                      child_id={
                                                        selected_child_item.label
                                                      }
                                                      selected_specification={
                                                        selected_specification
                                                      }
                                                      selected_child_item={
                                                        selected_child_item
                                                      }
                                                      is_save={is_save}
                                                      set_save_value={(
                                                        data
                                                      ) => {
                                                        console.log(
                                                          "On save data in dependent:",
                                                          data
                                                        );
                                                        SetSaveDataDependent(
                                                          (prevValue) => {
                                                            let push_data = {
                                                              child_index:
                                                                index,
                                                              data: data,
                                                            };
                                                            prevValue.push(
                                                              push_data
                                                            );
                                                            if (
                                                              addon_group_selected
                                                                .data.children
                                                                .length -
                                                              1 ==
                                                              index
                                                            ) {
                                                              save_addon_pricing(
                                                                prevValue
                                                              );
                                                            }
                                                            return [
                                                              ...prevValue,
                                                            ];
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div className="w-100 pt-2">
                                <div className="text-end">
                                  {edit_independent_addon_price ? (
                                    <div className="py-2">
                                      {/* <button
                                        className="btn btn-secondary fw-bold btn-sm me-3 px-2"
                                        onClick={() => {
                                          console.log(
                                            'Item on edit addon_group_selected :',
                                            addon_group_selected,
                                          )
                                          SetEditInDependentAddonPrice(false)
                                          SetIsEdit(false)
                                        }}
                                      >
                                        <span className="pe-1">
                                          <Ionicons
                                            name="md-close"
                                            size={18}
                                            color="#fff"
                                          />
                                        </span>
                                        Cancel
                                      </button>

                                      <button
                                        className="btn btn-primary fw-bold btn-sm me-3 px-3"
                                        onClick={() => {
                                          SetIsSave(true)
                                        }}
                                      >
                                        <span className="pe-1">
                                          <MaterialCommunityIcons
                                            name="check-underline"
                                            size={16}
                                            color="#fff"
                                          />
                                        </span>
                                        Save
                                      </button> */}
                                    </div>
                                  ) : (
                                    <button
                                      className="btn btn-text px-3 fw-bold btn-sm"
                                      onClick={() => {
                                        console.log(
                                          "Item on edit addon_group_selected :",
                                          addon_group_selected
                                        );
                                        SetEditInDependentAddonPrice(true);
                                        SetIsEdit(true);
                                      }}
                                    >
                                      <Feather
                                        name="edit-3"
                                        size={16}
                                        color="#0053ad"
                                      />
                                      <span className="ps-2 text-primary">
                                        Edit
                                      </span>
                                    </button>
                                  )}
                                </div>

                                <div className="row p-2">
                                  {addon_group_selected.data.hasOwnProperty(
                                    "children"
                                  ) ? (
                                    addon_group_selected.data.children.map(
                                      (element, child_index) => (
                                        <div key={child_index}>
                                          <OwnAccordion
                                            field_data={element}
                                            edit_data={is_edit}
                                            is_dependent={false}
                                            child_id={""}
                                            selected_specification={{}}
                                            selected_child_item={{}}
                                            is_save={is_save}
                                            set_save_value={(data) => {
                                              console.log(
                                                "On save data not dependent:",
                                                data
                                              );
                                              SetSaveDataInDependent(
                                                (prevValue) => {
                                                  let push_data = {
                                                    child_index: child_index,
                                                    data: data,
                                                  };
                                                  prevValue.push(push_data);
                                                  if (
                                                    addon_group_selected.data
                                                      .children.length -
                                                    1 ==
                                                    child_index
                                                  ) {
                                                    save_addon_pricing(
                                                      prevValue
                                                    );
                                                  }
                                                  return [...prevValue];
                                                }
                                              );
                                            }}
                                          />
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <div className="">
                                      <p>No Childrens</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          {/* modal footer */}
          <div className="modal-footer shadow">
            {props.from == "addon_group" ? (
              <div className="d-flex align-items-center w-100">
                {modal_type != "create" ? (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      let item = close_data;
                      item.action = "close";
                      SetCloseData(item);
                      // SetSaveData(initial_value);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                ) : null}
                {modal_type != "create" ? (
                  view_data == "child" ? (
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-primary btn-radius px-3 "
                        onClick={() => {
                          console.log("Child Ok");
                          // SetViewData('main')
                          update_addon_item();
                        }}
                      >
                        OK
                      </button>
                    </div>
                  ) : null
                ) : null}

                {modal_type == "edit" ? (
                  view_data == "main" ? (
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-primary btn-radius px-3"
                        onClick={() => {
                          console.log("Child Ok");
                          // SetViewData('main')
                          update_addon_group_details();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  ) : null
                ) : null}

                {modal_type == "create" ? (
                  view_data == "category" ? (
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-primary btn-radius px-3"
                        onClick={() => {
                          console.log("Category Ok");
                          SetViewData("main");
                          SetSaveData((prevValue) => {
                            prevValue.category = selected_category;
                            return { ...prevValue };
                          });
                          input_on_change("category", null);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  ) : view_data == "main" ? (
                    <div className="d-flex algn-items-center w-100">
                      {error_data ? (
                        <p className="error_message me-3">{error_data}</p>
                      ) : null}

                      <button
                        type="button"
                        className="btn btn-secondary-outline btn-radius px-3 "
                        onClick={() => {
                          let item = close_data;
                          item.action = "close";
                          SetCloseData(item);
                          // SetSaveData(initial_value);
                          myModal.hide();
                        }}
                      >
                        Close
                      </button>
                      {error_data == "" ? (
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="btn btn-primary btn-radius px-3"
                            onClick={() => {
                              console.log("save");
                              create_addon_group();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : view_data == "child" ? (
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-primary btn-radius px-3 "
                        onClick={() => {
                          console.log("Child Ok");
                          SetViewData("main");
                          SetFilterAddonItemData((prevalue)=>{
                            prevalue = addon_item_data;
                            return [...prevalue]
                          })
                          SetErrorState((prevValue: any) => {
                            if (!addon_item_data.some((ele) => ele.checked)) {
                              prevValue.childError = "Child is required";
                            } else {
                              prevValue.childError = "";
                            }
                            return { ...prevValue };
                          });
                        }}
                      >
                        OK
                      </button>
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : null}

            {props.from == "add_product" ? (
              <div className="d-flex align-items-center w-100 m-0">
                {edit_addon_price ? (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      console.log("edit_addon_price cancel");
                      SetEditAddonPrice(false);
                      SetIsEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                ) : edit_independent_addon_price ? (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      console.log("edit_independent_addon_price cancel");
                      SetEditInDependentAddonPrice(false);
                      SetIsEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      let item = close_data;
                      item.action = "close";
                      SetCloseData(item);
                      // SetSaveData(initial_value);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                )}

                <div className="ms-auto">
                  {view_data == "list" ? (
                    addon_group_selected.is_open ? (
                      <div className="">
                        {!edit_addon_price && !edit_independent_addon_price ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-radius px-3"
                            onClick={() => {
                              console.log("Ok");
                              // let item = close_data;
                              // item.action = "close";
                              // SetCloseData(item);
                              // // SetSaveData(initial_value);
                              // myModal.hide();
                              submit_addon();
                            }}
                          >
                            Submit
                          </button>
                        ) : null}
                        {edit_addon_price ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-radius px-3"
                            onClick={() => {
                              console.log("edit_addon_price Update");
                              console.log(
                                "on Edit seleceted_addon_group_form:",
                                seleceted_addon_group_form
                              );
                              let latest_data = [];
                              let v_data =
                                seleceted_addon_group_form.addon_child_varriant_data;
                              v_data.map((v_ele, v_index) => {
                                let data = {};
                                let data1 = [];

                                v_ele.varriant.map((vv_ele, vv_index) => {
                                  data1.push({
                                    id: vv_ele.id,
                                    price: vv_ele.mrp,
                                  });
                                });
                                data.id = v_ele.addon_items_id;
                                data.specification = data1;
                                latest_data.push(data);
                              });
                              // update_price(latest_data);
                              SetEditAddonPrice(false);
                              SetIsEdit(false);
                              SetIsSave(true);
                            }}
                          >
                            Update
                          </button>
                        ) : null}
                        {edit_independent_addon_price ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-radius px-3"
                            onClick={() => {
                              console.log(
                                "edit_independent_addon_price Update"
                              );
                              SetEditInDependentAddonPrice(false);
                              SetIsEdit(false);
                              SetIsSave(true);
                            }}
                          >
                            Update
                          </button>
                        ) : null}
                      </div>
                    ) : null
                  ) : null}

                  {view_data == "main" ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-radius px-3"
                      onClick={() => {
                        console.log("Create");
                        // let item = close_data;
                        // item.action = "close";
                        // SetCloseData(item);
                        // // SetSaveData(initial_value);
                        // myModal.hide();
                        product_create_addon_group();
                      }}
                    >
                      Create
                    </button>
                  ) : null}

                  {view_data == "child" ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-radius px-3"
                      onClick={() => {
                        console.log("Child ok");
                        // let item = close_data;
                        // item.action = "close";
                        // SetCloseData(item);
                        // // SetSaveData(initial_value);
                        // myModal.hide();
                        SetViewData("main");
                      }}
                    >
                      Ok
                    </button>
                  ) : null}

                  {view_data == "add_child" ? (
                    <button
                      type="button"
                      className="btn btn-primary btn-radius px-3"
                      onClick={() => {
                        console.log("create item ok");
                        // let item = close_data;
                        // item.action = "close";
                        // SetCloseData(item);
                        // // SetSaveData(initial_value);
                        // myModal.hide();
                        create_addon_item();
                      }}
                    >
                      Create Item
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}

            {props.from == "product_details" ? (
              <div className="d-flex align-items-center w-100">
                {edit_addon_price ? (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      console.log("edit_addon_price cancel");
                      SetEditAddonPrice(false);
                      SetIsEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                ) : edit_independent_addon_price ? (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      console.log("edit_independent_addon_price cancel");
                      SetEditInDependentAddonPrice(false);
                      SetIsEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      let item = close_data;
                      item.action = "close";
                      SetCloseData(item);
                      // SetSaveData(initial_value);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                )}
                {view_data == "list" ? (
                  addon_group_selected.is_open ? (
                    <div className="ms-auto">
                      {!edit_addon_price && !edit_independent_addon_price ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-radius px-3"
                          onClick={() => {
                            console.log(
                              "Submit addon_group_selected :",
                              addon_group_selected
                            );
                            submit_addon();
                          }}
                        >
                          Submit
                        </button>
                      ) : null}
                      {edit_addon_price ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-radius px-3"
                          onClick={() => {
                            console.log(
                              "on Edit seleceted_addon_group_form:",
                              seleceted_addon_group_form
                            );
                            let latest_data = [];
                            let v_data =
                              seleceted_addon_group_form.addon_child_varriant_data;
                            v_data.map((v_ele, v_index) => {
                              let data = {};
                              let data1 = [];

                              v_ele.varriant.map((vv_ele, vv_index) => {
                                data1.push({
                                  id: vv_ele.id,
                                  price: vv_ele.mrp,
                                });
                              });
                              data.id = v_ele.addon_items_id;
                              data.specification = data1;
                              latest_data.push(data);
                            });
                            // update_price(latest_data);
                            SetEditAddonPrice(false);
                            SetIsEdit(false);
                            SetIsSave(true);
                          }}
                        // onClick={() => {
                        //   console.log('edit_addon_price Update')
                        //   console.log(
                        //     'on Edit seleceted_addon_group_form:',
                        //     seleceted_addon_group_form,
                        //   )
                        //   let latest_data = []
                        //   let v_data =
                        //     seleceted_addon_group_form.addon_child_varriant_data
                        //   v_data.map((v_ele, v_index) => {
                        //     let data = {}
                        //     let data1 = []

                        //     v_ele.varriant.map((vv_ele, vv_index) => {
                        //       data1.push({
                        //         id: vv_ele.id,
                        //         price: vv_ele.mrp,
                        //       })
                        //     })
                        //     data.id = v_ele.addon_items_id
                        //     data.specification = data1
                        //     latest_data.push(data)
                        //   })
                        //   // update_price(latest_data);
                        //   SetEditAddonPrice(false)
                        //   SetIsEdit(false)
                        //   SetIsSave(true)
                        // }}
                        >
                          Update
                        </button>
                      ) : null}
                      {edit_independent_addon_price ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-radius px-3"
                          onClick={() => {
                            console.log("edit_independent_addon_price Update");
                            SetEditInDependentAddonPrice(false);
                            SetIsEdit(false);
                            SetIsSave(true);
                          }}
                        >
                          Update
                        </button>
                      ) : null}
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
