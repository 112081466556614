import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../utils/Api'
import DataContext from '../../utils/Context/DataContext'
import {
  AntDesign,
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from '@expo/vector-icons'
import ModalCreateSpecification from './ModalCreateSpecification'
import ModalCreateSpecificationItem from './ModalCreateSpecificationItem'
import ModalDelete from '../../components/ModalDelete/ModalDelete'
import toast from 'react-hot-toast'
import { Divider, makeStyles, Dialog } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end', // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxWidth: '100%',
      width: '100%',
      overflow: 'scroll',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    overflow: 'scroll',
  },
}))

function Accordian(props) {
  const [expand, setExpand] = useState(false)
  const [pad_value, SetPadValue] = useState(12)

  useEffect(() => {
    if (props.is_child) {
      SetPadValue((prevValue) => {
        prevValue = prevValue * props.tree_level
        return prevValue
      })
    }
  }, [])

  // if (props.explorer.hasOwnProperty('children')) {
  //   return (
  //     <div>
  //       <div
  //         style={props.is_child ? { paddingLeft: pad_value + 'px' } : null}
  //         className={
  //           props.is_child
  //             ? 'd-flex align-items-center p-2 pb-3 cursor'
  //             : props.explorer.id == props.active_data
  //             ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
  //             : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
  //         }
  //         onClick={() => {
  //           setExpand((prevState) => !prevState)
  //           console.log('props :', props)
  //           if (props.is_child) {
  //             props.onChildSelect(props.explorer)
  //           } else {
  //             props.onSelect(props.explorer)
  //           }
  //         }}
  //       >
  //         {props.is_child ? (
  //           <span
  //             className={
  //               props.explorer.id == props.child_active_data
  //                 ? 'text-dark mb-0 '
  //                 : 'text-gray mb-0'
  //             }
  //           >
  //             {capitalizeFirstLetter(props.explorer.name)}
  //           </span>
  //         ) : (
  //           capitalizeFirstLetter(props.explorer.name)
  //         )}

  //         <span className="ms-auto">
  //           {expand ? (
  //             <MaterialIcons
  //               name="keyboard-arrow-down"
  //               size={18}
  //               // color="#777"
  //               color="#0053ad"
  //             />
  //           ) : (
  //             <MaterialIcons
  //               name="keyboard-arrow-right"
  //               size={18}
  //               // color="#777"
  //               color="#777"
  //             />
  //           )}
  //         </span>
  //       </div>
  //       {expand
  //         ? props.explorer.children.map((child) => {
  //             return (
  //               <Accordian
  //                 explorer={child}
  //                 key={child.id}
  //                 active_data={props.active_data}
  //                 onSelect={props.onSelect}
  //                 onChildSelect={props.onChildSelect}
  //                 is_child={true}
  //                 tree_level={props.tree_level + 1}
  //                 sub_active_data={props.sub_active_data}
  //               />
  //             )
  //           })
  //         : null}
  //     </div>
  //   )
  // } else {
  //   return (
  //     <div
  //       style={props.is_child ? { paddingLeft: pad_value + 'px' } : null}
  //       className={
  //         props.is_child
  //           ? 'd-flex align-items-center p-2 pb-3 cursor'
  //           : props.explorer.id == props.active_data
  //           ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
  //           : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
  //       }
  //       onClick={() => {
  //         setExpand((prevState) => !prevState)
  //         console.log('props :', props)
  //         if (props.is_child) {
  //           props.onChildSelect(props.explorer)
  //         } else {
  //           props.onSelect(props.explorer)
  //         }
  //       }}
  //     >
  //       {props.is_child ? (
  //         <span
  //           className={
  //             props.explorer.id == props.child_active_data
  //               ? 'text-dark mb-0 '
  //               : 'text-gray mb-0'
  //           }
  //         >
  //           {capitalizeFirstLetter(props.explorer.name)}
  //         </span>
  //       ) : (
  //         capitalizeFirstLetter(props.explorer.name)
  //       )}
  //     </div>
  //   )
  // }

  function is_even(level) {
    if (level % 2 == 0) {
      console.log('even :', level)
      return true
    } else return false
  }

  return (
    <div>
      <div
        // style={props.is_child ? { paddingLeft: pad_value + 'px'  } : null}
        className={
          props.is_child
            ? 'd-flex align-items-center p-2 pb-3  cursor'
            : props.explorer.id == props.active_data
              ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
              : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
        }
        onClick={() => {
          setExpand((prevState) => !prevState)
          console.log('props :', props)
          if (props.is_child) {
            props.onChildSelect(props.explorer)
          } else {
            props.onSelect(props.explorer)
          }
        }}
      >
        {props.is_child ? (
          <span
            className={
              props.explorer.id == props.sub_active_data
                ? // ? is_even(props.tree_level)
                'text-dark mb-0 '
                : // : 'text-red mb-0'
                'text-gray mb-0'
            }
          >
            {capitalizeFirstLetter(props.explorer.name)}
          </span>
        ) : (
          capitalizeFirstLetter(props.explorer.name)
        )}

        <span className="ms-auto">
          {props.explorer.hasOwnProperty('children') ? (
            <MaterialIcons
              name={expand ? 'keyboard-arrow-down' : 'keyboard-arrow-right'}
              size={18}
              color={expand ? '#0053ad' : '#777'}
            />
          ) : null}
        </span>
      </div>
      {expand
        ? props.explorer.hasOwnProperty('children')
          ? props.explorer.children.map((child) => {
            return (
              <div className="ps-3">
                <Accordian
                  explorer={child}
                  key={child.id}
                  active_data={props.active_data}
                  onSelect={props.onSelect}
                  onChildSelect={props.onChildSelect}
                  is_child={true}
                  tree_level={props.tree_level + 1}
                  sub_active_data={props.sub_active_data}
                />
              </div>
            )
          })
          : null
        : null}
    </div>
  )
}

function capitalizeFirstLetter(string) {
  if (string == undefined) {
    return ''
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export default function Specifications(props: any) {
  const classes = useStyles()
  const [org_specification_data, SetOrgSpecificationData] = useState([])
  const [specification_data, SetSpecificationData] = useState([])
  const [id_value, SetIdValue] = useState(useParams())
  const [spec_type_options, SetSpecTypeOptions] = useState([])
  const [modal_specification, SetModalSpecification] = useState({
    is_open: false,
    data: {},
    type: 'create',
  })
  const context = useContext(DataContext)
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: 'single',
    data: {
      title: '',
      body: '',
    },
    have_option: false,
    options: {},
  })
  const [org_category_data, SetOrgCategoryData] = useState([])
  const [category_data, SetCategoryData] = useState([])
  const [active, SetActive] = useState({
    id: '',
    name: '',
  })
  const [child_active, SetChildActive] = useState({
    id: '',
    name: '',
  })
  const [sub_active, SetSubActive] = useState({
    id: '',
    name: '',
  })
  const [specify_active, SetSpecifyActive] = useState({
    open: false,
    value: '',
  })

  const [category_selected, SetCategorySelected] = useState(false)

  const [org_specify_web_active, SetOrgSpecifyWebActive] = useState({
    open: false,
    value: {},
  })
  const [specify_web_active, SetSpecifyWebActive] = useState({
    open: false,
    value: {},
  })
  const [cat_id, SetCatId] = useState('')

  const [cat_search_data, SetCatSearchData] = useState('')
  const [spec_search_data, SetSpecSearchData] = useState('')
  const [child_search_data, SetChildSearchData] = useState('')
  const [modal_specification_item, SetModalSpecificationItem] = useState({
    is_open: false,
    from: "",
    spec_value: {}
  })

  useEffect(() => {
    if (cat_search_data != '') {
      const filter_array = org_category_data.filter((ele) =>
        ele.name.toLowerCase().match(cat_search_data.toLowerCase()),
      )
      SetCategoryData(filter_array)
    } else {
      SetCategoryData(org_category_data)
    }
  }, [cat_search_data])

  useEffect(() => {
    if (spec_search_data != '') {
      const filter_array = org_specification_data.filter((ele) =>
        ele.name.toLowerCase().match(spec_search_data.toLowerCase()),
      )
      SetSpecificationData(filter_array)
    } else {
      SetSpecificationData(org_specification_data)
    }
  }, [spec_search_data])

  useEffect(() => {
    // if (child_search_data != '') {
    //   console.log('child_search_data :', child_search_data)
    // }
    if (child_search_data != '') {
      // SetSpecifyWebActive
      const filter_array = org_specify_web_active.value.children.filter((ele) =>
        ele.label.toLowerCase().match(child_search_data.toLowerCase()),
      )
      SetSpecifyWebActive((prevValue: any) => {
        prevValue.value = filter_array
        return { ...prevValue }
      })
    } else {
      SetSpecifyWebActive((prevValue: any) => {
        prevValue.value = org_specify_web_active.value
        return { ...prevValue }
      })
    }
  }, [child_search_data])

  useEffect(() => {
    console.log('Specifications On mount :', props)
    console.log('Specifications On mount context :', context)
    // get_specifications()
    get_category()
  }, [])

  async function get_specifications() {
    let pass_data = {
      get: {},
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let res_data = await api('/product/get_specifications', pass_data)
    console.log('/product/get_specifications response :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      SetOrgSpecificationData(res_data.response.data)
      SetSpecificationData(res_data.response.data)
    }
    if (res_data.response.hasOwnProperty('options')) {
      SetSpecTypeOptions(res_data.response.options.type)
    }
  }

  async function delete_specification(pass_value) {
    console.log('delete_specification pass_value: ', pass_value)

    let pass_data = {
      get: {},
      post: {
        specification: [pass_value.data.id],
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }

    console.log('delete_specification pass_data: ', pass_data)
    let res_data = await api('/product/delete_pre_specification', pass_data)
    console.log('/product/delete_pre_specification response :', res_data)
    if (res_data.status_code == 200) {
      toast.success('Specification Deleted')
      get_specifications()

      if (res_data.response.hasOwnProperty('data')) {
        SetOrgSpecificationData(res_data.response.data)

        SetSpecificationData(res_data.response.data)
      }
    } else {
      get_specifications()
    }
  }

  async function get_category() {
    let pass_data = {
      get: {},
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data = await api('/product/get_all_categories', pass_data)
    console.log('/product/get_all_categories :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      SetCategoryData(res_data.response.data)
      SetOrgCategoryData(res_data.response.data)
    }
  }

  function capitalizeFirstLetter(string) {
    if (string == undefined) {
      return ''
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }

  async function get_specifications_data(cat_id_value: any) {
    let pass_data = {
      get: {
        category_id: cat_id_value,
        specifications_only: 1,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let res_data = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications response :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      SetOrgSpecificationData(res_data.response.data)

      SetSpecificationData(res_data.response.data)
    }
    if (res_data.response.hasOwnProperty('options')) {
      SetSpecTypeOptions(res_data.response.options.type)
    }
  }

  async function get_specifications_childrens(get_data: any, for_value: any) {
    console.log("get_specifications_childrens get_data :", get_data)
    let pass_data = {
      get: {
        category_id: cat_id,
        specifications_only: 1,
      },
      post: {
        specification: [get_data],
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let res_data = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications response :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      // SetSpecificationData(res_data.response.data)
      if (for_value == 'web') {
        SetSpecifyWebActive({ open: true, value: res_data.response.data[0] })
        SetOrgSpecifyWebActive({ open: true, value: res_data.response.data[0] })
      } else {
        SetSpecifyActive({ open: true, value: res_data.response.data[0] })
      }
    }
    if (res_data.response.hasOwnProperty('options')) {
      SetSpecTypeOptions(res_data.response.options.type)
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className="py-3 row align-items-center head-bar">
        <div className="col-md-10 col-7">
          <h5 className="mb-0">Specifications</h5>
        </div>

        <div className="col-5 col-md-2 ps-0 text-end">
          <button
            className="btn btn-primary btn-sm btn-radius px-3"
            onClick={() => {
              console.log('SetModalSpecification new')
              SetModalSpecification((prevValue: any) => {
                prevValue.is_open = true
                prevValue.type = 'create'
                return { ...prevValue }
              })
            }}
          >
            Create New
          </button>
        </div>
      </div>

      <div className="row p-3 px-sm-0 px-md-3 pb-5">
        {/* Mobile view */}
        {category_selected == false ? (
          <div className="col-md-4  d-sm-block d-md-none ">
            <div className={active.id != '' ? 'card active_tab' : 'card'}>
              <div className="p-3 shadow-bottom">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0 pb-3">Category</h6>
                  {active.id != '' ? (
                    <div
                      className="end d-sm-block d-md-none cursor pb-3 ps-3"
                      onClick={() => {
                        SetCategorySelected(true)
                      }}
                    >
                      <Feather name="check-circle" size={14} color="#0053ad" />
                      <span className="mb-0 text-primary fw-bold ps-1">
                        Next
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="input-icons form-group p-2">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search here..."
                    value={cat_search_data}
                    onChange={(e) => {
                      SetCatSearchData(e.target.value)
                    }}
                  />
                  <div className="cursor px-2 border-left">
                    <Ionicons name="md-search" size={18} color="#0053ad" />
                  </div>
                </div>
              </div>
              <div className="overflowY p-2">
                {/* {category_data.map((element: any, index) => (
                  <div
                    key={index}
                    className={element.id == active.id ? 'active_sub' : ''}
                  >
                    <div
                      className={
                        element.id == active.id
                          ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                          : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                      }
                      onClick={() => {
                        SetActive(element)
                      }}
                    >
                      {capitalizeFirstLetter(element.name)}

                      <span className="ms-auto">
                        {element.hasOwnProperty('children') ? (
                          element.id == active.id ? (
                            <MaterialIcons
                              name="keyboard-arrow-down"
                              size={18}
                              // color="#777"
                              color="#0053ad"
                            />
                          ) : (
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={18}
                              // color="#777"
                              color="#777"
                            />
                          )
                        ) : null}
                      </span>
                    </div>

                    {element.id == active.id
                      ? element.hasOwnProperty('children')
                        ? element.children.map((c_ele, c_index) => (
                            <div key={c_index} className="px-2">
                              <div
                                className="d-flex align-items-center p-2 pb-3 cursor"
                                onClick={() => {
                                  SetChildActive(c_ele)
                                  console.log('Child active:', c_ele.id)
                                }}
                              >
                                <span
                                  className={
                                    c_ele.id == child_active.id
                                      ? 'text-dark mb-0 '
                                      : 'text-gray mb-0'
                                  }
                                >
                                  {capitalizeFirstLetter(c_ele.name)}
                                </span>
                                <span className="ms-auto">
                                  {c_ele.hasOwnProperty('children') ? (
                                    c_ele.id == child_active.id ? (
                                      <MaterialIcons
                                        name="keyboard-arrow-down"
                                        size={18}
                                        color="#000"
                                      />
                                    ) : (
                                      <MaterialIcons
                                        name="keyboard-arrow-right"
                                        size={18}
                                        color="#777"
                                      />
                                    )
                                  ) : null}
                                </span>
                              </div>

                              {c_ele.id == child_active.id
                                ? c_ele.hasOwnProperty('children')
                                  ? c_ele.children.map((sub_ele, c_index) => (
                                      <div key={c_index} className="px-2">
                                        <div
                                          className="d-flex align-items-center p-2 pb-3 cursor"
                                          onClick={() => {
                                            SetSubActive(sub_ele)
                                            console.log(
                                              'Child sub active:',
                                              sub_ele.id,
                                            )
                                          }}
                                        >
                                          <span
                                            className={
                                              sub_ele.id == sub_active.id
                                                ? 'text-dark mb-0'
                                                : 'text-gray mb-0'
                                            }
                                          >
                                            {capitalizeFirstLetter(
                                              sub_ele.name,
                                            )}
                                          </span>
                                          <span className="ms-auto">
                                            {sub_ele.hasOwnProperty(
                                              'children',
                                            ) ? (
                                              sub_ele.id == sub_active.id ? (
                                                <MaterialIcons
                                                  name="keyboard-arrow-down"
                                                  size={18}
                                                  color="#000"
                                                />
                                              ) : (
                                                <MaterialIcons
                                                  name="keyboard-arrow-right"
                                                  size={18}
                                                  color="#777"
                                                />
                                              )
                                            ) : null}
                                          </span>
                                        </div>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          ))
                        : null
                      : null}
                  </div>
                ))} */}

                {category_data.map((c_ele: any, c_index: any) => (
                  <div
                    key={c_index}
                    className={c_ele.id == active ? 'active_sub' : ''}
                  >
                    <Accordian
                      key={c_index}
                      explorer={c_ele}
                      active_data={active}
                      sub_active_data={sub_active}
                      is_child={false}
                      tree_level={1}
                      onSelect={(data) => {
                        console.log('on select data :', data)
                        SetActive(data.id)
                        get_specifications_data(data.id)
                        SetCatId(data.id)
                      }}
                      onChildSelect={(data) => {
                        console.log('on Child select data :', data)
                        SetSubActive(data.id)
                        get_specifications_data(data.id)
                        SetCatId(data.id)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-4 d-sm-block d-md-none">
            <div className="card">
              <div className="p-3 shadow-bottom">
                <div className="d-flex align-items-center">
                  <div
                    className="ps-1 pe-2 pb-3 cursor"
                    onClick={() => {
                      SetCategorySelected(false)
                    }}
                  >
                    <Octicons name="arrow-left" size={18} color="black" />
                  </div>
                  <h6 className="mb-3">Specifications</h6>
                </div>

                <div className="input-icons form-group p-2">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search here..."
                    value={spec_search_data}
                    onChange={(e) => {
                      SetSpecSearchData(e.target.value)
                    }}
                  />
                  <div className="cursor px-2 border-left">
                    <Ionicons name="md-search" size={18} color="#0053ad" />
                  </div>
                </div>

                <div className="pt-3">
                  <small className="fw-500">Selected Category</small>
                  <p className="pt-1 mb-0 text-black v-small">{active.name}</p>
                </div>
              </div>
              <div className="overflowY  p-2">
                {/* {specification_data.map((element, index) => (
                  <div className=" " key={index}>
                    <div
                      className={
                        element.id == specify_active.id
                          ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                          : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                      }
                      onClick={() => {
                        SetSpecifyActive({ open: true, value: element })
                      }}
                    >
                      {capitalizeFirstLetter(element.name)}
                      <span className="ms-auto">
                        {element.hasOwnProperty('children') ? (
                          element.id == specify_active.id ? (
                            <MaterialIcons
                              name="keyboard-arrow-down"
                              size={18}
                              color="#0053ad"
                            />
                          ) : (
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={18}
                              color="#777"
                            />
                          )
                        ) : null}
                      </span>
                    </div>
                  </div>
                ))} */}

                {specification_data.length > 0 ? (
                  specification_data.map((element, index) => (
                    <div className=" " key={index}>
                      <div
                        className={
                          element.id == specify_web_active.value.id
                            ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                            : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                        }
                        onClick={() => {
                          // SetSpecifyWebActive({ open: true, value: element })
                          console.log('SetSpecifyWebActive', element)

                          if (
                            element.type_id == '2' ||
                            element.type_id == '3'
                          ) {
                            let pass_data = {
                              source_type: element.source_type,
                              data_type: element.data_type,
                            }
                            if (element.source_type == 'specification') {
                              pass_data.id = element.id
                            } else {
                              pass_data.slug = element.slug
                            }
                            get_specifications_childrens(pass_data, 'mobile')
                          }
                        }}
                      >
                        {capitalizeFirstLetter(element.name)}
                        <span className="ms-auto">
                          {element.type_id == '2' || element.type_id == '3' ? (
                            <MaterialIcons
                              name="arrow-forward-ios"
                              size={11}
                              color={
                                element.id == specify_web_active.value.id
                                  ? '#000'
                                  : '#777'
                              }
                            />
                          ) : null}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="center h-170 w-100">
                    <div>
                      <img
                        src={require('../../assets/img/svg/no_data_2.svg')}
                        className="product-img"
                      />
                      <p className="mt-3 fw-bold text-center">
                        No Specifications
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Desktop view */}
        {/* Category */}
        <div className="col-md-4 d-sm-none d-md-block ">
          <div className={active != '' ? 'card active_tab' : 'card'}>
            <div className="p-3 shadow-bottom">
              <div className="">
                <h6 className="mb-0 pb-3">Category</h6>
              </div>
              <div className="input-icons form-group p-2">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  value={cat_search_data}
                  onChange={(e) => {
                    SetCatSearchData(e.target.value)
                  }}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={18} color="#0053ad" />
                </div>
              </div>
            </div>
            <div className="overflowY p-2">
              {/* {category_data.map((element, index) => (
                <div
                  key={index}
                  className={element.id == active ? 'active_sub' : ''}
                >
                  <div
                    className={
                      element.id == active
                        ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                        : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                    }
                    onClick={() => {
                      SetActive((prevValue: any) => {
                        if (prevValue == element.id) {
                          prevValue = ''
                        } else {
                          prevValue = element.id
                        }
                        return prevValue
                      })
                    }}
                  >
                    {capitalizeFirstLetter(element.name)}

                    <span className="ms-auto">
                      {element.hasOwnProperty('children') ? (
                        element.id == active ? (
                          <MaterialIcons
                            name="keyboard-arrow-down"
                            size={18}
                            // color="#777"
                            color="#0053ad"
                          />
                        ) : (
                          <MaterialIcons
                            name="keyboard-arrow-right"
                            size={18}
                            // color="#777"
                            color="#777"
                          />
                        )
                      ) : null}
                    </span>
                  </div>

                  {element.id == active
                    ? element.hasOwnProperty('children')
                      ? element.children.map((c_ele, c_index) => (
                          <div key={c_index} className="px-2">
                            <div
                              className="d-flex align-items-center p-2 pb-3 cursor"
                              onClick={() => {
                                SetChildActive(c_ele.id)
                                console.log('Child active:', c_ele.id)
                              }}
                            >
                              <span
                                className={
                                  c_ele.id == child_active
                                    ? 'text-dark mb-0 '
                                    : 'text-gray mb-0'
                                }
                              >
                                {capitalizeFirstLetter(c_ele.name)}
                              </span>
                              <span className="ms-auto">
                                {c_ele.hasOwnProperty('children') ? (
                                  c_ele.id == child_active ? (
                                    <MaterialIcons
                                      name="keyboard-arrow-down"
                                      size={18}
                                      color="#000"
                                    />
                                  ) : (
                                    <MaterialIcons
                                      name="keyboard-arrow-right"
                                      size={18}
                                      color="#777"
                                    />
                                  )
                                ) : null}
                              </span>
                            </div>

                            {c_ele.id == child_active
                              ? c_ele.hasOwnProperty('children')
                                ? c_ele.children.map((sub_ele, c_index) => (
                                    <div key={c_index} className="px-2">
                                      <div
                                        className="d-flex align-items-center p-2 pb-3 cursor"
                                        onClick={() => {
                                          SetSubActive(sub_ele.id)
                                          console.log(
                                            'Child sub active:',
                                            sub_ele.id,
                                          )
                                        }}
                                      >
                                        <span
                                          className={
                                            sub_ele.id == sub_active
                                              ? 'text-dark mb-0'
                                              : 'text-gray mb-0'
                                          }
                                        >
                                          {capitalizeFirstLetter(sub_ele.name)}
                                        </span>
                                        <span className="ms-auto">
                                          {sub_ele.hasOwnProperty(
                                            'children',
                                          ) ? (
                                            sub_ele.id == sub_active ? (
                                              <MaterialIcons
                                                name="keyboard-arrow-down"
                                                size={18}
                                                color="#000"
                                              />
                                            ) : (
                                              <MaterialIcons
                                                name="keyboard-arrow-right"
                                                size={18}
                                                color="#777"
                                              />
                                            )
                                          ) : null}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                : null
                              : null}
                          </div>
                        ))
                      : null
                    : null}
                </div>
              ))} */}

              {category_data.map((c_ele: any, c_index: any) => (
                <div
                  key={c_index}
                  className={c_ele.id == active ? 'active_sub' : ''}
                >
                  <Accordian
                    key={c_index}
                    explorer={c_ele}
                    active_data={active}
                    sub_active_data={sub_active}
                    is_child={false}
                    tree_level={1}
                    onSelect={(data) => {
                      console.log('on select data :', data)
                      SetActive(data.id)
                      get_specifications_data(data.id)
                      SetCatId(data.id)
                    }}
                    onChildSelect={(data) => {
                      console.log('on Child select data :', data)
                      SetSubActive(data.id)
                      get_specifications_data(data.id)
                      SetCatId(data.id)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Specifications */}
        <div className="col-md-4 d-sm-none d-md-block">
          <div className="card">
            {/* header */}
            <div className="p-3 shadow-bottom">
              <h6 className="mb-3">Specifications</h6>
              {/* search */}
              <div className="input-icons form-group p-2">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  value={spec_search_data}
                  onChange={(e) => {
                    SetSpecSearchData(e.target.value)
                  }}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={18} color="#0053ad" />
                </div>
              </div>
            </div>
            <div className="overflowY  p-2">
              {specification_data.length > 0 ? (
                <div className="">
                  <p className="fs-7 text-dark">Recommended</p>
                  {specification_data.some(
                    (ss_ele) => ss_ele.recommended == true,
                  ) ? (
                    specification_data?.map((element, index) =>
                      element.recommended ? (
                        <div className=" " key={index}>
                          <div
                            className={
                              element.id == specify_web_active.value.id
                                ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                                : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                            }
                            onClick={() => {
                              // SetSpecifyWebActive({ open: true, value: element })
                              console.log('SetSpecifyWebActive', element)

                              if (
                                element.type_id == '2' ||
                                element.type_id == '3'
                              ) {
                                let pass_data = {
                                  source_type: element.source_type,
                                  data_type: element.data_type,
                                }
                                if (element.source_type == 'specification') {
                                  pass_data.id = element.id
                                } else {
                                  pass_data.slug = element.slug
                                }
                                get_specifications_childrens(pass_data, 'web')
                              }
                            }}
                          >
                            {capitalizeFirstLetter(element.name)}
                            <span className="ms-auto">
                              {element.type_id == '2' ||
                                element.type_id == '3' ? (
                                <MaterialIcons
                                  name="arrow-forward-ios"
                                  size={11}
                                  color={
                                    element.id == specify_web_active.value.id
                                      ? '#0053ad'
                                      : '#777'
                                  }
                                />
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ) : null,
                    )
                  ) : (
                    <div className="center p-2">
                      <hr className="w-30px" />
                    </div>
                  )}
                  <p className="fs-7 text-dark">Required</p>
                  {specification_data.some(
                    (ss_ele) => ss_ele.required == true,
                  ) ? (
                    specification_data?.map((element, index) =>
                      element.required ? (
                        <div className=" " key={index}>
                          <div
                            className={
                              element.id == specify_web_active.value.id
                                ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                                : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                            }
                            onClick={() => {
                              // SetSpecifyWebActive({ open: true, value: element })
                              console.log('SetSpecifyWebActive', element)

                              if (
                                element.type_id == '2' ||
                                element.type_id == '3'
                              ) {
                                let pass_data = {
                                  source_type: element.source_type,
                                  data_type: element.data_type,
                                }
                                if (element.source_type == 'specification') {
                                  pass_data.id = element.id
                                } else {
                                  pass_data.slug = element.slug
                                }
                                get_specifications_childrens(pass_data, 'web')
                              }
                            }}
                          >
                            {capitalizeFirstLetter(element.name)}
                            <span className="ms-auto">
                              {element.type_id == '2' ||
                                element.type_id == '3' ? (
                                <MaterialIcons
                                  name="arrow-forward-ios"
                                  size={11}
                                  color={
                                    element.id == specify_web_active.value.id
                                      ? '#0053ad'
                                      : '#777'
                                  }
                                />
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ) : null,
                    )
                  ) : (
                    <div className="center p-2">
                      <hr className="w-30px" />
                    </div>
                  )}
                  <p className="fs-7 text-dark">Others</p>
                  {specification_data.some(
                    (ss_ele) => !ss_ele.required && !ss_ele.recommended,
                  ) ? (
                    specification_data?.map((element, index) =>
                      !element.required && !element.recommended ? (
                        <div className=" " key={index}>
                          <div
                            className={
                              element.id == specify_web_active.value.id
                                ? 'd-flex align-items-center cursor px-2 py-3 active_spc_cat'
                                : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                            }
                            onClick={() => {
                              // SetSpecifyWebActive({ open: true, value: element })
                              console.log('SetSpecifyWebActive', element)

                              if (
                                element.type_id == '2' ||
                                element.type_id == '3'
                              ) {
                                let pass_data = {
                                  source_type: element.source_type,
                                  data_type: element.data_type,
                                }
                                if (element.source_type == 'specification') {
                                  pass_data.id = element.id
                                } else {
                                  pass_data.slug = element.slug
                                }
                                get_specifications_childrens(pass_data, 'web')
                              } else {
                                console.log('SetSpecifyWebActive false', element)
                                SetSpecifyWebActive((prevalue: any) => {
                                  prevalue.value = {};
                                  return { ...prevalue }
                                })
                              }
                            }}
                          >
                            {capitalizeFirstLetter(element.name)}
                            <span className="ms-auto">
                              {element.type_id == '2' ||
                                element.type_id == '3' ? (
                                <MaterialIcons
                                  name="arrow-forward-ios"
                                  size={11}
                                  color={
                                    element.id == specify_web_active.value.id
                                      ? '#0053ad'
                                      : '#777'
                                  }
                                />
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ) : null,
                    )
                  ) : (
                    <div className="center p-2">
                      <hr className="w-30px" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="center h-170 w-100">
                  <div>
                    <img
                      src={require('../../assets/img/svg/no_data_2.svg')}
                      className="product-img"
                    />
                    <p className="mt-3 fw-bold text-center">
                      No Specifications
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {specify_web_active.open == true &&
          specify_web_active.value.hasOwnProperty('children') && specify_web_active.value.children.length > 0 ? (
          <div className="col-md-4 d-sm-none d-md-block">
            <div className="card">
              <div className="p-3 shadow-bottom d-flex align-items-center">
                <h6 className="mb-0">Specifications Item</h6>
                <button className="btn btn-text text-primary btn-sm d-flex align-items-center ms-auto"
                  onClick={() => {
                    console.log("")
                    SetModalSpecificationItem((prevalue: any) => {
                      prevalue.is_open = true;
                      prevalue.from = "web";
                      prevalue.value = specify_web_active
                      return { ...prevalue }
                    })
                  }}>
                  <Ionicons name="add" size={16} color="#0053ad" />
                  <p className="ps-1 text-primary mb-0 fw-bold">Add</p>
                </button>
                {/* <div className="input-icons form-group p-2">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search here..."
                    value={child_search_data}
                    onChange={(e) => {
                      SetChildSearchData(e.target.value)
                    }}
                  />
                  <div className="cursor px-2 border-left">
                    <Ionicons name="md-search" size={18} color="#0053ad" />
                  </div>
                </div> */}
              </div>
              <div className="overflowY  p-2">
                {specify_web_active.value.children.length > 0 ? (
                  <div className="">
                    {specify_web_active.value.children.map((element, index) => (
                      <div className=" " key={index}>
                        <div
                          className={
                            element.id == specify_web_active.id
                              ? 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                              : 'd-flex align-items-center cursor px-2 py-3 cat_spc_hover'
                          }
                        >
                          {capitalizeFirstLetter(element.label)}
                          <span className="ms-auto">
                            {element.hasOwnProperty('children') ? (
                              <MaterialIcons
                                name="arrow-forward-ios"
                                size={11}
                                color={
                                  element.id == specify_web_active.id
                                    ? '#0053ad'
                                    : '#777'
                                }
                              />
                            ) : null}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="center h-170 w-100">
                    <div>
                      <img
                        src={require('../../assets/img/svg/no_data_2.svg')}
                        className="product-img"
                      />
                      <p className="mt-3 fw-bold text-center">No Children</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Dialog
        open={specify_active.open}
        onEntered={console.log(
          'specify_active open: ',
          specify_active.value.children,
        )}
        classes={{ container: classes.root, paper: classes.paper }}
        onClose={(data) => {
          SetSpecifyActive((prevValue: any) => {
            prevValue.open = false
            return { ...prevValue }
          })
        }}
      >
        <div className="">
          <div className=" bg-fff ">
            <div className="sticky_drawer bg-fff shadow-bottom">
              <div className="d-flex align-items-center">
                <h6 className="text-dark mb-0 p-3">Specifications Item</h6>

                <div
                  className="ms-auto p-3 cursor"
                // onClick={() => {
                //   SetSpecifyActive((prevValue: any) => {
                //     prevValue.open = false
                //     return { ...prevValue }
                //   })
                // }}
                >
                  {/* <Ionicons name="close" size={18} color="#444" /> */}
                  <button className="btn btn-text text-primary btn-sm d-flex align-items-center"
                    onClick={() => {
                      SetSpecifyActive((prevalue: any) => {
                        prevalue.open = false;
                        return { ...prevalue }
                      })
                      SetModalSpecificationItem((prevalue: any) => {
                        prevalue.is_open = true;
                        prevalue.from = "mobile";
                        prevalue.value = specify_active
                        return { ...prevalue }
                      })
                    }}>
                    <Ionicons name="add" size={14} color="#0053ad" />
                    <p className="ps-1 text-primary mb-0">Add</p>
                  </button>
                </div>
              </div>
            </div>

            <div className="p-3">
              <div className="mb-5 px-2">
                {specify_active.value.children?.map((c_ele, c_index) => (
                  <div key={c_index}>
                    <p>{c_ele.label}</p>
                  </div>
                ))}
              </div>
              <div className="mt-3 bottom-fixed bg-fff center left-none w-100 h-auto">
                <button className="btn btn-primary btn-sm w-100 py-2" onClick={() => {
                  SetSpecifyActive((prevValue: any) => {
                    prevValue.open = false
                    return { ...prevValue }
                  })
                }}>Done</button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <ModalCreateSpecification
        open={modal_specification.is_open}
        type={modal_specification.type}
        data={modal_specification.data}
        all_data={specification_data}
        spec_type_options={[
          { value: '1', label: 'Text Field' },
          { value: '2', label: 'Select' },
          { value: '3', label: 'Multi Select' },
          { value: '4', label: 'Textarea' },
        ]}
        category_id={''}
        add_type="single"
        from="specification"
        close={(data: any) => {
          console.log('ModalCreateSpecification on close :', data)
          SetModalSpecification((prevValue: any) => {
            prevValue.is_open = false
            return { ...prevValue }
          })
          if (data.action == 'save') {
            // get_specifications()
          }
        }}
      />
      <ModalCreateSpecificationItem
        open={modal_specification_item.is_open}
        from={modal_specification_item.from}
        all_data={modal_specification_item.value}
        close={(data: any) => {
          console.log('ModalCreateSpecification on close :', data)
          SetModalSpecificationItem((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.from = "";
            prevValue.value = {};
            return { ...prevValue }
          })
          if (data.action == 'save') {
            // get_specifications()

            if (data.from == "web") {
              if (
                data.all_data.value.type_id == '2' ||
                data.all_data.value.type_id == '3'
              ) {
                let pass_data = {
                  source_type: data.all_data.value.source_type,
                  data_type: data.all_data.value.data_type,
                }
                if (data.all_data.value.source_type == 'specification') {
                  pass_data.id = data.all_data.value.id
                } else {
                  pass_data.slug = data.all_data.value.slug
                }
                get_specifications_childrens(pass_data, 'web')
              }
            }

            if (data.from == "mobile") {
              if (
                data.all_data.value.type_id == '2' ||
                data.all_data.value.type_id == '3'
              ) {
                let pass_data = {
                  source_type: data.all_data.value.source_type,
                  data_type: data.all_data.value.data_type,
                }
                if (data.all_data.value.source_type == 'specification') {
                  pass_data.id = data.all_data.value.id
                } else {
                  pass_data.slug = data.all_data.value.slug
                }
                get_specifications_childrens(pass_data, 'mobile')
              }
            }
          }
        }}
      />

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data) => {
          console.log('on close delete modal data:', data)
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false
            return { ...prevValue }
          })

          if (data.action == 'save') {
            delete_specification(data)
          }
        }}
      />
    </div>
  )
}
