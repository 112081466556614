import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'

let myModal = {}

export default function ModalDeleteConfirm(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [options_data, SetOptionsData] = useState({})

  useEffect(() => {
    if (props.open) {
      console.log('ModalDeleteConfirm On Open :', props)
      myModal = new bootstrap.Modal(document.getElementById('DeleteConfirmModal'), {})
      myModal.show()
      if (props.have_option) {
        SetOptionsData(props.options)
      }
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalDeleteConfirm On mount :', props)
    let myModal1 = document.getElementById('DeleteConfirmModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      props.close(close_data)
    })
  }, [])

  return (
    <div
      className="modal p-2"
      id="DeleteConfirmModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content radius m-2">
          {/* header */}
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {props.data.title}
            </h6>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button> */}
          </div>
          {/* body */}
          <div className="modal-body">
            {/* <h6 className="mb-3  modal-title" id="ModalLabel">
              {props.data.title}
            </h6> */}

            <p className='text-dark fs-7'>{props.data.body}</p>
          </div>
          {/* footer */}
          <div className="modal-footer">
            <div className="d-flex align-items-center w-100">
              <button
                type="button"
                className="btn btn-secondary-outline btn-sm btn-radius px-3" 
                onClick={() => {
                  let item = close_data
                  item.action = 'close'
                  SetCloseData(item)
                  myModal.hide()
                }}
              >
                Cancel
              </button>
              <div className="ms-auto">
                <button
                  type="button"
                  className="btn btn-primary btn-radius px-3"
                  onClick={() => {
                    // onSubmit();
                    let item = close_data
                    item.action = 'save'
                    item.pass_value = props.data.pass_value
                    item.for = props.data.for
                    console.log('Delete send item on hide:', item)
                    SetCloseData(item)
                    myModal.hide()
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
