import React, { useState, useEffect, useContext, useRef } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome5,
} from '@expo/vector-icons'

import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

let myModal = {}
const animatedComponents = makeAnimated()

export default function UpdateGroupDetailModal(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [save_data, SetSaveData] = useState({
    name:"",
    description:"",
  })
  

  useEffect(() => {
    if (props.open) {
      console.log('UpdateGroupDetailModal On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('ModalGroupDetailUpdate'),
        {},
      )
      // SetSaveData(props.data)
      process_data()
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('UpdateGroupDetailModal On mount :', props)
    let myModal1 = document.getElementById('ModalGroupDetailUpdate')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData({})
      props.close(close_data)
    })
  }, [])

  function process_data(){
    let all_data = props.data;

    if(all_data.hasOwnProperty("update_request_basic")){
      if(Object.keys(all_data.update_request_basic).length > 0){
        SetSaveData((prevValue: any)=>{
          prevValue.name = all_data.update_request_basic.name
          prevValue.description = all_data.update_request_basic.description
          return { ...prevValue }
        })
      }else{
        SetSaveData((prevValue: any)=>{
          prevValue.name = all_data.name
          prevValue.description = all_data.description
          return { ...prevValue }
        })
      }
    }else{
      SetSaveData((prevValue: any)=>{
        prevValue.name = all_data.name
        prevValue.description = all_data.description
        return { ...prevValue }
      })
    }
  }


  async function update_details() {
    let pass_data = {
      get: {},
      post: {
        product_id: id_value.product_group_id,
        product_data: {
          name: save_data.name,
          description: save_data.description,
        },
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('/product/update_product_group_details pass_data :', pass_data)
    let response = await api('/product/update_product_group_details', pass_data)
    console.log('/product/update_product_group_details response :', response)
    if (response.status_code == 200) {
      if (response.response.status) {
        let item = close_data
        item.action = 'save'
        SetCloseData(item)
        myModal.hide()
      } else {
        toast.error(response.response.message)
      }
    }
  }

  return (
    <div
      className="modal"
      id="ModalGroupDetailUpdate"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Update Group Details
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-2 border-top">
            <div className="row">
              <div className="col-md-6 p-3">
                <label className='form-label'>Group Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={save_data.name}
                  onChange={(e) => {
                    console.log('Group Name on change :', e.target.value)
                    SetSaveData((prevValue: any) => {
                      prevValue.name = e.target.value
                      return { ...prevValue }
                    })
                  }}
                />
              </div>
              <div className="col-md-6 p-3">
                <label className='form-label'>Description</label>
                <textarea
                  className="form-control"
                  value={save_data.description}
                  onChange={(e) => {
                    console.log('Group Description on change :', e.target.value)
                    SetSaveData((prevValue: any) => {
                      prevValue.description = e.target.value
                      return { ...prevValue }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline  btn-radius px-3"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary btn-radius px-3"
                onClick={() => {
                  console.log('update')
                  update_details()
                  //   let item = close_data
                  //   item.action = 'save'
                  //   SetCloseData(item)
                  //   myModal.hide()
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
