import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../utils/Api'
import DataContext from '../../utils/Context/DataContext'
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'
import ModalCreateAddonItem from './ModalCreateAddonItem'
import ModalDelete from '../../components/ModalDelete/ModalDelete'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'

export default function AddonItems(props: any) {
  const [addon_item_data, SetAddonItemData] = useState([])
  const [id_value, SetIdValue] = useState(useParams())
  const [modal_addon_item, SetModalAddonItem] = useState({
    is_open: false,
    data: {},
    type: 'create',
  })
  const context = useContext(DataContext)
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: 'single',
    data: {
      title: '',
      body: '',
    },
    have_option: false,
    options: {},
  })

  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: 1,
    next_page: false,
    count: { label: 'All', value: 'all' },
    count_option: [
      { label: 'All', value: 'all' },
      { label: '10', value: '10' },
      { label: '20', value: '20' },
      { label: '30', value: '30' },
    ],
    sort: { label: 'asc', value: 'asc' },
    sort_option: [
      { label: 'asc', value: 'asc' },
      { label: 'desc', value: 'desc' },
    ],
    search: '',
    // spec: [],
    // mrp: [],
    // addon_groups: [],
    // category_id: '',
  })

  useEffect(() => {
    console.log('AddonItems On mount :', props)
    console.log('AddonItems On mount context :', context)
    // get_addon_items()
  }, [])

  useEffect(() => {
    if (filter_data.search == '') {
      get_addon_items(filter_data, false)
    } else {
      const timeOutId = setTimeout(
        () => triggerItemChange(filter_data.search),
        500,
      )
      return () => clearTimeout(timeOutId)
    }
  }, [filter_data.search])

  function triggerItemChange(value) {
    SetFilterData((prevValue: any) => {
      // prevValue.page_no = prevValue.page_no + 1
      prevValue.page_no = 1
      get_addon_items(prevValue, false)
      return { ...prevValue }
    })
  }

  // async function get_addon_items() {
  //   let pass_data = {
  //     get: {},
  //   }

  //  // if (context.app_data.app_from == 'seller') {
    // if (id_value.hasOwnProperty("brand_id")) {
  //     pass_data.get.brand = id_value.brand_id
  //   } else {
  //     pass_data.get.m_id = id_value.m_id
  //   }
  //   console.log('pass_data :', pass_data)
  //   let res_data = await api('/product/get_all_addon_items', pass_data)
  //   console.log('/product/get_all_addon_items response :', res_data)
  //   if (res_data.response.hasOwnProperty('data')) {
  //     SetAddonItemData(res_data.response.data)
  //   }
  // }

  async function get_addon_items(filter_value, fetch_value) {
    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        // spec: filter_value.spec,
        // mrp: filter_value.mrp,
        // addon_groups: filter_value.addon_groups,
        // category_id: filter_value.category_id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let res_data = await api('/product/get_all_addon_items', pass_data)
    console.log('/product/get_all_addon_items response :', res_data)
    if (res_data.response.hasOwnProperty('data')) {
      let addon_item_value = res_data.response.data
      addon_item_value.map((ele: any) => {
        ele.checked = false
      })
      if (fetch_value) {
        SetAddonItemData((prevValue: any) => {
          addon_item_value.map((ele: any) => {
            prevValue.push(ele)
          })
          SetFilterData((prevValue1: any) => {
            if (prevValue.every((v_ele) => v_ele.checked == true)) {
              prevValue1.is_all_checked = true
            } else {
              prevValue1.is_all_checked = false
            }
            return { ...prevValue1 }
          })
          return [...prevValue]
        })
      } else {
        SetFilterData((prevValue: any) => {
          if (addon_item_value.every((v_ele) => v_ele.checked == true)) {
            prevValue.is_all_checked = true
          } else {
            prevValue.is_all_checked = false
          }
          return { ...prevValue }
        })
        SetAddonItemData(addon_item_value)
      }
    }
    // if (res_data.response.hasOwnProperty('data')) {
    //   SetAddonItemData(res_data.response.data)
    // }
    if (res_data.response.hasOwnProperty('next_page')) {
      SetFilterData((prevValue: any) => {
        prevValue.next_page = res_data.response.next_page
        return { ...prevValue }
      })
    }
  }

  // async function delete_image(pass_value, product_data_value, delete_type) {
  async function delete_addon_item(
    pass_value,
    product_data_value,
    delete_type,
  ) {
    console.log('delete_addon_item pass_value: ', pass_value)

    let pass_data = {
      get: {},
      post: {
        addons: [],
      },
    }

    if (delete_type == 'single') {
      pass_data.post.addons.push(pass_value.data.id)
    } else {
      let push_value = []
      console.log('addon_item_data :', product_data_value)
      product_data_value.map((ele: any) => {
        if (ele.checked) {
          push_value.push(ele.id)
        }
      })
      pass_data.post.addons = push_value
    }

    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }

    console.log('delete_addon_item pass_data: ', pass_data)
    let res_data = await api('/product/delete_addon_item', pass_data)
    console.log('/product/delete_addon_item response :', res_data)
    if (res_data.status_code == 200) {
      toast.success('Addon Item Deleted')
      get_addon_items(filter_data, false)
      SetFilterData((prevValue1: any) => {
        prevValue1.is_checked = false
        prevValue1.is_all_checked = false
        return { ...prevValue1 }
      })
      if (res_data.response.hasOwnProperty('data')) {
        SetAddonItemData(res_data.response.data)
      }
    } else {
      get_addon_items(filter_data, false)
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar">
        <div className="col-md-10 col-7 sm-mb-2">
          <h5 className="  mb-0">Addon Items</h5>
        </div>

        <div className="col-5 col-md-2 ps-0 text-end sm-mb-2">
          <button
            className="btn btn-primary btn-radius px-3"
            onClick={() => {
              console.log('Brand new')
              SetModalAddonItem((prevValue: any) => {
                prevValue.is_open = true
                prevValue.type = 'create'
                return { ...prevValue }
              })
            }}
          >
            Create New
          </button>
        </div>
      </div>
      {/* <div className="border-top">
        <div className=" row align-items-center head-bar">
          <div className="col-md-6 col-9 d-flex">
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={18} color="#ccc" />
              </div>
            </div>
          </div>

          <div className="text-center col-md-1 col-3">
            <select className="form-select px-2" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div> */}

      <div className="border-top fixed-sub-header">
        <div className=" row align-items-center head-bar">
          <div className="col-md-5 col-10 d-flex">
            {/* search */}
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
                onChange={(e) => {
                  // SetSearchData((prevValue: any) => {
                  //   prevValue = e.target.value
                  //   return prevValue
                  // })
                  SetFilterData((prevValue1: any) => {
                    prevValue1.search = e.target.value
                    return { ...prevValue1 }
                  })
                }}
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={16} color="#ccc" />
              </div>
            </div>
          </div>
          {/* count */}
          {/* <div className="text-center col-md-1 col-2 ps-0">
            <select
              className="form-select"
              value={filter_data.count}
              onChange={() => { }}
            >
              {filter_data.count_option.map((ele: any, index: any) => (
                <option value={ele.value} key={index}>
                  {ele.label}
                </option>
              ))}
            </select>
          </div> */}
          <div className="col-12 col-md-6 end sm-mt-3">
            {!filter_data.is_checked ? (
              <div className="">
                {/* Select All */}
                <button
                  className="btn btn-sm pe-2"
                  onClick={() => {
                    SetAddonItemData((prevValue: any) => {
                      prevValue.map((ele) => {
                        ele.checked = true
                      })
                      SetFilterData((prevValue1: any) => {
                        prevValue1.is_checked = true
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          prevValue1.is_all_checked = true
                        } else {
                          prevValue1.is_all_checked = false
                        }
                        return { ...prevValue1 }
                      })
                      return [...prevValue]
                    })
                  }}
                >
                  <MaterialCommunityIcons
                    name="checkbox-marked-circle-outline"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Select All</span>
                </button>
              </div>
            ) : (
              <div>
                {/* Select All */}
                {!filter_data.is_all_checked ? (
                  <button
                    className="btn btn-sm pe-2 border-right"
                    onClick={() => {
                      SetAddonItemData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = true
                        })
                        SetFilterData((prevValue1: any) => {
                          prevValue1.is_checked = true
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            prevValue1.is_all_checked = true
                          } else {
                            prevValue1.is_all_checked = false
                          }
                          return { ...prevValue1 }
                        })
                        return [...prevValue]
                      })
                    }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-marked-circle-outline"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Select All</span>
                  </button>
                ) : null}
                {/* Un Select All */}
                <button
                  className="btn btn-sm px-2 border-right"
                  onClick={() => {
                    SetAddonItemData((prevValue: any) => {
                      prevValue.map((ele) => {
                        ele.checked = false
                      })
                      SetFilterData((prevValue1: any) => {
                        prevValue1.is_checked = false
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          prevValue1.is_all_checked = true
                        } else {
                          prevValue1.is_all_checked = false
                        }
                        return { ...prevValue1 }
                      })
                      return [...prevValue]
                    })
                  }}
                >
                   <MaterialCommunityIcons
                      name="progress-close"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Unselect All</span>
                </button>
                {/* Delete */}
                <button
                  className="btn btn-sm pe-2"
                  onClick={() => {
                    SetModalDelete((prevValue: any) => {
                      prevValue.is_open = true
                      prevValue.delete_type = 'multiple'
                      prevValue.data = {
                        title: 'Delete Addon Item  ',
                        body: 'Are you sure? want to delete addon item',
                        data: addon_item_data,
                      }
                      prevValue.have_option = false
                      prevValue.options = {}
                      return { ...prevValue }
                    })
                  }}
                >
                  <MaterialCommunityIcons
                    name="delete-outline"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Delete</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="container">
        <div className="card p-3 my-3">
          {addon_item_data.length > 0 ? (
            <InfiniteScroll
              dataLength={addon_item_data.length}
              next={() => {
                SetFilterData((prevValue: any) => {
                  prevValue.page_no = prevValue.page_no + 1
                  get_addon_items(prevValue, true)
                  return { ...prevValue }
                })
              }}
              hasMore={filter_data.next_page}
              className=""
              loader={
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    padding: '10px',
                  }}
                >
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              }
              endMessage={
                <div className="center pt-5 pb-3">
                  <hr className="w-25" />
                </div>
              }
            >
              <div className="table-responsive">
                <table className='table table-hover'>
                  <thead className='table-light shadow'>
                    <tr>
                      <th scope="col" className='py-3'>Name</th>
                      <th scope="col" className='py-3'>Notes</th>
                      <th scope="col" className='py-3'>Custom Quantity</th>
                      <th scope="col" className='py-3'>Updates</th>
                      <th scope="col" className='py-3'>Actions</th>
                    </tr>
                  </thead>
                  {addon_item_data.map((ele: any, a_index: any) => (
                    <tbody key={a_index}>
                      <tr>
                        <td scope='row' className='py-3 cursor'>
                          <label className='d-flex align-items-center cursor'>
                            <div className="center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={ele.checked}
                                onChange={() => {
                                  SetAddonItemData((prevValue: any) => {
                                    prevValue[a_index].checked = !prevValue[
                                      a_index
                                    ].checked

                                    SetFilterData((prevValue1: any) => {
                                      if (
                                        prevValue.some(
                                          (v_ele) => v_ele.checked == true,
                                        )
                                      ) {
                                        prevValue1.is_checked = true
                                      } else {
                                        prevValue1.is_checked = false
                                      }

                                      if (
                                        prevValue.every(
                                          (v_ele) => v_ele.checked == true,
                                        )
                                      ) {
                                        prevValue1.is_all_checked = true
                                      } else {
                                        prevValue1.is_all_checked = false
                                      }
                                      return { ...prevValue1 }
                                    })

                                    return [...prevValue]
                                  })
                                }}
                              />
                            </div>
                            <p className='mb-0 ps-4 text-dark '>{ele.name}</p>
                          </label>
                        </td>
                        <td  className='py-3 v-small text-gray'>{ele.notes}</td>
                        <td  className='py-3 v-small text-gray'>{ele.custom_quantity ? "Yes" : "No"}</td>
                        <td  className='py-3 v-small text-gray'>{Object.keys(ele.update_request).length > 0 ? (
                          <>
                          <span>Name</span> : {ele.update_request.name},
                          <span>Notes</span> : {ele.update_request.notes},
                          <span>Update Time</span> : {ele.update_request.updated_time}
                          </>
                        ) : "-"}</td>
                        <td scope='row ' className='py-3'>
                          <div className="d-flex align-items-center">
                            <div className="px-3">
                              <div className="center cursor"
                                onClick={() => {
                                  console.log('view')
                                  SetModalAddonItem((prevValue: any) => {
                                    prevValue.is_open = true
                                    prevValue.type = 'view'
                                    prevValue.data = ele
                                    return { ...prevValue }
                                  })
                                }}
                                style={{ marginRight: '5px' }}
                              >
                                <FontAwesome
                                  name="eye"
                                  size={16}
                                  color="gray"
                                />
                                {/* &nbsp; View */}
                              </div>
                            </div>

                            <div className="px-3">
                              <div
                                className="cursor center"
                                onClick={() => {
                                  console.log('edit')
                                  SetModalAddonItem((prevValue: any) => {
                                    prevValue.is_open = true
                                    prevValue.type = 'edit'
                                    prevValue.data = ele
                                    return { ...prevValue }
                                  })
                                }}
                                style={{ marginRight: '5px' }}
                              >
                                <AntDesign
                                  name="edit"
                                  size={16}
                                  color="gray"
                                />
                                {/* &nbsp; Edit */}
                              </div>
                            </div>

                            <div className="px-3">
                              <div
                                className="cursor center"
                                onClick={() => {
                                  console.log('Delete')
                                  SetModalDelete((prevValue: any) => {
                                    prevValue.is_open = true
                                    prevValue.delete_type = 'single'
                                    prevValue.data = {
                                      title: 'Delete Addon Item  ',
                                      body:
                                        'Are you sure? want to delete addon item',
                                      data: ele,
                                    }
                                    prevValue.have_option = false
                                    prevValue.options = {}
                                    return { ...prevValue }
                                  })
                                }}
                                style={{ marginRight: '5px' }}
                              >
                                <MaterialCommunityIcons
                                  name="delete-outline"
                                  size={16}
                                  color="gray"
                                />
                                {/* &nbsp; Delete */}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>

              {/* <div className="row">
                  {addon_item_data.map((ele: any, a_index: any) => (
                    <div
                      className="col-md-4 col-sm-6 col-12 mb-3"
                      key={a_index}
                    >
                      <div className="card p-3 cursor">
                        <label className="d-flex align-items-center cursor mb-3 ps-0">
                          <h6 className="mb-0">{ele.name}</h6>

                          <div className="ms-auto ">
                            <input
                              className="form-check-input-custom"
                              type="checkbox"
                              checked={ele.checked}
                              onChange={() => {
                                SetAddonItemData((prevValue: any) => {
                                  prevValue[a_index].checked = !prevValue[
                                    a_index
                                  ].checked

                                  SetFilterData((prevValue1: any) => {
                                    if (
                                      prevValue.some(
                                        (v_ele) => v_ele.checked == true,
                                      )
                                    ) {
                                      prevValue1.is_checked = true
                                    } else {
                                      prevValue1.is_checked = false
                                    }

                                    if (
                                      prevValue.every(
                                        (v_ele) => v_ele.checked == true,
                                      )
                                    ) {
                                      prevValue1.is_all_checked = true
                                    } else {
                                      prevValue1.is_all_checked = false
                                    }
                                    return { ...prevValue1 }
                                  })

                                  return [...prevValue]
                                })
                              }}
                            />
                          </div>
                        </label>
                        <div className="row">
                          <div className="col-10">
                            <small>{ele.notes}</small>
                          </div>
                          <div className="col-2 px-2 p-0 text-center">
                            <div className="dropdown cursor ms-auto">
                              <div
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Entypo
                                  name="dots-three-vertical"
                                  size={16}
                                  color="gray"
                                />
                              </div>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <div
                                    className="cursor dropdown-item"
                                    onClick={() => {
                                      console.log('view')
                                      SetModalAddonItem((prevValue: any) => {
                                        prevValue.is_open = true
                                        prevValue.type = 'view'
                                        prevValue.data = ele
                                        return { ...prevValue }
                                      })
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <AntDesign
                                      name="eyeo"
                                      size={16}
                                      color="gray"
                                    />
                                    &nbsp; View
                                  </div>
                                </li>

                                <li>
                                  <div
                                    className="cursor dropdown-item"
                                    onClick={() => {
                                      console.log('edit')
                                      SetModalAddonItem((prevValue: any) => {
                                        prevValue.is_open = true
                                        prevValue.type = 'edit'
                                        prevValue.data = ele
                                        return { ...prevValue }
                                      })
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <AntDesign
                                      name="edit"
                                      size={16}
                                      color="gray"
                                    />
                                    &nbsp; Edit
                                  </div>
                                </li>

                                <li>
                                  <div
                                    className="cursor dropdown-item"
                                    onClick={() => {
                                      console.log('Delete')
                                      SetModalDelete((prevValue: any) => {
                                        prevValue.is_open = true
                                        prevValue.delete_type = 'single'
                                        prevValue.data = {
                                          title: 'Delete Addon Item  ',
                                          body:
                                            'Are you sure? want to delete addon item',
                                          data: ele,
                                        }
                                        prevValue.have_option = false
                                        prevValue.options = {}
                                        return { ...prevValue }
                                      })
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <AntDesign
                                      name="delete"
                                      size={16}
                                      color="gray"
                                    />
                                    &nbsp; Delete
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}
            </InfiniteScroll>
          ) : (
            <div className="center h-170 w-100">
              <div>
                <img
                  src={require('../../assets/img/svg/no_data_2.svg')}
                  className="product-img"
                />
                <p className="mt-3 fw-bold text-center">No Data</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalCreateAddonItem
        open={modal_addon_item.is_open}
        type={modal_addon_item.type}
        data={modal_addon_item.data}
        close={(data: any) => {
          console.log('ModalCreateAddonItem on close :', data)
          SetModalAddonItem((prevValue: any) => {
            prevValue.is_open = false
            return { ...prevValue }
          })
          if (data.action == 'save') {
            get_addon_items(filter_data, false)
          }
        }}
      />

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        delete_type={modal_delete.delete_type}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data) => {
          console.log('on close delete modal data:', data)
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false
            return { ...prevValue }
          })

          if (data.action == 'save') {
            delete_addon_item(data, data.data, data.delete_type)
          }
        }}
      />
    </div>
  )
}
