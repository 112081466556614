import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  AntDesign,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-checkbox-tree/src/less/react-checkbox-tree.less'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
const animatedComponents = makeAnimated()

let myModal = {}

export default function ModalSpecification(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [spec_data, SetSpecData] = useState([])
  const [spec_data_with_child, SetSpecDataWithChild] = useState([])
  const [option_data, SetOptionData] = useState([])
  const [view_data, SetViewData] = useState('main')
  const [save_data, SetSaveData] = useState([])

  useEffect(() => {
    if (props.open) {
      console.log('ModalSpecification On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('SpecificationModal'),
        {},
      )
      SetViewData('main')
      let ss_data = props.selected_spec_data
      ss_data.map((ele: any) => {
        // if (ele.type_id == '1') {
        // }

        if (ele.type_id == '2') {
          ele.value.label = ele.value.name
        }
        if (ele.type_id == '3') {
          ele.value.map((e_ele) => {
            e_ele.label = e_ele.name
          })
        }
        // if (ele.type_id == '4') {
        // }

        // if (ele.hasOwnProperty('value')) {
        //   if (Array.isArray(ele.value)) {
        //     console.log('is array ele :', ele.value)
        //   } else {
        //     // if (Object.keys(ele.value).length > 0) {
        //     //   ele.value.label = ele.value.name
        //     // }else{

        //     // }
        //     ele.value.label = ele.value.name
        //   }
        // }
      })
      console.log('SetSaveData :', ss_data)
      SetSaveData(ss_data)
      get_specifications()
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalSpecification On mount :', props)
    let myModal1 = document.getElementById('SpecificationModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSpecData([])
      SetSpecDataWithChild([])
      SetOptionData([])
      SetViewData('main')
      SetSaveData([])
      props.close(close_data)
    })
  }, [])

  async function get_specifications() {
    let pass_data = {
      get: {
        country: props.category_id,
        specifications_only: 1,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications response :', response)
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty('data')) {
        let data = response.response.data
        data.map((ele: any) => {
          ele.specification_id = ele.id
        })
        SetSpecData(data)
      }
      if (response.response.hasOwnProperty('options')) {
        SetOptionData(response.response.options)
      }
    }
  }

  async function get_child_values() {
    let spec_value = []
    spec_data.map((ele: any) => {
      if (ele.checked) {
        spec_value.push({
          id: ele.id,
          source_type: ele.source_type,
          data_type: ele.data_type,
        })
      }
    })
    let pass_data = {
      get: {
        country: props.category_id,
        specifications_only: 1,
      },
      post: {
        specification: spec_value,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications response :', response)
    if (response.status_code == 200) {
      SetViewData('main')
      if (response.response.hasOwnProperty('data')) {
        // SetSpecDataWithChild(response.response.data)
        SetSaveData((prevValue: any) => {
          response.response.data.map((ele: any) => {
            ele.specifications_id = ele.id
            ele.options = ele.children
            if (!prevValue.some((p_ele) => p_ele.specifications_id == ele.id)) {
              prevValue.push(ele)
            }
          })
          return [...prevValue]
        })

        SetSpecData((prevValue: any) => {
          prevValue.map((ele) => {
            ele.checked = false
          })
          return [...prevValue]
        })
      }
    }
  }

  async function update_spectification() {
    let spec_value = []
    save_data.map((ele) => {
      spec_value.push({
        id: ele.specifications_id,
        value: ele.value,
      })
    })
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        specification: spec_value,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/update_product_specification', pass_data)
    console.log('/product/update_product_specification response :', response)
    if (response.status_code == 200) {
      if (response.response.status) {
        let item = close_data
        item.action = 'save'
        SetCloseData(item)
        SetSpecData([])
        SetSpecDataWithChild([])
        SetOptionData([])
        SetViewData('main')
        SetSaveData([])
        myModal.hide()
      }
    }
  }

  return (
    <div
      className="modal"
      id="SpecificationModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down  modal-dialog-centered">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            {view_data == 'main' ? (
              <h5 className="  modal-title" id="ModalLabel">
                Update Specification
              </h5>
            ) : null}
            {view_data == 'add_more' ? (
              <div className="d-flex align-items-center">
                <button
                  className='btn btn-sm'
                  onClick={() => {
                    SetViewData('main')
                  }}
                >
                  <Ionicons name="arrow-back-outline" size={20} color="black" />
                </button>
                <h5 className="mb-0 ps-2 modal-title" id="ModalLabel">
                  Add Specification
                </h5>
              </div>
            ) : null}
            {view_data == 'child' ? (
              <div className="d-flex align-items-center">
                <button
                  onClick={() => {
                    SetViewData('main')
                  }}
                >
                  <Ionicons name="arrow-back-outline" size={20} color="black" />
                </button>
                <h5 className="mb-0 ps-2  modal-title" id="ModalLabel">
                  Select Specification
                </h5>
              </div>
            ) : null}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body">
            {view_data == 'main' ? (
              <div className="">
                {/* add more */}
                <div className="text-end mb-3">
                  <button className="btn text-primary d-flex alifn-items-center shadow ms-auto"
                    onClick={() => {
                      SetViewData('add_more')
                    }}
                  >
                    <MaterialIcons name="add-task" size={16} color="#0053ad" />
                    <span className="text-primary ps-2">Add more</span>
                  </button>
                </div>
                <div className="row">
                  {save_data.map((s_ele: any, s_index: any) => (
                    <div className="col-md-6">
                      {s_ele.type_id == '1' ? (
                        <div className='py-2'>
                          <div className="d-flex align-items-center">
                            <label>{s_ele.name}</label>
                            <div className="ms-auto">
                              <button className='btn btn-sm d-flex align-items-center'
                                onClick={() => {
                                  SetSpecData((prevValue: any) => {
                                    let index_value = prevValue.findIndex(
                                      (ele) => ele.id == s_ele.specifications_id,
                                    )
                                    if (index_value != -1) {
                                      prevValue[index_value].disabled = false
                                    }
                                    console.log('after prevValue :', prevValue)
                                    return [...prevValue]
                                  })
                                  SetSaveData((prevValue: any) => {
                                    prevValue.splice(s_index, 1)
                                    return [...prevValue]
                                  })
                                }}
                              >
                                <Ionicons name="close-circle-outline" size={16} color="gray" />
                                <p className='mb-0 small text-gray ps-1'>Remove</p>
                              </button>
                            </div>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            value={s_ele.value}
                            onChange={(e) => {
                              SetSaveData((prevValue: any) => {
                                prevValue[s_index].value = e.target.value
                                return [...prevValue]
                              })
                            }}
                          />

                        </div>
                      ) : null}

                      {s_ele.type_id == '2' ? (
                        <div className='py-2'>
                          <div className="d-flex align-items-center">
                            <label>{s_ele.name}</label>
                            <div className="ms-auto">
                              <button className='btn btn-sm  d-flex align-items-center'
                                onClick={() => {
                                  SetSpecData((prevValue: any) => {
                                    let index_value = prevValue.findIndex(
                                      (ele) => ele.id == s_ele.specifications_id,
                                    )
                                    if (index_value != -1) {
                                      prevValue[index_value].disabled = false
                                    }
                                    console.log('after prevValue :', prevValue)
                                    return [...prevValue]
                                  })
                                  SetSaveData((prevValue: any) => {
                                    // prevValue[s_index].disabled = true
                                    prevValue.splice(s_index, 1)
                                    return [...prevValue]
                                  })
                                }}
                              >
                                <Ionicons name="close-circle-outline" size={16} color="gray" />
                                <p className='mb-0 small text-gray ps-1'>Remove</p>
                              </button>
                            </div>
                          </div>
                          <Select
                            className="no-radius border-none"
                            classNamePrefix="select"
                            value={s_ele.value}
                            menuPortalTarget={document.body}
                            options={s_ele.options}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (base) => ({
                                ...base,
                                zIndex: 0,
                              })
                            }}
                            onChange={(e) => {
                              console.log('selct on change :', e)
                              SetSaveData((prevValue: any) => {
                                prevValue[s_index].value = e
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}

                      {s_ele.type_id == '3' ? (
                        <div className='py-2'>
                          <div className="d-flex align-items-center">
                            <label htmlFor="">{s_ele.name}</label>
                            <div className="ms-auto">
                              <button className='btn btn-sm d-flex align-items-center'
                                onClick={() => {
                                  SetSpecData((prevValue: any) => {
                                    let index_value = prevValue.findIndex(
                                      (ele) => ele.id == s_ele.specifications_id,
                                    )
                                    if (index_value != -1) {
                                      prevValue[index_value].disabled = false
                                    }
                                    console.log('after prevValue :', prevValue)
                                    return [...prevValue]
                                  })
                                  SetSaveData((prevValue: any) => {
                                    // prevValue[s_index].disabled = true
                                    prevValue.splice(s_index, 1)
                                    return [...prevValue]
                                  })
                                }}
                              >
                                <Ionicons name="close-circle-outline" size={16} color="gray" />
                                <p className='mb-0 small text-gray ps-1'>Remove</p>
                              </button>
                            </div>
                          </div>
                          <Select
                            className="no-radius border-none"
                            isMulti
                            classNamePrefix="select"
                            menuPortalTarget={document.body}
                            value={s_ele.value}
                            options={s_ele.options}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (base) => ({
                                ...base,
                                zIndex: 0,
                              })
                            }}
                            onChange={(e) => {
                              console.log('selct on change :', e)
                              SetSaveData((prevValue: any) => {
                                prevValue[s_index].value = e
                                return [...prevValue]
                              })
                            }}
                          />

                        </div>
                      ) : null}

                      {s_ele.type_id == '4' ? (
                        <div className='py-2'>
                          <div className="d-flex align-items-center">
                            <label>{s_ele.name}</label>
                            <div className="ms-auto">
                              <button className='btn btn-sm d-flex align-items-center'
                                onClick={() => {
                                  SetSpecData((prevValue: any) => {
                                    let index_value = prevValue.findIndex(
                                      (ele) => ele.id == s_ele.specifications_id,
                                    )
                                    if (index_value != -1) {
                                      prevValue[index_value].disabled = false
                                    }
                                    console.log('after prevValue :', prevValue)
                                    return [...prevValue]
                                  })
                                  SetSaveData((prevValue: any) => {
                                    // prevValue[s_index].disabled = true
                                    prevValue.splice(s_index, 1)
                                    return [...prevValue]
                                  })
                                }}
                              >
                                <Ionicons name="close-circle-outline" size={16} color="gray" />
                                <p className='mb-0 small text-gray ps-1'>Remove</p>
                              </button>
                            </div>
                          </div>
                          <textarea
                            className="form-control"
                            value={s_ele.value}
                            onChange={(e) => {
                              SetSaveData((prevValue: any) => {
                                prevValue[s_index].value = e.target.value
                                return [...prevValue]
                              })
                            }}
                          />

                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {view_data == 'add_more' ? (
              <div className="row">
                {spec_data?.map((s_ele: any, s_index: any) =>
                  !save_data.some(
                    (ele) => ele.specifications_id == s_ele.specification_id,
                  ) ? (
                    <div className="col-md-6 mb-3" key={s_index}>
                      <label className='d-flex align-items-center cursor pb-1'>
                        <input className='form-check-input'
                          type="checkbox"
                          checked={s_ele.checked}
                          onChange={(e) => {
                            SetSpecData((prevValue: any) => {
                              prevValue[s_index].checked = e.target.checked
                              return [...prevValue]
                            })
                          }}
                        />
                        <span className='ps-2'> {s_ele.name}</span>
                      </label>
                    </div>
                  ) : null,
                )}
              </div>
            ) : null}
            {view_data == 'child' ? (
              <div className="">
                <p>Specification Child Editing</p>
                <div className="row">
                  {spec_data_with_child.map((s_ele: any, s_index: any) => (
                    <div className="col-md-6">
                      {s_ele.type_id == '1' ? (
                        <div className="">
                          <label>{s_ele.name}</label>
                          <input
                            className="form-control"
                            type="text"
                            value={s_ele.value}
                            onChange={(e) => {
                              SetSpecDataWithChild((prevValue: any) => {
                                prevValue[s_index].value = e.target.value
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}

                      {s_ele.type_id == '2' ? (
                        <div>
                          <label htmlFor="">{s_ele.name}</label>
                          <Select
                            className="no-radius border-none"
                            value={s_ele.value}
                            menuPortalTarget={document.body}
                            classNamePrefix="select"
                            options={s_ele.children}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (base) => ({
                                ...base,
                                zIndex: 0,
                              })
                            }}
                            onChange={(e) => {
                              console.log('selct on change :', e)
                              SetSpecDataWithChild((prevValue: any) => {
                                prevValue[s_index].value = e
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}

                      {s_ele.type_id == '3' ? (
                        <div>
                          <label htmlFor="">{s_ele.name}</label>
                          <Select
                            className="no-radius border-none"
                            classNamePrefix="select"
                            menuPortalTarget={document.body}
                            isMulti
                            value={s_ele.value}
                            options={s_ele.children}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (base) => ({
                                ...base,
                                zIndex: 0,
                              })
                            }}
                            onChange={(e) => {
                              console.log('selct on change :', e)
                              SetSpecDataWithChild((prevValue: any) => {
                                prevValue[s_index].value = e
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}

                      {s_ele.type_id == '4' ? (
                        <div>
                          <label>{s_ele.name}</label>
                          <textarea
                            className="form-control"
                            value={s_ele.value}
                            onChange={(e) => {
                              SetSpecDataWithChild((prevValue: any) => {
                                prevValue[s_index].value = e.target.value
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          {/* footer */}
          <div className="modal-footer shadow">
            {view_data == 'main' ? (
              <div className="d-flex align-items-center w-100">
                <div className="w-50">
                  <button
                    type="button"
                    className="btn btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      let item = close_data
                      item.action = 'close'
                      SetCloseData(item)
                      myModal.hide()
                    }}
                  >
                    Close
                  </button>
                </div>
                <div className="w-50 text-end">
                  <button
                    type="button"
                    className="btn btn-primary ms-auto btn-radius px-3"
                    onClick={() => {
                      console.log('update')
                      update_spectification()
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {view_data == 'add_more' ? (
              <div className="d-flex align-items-center w-100">
                <button
                  type="button"
                  className="btn btn-secondary-outline btn-radius px-3 btn-sm"
                  onClick={() => {
                    let item = close_data
                    item.action = 'close'
                    SetCloseData(item)
                    myModal.hide()
                  }}
                >
                  Close
                </button>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-primary btn-radius px-3"
                    onClick={() => {
                      console.log('OK spec data :', spec_data)
                      get_child_values()
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
