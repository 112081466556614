import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";

export default function SellerDashboard(props: any) {
  const context = useContext(DataContext);

  const [account_data, SetAccountData] = useState([]);

  useEffect(() => {
    console.log("SellerDashboard On mount :", props);
    console.log("SellerDashboard On mount context :", context);
  }, []);

  return (
    <div className="">
      <div className="bg-fff py-2 px-4">
        <h5 className="  mb-1">Seller Dashboard</h5>
      </div>
    </div>
  );
}
