import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  Ionicons,
  AntDesign,
  Feather,
} from "@expo/vector-icons";
import toast from "react-hot-toast";
import VarriantModal from "../../components/VarriantModal/VarriantModal";
import AddonModal from "./AddonModal";

export default function ProductEditVariant(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [group_data, SetGroupData] = useState({});
  const [combination_data, SetCombinationData] = useState([]);
  const [width, SetWidth] = React.useState(innerWidth);
  const [varriant_modal, SetVarriantModal] = useState({
    is_open: false,
    selected_product: {},
    index: "",
    all_selected_product_value: [],
  });

  const [addon_modal_data, SetAddonModalData] = useState({
    is_open: false,
    selected_addon: [],
    combination_index: "",
  });

  useEffect(() => {
    console.log("ProductEditVariant On mount :", props);
    console.log("ProductEditVariant On mount context :", context);
    get_combinations();
  }, []);

  async function get_combinations() {
    let pass_data = {
      get: {
        product_id: id_value.product_group_id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let response = await api("/product/product_group_combinations", pass_data);
    console.log("/product/product_group_combinations response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("combination_rows")) {
        let combi_value = response.response.combination_rows;
        SetCombinationData(combi_value);
      }

      if (response.response.hasOwnProperty("product_group")) {
        let group_value = response.response.product_group;
        SetGroupData(group_value);
      }
    }
  }

  function get_selected_products() {
    let selected_product = [];
    combination_data.map((ele: any) => {
      if (Object.keys(ele.product).length > 0) {
        selected_product.push(ele.product.id);
      }
    });
    return selected_product;
  }

  async function update_combination() {
    let pass_data = {
      get: {
        product_id: id_value.product_group_id,
      },
      post: {
        product_data: combination_data,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("update_combination pass_data : ",pass_data)
    let response = await api("/product/update_combination_products", pass_data);
    console.log("/product/update_combination_products response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Combination updated successfully");
        if (response.response.hasOwnProperty("combination_rows")) {
          let combi_value = response.response.combination_rows;
          SetCombinationData(combi_value);
        }

        if (response.response.hasOwnProperty("product_group")) {
          let group_value = response.response.product_group;
          SetGroupData(group_value);
        }
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className="py-3 row align-items-center head-bar">
        <div className="d-flex align-items-center">
          <div
            className="cursor"
            onClick={() => {
              window.history.back();
            }}
          >
            <Ionicons name="arrow-back-sharp" size={18} color="#222" />
          </div>
          <div className="ps-2">
            <h5 className=" mb-0">Variants Details</h5>
          </div>
          <div className="ms-auto">
            <button
              className="btn btn-primary btn-radius btn-sm px-4"
              onClick={() => {
                update_combination();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container py-3 mb-5">
        {/* <div className="">
          {combination_data.map((c_ele: any, c_index: any) => (
            <div className=" card p-2 mb-2">

              <div className="row">
                <div className='col-md-5 row py-sm-2 py-md-0'>
                  {Object.keys(group_data).length > 0
                    ? group_data.variants.map((v_ele: any, v_index: any) => (
                      <div key={v_index} className="col-md-2 col-3">
                        <p className='mb-1'><small>{v_ele.name}</small></p>
                        <div>
                          {c_ele.variant.map((cv_ele: any) =>
                            v_ele.id == cv_ele.id ? (
                              <p className='mb-0 text-black' key={v_index}>{cv_ele.items.name}</p>
                            ) : null,
                          )}
                        </div>
                      </div>


                    ))
                    : null}
                </div>
                {Object.keys(c_ele.product).length > 0 ? (
                  <div className="col-md-5">
                    <div className="d-flex ">
                      <img src={require("../../assets/img/11.jpeg")} className="variant_img" />

                      <div>
                        <p className='ps-2 mb-2 text-black'>{c_ele.product.name}</p>
                        <p><small>{c_ele.product.description}</small></p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mt-2">
                      <button className='btn btn-sm'
                        onClick={() => {
                          SetCombinationData((prevValue: any) => {
                            prevValue[c_index].product = {}
                            return [...prevValue]
                          })
                        }}
                      >
                        <MaterialIcons name="delete-outline" size={16} color="black" />
                        <span className='ps-1'>Delete</span>
                      </button>
                      <button className='btn btn-sm ms-auto'
                        onClick={() => {
                          let selected_product_value = get_selected_products()
                          console.log('c_index on edit product :', c_index)
                          SetVarriantModal((prevValue: any) => {
                            prevValue.is_open = true
                            prevValue.selected_product = c_ele.product
                            prevValue.index = c_index
                            prevValue.all_selected_product_value = selected_product_value
                            return { ...prevValue }
                          })
                        }}
                      >
                        <AntDesign name="edit" size={16} color="black" />
                        <span className='ps-1'>Edit</span>
                      </button>

                    </div>

                  </div>
                ) : (
                  <div className="col-md-5 center">
                    <button className='btn btn-sm text-dark fw-bold w-100'
                      onClick={() => {
                        console.log('c_index on add product :', c_index)
                        let selected_product_value = get_selected_products()
                        SetVarriantModal((prevValue: any) => {
                          prevValue.is_open = true
                          prevValue.selected_product = {}
                          prevValue.all_selected_product_value = selected_product_value
                          prevValue.index = c_index
                          return { ...prevValue }
                        })
                      }}
                    >
                      Add Product
                    </button>
                  </div>
                )}

                <div className='col-md-2'>
                  <p>Addons</p>
                  <button
                    onClick={() => {
                      SetAddonModalData((prevValue: any) => {
                        prevValue.is_open = !prevValue.is_open
                        prevValue.selected_addon = c_ele.addons
                        prevValue.combination_index = c_index
                        return { ...prevValue }
                      })
                    }}
                  >
                    Addon
                  </button>
                </div>
              </div>

            </div>
          ))}
        </div> */}

      <div className="card p-2">
      <div className="row">
          <div
            className="table-responsive"
            style={{
              maxWidth: "2000px",
              maxHeight: "600px",
              overflowX: "scroll",
            }}
          >
            <table className="table table-hover">
              <thead className="sticky-top">
                <tr className="d-flex">
                  <th className="col-2 col-md-1 text-center ">S No</th>
                  {Object.keys(group_data).length > 0
                    ? group_data.hasOwnProperty("variants")
                      ? group_data.variants.map((v_ele: any, v_index: any) => (
                          <th className="col-3 col-md-2" key={v_index}>
                            {v_ele.name}
                          </th>
                        ))
                      : null
                    : null}
                  <th className="col-10 col-md-4 text-center">Product</th>
                  <th className="col-4 col-md-3 text-center">Addon</th>
                </tr>
              </thead>

              <tbody>
                {combination_data.map((c_ele: any, c_index: any) => (
                  <tr key={c_index} className="d-flex">
                    <td className="col-2 col-md-1 text-center bg-fff ">
                      <p className="mb-0">{c_index + 1}</p>
                    </td>

                    {Object.keys(group_data).length > 0
                      ? group_data.variants.map((v_ele: any, v_index: any) => (
                          <td key={v_index} className="col-3 col-md-2 bg-fff ">
                            {c_ele.variant.map((cv_ele: any) =>
                              v_ele.id == cv_ele.id ? (
                                <span> {cv_ele.items.name}</span>
                              ) : null
                            )}
                          </td>
                        ))
                      : null}
                    <td className="col-10 col-md-4 bg-fff ">
                      {Object.keys(c_ele.product).length > 0 ? (
                        <div className="row">
                          <div className="col-10 d-flex pe-1">
                            {/* <img
                              src={require("../../assets/img/11.jpeg")}
                              className="variant_img"
                            /> */}
                            <img
                              src={require("../../assets/img/no-image.jpg")}
                              className="product_img radius"
                            />
                            <p className="ps-2 mb-0 text-black v-small">
                              {c_ele.product.name}
                            </p>
                          </div>

                          <div className="col-2 ps-0">
                            {/* d-flex align-items-center  */}
                            <button
                              className="btn btn-sm ms-auto"
                              onClick={() => {
                                SetCombinationData((prevValue: any) => {
                                  prevValue[c_index].product = {};
                                  return [...prevValue];
                                });
                              }}
                            >
                              {/* <Ionicons name="close-outline" size={18} color="gray" /> */}
                              <MaterialIcons
                                name="delete-outline"
                                size={18}
                                color="#888"
                              />
                              {/* <span className='ps-1'>Delete</span> */}
                            </button>
                            {/* <button className='btn btn-sm ms-auto '
                              onClick={() => {
                                let selected_product_value = get_selected_products()
                                console.log('c_index on edit product :', c_index)
                                SetVarriantModal((prevValue: any) => {
                                  prevValue.is_open = true
                                  prevValue.selected_product = c_ele.product
                                  prevValue.index = c_index
                                  prevValue.all_selected_product_value = selected_product_value
                                  return { ...prevValue }
                                })
                              }}
                            >
                              <AntDesign name="edit" size={16} color="gray" />
                            </button> */}
                          </div>
                        </div>
                      ) : (
                        <div className="center">
                          <button
                            className="btn btn-sm fw-bold w-100"
                            onClick={() => {
                              console.log("c_index on add product :", c_index);
                              let selected_product_value =
                                get_selected_products();
                              SetVarriantModal((prevValue: any) => {
                                prevValue.is_open = true;
                                prevValue.selected_product = {};
                                prevValue.all_selected_product_value =
                                  selected_product_value;
                                prevValue.index = c_index;
                                return { ...prevValue };
                              });
                            }}
                          >
                            <Feather
                              name="plus-circle"
                              size={16}
                              color="#5682d7"
                            />
                            <span className="ps-2 text-primary-light">
                              Add Product
                            </span>
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="col-4 col-md-3 center bg-fff p-2">
                      <button
                        className="btn btn-sm w-75"
                        onClick={() => {
                          SetAddonModalData((prevValue: any) => {
                            prevValue.is_open = !prevValue.is_open;
                            prevValue.selected_addon = c_ele.addons;
                            prevValue.combination_index = c_index;
                            return { ...prevValue };
                          });
                        }}
                      >
                        <Ionicons name="open-outline" size={18} color="gray" />
                        {/* <MaterialIcons name="add" size={16} color="gray" />
                        <span className='ps-2 text-darkgray'>Add</span> */}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>

      <VarriantModal
        open={varriant_modal.is_open}
        selected_product={varriant_modal.selected_product}
        all_selected_product={varriant_modal.all_selected_product_value}
        index={varriant_modal.index}
        close={(data) => {
          SetVarriantModal((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.selected_product = {};
            prevValue.all_selected_product_value = [];
            prevValue.index = "";
            return { ...prevValue };
          });
          if (data.action == "save") {
            console.log("VarriantModal on close save data :", data);
            console.log(
              "VarriantModal on close varriant_modal :",
              varriant_modal
            );
            // variant_modal_on_sumbmit(data)
            SetCombinationData((prevValue: any) => {
              prevValue[data.index].product = data.value;
              return [...prevValue];
            });
          }
        }}
      />

      <AddonModal
        open={addon_modal_data.is_open}
        from={"product_edit_variant"}
        selected_addon={addon_modal_data.selected_addon}
        combination_index={addon_modal_data.combination_index}
        addon_group={addon_modal_data.selected_addon}
        close={(data) => {
          console.log("On close addon modal data: ", data);
          SetAddonModalData((prevValue: any) => {
            prevValue.is_open = !prevValue.is_open;
            return { ...prevValue };
          });
          if (data.action == "save") {
            console.log(
              "addon_modal_on_submit checked_value:",
              data.checked_value
            );
            console.log(
              "addon_modal_on_submit addon_group :",
              data.addon_group
            );
            let checked_value = data.checked_value;
            let addon_group = data.addon_group;
            addon_group.map((ele: any) => {
              ele.children.map((c_ele: any, c_index: any) => {
                if (
                  checked_value.some(
                    (check_ele) => check_ele == c_ele.addon_group_item_id
                  )
                ) {
                  ele.checked = true;
                  c_ele.checked = true;
                } else {
                  c_ele.checked = false;
                }
              });
            });
            console.log("addon_group :", addon_group);
            SetCombinationData((prevValue: any) => {
              prevValue[data.combination_index].addons = addon_group;
              return [...prevValue];
            });
            // let send_value = {
            //   id: data.product_data.id,
            //   combination_data: [
            //     {
            //       id: data.variant_data.id,
            //       addon: [],
            //     },
            //   ],
            // }

            // addon_group.map((g_ele: any) => {
            //   let push_value = {
            //     value: '',
            //     children: [],
            //   }
            //   if (g_ele.checked) {
            //     push_value.value = g_ele.id
            //     g_ele.children.map((c_ele: any) => {
            //       if (c_ele.checked) {
            //         push_value.children.push({
            //           value: c_ele.addon_group_item_id,
            //         })
            //       }
            //     })
            //     send_value.combination_data[0].addon.push(push_value)
            //   }
            // })
            // console.log('send_value :', send_value)
            // save_addon_to_variant(send_value)
          }
        }}
      />
    </div>
  );
}
