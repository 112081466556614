import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom'
import { DataProvider } from './src/utils/Context/DataContext'

//css
import './src/assets/libraries/bootstrap/css/bootstrap.min.css'
import './src/assets/libraries/fontawesome/css/all.min.css'
import './src/assets/css/style.css'

//js
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import Loader from './src/utils/Loader'
import { api } from './src/utils/Api'
import { usePromiseTracker } from 'react-promise-tracker'

//components
import HomeLayout from './src/components/HomeLayout/HomeLayout'
import ManufacturerLayout from './src/components/ManufacturerLayout/ManufacturerLayout'
import SellerLayout from './src/components/SellerLayout/SellerLayout'

//pages
import Home from './src/pages/Home/Home'
import Dashboard from './src/pages/Dashboard/Dashboard'
// import CreateAccount from "./src/pages/CreateAccount/CreateAccount";
import ManufacturerDashboard from './src/pages/ManufacturerDashboard/ManufacturerDashboard'
import SellerDashboard from './src/pages/SellerDashboard/SellerDashboard'
//
import Brand from './src/pages/Brand/Brand'
import CreateBrand from './src/pages/Brand/CreateBrand'
import EditBrand from './src/pages/Brand/EditBrand'

//
import AddProduct from './src/pages/Products/AddProduct'
//
import Products from './src/pages/Products/Products'
import AddGroupProduct from './src/pages/Products/AddGroupProduct'
import ProductDetail from './src/pages/Products/ProductDetail'
import ProductEditVariant from './src/pages/Products/ProductEditVariant'
//
import ProductsVariant from './src/pages/ProductsVariant/ProductsVariant'
import AddProductVariant from './src/pages/ProductsVariant/AddProductVariant'
import ProductVariantDetail from './src/pages/ProductsVariant/ProductVariantDetail'

//
import Gallery from './src/pages/Gallery/Gallery'
import AddonGroups from './src/pages/AddonGroups/AddonGroups'
import AddonItems from './src/pages/AddonItems/AddonItems'
import Specifications from './src/pages/Specifications/Specifications'
import Details from './src/pages/Products/Details'
// import UserRoles from "./src/pages/UserRoles/UserRoles";
// import Users from "./src/pages/Users/Users";

export default function App() {
  const [language_data, SetLanguageData] = useState({})
  const [selected_language, SetSelectedLanguage] = useState({})
  const [language_list, SetLanguageList] = useState([])
  const [account_data, SetAccountData] = useState([])
  const [user_details, SetUserDetails] = useState([])

  useEffect(() => {
    console.log('App On mount :')
  }, [])

  return (
    <DataProvider
    // selected_language={selected_language}
    // language_data={language_data}
    // language_list={language_list}
    // account_data={account_data}
    // user_details={user_details}
    >
      <Loader promiseTracker={usePromiseTracker} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="/manufacturer/:m_id" element={<ManufacturerLayout />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<ManufacturerDashboard />} />
            <Route path="brand" element={<Brand />} />
            <Route path="create_brand" element={<CreateBrand />} />
            <Route path="edit_brand" element={<EditBrand />} />
            <Route path="generate" element={<AddProduct />} />
            <Route path="group">
              <Route index element={<Navigate to="list" />} />
              <Route path="list" element={<Products />} />
              {/* <Route path="generate" element={<AddProduct />} /> */}
              <Route path="add" element={<AddGroupProduct />} />
              <Route
                path="details/:product_group_id"
                element={<ProductDetail />}
              />
              <Route
                path="edit_variant/:product_group_id"
                element={<ProductEditVariant />}
              />

              <Route path="detail" element={<Details />} />
            </Route>
            <Route path="product">
              <Route index element={<Navigate to="list" />} />
              <Route path="list" element={<ProductsVariant />} />
              <Route path="add" element={<AddProductVariant />} />
              <Route
                path="details/:product_variant_id"
                element={<ProductVariantDetail />}
              />
            </Route>
            <Route path="gallery" element={<Gallery />} />
            <Route path="addon_items" element={<AddonItems />} />
            <Route path="addon_groups" element={<AddonGroups />} />
            <Route path="specifications" element={<Specifications />} />
          </Route>

          <Route path="/seller/:brand_id" element={<SellerLayout />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<SellerDashboard />} />
            <Route path="brand" element={<Brand />} />
            <Route path="create_brand" element={<CreateBrand />} />
            <Route path="edit_brand" element={<EditBrand />} />
            <Route path="generate" element={<AddProduct />} />

            <Route path="group">
              <Route index element={<Navigate to="list" />} />
              <Route path="list" element={<Products />} />
              {/* <Route path="add" element={<AddProduct />} /> */}
              <Route path="add" element={<AddGroupProduct />} />
              <Route
                path="details/:product_group_id"
                element={<ProductDetail />}
              />
              <Route
                path="edit_variant/:product_group_id"
                element={<ProductEditVariant />}
              />
              <Route path="detail" element={<Details />} />

            </Route>

            <Route path="product">
              <Route index element={<Navigate to="list" />} />
              <Route path="list" element={<ProductsVariant />} />
              <Route path="add" element={<AddProductVariant />} />
              <Route
                path="details/:product_variant_id"
                element={<ProductVariantDetail />}
              />
            </Route>

            <Route path="gallery" element={<Gallery />} />
            <Route path="addon_items" element={<AddonItems />} />
            <Route path="addon_groups" element={<AddonGroups />} />
            <Route path="specifications" element={<Specifications />} />
          </Route>

          {/* <Route path=":account_id" element={<MainLayout />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="brand" element={<Brand />} />
            <Route path="products" element={<Products />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="addon_items" element={<AddonItems />} />
            <Route path="addon_groups" element={<AddonGroups />} />
            <Route path="specifications" element={<Specifications />} />
            <Route path="user_roles" element={<UserRoles />} />
            <Route path="users" element={<Users />} />
          </Route> */}
        </Routes>
      </BrowserRouter>
    </DataProvider>
  )
}
