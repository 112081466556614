import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  Entypo,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import Select from "react-select";
import makeAnimated, { Placeholder } from "react-select/animated";
import { MultiStepForm, Step } from "react-multi-form";
import ModalCategory from "./ModalCategory";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ModalCreateSpecification from "../Specifications/ModalCreateSpecification";

import ModalCreateAddonGroup from "../AddonGroups/ModalCreateAddonGroup";

import ProductAccordion from "../../components/ProductAccordion/ProductAccordion";

import CreatableSelect from "react-select/creatable";
// import Select from "react-select";

import ModalReviewAddon from "./ModalReviewAddon";
import ModalAddonConfirm from "./ModalAddonConfirm";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import toast from "react-hot-toast";
import ModalReviewMrp from "./ModalReviewMrp";
import { AppHelper } from "../../utils/Helper";

const animatedComponents = makeAnimated();

export default function AddProduct(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());

  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: "1",
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
    spec: [],
    mrp: [1, 2, 3, 4],
    addon_groups: [],
    category_id: "",
  });

  // init data
  const [countries_data, SetCountriesData] = useState([]);
  const [languages_data, SetLanguagesData] = useState([]);
  const [product_languages_data, SetProductLanguagesData] = useState([]);
  const [product_brands_data, SetProductBrandsData] = useState([]);
  const [product_categories_data, SetProductCategoriesData] = useState([]);
  const [currencies_data, SetCurrenciessData] = useState([]);
  const [product_currencies_data, SetProductCurrenciesData] = useState([]);
  const [active_step, SetActiveStep] = useState(1);

  const [category_value, SetCategoryValue] = useState({
    selected_category: {},
    category: "",
  });
  // pre spec data
  const [pre_spec_data, SetPreSpecData] = useState([]);
  const [modal_specification, SetModalSpecification] = useState({
    is_open: false,
    category: "",
  });
  //account addon
  const [account_addon_group, SetAccountAddonGroup] = useState([]);
  const [modal_addon, SetModalAddon] = useState({
    is_open: false,
    category: "",
  });
  // review
  const [avail_prod_name, SetAvailProdName] = useState(false);
  const [combination_row, SetCombinationRow] = useState([]);
  const [combination_head, SetCombinationHead] = useState([]);
  const [combination_data, SetCombinationData] = useState([]);
  const [row_generated, SetRowGenerated] = useState(false);
  // const [deleted_combi_row, SetDeletedCombiRow] = useState([])

  ///////

  //Basic Details
  const [basic_details, SetBasicDetails] = useState({
    selected_country: {},
    country: "",
    selected_language: {
      code: "en",
      id: "40",
      label: "English",
      name: "English",
      native_name: "English",
      value: "40",
    },
    language: "40",
    selected_category_group: [],
    selected_category: {},
    category: "",
    selected_brand: "",
    brand: "",
    product_name: "",
    description: "",
    selected_image: [],
  });

  // spec value
  const [common_data, SetCommonData] = useState([]);
  const [variant_data, SetVariantData] = useState([]);
  const [varying_data, SetVaryingData] = useState([]);
  const [init_spec_data, SetInitSpecData] = useState([]);
  const [spec_type_options, SetSpecTypeOptions] = useState([]);
  const [show_varying, SetShowVarying] = useState(false);
  const [specification_data, SetSpecificationData] = useState({
    common_data: [],
    variant_data: [],
    varying_data: [],
  });

  ///Common Details
  const [common_details, SetCommonDetails] = useState({
    common_data: [],
  });

  // Variant Details
  const [variant_details, SetVariantDetails] = useState({
    variant_data: [],
  });

  // Addon Details
  const [addon_details, SetAddonDetails] = useState({
    addon_data: [],
  });

  // Review Details
  const [review_details, SetReviewDetails] = useState({
    review_data: [],
  });
  ///////

  const [basic_error_state, SetBasicErrorState] = useState({});

  ///////
  const [deleted_combi_row, SetDeletedCombiRow] = useState([]);
  const [review_is_first_time, SetReviewIsFirstTime] = useState(true);

  useEffect(() => {
    console.log("AddProduct On mount :", props);
    console.log("AddProduct On mount context :", context);
    get_add_product_init();
  }, []);

  async function get_add_product_init() {
    let pass_data = {
      get: {},
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/create_init", pass_data);
    console.log("/product/create_init :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.data.hasOwnProperty("countries")) {
        SetCountriesData(res_data.response.data.countries);
      }
      if (res_data.response.data.hasOwnProperty("languages")) {
        SetLanguagesData(res_data.response.data.languages);
      }
      if (res_data.response.data.hasOwnProperty("product_languages")) {
        SetProductLanguagesData(res_data.response.data.product_languages);
      }
      if (res_data.response.data.hasOwnProperty("product_brands")) {
        SetProductBrandsData(res_data.response.data.product_brands);
      }
      if (res_data.response.data.hasOwnProperty("product_categories")) {
        SetProductCategoriesData(res_data.response.data.product_categories);
      }
      if (res_data.response.data.hasOwnProperty("currencies")) {
        SetCurrenciessData(res_data.response.data.currencies);
      }
      if (res_data.response.data.hasOwnProperty("product_currencies")) {
        SetProductCurrenciesData(res_data.response.data.product_currencies);
      }
    }
  }

  async function get_account_addon(filter_value) {
    // let pass_data = {
    //   get: {
    //     category_id: basic_details.selected_category.id,
    //   },
    // };
    //    // if (context.app_data.app_from == "seller") {
    // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id;
    // } else {
    //   pass_data.get.m_id = id_value.m_id;
    // }
    // let res_data = await api("/product/get_addon_groups", pass_data);
    // console.log("/product/get_addon_groups :", res_data);
    // if (res_data.status_code == 200) {
    //   if (res_data.response.hasOwnProperty("data")) {
    //     SetAccountAddonGroup(res_data.response.data);
    //   }
    // }

    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        spec: filter_value.spec,
        mrp: encodeURI(JSON.stringify(filter_value.mrp)),
        addon_groups: filter_value.addon_groups,
        category_id: basic_details.selected_category.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/get_all_addon_groups", pass_data);
    console.log("/product/get_all_addon_groups :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("addon")) {
        SetAccountAddonGroup(res_data.response.addon);
      }
    }
  }

  function onNext(data: any) {
    if (data.from == "basic_details") {
      // console.log('onNext basic_details :', data)
      SetBasicDetails((prevValue: any) => {
        prevValue.selected_country = data.data.selected_country;
        prevValue.country = data.data.country;
        prevValue.selected_language = data.data.selected_language;
        prevValue.language = data.data.language;
        prevValue.selected_category = data.data.selected_category;
        prevValue.category = data.data.category;
        prevValue.selected_brand = data.data.selected_brand;
        prevValue.brand = data.data.brand;
        prevValue.product_name = data.data.product_name;
        prevValue.description = data.data.description;
        prevValue.selected_image = data.data.selected_image;
        prevValue.selected_category_group = data.data.selected_category_group;

        return { ...prevValue };
      });

      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      get_init_pre_specifications();
      get_account_addon(filter_data);
    }
    if (data.from == "specifiaction_data") {
      SetSpecificationData((prevValue: any) => {
        prevValue.common_data = data.data.common_data;
        prevValue.variant_data = data.data.variant_data;
        prevValue.varying_data = data.data.varying_data;
        return { ...prevValue };
      });
      SetCommonDetails((prevValue: any) => {
        prevValue.common_data = data.data.common_data;
        return { ...prevValue };
      });
      SetVariantDetails((prevValue: any) => {
        prevValue.variant_data = data.data.variant_data;
        return { ...prevValue };
      });
      let step_value;
      if (data.data.common_data.length > 0) {
        step_value = parseInt(data.active_step) + 1;
      } else if (data.data.variant_data.length > 0) {
        step_value = parseInt(data.active_step) + 2;
      } else {
        step_value = parseInt(data.active_step) + 3;
      }
      SetActiveStep(step_value);
    }
    if (data.from == "common_data") {
      SetCommonDetails((prevValue: any) => {
        prevValue.common_data = data.data;
        return { ...prevValue };
      });

      // let step_value = parseInt(data.active_step) + 1
      // SetActiveStep(step_value)

      let step_value;
      if (variant_details.variant_data.length > 0) {
        step_value = parseInt(data.active_step) + 1;
      } else {
        step_value = parseInt(data.active_step) + 2;
      }
      SetActiveStep(step_value);
    }
    if (data.from == "variant_data") {
      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      SetVariantDetails((prevValue: any) => {
        prevValue.variant_data = data.data;
        return { ...prevValue };
      });
    }
    if (data.from == "addon_data") {
      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      if (!row_generated) {
        generate_product_variants(avail_prod_name);
      }
    }
  }

  function onPrev(data) {
    // if (data.from == 'specifiaction_data') {
    //   let step_value = parseInt(data.active_step) - 1
    //   SetActiveStep(step_value)
    // }
    if (data.from == "specifiaction_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "common_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "variant_data") {
      // let step_value
      // if (data.data.common_data.length > 0) {
      //   step_value = parseInt(data.active_step) + 1
      // } else if (data.data.variant_data.length > 0) {
      //   step_value = parseInt(data.active_step) + 2
      // } else {
      //   step_value = parseInt(data.active_step) + 3
      // }
      // SetActiveStep(step_value)

      let step_value;

      if (common_details.common_data.length > 0) {
        step_value = parseInt(data.active_step) - 1;
      } else {
        step_value = parseInt(data.active_step) - 2;
      }

      SetActiveStep(step_value);

      // let step_value = parseInt(data.active_step) - 1
      // SetActiveStep(step_value)
    }
    if (data.from == "addon_data") {
      //       ///Common Details
      // const [common_details, SetCommonDetails] = useState({
      //   common_data: [],
      // })

      // // Variant Details
      // const [variant_details, SetVariantDetails] = useState({
      //   variant_data: [],
      // })

      // let step_value
      // if (data.data.common_data.length > 0) {
      //   step_value = parseInt(data.active_step) + 1
      // } else if (data.data.variant_data.length > 0) {
      //   step_value = parseInt(data.active_step) + 2
      // } else {
      //   step_value = parseInt(data.active_step) + 3
      // }
      // SetActiveStep(step_value)

      let step_value;
      if (variant_details.variant_data.length > 0) {
        step_value = parseInt(data.active_step) - 1;
      } else {
        if (common_details.common_data.length > 0) {
          step_value = parseInt(data.active_step) - 2;
        } else {
          step_value = parseInt(data.active_step) - 3;
        }
      }
      SetActiveStep(step_value);
      SetRowGenerated(false);
      // let step_value = parseInt(data.active_step) - 1
      // SetActiveStep(step_value)
    }
    if (data.from == "review_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
  }

  const BasicDetailsComponent = (child_props) => {
    const [basic_details_value, SetBasicDetailsValue] = useState(
      child_props.basic_data
    );
    const [modal_category, SetModalCategory] = useState({
      is_open: false,
      data: {},
    });
    const [modal_image, SetModalImage] = useState({
      is_open: false,
      selected_images: [],
    });

    const [basic_error_state, SetBasicErrorState] = useState({});

    function onImgSelect() {
      SetModalImage((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        // prevValue.selected_images = data.selected_img;
        return { ...prevValue };
      });
    }

    function validate(data) {
      console.log("validate :", data);
      let languageError = "";
      let categoryError = "";
      let group_nameError = "";

      if (Object.keys(data.selected_language).length == 0) {
        languageError = "Language is required";
      }

      if (Object.keys(data.selected_category).length == 0) {
        categoryError = "Category is required";
      }

      if (!data.product_name) {
        group_nameError = "Group Name is required";
      }

      if (languageError || categoryError || group_nameError) {
        SetBasicErrorState({ languageError, categoryError, group_nameError });
        return false;
      }
      return true;
    }

    function get_category_value() {
      let category_value = "";
      basic_details_value.selected_category_group.map(
        (ele: any, index: any) => {
          // if (basic_details_value.selected_category_group.length - 1 == index) {
          //   category_value = category_value + ele.name;
          // } else {
          //   category_value = category_value + ele.name + " » ";
          // }
          if (basic_details_value.selected_category_group.length - 1 == index) {
            category_value = category_value + ele.name;
          }
        }
      );
      return category_value;
    }

    return (
      <div className=" mb-5">
        {/* button */}
        <div className="d-flex mb-3 align-items-center">
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Basic Details</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              if (validate(basic_details_value)) {
                console.log("basic_validate ok");
                let data = {
                  from: "basic_details",
                  active_step: child_props.active_step,
                  data: basic_details_value,
                };
                child_props.onNext(data);
              } else {
                console.log("basic_validate not ok");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
            {/* <Feather name="arrow-right-circle" size={20} color="black" /> */}
          </button>
        </div>

        <div className="d-sm-block d-md-none">
          <h6>Basic Details</h6>
        </div>
        <div className="row p-3">
          {/* Language */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Language <span className="text-red">*</span>{" "}
            </label>
            <Select
              components={animatedComponents}
              placeholder="Select Language"
              className="basic-multi-select"
              classNamePrefix="select"
              options={context.app_data.languages}
              value={basic_details_value.selected_language}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(e) => {
                console.log(" Language :", e);
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_language = e;
                  prevValue.language = e.id;
                  return { ...prevValue };
                });
                SetBasicErrorState((prevValue: any) => {
                  // { languageError, categoryError, group_nameError }
                  prevValue.languageError = "";
                  return { ...prevValue };
                });
              }}
            />
            <span className="text-danger small pt-1 ps-1">
              {basic_error_state.languageError}
            </span>
          </div>
          {/* Category */}
          <div
            className="col-md-6 mb-3"
            onClick={() => {
              SetModalCategory((prevValue: any) => {
                prevValue.is_open = true;
                return { ...prevValue };
              });
            }}
          >
            <label className="form-label">
              Category <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Category"
              // value={basic_details_value.selected_category.name}
              value={get_category_value()}
              onChange={(e) => {}}
              onFocus={() => {
                SetModalCategory((prevValue: any) => {
                  prevValue.is_open = true;
                  return { ...prevValue };
                });
              }}
            />
            <span className="text-danger small pt-1 ps-1">
              {basic_error_state.categoryError}
            </span>
          </div>

          <p className="text-dark fw-bold pt-2">Group Details</p>

          <div className="col-md-6 md-border-right">
            {/* Group Name */}
            <div className="mb-3">
              <label className="form-label">
                Group Name <span className="text-red">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Group Name"
                value={basic_details_value.product_name}
                onChange={(e) => {
                  SetBasicDetailsValue((prevValue: any) => {
                    prevValue.product_name = e.target.value;
                    return { ...prevValue };
                  });
                  SetBasicErrorState((prevValue: any) => {
                    // { languageError, categoryError, group_nameError }
                    if (e.target.value == "") {
                      prevValue.group_nameError = "Group Name is required";
                    } else {
                      prevValue.group_nameError = "";
                    }
                    return { ...prevValue };
                  });
                }}
              />
              <span className="text-danger small pt-1 ps-1">
                {basic_error_state.group_nameError}
              </span>
            </div>
            {/* Description */}
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                placeholder="Description"
                value={basic_details_value.description}
                onChange={(e) => {
                  SetBasicDetailsValue((prevValue: any) => {
                    prevValue.description = e.target.value;
                    return { ...prevValue };
                  });
                }}
              />
            </div>
          </div>

          {/* Image */}
          <div
            className={
              basic_details_value.selected_image.length > 0
                ? "col-md-6 px-sm-0 px-md-2"
                : "col-md-6 center px-sm-0 px-md-2"
            }
          >
            {basic_details_value.selected_image.length > 0 ? (
              <div className=" p-1">
                <div className="row py-3 px-2 overflow-img-scroll ">
                  {basic_details_value.selected_image.map(
                    (img_src, img_index) => (
                      <div className="col-4 p-1" key={img_index}>
                        <div className="card">
                          {/* <p>image{img_index},</p> */}
                          <img
                            src={AppHelper.imageSrc(
                              context.app_data.image_base_url,
                              img_src.url,
                              {
                                format: "webp",
                                width: 320,
                              }
                            )}
                            // src={
                            //   "https://cdn.1superapp.com/images/320/" +
                            //   img_src.url
                            // }
                            className="cover_img radius"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div
                  className="center cursor pt-4 pb-3 bg-fff add-more shadow-top"
                  onClick={() => {
                    onImgSelect(basic_details_value.selected_image);
                  }}
                >
                  <MaterialCommunityIcons
                    name="image-plus"
                    size={18}
                    color="gray"
                  />
                  <p className="ps-2 mb-0 text-darkgray fw-bold ">
                    Add / Select images
                  </p>
                </div>
              </div>
            ) : (
              <div
                className="center cursor py-2"
                onClick={() => {
                  onImgSelect(modal_image.selected_images);
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <MaterialCommunityIcons
                      name="image-plus"
                      size={22}
                      color="gray"
                    />
                    <h6 className="ps-2 mb-0 text-darkgray">
                      Add / Select Images
                    </h6>
                  </div>
                  <div className="mt-3">
                    <small className="">
                      <ul className="ps-0">
                        <li className="pb-1">
                          Add / Select your product images
                        </li>
                        <li className="pb-1">No image file larger then 5MB</li>
                      </ul>
                    </small>
                  </div>
                </div>
              </div>
            )}

            {/* {basic_details_value.selected_image.length > 0 ? (
              <img
                src={
                  basic_details_value.selected_image.length > 0
                    ? "https://cdn.1superapp.com/images/320/" +
                    basic_details_value.selected_image[0].url
                    : require("../../assets/img/add.png")
                }
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  onImgSelect(basic_details_value.selected_image);
                }}
              />
            ) : (
              <img
                src={
                  modal_image.selected_images.length > 0
                    ? "https://cdn.1superapp.com/images/320/" +
                    modal_image.selected_images[0].url
                    : require("../../assets/img/add.png")
                }
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  onImgSelect(modal_image.selected_images);
                }}
              />
            )} */}
          </div>
        </div>

        {modal_category.is_open ? (
          <ModalCategory
            open={modal_category.is_open}
            data={modal_category.data}
            close={(data: any) => {
              console.log("ModalCreateAddonItem on close :", data);
              SetModalCategory((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                // SetBasicDetails((prevValue: any) => {
                //   prevValue.selected_category = data.value;
                //   prevValue.category = data.value.id;
                //   return { ...prevValue };
                // });
                // SetCategoryValue((prevValue: any) => {
                //   prevValue.selected_category = data.value;
                //   prevValue.category = data.value.id;
                //   return { ...prevValue };
                // });
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_category = data.value;
                  prevValue.selected_category_group = data.group_value;
                  prevValue.category = data.value.id;
                  return { ...prevValue };
                });
                SetBasicErrorState((prevValue: any) => {
                  // { languageError, categoryError, group_nameError }
                  prevValue.categoryError = "";
                  return { ...prevValue };
                });
              }
            }}
          />
        ) : null}
        {modal_image.is_open ? (
          <ModalAddGallery
            open={modal_image.is_open}
            type={modal_image.type}
            data={modal_image.data}
            selected={modal_image.selected_images}
            from={"add_product"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalImage((prevValue: any) => {
                prevValue.is_open = false;
                if (data.action == "save") {
                  prevValue.selected_images = data.value;
                }
                return { ...prevValue };
              });
              if (data.action == "save") {
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_image = data.value;
                  return { ...prevValue };
                });
              }

              // if (data.action == "save") {
              //   console.log("ModalAddGallery on close save :", modal_image);
              //   SetCombiData((prevValue: any) => {
              //     prevValue[modal_image.index].images = data.value;
              //     return [...prevValue];
              //   });
              // }
            }}
          />
        ) : null}
      </div>
    );
  };

  const SpecificationDataComponent = (child_props) => {
    const [expanded, SetExpanded] = useState("panel1");
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    };

    window.addEventListener("scroll", toggleVisible);

    function common_onchange(index) {
      let old_common_data = common_data;
      let common_data_value = [...common_data];
      common_data_value[index] = {
        ...common_data_value[index],
        checked: !old_common_data[index].checked,
      };
      SetCommonData(common_data_value);
      let old_variant_data = variant_data;
      let variant_data_value = [...variant_data];
      variant_data_value[index] = {
        ...variant_data_value[index],
        disabled: !old_variant_data[index].disabled,
      };
      SetVariantData(variant_data_value);
      let old_varying_data = varying_data;
      let varying_data_value = [...varying_data];
      varying_data_value[index] = {
        ...varying_data_value[index],
        disabled: !old_varying_data[index].disabled,
      };
      SetVaryingData(varying_data_value);
    }

    function variant_onchange(index) {
      let old_common_data = common_data;
      let common_data_value = [...common_data];
      common_data_value[index] = {
        ...common_data_value[index],
        disabled: !old_common_data[index].disabled,
      };
      SetCommonData(common_data_value);
      let old_variant_data = variant_data;
      let variant_data_value = [...variant_data];
      variant_data_value[index] = {
        ...variant_data_value[index],
        checked: !old_variant_data[index].checked,
      };
      let v_data = variant_data_value;
      let found = v_data.filter((ele) => {
        return ele.checked == true;
      });
      console.log("found :", found);
      if (found.length > 0) {
        console.log("found true");
        SetShowVarying((prevValue: any) => {
          prevValue = true;
          console.log("found true prevValue :", prevValue);
          return prevValue;
        });
      } else {
        console.log("found false");

        SetShowVarying((prevValue: any) => {
          prevValue = false;
          console.log("found false prevValue :", prevValue);
          return prevValue;
        });
      }
      SetVariantData(variant_data_value);
      let old_varying_data = varying_data;
      let varying_data_value = [...varying_data];
      varying_data_value[index] = {
        ...varying_data_value[index],
        disabled: !old_varying_data[index].disabled,
      };
      SetVaryingData(varying_data_value);
    }

    function varying_onchange(index) {
      let old_common_data = common_data;
      let common_data_value = [...common_data];
      common_data_value[index] = {
        ...common_data_value[index],
        disabled: !old_common_data[index].disabled,
      };
      SetCommonData(common_data_value);
      let old_variant_data = variant_data;
      let variant_data_value = [...variant_data];
      variant_data_value[index] = {
        ...variant_data_value[index],
        disabled: !old_variant_data[index].disabled,
      };
      SetVariantData(variant_data_value);
      let old_varying_data = varying_data;
      let varying_data_value = [...varying_data];
      varying_data_value[index] = {
        ...varying_data_value[index],
        checked: !old_varying_data[index].checked,
      };
      SetVaryingData(varying_data_value);
    }

    function checkbox_on_change(get_value: any) {
      SetInitSpecData((prevValue: any) => {
        if (get_value.event.target.checked) {
          prevValue[get_value.index].checked = true;
          if (get_value.from == "common_data") {
            prevValue[get_value.index].data_type = "common_data";
          }
          if (get_value.from == "varriant_data") {
            prevValue[get_value.index].data_type = "varriant_data";
          }
          if (get_value.from == "varying_data") {
            prevValue[get_value.index].data_type = "varying_data";
          }
        } else {
          prevValue[get_value.index].checked = false;
          prevValue[get_value.index].data_type = "";
        }
        // prevValue[get_value.index].checked = !prevValue[get_value.index].checked;
        // prevValue[get_value.index].data_type = "";
        // if (get_value.from == "common_data") {
        //   prevValue[get_value.index].data_type = "common_data";
        // }
        // if (get_value.from == "varriant_data") {
        //   prevValue[get_value.index].data_type = "varriant_data";
        // }
        // if (get_value.from == "varying_data") {
        //   prevValue[get_value.index].data_type = "varying_data";
        // }
        return [...prevValue];
      });
    }

    async function process_next() {
      let next_pass_data = {};
      let common_data_value = [];
      let variant_data_value = [];
      let varying_data_value = [];
      let template_data = [];

      let spec_data_with_child = init_spec_data;

      let pass_data = {
        get: {
          category_id: basic_details.selected_category.id,
        },
        post: {
          specification: init_spec_data,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("init_specifications pass_data :", pass_data);
      let res_data = await api("/product/init_specifications", pass_data);
      console.log("/product/init_specifications response  :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.hasOwnProperty("data")) {
          spec_data_with_child.map((ele: any) => {
            res_data.response.data.map((data: any) => {
              if (
                ele.data_type == data.data_type &&
                ele.name == data.name &&
                ele.slug == data.slug &&
                ele.source_type == data.source_type &&
                ele.type == data.type
              ) {
                if (data.hasOwnProperty("linked")) {
                  ele.linked = data.linked;
                }
                if (data.hasOwnProperty("children")) {
                  ele.children = data.children;
                }
              }
            });
          });
        }
      }

      spec_data_with_child.map((ele: any, index: any) => {
        if (ele.checked === true) {
          if (ele.source_type == "templates") {
            let data = {
              from: ele.data_type,
              value: {
                name: ele.name,
                type: ele.type,
              },
            };
            if (ele.hasOwnProperty("children")) {
              let child_value = [];
              ele.children.map((c_ele: any, c_index: any) => {
                child_value.push({ name: c_ele.label });
              });
              data.value.children = child_value;
            }
            template_data.push(data);
          } else {
            if (ele.data_type == "common_data") {
              ele.error = "";
              if (ele.hasOwnProperty("linked")) {
                ele.linked_error = "";
              }
              common_data_value.push(ele);
            }
            if (ele.data_type == "varriant_data") {
              if (!Array.isArray(ele.value)) {
                ele.value = [];
              }
              ele.error = "";
              variant_data_value.push(ele);
            }
            if (ele.data_type == "varying_data") {
              ele.error = "";

              varying_data_value.push(ele);
            }
          }
        }
      });

      if (template_data.length > 0) {
        let spec_data = [];
        template_data.map((element: any) => {
          spec_data.push(element.value);
        });
        let pass_data = {
          get: {},
          post: {
            category_id: basic_details.selected_category.id,
            specification_data: spec_data,
            location: "create_product",
          },
        };
        // if (context.app_data.app_from == 'seller') {
        if (id_value.hasOwnProperty("brand_id")) {
          pass_data.get.brand = id_value.brand_id;
        } else {
          pass_data.get.m_id = id_value.m_id;
        }

        let res_data = await api("/product/add_pre_specification", pass_data);
        if (res_data.status_code == 201) {
          if (res_data.response.hasOwnProperty("data")) {
            res_data.response.data.map((ele: any, index: any) => {
              ele.checked = true;
              if (template_data[index].from == "common_data") {
                common_data_value.push(res_element);
              }
              if (template_data[index].from == "variant_data") {
                variant_data_value.push(res_element);
              }
              if (template_data[index].from == "varying_data") {
                varying_data_value.push(res_element);
              }
            });
          }
        }
      }

      next_pass_data.common_data = common_data_value;
      next_pass_data.variant_data = variant_data_value;
      next_pass_data.varying_data = varying_data_value;

      let data = {
        from: "specifiaction_data",
        active_step: child_props.active_step,
        data: next_pass_data,
      };
      console.log("specifiaction_data on next data :", data);
      child_props.onNext(data);

      // /////////////////////////////////// old
      // let next_pass_data = {};
      // let common_data_value = [];
      // let variant_data_value = [];
      // let varying_data_value = [];
      // let template_data = [];
      // common_data.map((ele: any, index: any) => {
      //   if (ele.checked == true) {
      //     if (ele.source_type == "templates") {
      //       let data = {
      //         from: "common_data",
      //         value: {
      //           name: ele.name,
      //           type: ele.type,
      //         },
      //       };
      //       if (ele.hasOwnProperty("children")) {
      //         let child_value = [];
      //         ele.children.map((c_ele: any, c_index: any) => {
      //           child_value.push({ name: c_ele.label });
      //         });
      //         data.value.children = child_value;
      //       }
      //       template_data.push(data);
      //     } else {
      //       common_data_value.push(ele);
      //     }
      //   }
      // });
      // variant_data.map((ele: any, index: any) => {
      //   if (ele.checked == true) {
      //     if (ele.source_type == "templates") {
      //       let data = {
      //         from: "variant_data",
      //         value: {
      //           name: ele.name,
      //           type: ele.type,
      //         },
      //       };
      //       if (ele.hasOwnProperty("children")) {
      //         let child_value = [];
      //         ele.children.map((c_ele: any, c_index: any) => {
      //           child_value.push({ name: c_ele.label });
      //         });
      //         data.value.children = child_value;
      //       }
      //       template_data.push(data);
      //     } else {
      //       variant_data_value.push(ele);
      //     }
      //   }
      // });
      // varying_data.map((ele: any, index: any) => {
      //   if (ele.checked == true) {
      //     if (ele.source_type == "templates") {
      //       let data = {
      //         from: "varying_data",
      //         value: {
      //           name: ele.name,
      //           type: ele.type,
      //         },
      //       };
      //       if (ele.hasOwnProperty("children")) {
      //         let child_value = [];
      //         ele.children.map((c_ele: any, c_index: any) => {
      //           child_value.push({ name: c_ele.label });
      //         });
      //         data.value.children = child_value;
      //       }
      //       template_data.push(data);
      //     } else {
      //       varying_data_value.push(ele);
      //     }
      //   }
      // });

      // if (template_data.length > 0) {
      //   let spec_data = [];
      //   template_data.map((element: any) => {
      //     spec_data.push(element.value);
      //   });
      //   let pass_data = {
      //     get: {},
      //     post: {
      //       category_id: basic_details.selected_category.id,
      //       specification_data: spec_data,
      //       location: "create_product",
      //     },
      //   };
      //  //   if (context.app_data.app_from == "seller") {
      // if (id_value.hasOwnProperty("brand_id")) {
      //     pass_data.get.brand = id_value.brand_id;
      //   } else {
      //     pass_data.get.m_id = id_value.m_id;
      //   }

      //   let res_data = await api("/product/add_pre_specification", pass_data);
      //   if (res_data.status_code == 201) {
      //     if (res_data.response.hasOwnProperty("data")) {
      //       res_data.response.data.map((ele: any, index: any) => {
      //         ele.checked = true;
      //         if (template_data[index].from == "common_data") {
      //           common_data_value.push(res_element);
      //         }
      //         if (template_data[index].from == "variant_data") {
      //           variant_data_value.push(res_element);
      //         }
      //         if (template_data[index].from == "varying_data") {
      //           varying_data_value.push(res_element);
      //         }
      //       });
      //     }
      //   }
      // }

      // next_pass_data.common_data = common_data_value;
      // next_pass_data.variant_data = variant_data_value;
      // next_pass_data.varying_data = varying_data_value;

      // let data = {
      //   from: "specifiaction_data",
      //   active_step: child_props.active_step,
      //   data: next_pass_data,
      // };
      // child_props.onNext(data);
    }

    return (
      <div className=" mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn bg-fff btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "specifiaction_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className=" w-100 d-md-block d-sm-none text-center">
            <h6>Specification Data</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              let req_ele = [];
              init_spec_data.map((ele: any, index: any) => {
                if (ele.required) {
                  req_ele.push(ele);
                }
              });

              if (req_ele.every((ele) => ele.checked == true)) {
                console.log("All required Checked");
                process_next();
              } else {
                toast.error("Please select the required fields");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>

        <div className="d-flex py-2 mb-2 align-items-center">
          <div className="d-md-none d-sm-block">
            <h6>Specification Data</h6>
          </div>
          <div
            className="end text-primary fw-bold cursor"
            onClick={() => {
              SetModalSpecification({
                is_open: true,
                category: basic_details.selected_category,
              });
            }}
          >
            <div className="me-1">
              <Entypo name="plus" size={16} color="#0053ad" />
            </div>
            Add New
          </div>
        </div>

        <div className="row border-bottom py-3 sticky-top55 shadow-bottom">
          <div className="col-3"></div>
          <div className="col-3 text-center">
            <p className="text-dark mb-0">Common Data</p>
          </div>
          <div className="col-3 text-center">
            <p className="text-dark mb-0">Variant Data</p>
          </div>
          <div className="col-3 text-center">
            <p className="text-dark mb-0">Varying Data</p>
          </div>
        </div>

        <div className="row p-3 top-scroll-content hover-row">
          <h6 className="mb-0 py-2 px-0 fw-500">Recommended</h6>

          {init_spec_data.some((ss_ele) => ss_ele.recommended == true) ? (
            init_spec_data.map((data, index) =>
              data.hasOwnProperty("addon") ? null : data.recommended ? (
                <div className="" key={index}>
                  <div className="row align-items-center border-bottom ps-2">
                    <div className="col-3 p-0">
                      <p className="text-gray mb-0">
                        {data.name}
                        {data.required ? (
                          <span className="text-red ps-1 fs-6">*</span>
                        ) : null}
                      </p>
                    </div>
                    <div className="col-3 text-center">
                      <label className="form-label p-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data.data_type == "common_data"}
                          onChange={(e) => {
                            let pass_value = {
                              from: "common_data",
                              index: index,
                              ele: data,
                              event: e,
                            };
                            checkbox_on_change(pass_value);
                            setVisible(true);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-3 text-center">
                      <label className="form-label p-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data.data_type == "varriant_data"}
                          disabled={
                            (data.type == "Select" ||
                              data.type == "Multi Select") &&
                            data.source_type != "product_details"
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            let pass_value = {
                              from: "varriant_data",
                              index: index,
                              ele: data,
                              event: e,
                            };
                            checkbox_on_change(pass_value);
                            setVisible(true);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-3 text-center">
                      <label className="form-label p-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data.data_type == "varying_data"}
                          onChange={(e) => {
                            let pass_value = {
                              from: "varying_data",
                              index: index,
                              ele: data,
                              event: e,
                            };
                            checkbox_on_change(pass_value);
                            setVisible(true);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  {/* <div className="card p-2">
                  <p className="text-dark py-2 border-bottom">
                    {data.name}
                    {data.required ?
                      <span className="text-red ps-1 fs-6">*</span>
                      : null}
                  </p>
                  <div className="row align-items-center">
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "common_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "common_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className="v-small fw-bold ps-2 text-darkgray">Common Data</span>
                    </label>
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varriant_data"}
                        disabled={
                          (data.type == "Select" ||
                            data.type == "Multi Select") &&
                            data.source_type != "product_details"
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          let pass_value = {
                            from: "varriant_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className={data.source_type != "product_details" ? "v-small fw-bold ps-2 text-darkgray" : "v-small ps-2"}>Varriant Data</span>
                    </label>
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varying_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "varying_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className="v-small fw-bold ps-2 text-darkgray">Varying Data</span>
                    </label>
                  </div>
                </div> */}
                </div>
              ) : null
            )
          ) : (
            <div className="center p-2">
              <hr className="w-30px" />
            </div>
          )}
          <h6 className="mb-0 py-2 px-0 fw-500">Others</h6>

          {init_spec_data.map((data, index) =>
            data.hasOwnProperty("addon") ? null : !data.recommended ? (
              <div className="" key={index}>
                <div className="row align-items-center border-bottom ps-2">
                  <div className="col-3  p-0">
                    <p className="text-gray mb-0">
                      {data.name}
                      {data.required ? (
                        <span className="text-red ps-1 fs-6">*</span>
                      ) : null}
                    </p>
                  </div>
                  <div className="col-3 text-center">
                    <label className="form-label p-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "common_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "common_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                    </label>
                  </div>
                  <div className="col-3 text-center">
                    <label className="form-label p-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varriant_data"}
                        disabled={
                          (data.type == "Select" ||
                            data.type == "Multi Select") &&
                          data.source_type != "product_details"
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          let pass_value = {
                            from: "varriant_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                          setVisible(true);
                        }}
                      />
                    </label>
                  </div>
                  <div className="col-3 text-center">
                    <label className="form-label p-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varying_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "varying_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                          setVisible(true);
                        }}
                      />
                    </label>
                  </div>
                </div>

                {/* <div className="card p-2">
                  <p className="text-dark py-2 border-bottom">
                    {data.name}
                    {data.required ?
                      <span className="text-red ps-1 fs-6">*</span>
                      : null}
                  </p>
                  <div className="row align-items-center">
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "common_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "common_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className="v-small fw-bold ps-2 text-darkgray">Common Data</span>
                    </label>
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varriant_data"}
                        disabled={
                          (data.type == "Select" ||
                            data.type == "Multi Select") &&
                            data.source_type != "product_details"
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          let pass_value = {
                            from: "varriant_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className={data.source_type != "product_details" ? "v-small fw-bold ps-2 text-darkgray" : "v-small ps-2"}>Varriant Data</span>
                    </label>
                    <label className="col-6 pb-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data.data_type == "varying_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "varying_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      <span className="v-small fw-bold ps-2 text-darkgray">Varying Data</span>
                    </label>
                  </div>
                </div> */}
              </div>
            ) : null
          )}
        </div>

        <div className="ms-auto top-scroll-btn w-100">
          <button
            className="btn round-radius btn-primary"
            onClick={scrollToTop}
            style={{ display: visible ? "block" : "none" }}
          >
            <MaterialIcons name="arrow-upward" size={20} color="white" />
          </button>
        </div>

        {/* <div className="row"> */}
        {/* Common Fields */}
        {/* <div className="pt-3">
            <Accordion
              // expanded={expanded == "panel1"}
              expanded={true}
              onChange={(event, newExpanded) => {
                SetExpanded(newExpanded ? "panel1" : false);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <div className="title fw-bold">Common Fields</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row cursor">
                  {common_data.map((data, index) =>
                    data.name != "Product Name" &&
                    data.name != "Product Description" ? (
                      data.hasOwnProperty("addon") ? null : (
                        <div className="col-md-4 col-lg-3 pb-2" key={index}>
                          <label className="pt-2 cursor">
                            <input
                              type="checkbox"
                              disabled={data.disabled}
                              checked={data.checked}
                              onChange={() => {
                                console.log("common_onchange");
                                common_onchange(index);
                              }}
                            />

                            <small
                              className={
                                data.disabled ? "ms-2" : "ms-2 text-darkgray"
                              }
                            >
                              {data.name}
                            </small>
                          </label>
                        </div>
                      )
                    ) : null
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}
        {/* Variant Fields */}
        {/* <div className="pt-3">
            <Accordion
              // expanded={expanded == "panel2"}
              expanded={true}
              onChange={(event, newExpanded) => {
                SetExpanded(newExpanded ? "panel2" : false);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
              >
                <div className="title fw-bold">Variant Fields</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row cursor">
                  {variant_data.map((data, index) =>
                    data.hasOwnProperty("addon") ? null : (data.type ==
                        "Select" ||
                        data.type == "Multi Select") &&
                      data.source_type != "product_details" ? (
                      <div className="col-md-4 col-lg-3 pb-2" key={index}>
                        <label className="pt-2 cursor">
                          <input
                            type="checkbox"
                            disabled={data.disabled}
                            checked={data.checked}
                            onChange={() => {
                              console.log("variant_onchange");
                              variant_onchange(index);
                            }}
                          />
                          <small
                            className={
                              data.disabled ? "ms-2" : "ms-2 text-darkgray"
                            }
                          >
                            {data.name}
                          </small>
                        </label>
                      </div>
                    ) : null
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}
        {/* Varying Fields */}
        {/* {show_varying ? ( */}
        {/* <div className="pt-3 pb-2">
            <Accordion
              // expanded={expanded == "panel3"}
              expanded={true}
              onChange={(event, newExpanded) => {
                SetExpanded(newExpanded ? "panel3" : false);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
              >
                <div className="title fw-bold">Varying Fields</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row cursor">
                  {varying_data.map((data, index) =>
                    data.hasOwnProperty("addon") ? null : (
                      <div className="col-md-4 col-lg-3 pb-2" key={index}>
                        <label className="pt-2 cursor">
                          <input
                            type="checkbox"
                            disabled={data.disabled}
                            checked={data.checked}
                            onChange={() => {
                              console.log("varying_onchange");
                              varying_onchange(index);
                            }}
                          />
                          <small
                            className={
                              data.disabled ? "ms-2" : "ms-2 text-darkgray"
                            }
                          >
                            {data.name}
                          </small>
                        </label>
                      </div>
                    )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}
        {/* ) : null} */}
        {/* </div> */}
      </div>
    );
  };

  async function get_init_pre_specifications() {
    let pass_data = {
      get: {
        category_id: basic_details.selected_category.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let res_data = await api("/product/init_specifications", pass_data);
    console.log("/product/init_specifications response  :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("data")) {
        SetPreSpecData(res_data.response.data);
        SetCommonData(res_data.response.data);
        SetVariantData(res_data.response.data);
        SetVaryingData(res_data.response.data);
        SetInitSpecData(res_data.response.data);
        SetSpecTypeOptions(res_data.response.options.type);
      }
      if (res_data.response.hasOwnProperty("options")) {
        SetSpecTypeOptions(res_data.response.options.type);
      }
    }
  }

  const CommonDataComponent = (child_props) => {
    // const [common_data, SetCommonData] = useState(child_props.common_data)
    const [common_data, SetCommonData] = useState([]);

    useEffect(() => {
      // if (child_props.common_data) {
      //   validate(child_props.common_data)
      // }
      // validate(child_props.common_data)
      if (child_props.active_step == 3) {
        console.log("CommonDataComponent active_step == 3 :", child_props);
        SetCommonData(child_props.common_data);
      }
    }, [child_props]);

    useEffect(() => {
      if (common_data.length > 0) {
        console.log("CommonDataComponent common_data on change :", common_data);
      }
    }, [common_data]);

    function on_select(data, actionMeta, index, ele) {
      console.log("on_select data :", data);
      console.log("on_select actionMeta :", actionMeta);
      console.log("on_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }

      if (actionMeta.action == "create-option") {
        let pass_value = {
          created_data: data,
          ele: ele,
          index: index,
        };
        add_spec_item(pass_value);
        // SetCommonData((prevValue: any) => {
        //   prevValue[index].value = data;
        //   return [...prevValue];
        // });
      }

      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = [];
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
    }

    async function add_spec_item(get_value: any) {
      let pass_data = {
        get: {},
        post: {
          specification: [
            {
              id: get_value.ele.id,
              items: [{ name: get_value.created_data.value }],
            },
          ],
          init: 1,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("add_spec_item create_specification pass_data :", pass_data);
      let res_data = await api("/product/create_specification", pass_data);
      console.log("add_spec_item create_specification res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success("Specification Item Created");
          if (res_data.response.hasOwnProperty("data")) {
            SetCommonData((prevValue: any) => {
              prevValue[get_value.index].children =
                res_data.response.data[0].children;
              // prevValue[index].value = get_value.created_data;
              validate(prevValue, get_Value.index, "one");
              return [...prevValue];
            });
          }
        }
      }
    }

    function on_multi_select(data, actionMeta, index) {
      console.log("on_multi_select data :", data);
      console.log("on_multi_select actionMeta :", actionMeta);
      console.log("on_multi_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
      if (actionMeta.action == "create-option") {
        let pass_value = {
          created_data: data,
          ele: ele,
        };
        add_spec_item(pass_value);
        // SetCommonData((prevValue: any) => {
        //   prevValue[index].value = data;
        //   return [...prevValue];
        // });
      }
      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
      if (actionMeta.action == "remove-value") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
    }

    function linked_on_select(data, actionMeta, index) {
      console.log("linked_on_select data :", data);
      console.log("linked_on_select actionMeta :", actionMeta);
      console.log("linked_on_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }

      if (actionMeta.action == "create-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }

      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = [];
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
    }

    function linked_on_multi_select(data, actionMeta, index) {
      console.log("linked_on_multi_select data :", data);
      console.log("linked_on_multi_select actionMeta :", actionMeta);
      console.log("linked_on_multi_select index :", index);
      SetCommonData((prevValue: any) => {
        prevValue[index].linked.value = data;
        validate(prevValue, index, "one");
        return [...prevValue];
      });
    }

    function validate(data, index_value, type_value) {
      let common_data_value = data;
      common_data_value.map((ele: any, e_index: any) => {
        if (type_value == "all") {
          if (ele.type == "Textarea") {
            if (!ele.value) {
              ele.error = "Text Area Value Required";
            } else {
              ele.error = "";
            }
          }
          if (ele.type == "Text Field") {
            if (ele.hasOwnProperty("linked")) {
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (!ele.value) {
                ele.error = "Text Field Value Required";
              } else {
                ele.error = "";
              }
            }
          }
          if (ele.type == "Select") {
            if (ele.hasOwnProperty("linked")) {
              if (Object.keys(ele.value).length == 0) {
                ele.error = "Select Value Required";
              } else {
                ele.error = "";
              }
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (Object.keys(ele.value).length == 0) {
                ele.error = "Select Value Required";
              } else {
                ele.error = "";
              }
            }
          }
          if (ele.type == "Multi Select") {
            if (ele.hasOwnProperty("linked")) {
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (ele.value.length == 0) {
                ele.error = "Multi Select Value Required";
              } else {
                ele.error = "";
              }
            }
          }
        }

        if (type_value == "one") {
          if (e_index == index_value) {
            if (ele.type == "Textarea") {
              if (!ele.value) {
                ele.error = "Text Area Value Required";
              } else {
                ele.error = "";
              }
            }
            if (ele.type == "Text Field") {
              if (ele.hasOwnProperty("linked")) {
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (!ele.value) {
                  ele.error = "Text Field Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
            if (ele.type == "Select") {
              if (ele.hasOwnProperty("linked")) {
                if (Object.keys(ele.value).length == 0) {
                  ele.error = "Select Value Required";
                } else {
                  ele.error = "";
                }
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (Object.keys(ele.value).length == 0) {
                  ele.error = "Select Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
            if (ele.type == "Multi Select") {
              if (ele.hasOwnProperty("linked")) {
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (ele.value.length == 0) {
                  ele.error = "Multi Select Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
          }
        }
      });
      console.log("common_data_value :", common_data_value);
      SetCommonData((prevValue: any) => {
        prevValue = common_data_value;
        return [...prevValue];
      });
      if (common_data_value.some((ele) => ele.error)) {
        return false;
      }
      return true;
    }

    return (
      <div className="mb-5 px-sm-0 px-md-3">
        {/* Button */}
        <div className="d-flex mb-3 align-items-center">
          <button
            className="btn bg-fff btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "common_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Common Data</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              console.log("common_data :", common_data);

              if (validate(common_data, 0, "all")) {
                console.log("Validation ok");
                let data = {
                  from: "common_data",
                  active_step: child_props.active_step,
                  data: common_data,
                };
                child_props.onNext(data);
              } else {
                console.log("Validation not ok");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="py-2 mb-2 d-md-none d-sm-block">
          <h6>Common Data</h6>
        </div>
        <div className="row p-3">
          {common_data.map((ele: any, index: any) => (
            <div className="pt-3 col-lg-4 col-md-6" key={index}>
              <label className="form-label">{ele.name}</label>
              {ele.type == "Text Field" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={ele.value}
                      className="form-control"
                      placeholder="Enter here..."
                      onChange={(e) => {
                        console.log(
                          " on change common e text field :",
                          e.target.value
                        );
                        console.log(" ele.linked :", ele.linked);

                        SetCommonData((prevValue: any) => {
                          prevValue[index].value = e.target.value;
                          validate(prevValue, index, "one");

                          return [...prevValue];
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      {ele.linked.type == "Select" ? (
                        <Select
                          onChange={(data_value, actionMeta) => {
                            linked_on_select(data_value, actionMeta, index);
                            console.log(
                              "ele linked child :",
                              ele.linked.children
                            );
                            // validate(prevValue, index, 'one')

                            console.log("ele linked value :", ele.linked.value);
                          }}
                          options={ele.linked.children}
                          tabSelectsValue={false}
                          value={ele.linked.value}
                          menuPortalTarget={document.body}
                          placeholder="Select..."
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({ ...base, border: "none" }),
                            indicatorSeparator: (base) => ({
                              ...base,
                              backgroundColor: "none",
                            }),
                          }}
                          className="no-radius border-none"
                        />
                      ) : (
                        <Select
                          isMulti
                          onChange={(data_value, actionMeta) => {
                            linked_on_multi_select(
                              data_value,
                              actionMeta,
                              index
                            );
                            // validate(prevValue, index, 'one')
                          }}
                          options={ele.linked.children}
                          value={ele.linked.value}
                          menuPortalTarget={document.body}
                          placeholder="Select..."
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          className="no-radius border-none"
                        />
                      )}
                    </div>
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <>
                    <input
                      type="text"
                      value={ele.value}
                      className="form-control"
                      placeholder="Enter here..."
                      onChange={(e) => {
                        console.log(
                          " on change common e text field :",
                          e.target.value
                        );
                        SetCommonData((prevValue: any) => {
                          prevValue[index].value = e.target.value;
                          validate(prevValue, index, "one");
                          return [...prevValue];
                        });
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </>
                )
              ) : null}

              {ele.type == "Textarea" ? (
                <>
                  <textarea
                    value={ele.value}
                    className="form-control"
                    placeholder="Enter here..."
                    onChange={(e) => {
                      console.log(
                        " on change common e Textarea :",
                        e.target.value
                      );
                      SetCommonData((prevValue: any) => {
                        prevValue[index].value = e.target.value;
                        validate(prevValue, index, "one");
                        return [...prevValue];
                      });
                    }}
                  />
                  <span className="text-danger">{ele.error}</span>
                </>
              ) : null}

              {ele.type == "Select" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="d-flex">
                    <div className="">
                      <CreatableSelect
                        isClearable
                        value={ele.value}
                        onChange={(data_value, actionMeta) => {
                          on_select(data_value, actionMeta, index, ele);
                        }}
                        options={ele.children}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        placeholder="Select..."
                      />
                    </div>
                    {ele.linked.type == "Select" ? (
                      <Select
                        onChange={(data_value, actionMeta) => {
                          linked_on_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        placeholder="Select..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        isMulti
                        onChange={(data_value, actionMeta) => {
                          linked_on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        placeholder="Select..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <div className="">
                    {ele.slug == "product_brand" ||
                    ele.slug == "country_of_orgin" ? (
                      <>
                        <Select
                          isClearable
                          value={ele.value}
                          onChange={(data_value, actionMeta) => {
                            console.log("on select ele :", ele);
                            on_select(data_value, actionMeta, index, ele);
                          }}
                          options={ele.children}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <span className="text-danger">{ele.error}</span>
                      </>
                    ) : (
                      <>
                        <CreatableSelect
                          isClearable
                          value={ele.value}
                          onChange={(data_value, actionMeta) => {
                            console.log("on select ele :", ele);
                            on_select(data_value, actionMeta, index, ele);
                          }}
                          options={ele.children}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <span className="text-danger">{ele.error}</span>
                      </>
                    )}
                  </div>
                )
              ) : null}

              {ele.type == "Multi Select" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="d-flex">
                    <div className="">
                      <CreatableSelect
                        isClearable
                        isMulti
                        value={ele.value}
                        onChange={(data_value, actionMeta) => {
                          on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.children}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                    {ele.linked.type == "Select" ? (
                      <Select
                        onChange={(data_value, actionMeta) => {
                          linked_on_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        isMulti
                        onChange={(data_value, actionMeta) => {
                          linked_on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <div className="">
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={ele.value}
                      onChange={(data_value, actionMeta) => {
                        on_multi_select(data_value, actionMeta, index);
                      }}
                      options={ele.children}
                      menuPortalTarget={document.body}
                      placeholder="Select..."
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </div>
                )
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const VariantDataComponent = (child_props) => {
    const [variant_data, SetVariantData] = useState([]);

    useEffect(() => {
      if (child_props.active_step == 4) {
        console.log("VariantDataComponent active_step == 4 :", child_props);
        SetVariantData(child_props.variant_data);
      }
    }, [child_props]);

    useEffect(() => {
      if (variant_data.length > 0) {
        console.log(
          "VariantDataComponent variant_data on change :",
          variant_data
        );
      }
    }, [variant_data]);

    function variant_on_select(data, actionMeta, index, ele) {
      if (actionMeta.action == "create-option") {
        // create_child(ele.id, actionMeta.option.value, index);
        let pass_value = {
          created_data: data,
          ele: ele,
          index: index,
        };
        add_spec_item(pass_value);
      } else {
        SetVariantData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue);
          console.log(
            "variant_on_select SetVariantData prevValue :",
            prevValue
          );
          return [...prevValue];
        });
      }
    }

    async function create_child(group_id_value, spec_item_value, index) {
      let pass_data = {
        get: {},
        post: {
          specification_group_id: group_id_value,
          specification_item: spec_item_value,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("create_child pass_data :", pass_data);
      let res_data = await api(
        "/product/add_pre_specification_item",
        pass_data
      );
      console.log("res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.hasOwnProperty("id")) {
          SetVariantData((prevValue: any) => {
            let child_value = prevValue[index].children;
            child_value.push(res_data.response);
            prevValue[index].children = child_value;
            validate(prevValue);
            return [...prevValue];
          });
        }
      }
    }

    async function add_spec_item(get_value: any) {
      console.log("add_spec_item Get value :", get_value);
      let created_options = {
        name: "",
      };
      get_value.created_data.map((ele: any) => {
        if (ele.label == ele.value) {
          created_options.name = ele.label;
        }
      });
      let pass_data = {
        get: {},
        post: {
          specification: [
            {
              id: get_value.ele.id,
              items: [{ name: created_options.name }],
            },
          ],
          init: 1,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("add_spec_item create_specification pass_data :", pass_data);
      let res_data = await api("/product/create_specification", pass_data);
      console.log("add_spec_item create_specification res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success("Specification Item Created");
          if (res_data.response.hasOwnProperty("data")) {
            SetVariantData((prevValue: any) => {
              prevValue[get_value.index].children =
                res_data.response.data[0].children;
              validate(prevValue);
              return [...prevValue];
            });
          }
        }
      }
    }

    function validate(data) {
      let variant_data_value = data;
      variant_data_value.map((ele: any) => {
        if (ele.value.length == 0) {
          ele.error = "Value required";
          console.log("variant_data_value ele :", ele);
          console.log("variant_data_value ele.value.length == 0 :");
        } else {
          console.log("variant_data_value ele :", ele);
          console.log("variant_data_value ele.value.length > 0 :");
          ele.error = "";
        }
      });
      // SetVariantData(variant_data_value)
      SetVariantData((prevValue: any) => {
        prevValue = variant_data_value;
        return [...prevValue];
      });
      if (variant_data_value.every((ele) => ele.value.length > 0)) {
        return true;
      }
      return false;
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn bg-fff btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "variant_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Variant Data</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              console.log("variant data :", variant_data);

              if (validate(variant_data)) {
                console.log("validate ok");
                let data = {
                  active_step: child_props.active_step,
                  from: "variant_data",
                  data: variant_data,
                };
                child_props.onNext(data);
              } else {
                console.log("validate not ok");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="d-md-none d-sm-block py-2 mb-2">
          <h6>Variant Data</h6>
        </div>
        <div className="row">
          {variant_data.map((ele: any, index: any) => (
            <div className="col-md-6  pt-3" key={index}>
              <div className="pb-3 row">
                <label className="col-7">{ele.name}</label>

                <div className="col-5">
                  <label className="cursor end mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      onChange={(event) => {
                        if (event.target.checked) {
                          SetVariantData((prevValue: any) => {
                            prevValue[index].value = prevValue[index].children;
                            validate(prevValue);

                            return [...prevValue];
                          });
                        } else {
                          SetVariantData((prevValue: any) => {
                            prevValue[index].value = [];
                            validate(prevValue);

                            return [...prevValue];
                          });
                        }

                        console.log("select all :", variant_data);
                      }}
                      checked={ele.value.length == ele.children.length}
                    />
                    <span className="v-small text-dark">Select all</span>
                  </label>
                </div>
              </div>
              {ele.hasOwnProperty("strict") ? (
                ele.strict ? (
                  <>
                    <CreatableSelect
                      isSearchable={false}
                      isMulti
                      onChange={(data_value, actionMeta) => {
                        variant_on_select(data_value, actionMeta, index, ele);
                        console.log("strict data_value :", data_value);
                      }}
                      options={ele.children}
                      value={ele.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </>
                ) : (
                  <>
                    <CreatableSelect
                      isMulti
                      isSearchable={false}
                      onChange={(data_value, actionMeta) => {
                        variant_on_select(data_value, actionMeta, index, ele);
                        console.log("strict else data_value :", data_value);
                      }}
                      options={ele.children}
                      value={ele.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </>
                )
              ) : (
                <>
                  <CreatableSelect
                    isMulti
                    isSearchable={false}
                    onChange={(data_value, actionMeta) => {
                      variant_on_select(data_value, actionMeta, index, ele);
                      console.log("no strict else data_value :", data_value);
                    }}
                    options={ele.children}
                    value={ele.value}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  <span className="text-danger">{ele.error}</span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const AddonDataComponent = (child_props) => {
    function on_next_addon() {
      console.log("addon data :", addon_details);
      let data = {
        active_step: child_props.active_step,
        from: "addon_data",
      };
      child_props.onNext(data);
    }

    function get_dependent_name(get_data: any) {
      let return_value = "";
      get_data.seleceted_addon_group_form.varriant_data.map((ele: any) => {
        if (ele.id == get_data.dependent_id) {
          return_value = ele.name;
        }
      });
      return return_value;
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn bg-fff btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "addon_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Addon Data</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              // let data = {
              //   from: "variant_data",
              //   active_step: child_props.active_step,
              // };
              // child_props.onNext(data);
              console.log("Addon data :", addon_details.addon_data);
              on_next_addon();
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        {/* header */}
        <div className="d-flex align-items-center py-2 mb-2">
          <div className="d-md-none d-sm-block">
            <h6>Addon Data</h6>
          </div>
          {/* <div
            className="end text-primary cursor fw-bold"
            onClick={() => {
              SetModalAddon({
                is_open: true,
                category: basic_details.selected_category,
              })
              console.log('Add Addon')
            }}
          >
            <div className="me-1">
              <Entypo name="plus" size={16} color="#0053ad" />
            </div>
            Add addons
          </div> */}
        </div>
        {/* Body */}
        <div className="row px-1">
          {addon_details.addon_data.map((addon_ele: any, addon_index: any) => (
            <div className="col-md-4 col-lg-3 p-2" key={addon_index}>
              <div className="p-3 card border">
                <div className="d-flex">
                  <div className="">
                    <h6>{addon_ele.label}</h6>
                  </div>
                  <div className="ms-auto cursor">
                    <p
                      onClick={() => {
                        SetAddonDetails((prevValue: any) => {
                          prevValue.addon_data.splice(addon_index, 1);
                          return { ...prevValue };
                        });
                      }}
                    >
                      <Ionicons name="ios-close" size={20} color="black" />
                      {/* Remove */}
                    </p>
                  </div>
                </div>

                {addon_ele.is_dependent ? (
                  <div className="row">
                    <p className="text-dark border-bottom">
                      Dependent on {get_dependent_name(addon_ele)}
                    </p>
                    {addon_ele.children.map((child, i) => (
                      <div key={i} className="col-12 ">
                        <p className="my-2 text-darkgray font-weight-bold">
                          {child.name}
                        </p>
                        <div>
                          {/* <div className="p-0">
                            {child.varriant.map((v_ele, v_index) => (
                              <div key={v_index} className="mb-2">
                                <p className="fw-bold text-darkgray mb-0">{v_ele.label}</p>
                                {v_ele.hasOwnProperty("mrp") &&
                                  Array.isArray(v_ele.mrp)
                                  ? v_ele.mrp.map(
                                    (mrp_ele: any, mrp_index: any) => (
                                      <span className="pl-2" key={mrp_index}>
                                        # {mrp_ele.price}
                                      </span>
                                    )
                                  )
                                  : null}
                              </div>
                            ))}
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    {addon_ele.children.map((child, i) => (
                      <div className="col-12 " key={i}>
                        <p className="my-2 text-darkgray font-weight-bold">
                          {child.name}
                        </p>
                        {/* <div className="py-3">
                          {child.mrp.map((mrp_ele: any, mrp_index: any) => (
                            <div>
                              <div className="row align-items-center mb-2" key={mrp_index}>
                                <div className="col-1 pe-0 ps-1">
                                  <img src={require("../../assets/icon/earth.png")} className="cat_img" />
                                </div>
                                <div className="col-11 ps-2">
                                  <p className="mb-0 text-black">{mrp_ele.country}, {mrp_ele.state}</p>
                                </div>
                              </div>
                              {mrp_ele.hasOwnProperty("state") ? (
                                    <p className="mb-2">
                                      State : {mrp_ele.state}
                                    </p>
                                  ) : null}
                              <p className="mb-2">MRP : {mrp_ele.price}</p>
                            </div>
                          ))}
                        </div> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}

          <div className="mt-2 py-2 center">
            <button
              className="btn btn-page center w-auto text-primary fw-bold"
              onClick={() => {
                SetModalAddon({
                  is_open: true,
                  category: basic_details.selected_category,
                });
                console.log("Add Addon");
              }}
            >
              <div className="me-1">
                <Ionicons name="ios-add-outline" size={16} color="#0053ad" />
              </div>
              Add addons
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ReviewComponent = (child_props: any) => {
    // const [combi_data, SetCombiData] = useState(
    //   child_props.combination_data_value,
    // )
    const [combi_data, SetCombiData] = useState([]);
    const [modal_image, SetModalImage] = useState({
      is_open: false,
      index: "",
      selected_images: [],
    });
    const [modal_review_addon, SetModalReviewAddon] = useState({
      is_open: false,
      index: "",
      data: "",
    });
    const [modal_confirm_addon, SetModalConfirmAddon] = useState({
      is_open: false,
    });
    const [modal_mrp, SetModalMrp] = useState({
      is_open: false,
      index: "",
      data: "",
      mrp_data_value: [],
    });

    useEffect(() => {
      if (child_props.active_step == 6) {
        console.log("ReviewComponent active_step == 6 :", child_props);
        console.log("ReviewComponent deleted_combi_row :", deleted_combi_row);
        // SetCombiData(child_props.combination_data_value)
        // if(review_is_first_time) {

        SetCombiData((prevValue: any) => {
          child_props.combination_data_value.map((ele: any, ele_index: any) => {
            Object.keys(ele.row_data).map(
              (ele_key: any, ele_key_index: any) => {
                // console.log('ele_key_index :', ele_key_index)
                console.log("ele_key :", ele_key);
                if (ele.row_data[ele_key].hasOwnProperty("field_type")) {
                  if (!ele.row_data[ele_key].hasOwnProperty("value")) {
                    ele.row_data[ele_key].error = "Required";
                  } else {
                    if (typeof ele.row_data[ele_key].value == "string") {
                      if (ele.row_data[ele_key].value == "") {
                        ele.row_data[ele_key].error = "Required";
                      } else {
                        ele.row_data[ele_key].error = "";
                      }
                    }
                    if (typeof ele.row_data[ele_key].value == "object") {
                      if (Array.isArray(ele.row_data[ele_key].value)) {
                        if (ele.row_data[ele_key].value.length == 0) {
                          ele.row_data[ele_key].error = "Required";
                        } else {
                          ele.row_data[ele_key].error = "";
                        }
                      } else {
                        if (
                          Object.keys(ele.row_data[ele_key].value).length == 0
                        ) {
                          ele.row_data[ele_key].error = "Required";
                        } else {
                          ele.row_data[ele_key].error = "";
                        }
                      }
                    }
                  }
                  if (ele.row_data[ele_key].is_linked) {
                    if (!ele.row_data[ele_key].hasOwnProperty("linked_value")) {
                      ele.row_data[ele_key].linked_error = "Unit Required";
                    } else {
                      if (typeof ele.row_data[ele_key].value == "string") {
                        if (ele.row_data[ele_key].value == "") {
                          ele.row_data[ele_key].linked_error = "Unit Required";
                        } else {
                          ele.row_data[ele_key].linked_error = "";
                        }
                      }
                      if (typeof ele.row_data[ele_key].value == "object") {
                        if (Array.isArray(ele.row_data[ele_key].value)) {
                          if (ele.row_data[ele_key].value.length == 0) {
                            ele.row_data[ele_key].linked_error =
                              "Unit Required";
                          } else {
                            ele.row_data[ele_key].linked_error = "";
                          }
                        } else {
                          if (
                            Object.keys(ele.row_data[ele_key].value).length == 0
                          ) {
                            ele.row_data[ele_key].linked_error =
                              "Units Required";
                          } else {
                            ele.row_data[ele_key].linked_error = "";
                          }
                        }
                      }
                    }
                  }
                }
              }
            );
          });
          child_props.combination_data_value.map((ele: any, index: any) => {
            let row_value = [];
            Object.keys(ele.row_data).map((key: any, key_index: any) => {
              if (ele.row_data[key].type == "varying_data") {
                row_value.push(have_error_check(ele.row_data[key]));
                console.log(
                  "Have Error :",
                  have_error_check(ele.row_data[key])
                );
                console.log("ele.row_data[key] :", ele.row_data[key]);
              }
            });
            console.log("row_value :", row_value);
            if (row_value.every((ele) => ele == false)) {
              ele.completed = true;
            } else {
              ele.completed = false;
            }
          });
          console.log(
            "on validate SetCombiData child_props.combination_data_value :",
            child_props.combination_data_value
          );
          // if (prevValue.every((ele) => ele.completed)) {
          //   // validate_value = true
          //   console.log('validate ok')
          //   if (child_props.CustomAddonData.length > 0) {
          //     if (prevValue.every((ele) => !ele.addon_value)) {
          //       console.log(
          //         'Addon Is Selected But Not Added to atleast one open modal',
          //       )
          //       SetModalConfirmAddon((prevValue: any) => {
          //         prevValue.is_open = true
          //         return { ...prevValue }
          //       })
          //     } else {
          //       console.log('Create Product Submit')
          //       let data = {
          //         from: 'review_data',
          //         active_step: child_props.active_step,
          //         data: prevValue,
          //         // data: combi_data,
          //       }
          //       child_props.onSubmit(data)
          //     }
          //   }
          // } else {
          //   // validate_value = false
          //   console.log('validate not ok')
          // }
          SetReviewIsFirstTime(false);

          return [...child_props.combination_data_value];
        });
        // }
      }
    }, [child_props]);

    useEffect(() => {
      if (combi_data.length > 0) {
        console.log("ReviewComponent combi_data on change :", combi_data);
        console.log("ReviewComponent child_props :", child_props);
      }
    }, [combi_data]);

    function row_on_delete(index) {
      console.log("ReviewComponent row_on_delete index :", index);
      SetCombiData((prevValue: any) => {
        console.log("on deleted row value :", prevValue[index]);

        if (!prevValue[index].default) {
          // SetDeletedCombiRow((d_value:any,d_index:any)=>{
          //   d_value.push(prevValue[index])
          //   console.log("SetDeletedCombiRow d_value :", d_value)
          //   return [...d_value]
          // })
          prevValue = prevValue.filter((_, i) => i != index);
        } else {
          toast.error("Cannot Delete Default Varriant");
        }
        console.log("prevValue on row delete :", prevValue);
        return [...prevValue];
      });
    }

    function on_add_addon(index) {
      console.log("ReviewComponent on_add_addon index :", index);
      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.data = {
          type: "add",
          addon: child_props.CustomAddonData,
          checked: [],
        };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    function on_view_addon(index, checked_value) {
      console.log("ReviewComponent on_view_addon index :", index);
      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.data = {
          type: "view",
          addon: child_props.CustomAddonData,
          checked: checked_value,
        };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    function on_addon_delete(index) {
      console.log("ReviewComponent on_addon_delete :", index);

      SetCombiData((prevValue: any) => {
        prevValue[index].addon_value = false;
        prevValue[index].addon = "";
        console.log(
          "ReviewComponent on_addon_delete SetCombiData prevValue :",
          prevValue
        );

        return [...prevValue];
      });
    }

    function on_edit_addon(index, checked_value) {
      console.log("ReviewComponent on_edit_addon index :", index);
      console.log(
        "ReviewComponent on_edit_addon checked_value :",
        checked_value
      );
      // var dependant_addon_value = [];
      // addon_details.addon_data
      //   .filter((addon_element) => addon_element.dependent == true)
      //   .map((addon_filter_element) => {
      //     dependant_addon_value.push(addon_filter_element);
      //   });

      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        prevValue.data = {
          type: "edit",
          addon: child_props.CustomAddonData,
          checked: checked_value,
        };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    function on_remove_addon(index, row_data) {
      console.log("ReviewComponent on_remove_addon index :", index);
      console.log("ReviewComponent on_remove_addon row_data :", row_data);
      SetCombiData((prevValue: any) => {
        prevValue[index] = row_data;
        return [...prevValue];
      });
    }

    function on_addon_change(index, row_data) {
      console.log("ReviewComponent on_addon_change index :", index);
      console.log("ReviewComponent on_addon_change row_data :", row_data);
      SetCombiData((prevValue: any) => {
        prevValue[index] = row_data;
        return [...prevValue];
      });
    }

    function on_image_select(data) {
      console.log("ReviewComponent on_image_select data :", data);
      SetModalImage((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        prevValue.index = data.index;
        prevValue.selected_images = data.selected_img;
        return { ...prevValue };
      });
    }

    function have_error_check(row_value) {
      if (row_value.is_linked) {
        if (row_value.field_type == "Text Field") {
          if (
            row_value.hasOwnProperty("value") &&
            row_value.hasOwnProperty("linked_value")
          ) {
            if (row_value.linked_field_type == "Select") {
              if (row_value.value != "" && row_value.linked_value != {}) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }

            if (row_value.linked_field_type == "Multi Select") {
              if (row_value.value != "" && row_value.linked_value.length > 0) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }

        if (row_value.field_type == "Textarea") {
          if (
            row_value.hasOwnProperty("value") &&
            row_value.hasOwnProperty("linked_value")
          ) {
            if (row_value.linked_field_type == "Select") {
              if (row_value.value != "" && row_value.linked_value != {}) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }

            if (row_value.linked_field_type == "Multi Select") {
              if (row_value.value != "" && row_value.linked_value.length > 0) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }

        if (row_value.field_type == "Select") {
          if (
            row_value.hasOwnProperty("value") &&
            row_value.hasOwnProperty("linked_value")
          ) {
            if (row_value.linked_field_type == "Select") {
              if (row_value.value != {} && row_value.linked_value != {}) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }

            if (row_value.linked_field_type == "Multi Select") {
              if (row_value.value != {} && row_value.linked_value.length > 0) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }

        if (row_value.field_type == "Multi Select") {
          if (
            row_value.hasOwnProperty("value") &&
            row_value.hasOwnProperty("linked_value")
          ) {
            if (row_value.linked_field_type == "Select") {
              if (row_value.value.length > 0 && row_value.linked_value != {}) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }

            if (row_value.linked_field_type == "Multi Select") {
              if (
                row_value.value.length > 0 &&
                row_value.linked_value.length > 0
              ) {
                if (row_value.error == "" && row_value.linked_error == "") {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }

        // return false
      } else {
        if (row_value.field_type == "Text Field") {
          if (row_value.hasOwnProperty("value")) {
            if (row_value.value != "") {
              if (row_value.error == "") {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
        if (row_value.field_type == "Textarea") {
          if (row_value.hasOwnProperty("value")) {
            if (row_value.value != "") {
              if (row_value.error == "") {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
        if (row_value.field_type == "Select") {
          if (row_value.hasOwnProperty("value")) {
            if (row_value.value != {}) {
              if (row_value.error == "") {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
        if (row_value.field_type == "Multi Select") {
          if (row_value.hasOwnProperty("value")) {
            if (row_value.value.length > 0) {
              if (row_value.error == "") {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }

    function on_text_box_change(data_value1, type) {
      console.log("ReviewComponent on_text_box_change :", data_value1);
      // let index = data_value1.send_data.index;
      // let head = data_value1.send_data.head;
      // let value = data_value1.send_data.value;
      if (type == "textbox") {
        SetCombiData((prevValue: any) => {
          prevValue[data_value1.index].row_data[data_value1.head].value =
            data_value1.value;
          if (data_value1.value == "") {
            prevValue[data_value1.index].row_data[data_value1.head].error =
              "Required";
          } else {
            prevValue[data_value1.index].row_data[data_value1.head].error = "";
          }
          prevValue.map((ele: any, index: any) => {
            let row_value = [];
            Object.keys(ele.row_data).map((key: any, key_index: any) => {
              if (ele.row_data[key].type == "varying_data") {
                row_value.push(have_error_check(ele.row_data[key]));
                console.log(
                  "Have Error :",
                  have_error_check(ele.row_data[key])
                );
                console.log("ele.row_data[key] :", ele.row_data[key]);
              }
            });
            console.log("row_value :", row_value);
            if (row_value.every((ele) => ele == false)) {
              ele.completed = true;
            } else {
              ele.completed = false;
            }
          });
          console.log(
            "SetCombiData prevValue on_text_box_change !data_value1.have_link textbox :",
            prevValue
          );
          return [...prevValue];
        });
      } else {
        SetCombiData((prevValue: any) => {
          prevValue[data_value1.index].row_data[data_value1.head].value =
            data_value1.value;
          if (data_value1.value == "") {
            prevValue[data_value1.index].row_data[data_value1.head].error =
              "Required";
          } else {
            prevValue[data_value1.index].row_data[data_value1.head].error = "";
          }
          prevValue.map((ele: any, index: any) => {
            let row_value = [];
            Object.keys(ele.row_data).map((key: any, key_index: any) => {
              if (ele.row_data[key].type == "varying_data") {
                row_value.push(have_error_check(ele.row_data[key]));
                console.log(
                  "Have Error :",
                  have_error_check(ele.row_data[key])
                );
                console.log("ele.row_data[key] :", ele.row_data[key]);
              }
            });
            console.log("row_value :", row_value);
            if (row_value.every((ele) => ele == false)) {
              ele.completed = true;
            } else {
              ele.completed = false;
            }
          });

          console.log(
            "SetCombiData prevValue on_text_box_change textarea :",
            prevValue
          );
          return [...prevValue];
        });
      }
    }

    function addon_modal_on_submit(pass_data) {
      console.log(
        "Review Addon modal on submit review page pass_data :",
        pass_data
      );
      console.log(
        "CustomAddonData on submit review page :",
        addon_details.addon_data
      );
      let add_data = addon_details.addon_data;
      let sa_data = [];
      let a1_data = [];
      pass_data.addon.map((p_a_ele, p_a_index) => {
        let parent = {};
        add_data.map((element, index) => {
          parent = {
            label: element.label,
            value: element.value,
            dependent_id: element.dependent_id,
            is_dependent: element.is_dependent,
            children: [],
          };
          let found = element.children.find((child) => child.id == p_a_ele);
          if (found) {
            parent.children.push(found);
            sa_data.push(parent);
          }
        });
      });

      sa_data.map((element, index) => {
        if (a1_data) {
          let found = a1_data.find((ele) => ele.value == element.value);
          if (found) {
            let index = a1_data.findIndex((a1) => a1.value == found.value);
            a1_data[index].children.push(element.children[0]);
          } else {
            a1_data.push(element);
          }
        } else {
          a1_data.push(element);
        }
      });
      console.log("a1_data :", a1_data);
      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = false;
        return { ...prevValue };
      });
      SetCombiData((prevValue: any) => {
        prevValue[pass_data.index].addon_value =
          pass_data.addon.length > 0 ? true : false;
        prevValue[pass_data.index].checked = pass_data.addon;
        prevValue[pass_data.index].addon = a1_data;
        console.log("SetCombiData prevValue :", prevValue);
        return [...prevValue];
      });
    }

    function save_mrp_data(data: any, index: any) {
      console.log("save_mrp_data data :", data);
      console.log("save_mrp_data index :", index);

      SetCombiData((prevValue: any) => {
        prevValue[index].mrp = data.mrp_data;
        console.log(
          "ReviewComponent save_mrp_data SetCombiData prevValue :",
          prevValue
        );
        return [...prevValue];
      });
    }

    function on_mrp(index) {
      console.log("ReviewComponent on_mrp index :", index);
      console.log("combi_data :", combi_data);
      console.log("combi_data[index].mrp :", combi_data[index].mrp);

      SetModalMrp((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.mrp_data_value =
          combi_data[index].mrp == undefined ? [] : combi_data[index].mrp;
        // prevValue.data = {
        //   type: "add",
        //   addon: child_props.CustomAddonData,
        // };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn bg-fff btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "review_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Products Review</h6>
          </div>
          <button
            className="btn bg-fff ms-auto btn-page align-items-center"
            onClick={() => {
              console.log("On Submit combi_data :", combi_data);
              let validate_value;
              SetCombiData((prevValue: any) => {
                prevValue.map((ele: any, ele_index: any) => {
                  // if (pass_index == ele_index) {
                  Object.keys(ele.row_data).map(
                    (ele_key: any, ele_key_index: any) => {
                      // console.log('ele_key_index :', ele_key_index)
                      console.log("ele_key :", ele_key);
                      if (ele.row_data[ele_key].hasOwnProperty("field_type")) {
                        if (!ele.row_data[ele_key].hasOwnProperty("value")) {
                          ele.row_data[ele_key].error = "Required";
                        } else {
                          if (typeof ele.row_data[ele_key].value == "string") {
                            if (ele.row_data[ele_key].value == "") {
                              ele.row_data[ele_key].error = "Required";
                            } else {
                              ele.row_data[ele_key].error = "";
                            }
                          }
                          if (typeof ele.row_data[ele_key].value == "object") {
                            if (Array.isArray(ele.row_data[ele_key].value)) {
                              if (ele.row_data[ele_key].value.length == 0) {
                                ele.row_data[ele_key].error = "Required";
                              } else {
                                ele.row_data[ele_key].error = "";
                              }
                            } else {
                              if (
                                Object.keys(ele.row_data[ele_key].value)
                                  .length == 0
                              ) {
                                ele.row_data[ele_key].error = "Required";
                              } else {
                                ele.row_data[ele_key].error = "";
                              }
                            }
                          }
                        }
                        if (ele.row_data[ele_key].is_linked) {
                          if (
                            !ele.row_data[ele_key].hasOwnProperty(
                              "linked_value"
                            )
                          ) {
                            ele.row_data[ele_key].linked_error =
                              "Unit Required";
                          } else {
                            if (
                              typeof ele.row_data[ele_key].value == "string"
                            ) {
                              if (ele.row_data[ele_key].value == "") {
                                ele.row_data[ele_key].linked_error =
                                  "Unit Required";
                              } else {
                                ele.row_data[ele_key].linked_error = "";
                              }
                            }
                            if (
                              typeof ele.row_data[ele_key].value == "object"
                            ) {
                              if (Array.isArray(ele.row_data[ele_key].value)) {
                                if (ele.row_data[ele_key].value.length == 0) {
                                  ele.row_data[ele_key].linked_error =
                                    "Unit Required";
                                } else {
                                  ele.row_data[ele_key].linked_error = "";
                                }
                              } else {
                                if (
                                  Object.keys(ele.row_data[ele_key].value)
                                    .length == 0
                                ) {
                                  ele.row_data[ele_key].linked_error =
                                    "Units Required";
                                } else {
                                  ele.row_data[ele_key].linked_error = "";
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  );
                  // }
                });
                prevValue.map((ele: any, index: any) => {
                  let row_value = [];
                  Object.keys(ele.row_data).map((key: any, key_index: any) => {
                    if (ele.row_data[key].type == "varying_data") {
                      row_value.push(have_error_check(ele.row_data[key]));
                      console.log(
                        "Have Error :",
                        have_error_check(ele.row_data[key])
                      );
                      console.log("ele.row_data[key] :", ele.row_data[key]);
                    }
                  });
                  console.log("row_value :", row_value);
                  if (row_value.every((ele) => ele == false)) {
                    ele.completed = true;
                  } else {
                    ele.completed = false;
                  }
                });
                console.log("on validate SetCombiData prevValue :", prevValue);
                if (prevValue.every((ele) => ele.completed)) {
                  // validate_value = true
                  console.log("validate ok");
                  if (child_props.CustomAddonData.length > 0) {
                    if (prevValue.every((ele) => !ele.addon_value)) {
                      console.log(
                        "Addon Is Selected But Not Added to atleast one open modal"
                      );
                      SetModalConfirmAddon((prevValue1: any) => {
                        prevValue1.is_open = true;
                        return { ...prevValue1 };
                      });
                    } else {
                      console.log("Create Product Submit");
                      let data = {
                        from: "review_data",
                        active_step: child_props.active_step,
                        data: prevValue,
                        // data: combi_data,
                      };
                      child_props.onSubmit(data);
                    }
                  } else {
                    console.log("Create Product Submit");
                    let data = {
                      from: "review_data",
                      active_step: child_props.active_step,
                      data: prevValue,
                      // data: combi_data,
                    };
                    child_props.onSubmit(data);
                  }
                } else {
                  // validate_value = false
                  console.log("validate not ok");
                }
                return [...prevValue];
              });
              if (validate_value) {
              } else {
              }
            }}
          >
            <MaterialCommunityIcons
              name="checkbox-marked-circle-outline"
              size={16}
              color="#0053ad"
            />
            <span className="ps-2 text-primary">Submit</span>
          </button>
        </div>

        {/* header */}
        <div className="py-3">
          <div className="d-md-none d-sm-block">
            <h6>Products Review</h6>
          </div>
        </div>
        <div className="row">
          {/* Table */}
          <div className="px-2">
            <div
              className="mt-3 table-responsive "
              style={{
                // maxWidth: "1200px",
                // maxHeight: "600px",
                overflowX: "scroll",
              }}
            >
              <table className="table table-hover">
                <thead className="sticky-top">
                  <tr className="d-flex">
                    <th scope="col" className="col-2 col-md-1 center">
                      Default
                    </th>
                    <th scope="col" className="col-2 col-md-1 center">
                      Completed
                    </th>
                    {combination_head.map((head, i) =>
                      head == "Image" ? (
                        <th scope="col" className="col-2 text-center" key={i}>
                          {head}
                        </th>
                      ) : (
                        <th scope="col" className="col-4 col-md-3" key={i}>
                          {head}
                        </th>
                      )
                    )}
                    {child_props.CustomAddonData.length > 0 ? (
                      <th scope="col" className="col-2 center">
                        Addon
                      </th>
                    ) : null}
                    {true ? (
                      <th scope="col" className="col-2 center">
                        Mrp
                      </th>
                    ) : null}
                    {combi_data.length > 1 ? (
                      <th scope="col" className="col-2 center">
                        Action
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody className="overflowY">
                  {/* {combination_row.length == 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <em>Loading...</em>
                    </td>
                  </tr>
                ) : ( */}
                  {combi_data.map((row, i) => (
                    <ProductAccordion
                      key={"product_accordion_" + i}
                      index={i}
                      data={row}
                      header={combination_head}
                      onDefaultChange={(data) => {
                        console.log("default on change");
                        // SetCombinationData((prevValue: any) => {
                        SetCombiData((prevValue: any) => {
                          prevValue.map((pre_ele: any, pre_index: any) => {
                            if (pre_index == data.index) {
                              pre_ele.default = true;
                            } else {
                              pre_ele.default = false;
                            }
                          });
                          return [...prevValue];
                        });
                      }}
                      onDelete={(data) => {
                        row_on_delete(data);
                      }}
                      onAddAddon={(data) => {
                        on_add_addon(data);
                      }}
                      onViewAddon={(index, checked_value) => {
                        on_view_addon(index, checked_value);
                      }}
                      onMrp={(data) => {
                        on_mrp(data);
                      }}
                      onEditAddon={(index, checked_value) => {
                        on_edit_addon(index, checked_value);
                      }}
                      onRemoveAddon={(data) => {
                        on_remove_addon(data);
                      }}
                      onAddonChange={(data) => {
                        on_addon_change(data);
                      }}
                      onAddonDelete={(data) => {
                        on_addon_delete(data);
                      }}
                      onImgSelect={(data) => {
                        on_image_select(data);
                      }}
                      onTextChange={(data) => {
                        console.log("index :", i);
                        console.log("row :", row);
                        on_text_box_change(data, "textbox");
                      }}
                      OnTextAreaChange={(data) => {
                        on_text_box_change(data, "textarea");
                      }}
                      mrp_on_save={(data, index) => {
                        console.log("mrp_on save data:", data);
                        console.log("mrp_on save index:", index);
                        save_mrp_data(data, index);
                      }}
                      OnLinkedSelectChange={(data) => {
                        console.log("OnLinkedSelectChange data :", data);
                        // const new_combi_data = [...combi_data]
                        // new_combi_data[data.index].row_data[
                        //   data.head
                        // ].all_data.linked.value = data.value
                        // new_combi_data[data.index].row_data[
                        //   data.head
                        // ].linked_value = data.value
                        // SetCombiData(new_combi_data)
                        SetCombiData((prevValue: any) => {
                          prevValue[data.index].row_data[
                            data.head
                          ].all_data.linked.value = data.value;
                          prevValue[data.index].row_data[
                            data.head
                          ].linked_value = data.value;
                          if (Object.keys(data.value).length > 0) {
                            prevValue[data.index].row_data[
                              data.head
                            ].linked_error = "";
                          } else {
                            prevValue[data.index].row_data[
                              data.head
                            ].linked_error = "Unit Required";
                          }
                          prevValue.map((ele: any, index: any) => {
                            let row_value = [];
                            Object.keys(ele.row_data).map(
                              (key: any, key_index: any) => {
                                if (ele.row_data[key].type == "varying_data") {
                                  row_value.push(
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "Have Error :",
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "ele.row_data[key] :",
                                    ele.row_data[key]
                                  );
                                }
                              }
                            );
                            console.log("row_value :", row_value);
                            if (row_value.every((ele) => ele == false)) {
                              ele.completed = true;
                            } else {
                              ele.completed = false;
                            }
                          });

                          return [...prevValue];
                        });
                      }}
                      OnSelectChange={(data) => {
                        console.log("OnSelectChange data :", data);
                        // const new_combi_data = [...combi_data]
                        // new_combi_data[data.index].row_data[data.head].value =
                        //   data.value
                        // SetCombiData(new_combi_data)
                        SetCombiData((prevValue: any) => {
                          prevValue[data.index].row_data[data.head].value =
                            data.value;
                          if (Object.keys(data.value).length > 0) {
                            prevValue[data.index].row_data[data.head].error =
                              "";
                          } else {
                            prevValue[data.index].row_data[data.head].error =
                              "Required";
                          }
                          prevValue.map((ele: any, index: any) => {
                            let row_value = [];
                            Object.keys(ele.row_data).map(
                              (key: any, key_index: any) => {
                                if (ele.row_data[key].type == "varying_data") {
                                  row_value.push(
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "Have Error :",
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "ele.row_data[key] :",
                                    ele.row_data[key]
                                  );
                                }
                              }
                            );
                            console.log("row_value :", row_value);
                            if (row_value.every((ele) => ele == false)) {
                              ele.completed = true;
                            } else {
                              ele.completed = false;
                            }
                          });
                          return [...prevValue];
                        });
                      }}
                      OnMultiSelectChange={(data) => {
                        console.log("OnMultiSelectChange data :", data);
                        // const new_combi_data = [...combi_data]
                        // new_combi_data[data.index].row_data[data.head].value =
                        //   data.value
                        // SetCombiData(new_combi_data)
                        SetCombiData((prevValue: any) => {
                          prevValue[data.index].row_data[data.head].value =
                            data.value;
                          if (data.value.length > 0) {
                            prevValue[data.index].row_data[data.head].error =
                              "";
                          } else {
                            prevValue[data.index].row_data[data.head].error =
                              "Required";
                          }
                          prevValue.map((ele: any, index: any) => {
                            let row_value = [];
                            Object.keys(ele.row_data).map(
                              (key: any, key_index: any) => {
                                if (ele.row_data[key].type == "varying_data") {
                                  row_value.push(
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "Have Error :",
                                    have_error_check(ele.row_data[key])
                                  );
                                  console.log(
                                    "ele.row_data[key] :",
                                    ele.row_data[key]
                                  );
                                }
                              }
                            );
                            console.log("row_value :", row_value);
                            if (row_value.every((ele) => ele == false)) {
                              ele.completed = true;
                            } else {
                              ele.completed = false;
                            }
                          });
                          return [...prevValue];
                        });
                      }}
                      product_addon={child_props.CustomAddonData}
                      avail_prod_name={avail_prod_name}
                      show_delete={combi_data.length > 1 ? true : false}
                    />
                  ))}
                  {/* )} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {modal_image.is_open ? (
          <ModalAddGallery
            open={modal_image.is_open}
            type={modal_image.type}
            data={modal_image.data}
            selected={modal_image.selected_images}
            from={"add_product"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalImage((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                console.log("ModalAddGallery on close save :", modal_image);
                SetCombiData((prevValue: any) => {
                  prevValue[modal_image.index].images = data.value;
                  return [...prevValue];
                });
              }
            }}
          />
        ) : null}

        {modal_mrp.is_open ? (
          <ModalReviewMrp
            open={modal_mrp.is_open}
            mrp_data_value={modal_mrp.mrp_data_value}
            index_value={modal_mrp.index}
            on_save={(data) => {
              SetCombiData((prevValue: any) => {
                data.mrp_data.map((ele: any) => {
                  prevValue[data.index].mrp.push(ele);
                });
                console.log(
                  "ReviewComponent on_save save_mrp_data SetCombiData prevValue :",
                  prevValue
                );
                return [...prevValue];
              });
              // SetModalMrp((pre: any) => {
              //   pre.mrp_data_value = data.mrp_data
              //   return { ...pre }
              // })
            }}
            on_remove={(data) => {
              SetCombiData((prevValue: any) => {
                prevValue[data.index].mrp = data.mrp_data;
                console.log(
                  "ReviewComponent on_remove save_mrp_data SetCombiData prevValue :",
                  prevValue
                );
                return [...prevValue];
              });
              // SetModalMrp((pre: any) => {
              //   pre.mrp_data_value = data.mrp_data
              //   return { ...pre }
              // })
            }}
            close={() => {
              console.log("ReviewComponent close save_mrp_data");
              SetModalMrp((pre: any) => {
                pre.is_open = false;
                return { ...pre };
              });
            }}
          />
        ) : null}

        {modal_review_addon.is_open ? (
          <ModalReviewAddon
            key={modal_review_addon.index}
            open={modal_review_addon.is_open}
            index={modal_review_addon.index}
            data={modal_review_addon.data}
            addon_data={addon_details.addon_data}
            close={(data: any) => {
              console.log("ModalReviewAddon close :", data);
              SetModalReviewAddon((prevValue: any) => {
                prevValue.is_open = false;
                prevValue.index = "";
                prevValue.data = "";
                return { ...prevValue };
              });
              if (data.action == "save") {
                console.log("ModalReviewAddon close save:", data);

                addon_modal_on_submit(data.value);
              }
            }}
          />
        ) : null}

        {modal_confirm_addon.is_open ? (
          <ModalAddonConfirm
            open={modal_confirm_addon.is_open}
            close={(data) => {
              console.log("ModalAddonConfirm close :", data);
              SetModalConfirmAddon((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                console.log("ModalAddonConfirm close save:", data);
                let data = {
                  from: "review_data",
                  active_step: child_props.active_step,
                  // data: prevValue,
                  data: combi_data,
                };
                child_props.onSubmit(data);
              }
            }}
          />
        ) : null}
      </div>
    );
  };

  function generate_product_variants(avail_prod_name_value) {
    let addon_data = addon_details.addon_data;
    let variant_data = variant_details.variant_data;
    let varying_data = specification_data.varying_data;
    let common_data = specification_data.common_data;
    console.log("varying_data : ", varying_data);
    console.log("variant_data : ", variant_data);
    console.log("common_data on spec : ", common_data);

    // let avail_prod_name = avail_prod_name_value;
    console.log("avail_prod_name before :", avail_prod_name_value);
    // Getting Product Brand
    let brand_name = "";
    common_data.map((c_ele: any, c_index: any) => {
      if (c_ele.name == "Product Brand") {
        brand_name = c_ele.value.label;
      }
    });
    if (variant_data.length > 0) {
      // let prod_available = varying_data.filter(
      //   (a_data) => a_data.name === "Product Name"
      // );
      // console.log("prod_available :", prod_available);
      // if (prod_available.length > 0) {
      //   SetAvailProdName(true);
      // }
      SetAvailProdName(true);
      varying_data.push({
        name: "Product Name",
        checked: true,
        data_type: "varying_data",
        disabled: false,
        slug: "name",
        source_type: "product_details",
        type: "Text Field",
        value: "",
      });

      /**Generating Combination */
      const form_data = [];
      const res = [];
      const combination_row_data = [];
      const combinations = [];

      /**Combination Row Start */
      variant_data.forEach((v_data) => {
        let child_data = [];
        v_data.value.forEach((children) => {
          children.parent_id = v_data.id;
          children.parent_name = v_data.name;
          child_data.push(children);
        });
        form_data.push(child_data);
      });
      console.log("form data :", form_data);
      if (form_data.length > 0) {
        console.log("form_data.length :", form_data.length);
        let max = form_data.length - 1;
        let combination_value_data = combination_provider(
          [],
          0,
          form_data,
          res,
          max
        );
        console.log("combination_value_data : ", combination_value_data);
        combination_value_data.forEach((children) => {
          var result = {};
          for (var i = 0; i < children.length; i++) {
            result["Image"] = "";
            console.log("avail_prod_name_value :", avail_prod_name_value);
            if (!avail_prod_name_value) {
              result["Product Name"] = basic_details.product_name;
            }
            let data = {
              all_data: children[i],
              name: children[i].label,
              type: "variant_data",
            };
            // result[children[i].parent_name] = children[i].label;
            result[children[i].parent_name] = data;
          }
          for (var j = 0; j < varying_data.length; j++) {
            let data = {
              all_data: varying_data[j],
              name: varying_data[j].name,
              type: "varying_data",
              error: "",
              field_type: varying_data[j].type,
              is_linked: false,
              linked_field_type: "",
            };
            if (varying_data[j].hasOwnProperty("linked")) {
              data.is_linked = true;
              data.linked_error = "";
              data.linked_field_type = varying_data[j].linked.type;
            }
            // result[varying_data[j].name] = varying_data[j].name;
            result[varying_data[j].name] = data;
          }
          console.log("result : ", result);
          combination_row_data.push(result);
        });
        console.log("combination_row_data : ", combination_row_data);
        SetCombinationRow(combination_row_data);
      }
      /**Combination Row End */

      /**Combination Head Start */
      var col = [];
      for (var i = 0; i < combination_row_data.length; i++) {
        // let pro_value = brand_name + " " + basic_details.product_name; // need ***** this is hidded in 28010223
        let pro_value = basic_details.product_name;
        for (var key in combination_row_data[i]) {
          if (col.indexOf(key) === -1) {
            col.push(key);
          }
          console.log("col key check key with:", combination_row_data[i][key]);
          console.log("col key com[i] :", combination_row_data[i]);
          console.log("col key key :", key);

          if (key != "Product Name" && key != "Image") {
            if (combination_row_data[i][key].type == "variant_data") {
              pro_value = pro_value + " " + combination_row_data[i][key].name;
            }
          }
          // if (key == "Product Name") {
          combination_row_data[i]["Product Name"].all_data.value = pro_value;
          combination_row_data[i]["Product Name"].value = pro_value;
          // }
        }
      }
      console.log("col Head : ", col);
      SetCombinationHead(col);
      /**Combination Head End */

      /**Combination Data Start*/
      var sample_combi = {};
      combination_row_data.forEach((element, index) => {
        let parent = [];
        let child = [];

        for (var key in element) {
          if (key != "Image") {
            variant_data.forEach((variant_data_element) => {
              if (variant_data_element.name === key) {
                parent.push({
                  id: variant_data_element.id,
                  name: variant_data_element.name,
                });
                variant_data_element.value.forEach((children_value) => {
                  if (children_value.name == element[key]) {
                    child.push({
                      id: children_value.value,
                      name: children_value.label,
                    });
                  }
                });
              }
            });
          }
        }
        sample_combi["images"] = [];
        sample_combi["mrp"] = [];
        sample_combi["default"] = index == 0 ? true : false;
        sample_combi["row_data"] = element;
        sample_combi["parent_data"] = parent;
        sample_combi["child_data"] = child;
        sample_combi["addon"] = [];
        sample_combi["checked"] = [];

        sample_combi["addon_value"] = false;
        sample_combi["completed"] = false;

        combinations.push(sample_combi);
        sample_combi = {};
      });
      console.log("combinations on multi :", combinations);
      SetCombinationData(combinations);
      SetRowGenerated(true);
      /**Combination Data End*/
    } else {
      console.log("combination_data variant_data is 0");
      // let prod_available = varying_data.filter(
      //   (a_data) => a_data.name === "Product Name"
      // );
      // console.log("prod_available :", prod_available);
      // if (prod_available.length > 0) {
      //   SetAvailProdName(true);
      // }
      let brand_name = "";
      common_data.map((c_ele: any, c_index: any) => {
        if (c_ele.name == "Product Brand") {
          brand_name = c_ele.value.label;
        }
      });

      SetAvailProdName(true);
      varying_data.push({
        name: "Product Name",
        checked: true,
        data_type: "varying_data",
        disabled: false,
        slug: "name",
        source_type: "product_details",
        type: "Text Field",
        // value: brand_name + " " + basic_details.product_name,// need ***** this is hidded in 28010223
        value: basic_details.product_name,
      });
      let combinations = [];
      // combi row start
      const combination_row_data = [];
      var result = {};
      for (var j = 0; j < varying_data.length; j++) {
        if (varying_data[j].name == "Product Name") {
          let data = {
            all_data: varying_data[j],
            name: varying_data[j].name,
            type: "varying_data",
            // value: brand_name + " " + basic_details.product_name,// need ***** this is hidded in 28010223
            value: basic_details.product_name,
            error: "",
            linked_error: "",
            field_type: varying_data[j].type,
          };
          result[varying_data[j].name] = data;
        } else {
          let data = {
            all_data: varying_data[j],
            name: varying_data[j].name,
            type: "varying_data",
            error: "",
            linked_error: "",
            field_type: varying_data[j].type,
          };
          result[varying_data[j].name] = data;
        }
        // let data = {
        //   all_data: varying_data[j],
        //   name: varying_data[j].name,
        //   type: "varying_data",
        // };
        // result[varying_data[j].name] = data;
      }
      combination_row_data.push(result);
      console.log("combination_row_data on as :", combination_row_data);
      // combi row end
      // col start
      var col = ["Image", "Product Name"];
      for (var i = 0; i < combination_row_data.length; i++) {
        for (var key in combination_row_data[i]) {
          if (col.indexOf(key) === -1) {
            col.push(key);
          }
        }
      }
      console.log("col :", col);
      //col end
      let sample_combi = {
        default: true,
        images: [],
        row_data: {
          Image: "",
          // "Product Name": basic_details.product_name,
        },
        mrp: [],
        parent_data: [],
        child_data: [],
        addon: [],
        checked: [],
        addon_value: false,
        completed: false,
      };
      // combination_row_data.map((ele: any) => {

      //   sample_combi.row_data[];
      // });
      for (var i = 0; i < combination_row_data.length; i++) {
        for (var key in combination_row_data[i]) {
          // if (col.indexOf(key) === -1) {
          //   col.push(key);
          // }
          sample_combi.row_data[key] = combination_row_data[i][key];
        }
      }
      combinations.push(sample_combi);
      SetCombinationData(combinations);
      SetCombinationHead(col);
      SetRowGenerated(true);
      console.log("combination_data :", combinations);
      // stopped
    }
  }

  function combination_provider(arr, i, form_data, res, max) {
    for (let j = 0, l = form_data[i].length; j < l; j++) {
      let copy = arr.slice(0);
      copy.push(form_data[i][j]);
      if (i == max) {
        res.push(copy);
      } else {
        combination_provider(copy, i + 1, form_data, res, max);
      }
    }
    return res;
  }

  // function ReviewonSubmit() {
  //   let submit_data = {
  //     basic_data: basic_details,
  //     common_data: specification_data.common_data,
  //     addon_data: addon_details.addon_data,
  //     variant_data: variant_details.variant_data,
  //     combination_data: combination_data,
  //   };

  //   console.log("Submit Data :", submit_data);

  //   create_product(submit_data);
  // }

  async function create_product(product_data_value) {
    let pass_data = {
      get: {},
      post: {
        product_data: product_data_value,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    let res_data = await api("/product/create_product_v2", pass_data);
    console.log("/product/create_product_v2 response :", res_data);
    if (res_data.status_code == 201) {
      console.log("product created");
      toast.success("Product created");
      // let base_url = window.location.orgin
      // console.log('base_url :', base_url)
      // let pass_url = ''
      //    // if (context.app_data.app_from == 'seller') {
      // if (id_value.hasOwnProperty("brand_id")) {
      //   pass_url = base_url + '/seller/' + id_value.brand_id + '/group/list'
      // } else {
      //   pass_url = base_url + '/manufacturer/' + id_value.m_id + '/group/list'
      // }
      // console.log('pass_url :', pass_url)
      // // window.location.href = pass_url
      // window.location.back()

      let base_url = window.location.origin;
      let pass_url = "";
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_url = base_url + "/seller/" + id_value.brand_id + "/group/list";
      } else {
        pass_url = base_url + "/manufacturer/" + id_value.m_id + "/group/list";
      }
      console.log("pass_url :", pass_url);
      window.location.href = pass_url;
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar py-3">
        <div className="d-flex align-items-center">
          {/* <div
            className="cursor"
            onClick={() => {
              window.history.back()
            }}
          >
            <Ionicons name="arrow-back-sharp" size={19} color="#222" />
          </div> */}
          <div className="col-md-4 col-12 ps-2">
            <h5 className=" mb-0">Generate Product</h5>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="row card m-3 pt-3">
        <MultiStepForm activeStep={active_step} accentColor={"#0053ad"}>
          {/* basic */}
          <Step>
            <BasicDetailsComponent
              onNext={onNext}
              active_step={active_step}
              basic_data={basic_details}
            />
          </Step>
          {/* specification */}
          <Step>
            <SpecificationDataComponent
              onNext={onNext}
              active_step={active_step}
              onPrev={onPrev}
            />
          </Step>
          {/* common */}
          <Step>
            <CommonDataComponent
              onNext={onNext}
              active_step={active_step}
              common_data={common_details.common_data}
              onPrev={onPrev}
            />
          </Step>
          {/* variant */}
          <Step>
            <VariantDataComponent
              onNext={onNext}
              active_step={active_step}
              variant_data={variant_details.variant_data}
              onPrev={onPrev}
            />
          </Step>
          {/* addon */}
          <Step>
            <AddonDataComponent
              onNext={onNext}
              active_step={active_step}
              account_addon_group={account_addon_group}
              onPrev={onPrev}
            />
          </Step>
          {/* review */}
          <Step>
            <ReviewComponent
              active_step={active_step}
              onPrev={onPrev}
              CustomAddonData={addon_details.addon_data}
              onPrev={onPrev}
              combination_data_value={combination_data}
              onSubmit={(data) => {
                console.log("ReviewComponent onSubmit :", data);

                // SetCombinationData(data.data);
                let basic_details_value = basic_details;
                let image_array = basic_details_value.selected_image;
                basic_details_value.images = [];
                // basic_details_value.selected_image.map((ele: any) => {
                //   basic_details_value.images.push(ele.id);
                // });
                //
                let combination_data_value = data.data;
                combination_data_value.map((ele: any) => {
                  ele.addon.map((ad_ele: any) => {
                    let value_data_split = ad_ele.value.split("_");
                    ad_ele.id = value_data_split[1];
                  });
                });
                //
                let addon_data_value = addon_details.addon_data;
                addon_data_value.map((ele: any) => {
                  let value_data_split = ele.value.split("_");
                  ele.id = value_data_split[1];
                });
                //
                let submit_data = {
                  // basic_data: basic_details,
                  basic_data: basic_details_value,
                  images: image_array,
                  common_data: specification_data.common_data,
                  // addon_data: addon_details.addon_data,
                  addon_data: addon_data_value,
                  variant_data: variant_details.variant_data,
                  combination_data: combination_data_value,
                };

                console.log("Submit Data :", submit_data);

                create_product(submit_data);
              }}
            />
          </Step>
        </MultiStepForm>
      </div>

      <div className="">
        {/* <ModalCategory
          open={modal_category.is_open}
          data={modal_category.data}
          close={(data: any) => {
            console.log("ModalCreateAddonItem on close :", data);
            SetModalCategory((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // SetBasicDetails((prevValue: any) => {
              //   prevValue.selected_category = data.value;
              //   prevValue.category = data.value.id;
              //   return { ...prevValue };
              // });
              SetCategoryValue((prevValue: any) => {
                prevValue.selected_category = data.value;
                prevValue.category = data.value.id;
                return { ...prevValue };
              });
            }
          }}
        /> */}

        <ModalCreateSpecification
          open={modal_specification.is_open}
          type="create"
          data={""}
          all_data={pre_spec_data}
          category={modal_specification.category}
          spec_type_options={spec_type_options}
          add_type="multiple"
          from="add_product"
          close={(data: any) => {
            console.log("ModalCreateSpecification on close :", data);
            SetModalSpecification((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_init_pre_specifications();
            }
          }}
        />

        <ModalCreateAddonGroup
          open={modal_addon.is_open}
          type={modal_addon.type}
          data={""}
          all_data={account_addon_group}
          addon_data={addon_details.addon_data}
          category={modal_addon.category}
          from="add_product"
          variant_data={variant_details.variant_data}
          close={(data: any) => {
            console.log("ModalCreateAddonGroup on close :", data);
            SetModalAddon((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // get_addon_items();
              SetAddonDetails((prevValue: any) => {
                console.log(
                  "prevValue.addon_data.some((ele) => ele.value == data.data.value) :",
                  prevValue.addon_data.some(
                    (ele) => ele.value == data.data.value
                  )
                );
                console.log("prevValue :", prevValue);
                if (
                  prevValue.addon_data.some(
                    (ele) => ele.value == data.data.value
                  )
                ) {
                  console.log("some true");
                } else {
                  console.log("some false");
                  prevValue.addon_data.push(data.data);
                }
                return { ...prevValue };
              });
            }
            get_account_addon(filter_data);
          }}
        />
      </div>
    </div>
  );
}
