import React, {
  Component,
  PureComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { slideDown, slideUp } from "./anim";
import CheckboxTree from "react-checkbox-tree";
// import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useParams } from "react-router-dom";
import { api } from "../../utils/Api";

import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import DataContext from "../../utils/Context/DataContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import toast from "react-hot-toast";
import { AppHelper } from "../../utils/Helper";

const animatedComponents = makeAnimated();

function formatDate(str) {
  return str.substr(0, 10);
}

function capitalize(str) {
  return str;
  // .split(" ")
  // .map((s) => {
  //   return s.charAt(0).toUpperCase() + s.substr(1);
  // })
  // .join(" ");
}

export default function ProductAccordion(props: any) {
  const { data, header, open, show, avail_prod_name, show_delete } = props;
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [row_data_combi, SetRowDataCombi] = useState(props.data);

  const [state, SetState] = useState({
    expanded: false,
    checked: props.data.checked,
    addonexpanded: [],
    addon_data_value: [],
    avail_prod_name: props.avail_prod_name,
    show_delete: props.show_delete,
  });

  const [mrp_state, SetMrpState] = useState({
    expanded: false,
    is_edit: false,
    field_data: [
      {
        selected_country: {},
        country: "",
        state_data: [],
        selected_state: {},
        state: "",
        selected_currency: {},
        currency: "",
        price: "",
        selected_date: "",
        activation_date: "",
      },
    ],
    mrp_data: [],
  });

  useEffect(() => {
    console.log("ProductAccordion On mount :", props);
    // console.log("ProductAccordion On mount [i] :", props.index);
  }, []);

  useEffect(() => {
    console.log("ProductAccordion On Change props :", props);
    SetRowDataCombi(props.data);
    SetState((prevValue: any) => {
      prevValue.checked = props.data.checked;
      prevValue.show_delete = props.show_delete;
      prevValue.avail_prod_name = props.avail_prod_name;
      return { ...prevValue };
    });
  }, [props]);

  // useEffect(() => {
  //   console.log("ProductAccordion On checked props change :", props);

  // }, [props.data.checked]);

  // useEffect(() => {
  //   console.log("ProductAccordion On avail_prod_name props change :", props);
  //   SetState((prevValue: any) => {
  //     prevValue.avail_prod_name = props.avail_prod_name;
  //     return { ...prevValue };
  //   });
  // }, [props.avail_prod_name]);

  function onAddAddon() {
    console.log("ProductAccordion onAddAddon :");
    props.onAddAddon(props.index);
  }

  function onViewAddon() {
    console.log("ProductAccordion onViewAddon :");
    props.onViewAddon(props.index, state.checked);
  }

  function onMrp(event) {
    console.log("ProductAccordion onMrp :", event);
    props.onMrp(props.index);
  }

  function toggleExpander(e: any) {
    if (e.target.type === "checkbox") return;
    console.log("props :", props);
    if (!state.expanded) {
      SetState((prevValue: any) => {
        prevValue.expanded = true;
        return { ...prevValue };
      });
      SetMrpState((prevValue: any) => {
        prevValue.expanded = false;
        return { ...prevValue };
      });
    } else {
      SetState((prevValue: any) => {
        prevValue.expanded = false;
        return { ...prevValue };
      });
    }
  }

  function MrpToggleExpander(e) {
    if (e.target.type === "checkbox") return;
    if (!mrp_state.expanded) {
      SetMrpState((prevValue: any) => {
        prevValue.expanded = true;
        return { ...prevValue };
      });
      SetState((prevValue: any) => {
        prevValue.expanded = false;
        return { ...prevValue };
      });
    } else {
      SetMrpState((prevValue: any) => {
        prevValue.expanded = false;
        return { ...prevValue };
      });
    }

    // SetMrpState((prevValue: any) => {
    //   prevValue.expanded = !prevValue.expanded;
    //   return { ...prevValue };
    // });
    // SetState((prevValue: any) => {
    //   prevValue.expanded = !prevValue.expanded;
    //   return { ...prevValue };
    // });
  }

  function onAddonDelete() {
    console.log("ProductAccordion onAddonDelete :");
    props.onAddonDelete(props.index);
  }
  function onDelete() {
    console.log("ProductAccordion onDelete :");
    props.onDelete(props.index);
  }
  function onAddonChange(checked_value, value_data) {
    console.log("ProductAccordion onAddonChange :");

    SetState((prevValue: any) => {
      prevValue.checked = checked_value;
      return { ...prevValue };
    });
    const combination_data = props.data;
    console.log("checked on onAddonChange in Accordian :", checked);
    console.log("value_data on onAddonChange in Accordian :", value_data);
    console.log(
      "combination_data on onAddonChange in Accordian :",
      combination_data
    );
  }
  function onEditAddon() {
    console.log("ProductAccordion onEditAddon props.index :", props.index);
    console.log(
      "ProductAccordion onEditAddon props.data.addon :",
      props.data.addon
    );
    props.onEditAddon(props.index, state.checked);
  }
  function onImgSelect(selected_img_value) {
    console.log("ProductAccordion onImgSelect :");
    let data_value = {
      index: props.index,
      selected_img: selected_img_value,
    };
    props.onImgSelect(data_value);
  }
  function onTextChange(send_data) {
    console.log("ProductAccordion onTextChange send_data :", send_data);

    props.onTextChange(send_data);
  }
  function onTextAreaChange(send_data) {
    console.log("ProductAccordion onTextAreaChange send_data :", send_data);
    props.OnTextAreaChange(send_data);
  }

  async function get_state(c_data: any, index: any) {
    console.log("get_state");
    let pass_data = {
      get: {
        country: c_data.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let response = await api("/init/get_state", pass_data);
    console.log("/init/get_state response :", response);
    SetMrpState((prevValue: any) => {
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty("data")) {
          prevValue.field_data[index].state_data = response.response.data;
        }
      }
      return { ...prevValue };
    });
  }

  return [
    <tr key={props.index} className="d-flex">
      <td className="col-2 col-md-1 center">
        <label className="p-3 cursor">
          <input
            type="radio"
            checked={data.default}
            onChange={() => {
              let data_value = {
                index: props.index,
              };
              props.onDefaultChange(data_value);
            }}
          />
        </label>
      </td>
      <td className="col-2 col-md-1 center">
        <label className="p-3 cursor">
          {data.completed ? "Completed" : "Not Completed"}
        </label>
      </td>
      {header.map((head, j) =>
        head == "Image" ? (
          <td key={j} scope="col" className="cursor col-2 center">
            {data.images && data.images.length > 0 ? (
              <img
                src={AppHelper.imageSrc(
                  context.app_data.image_base_url,
                  data.images[0].url,
                  {
                    format: "webp",
                    width: 320,
                  }
                )}
                // src={
                //   "https://cdn.1superapp.com/images/320/" + data.images[0].url
                // }
                className="review_img"
                onClick={() => {
                  console.log("Select images clicked!", data.images[0].url);
                  console.log(
                    "Select images name clicked!",
                    data.images[0].name
                  );
                  onImgSelect(data.images);
                }}
              />
            ) : (
              <img
                src={require("../../assets/img/no-image.jpg")}
                className="review_img"
                onClick={() => {
                  onImgSelect([]);
                }}
              />
            )}
          </td>
        ) : !avail_prod_name ? (
          head == "Product Name" ? (
            <td key={j} className="col-4 col-md-3">
              {capitalize(row_data_combi.row_data[head])}
            </td>
          ) : row_data_combi.row_data[head].type == "variant_data" ? (
            <td key={j} className="col-4 col-md-3">
              {capitalize(row_data_combi.row_data[head].name)}
            </td>
          ) : (
            <td key={j} className="col-4 col-md-3">
              {row_data_combi.row_data[head].all_data.type == "Text Field" ? (
                row_data_combi.row_data[head].all_data.hasOwnProperty(
                  "linked"
                ) ? (
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fill the field"
                      value={row_data_combi.row_data[head].value}
                      onChange={(event) => {
                        event.preventDefault();
                        console.log("Text box onChange :", event.target.value);
                        let send_data = {
                          head: head,
                          index: props.index,
                          value: event.target.value,
                        };
                        onTextChange(send_data);
                        console.log("row_data_combi :", row_data_combi);
                      }}
                    />
                    <div className="input-group-text p-0">
                      {row_data_combi.row_data[head].all_data.linked.type ==
                      "Select" ? (
                        <Select
                          id={props.index}
                          onChange={(data_value, actionMeta) => {
                            // console.log(
                            //   "Select Single linked data_value :",
                            //   data_value
                            // );
                            // console.log(
                            //   "Select Single linked actionMeta :",
                            //   actionMeta
                            // );
                            let send_data = {
                              head: head,
                              index: props.index,
                              value: data_value,
                              action: actionMeta,
                              selection_type: "single",
                            };
                            props.OnLinkedSelectChange(send_data);
                          }}
                          options={
                            row_data_combi.row_data[head].all_data.linked
                              .children
                          }
                          tabSelectsValue={false}
                          value={row_data_combi.row_data[head].linked_value}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({ ...base, border: "none" }),
                            indicatorSeparator: (base) => ({
                              ...base,
                              backgroundColor: "none",
                            }),
                          }}
                          className="no-radius border-none"
                        />
                      ) : (
                        <Select
                          id={props.index}
                          isMulti
                          onChange={(data_value, actionMeta) => {
                            // console.log(
                            //   "Select multi linked data_value :",
                            //   data_value
                            // );
                            // console.log(
                            //   "Select multi linked actionMeta :",
                            //   actionMeta
                            // );
                            let send_data = {
                              head: head,
                              index: props.index,
                              value: data_value,
                              action: actionMeta,
                              selection_type: "multi",
                            };
                            props.OnLinkedSelectChange(send_data);
                          }}
                          options={
                            row_data_combi.row_data[head].all_data.linked
                              .children
                          }
                          value={row_data_combi.row_data[head].linked_value}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          className="no-radius border-none"
                        />
                      )}
                    </div>
                    <span className="text-danger">
                      {row_data_combi.row_data[head].error}
                    </span>
                    <span className="text-danger">
                      {row_data_combi.row_data[head].linked_error}
                    </span>
                  </div>
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control"
                      value={row_data_combi.row_data[head].value}
                      placeholder="Fill the field"
                      onChange={(event) => {
                        event.preventDefault();
                        console.log("Text box onChange :", event.target.value);
                        let send_data = {
                          head: head,
                          index: props.index,
                          value: event.target.value,
                        };
                        onTextChange(send_data);
                      }}
                    />
                    <span className="text-danger">
                      {row_data_combi.row_data[head].error}
                    </span>
                  </>
                )
              ) : row_data_combi.row_data[head].all_data.type == "Textarea" ? (
                <>
                  <textarea
                    type="text"
                    placeholder="Fill the field"
                    className="form-control"
                    value={row_data_combi.row_data[head].value}
                    style={{ height: 60 }}
                    onChange={(event) => {
                      console.log("text area onChange :", event);
                      event.preventDefault();
                      console.log(
                        "TextArea box onChange :",
                        event.target.value
                      );
                      let send_data = {
                        head: head,
                        index: props.index,
                        value: event.target.value,
                      };
                      onTextAreaChange(send_data);
                      console.log("row_data_combi :", row_data_combi);
                    }}
                  />
                  <span className="text-danger">
                    {row_data_combi.row_data[head].error}
                  </span>
                </>
              ) : row_data_combi.row_data[head].all_data.type == "Select" ? (
                <>
                  <Select
                    id={props.index}
                    onChange={(data_value, actionMeta) => {
                      console.log("Select data_value :", data_value);
                      console.log("Select actionMeta :", actionMeta);
                      let send_data = {
                        head: head,
                        index: props.index,
                        value: data_value,
                        action: actionMeta,
                        selection_type: "single",
                      };
                      props.OnSelectChange(send_data);
                    }}
                    options={row_data_combi.row_data[head].all_data.children}
                    tabSelectsValue={false}
                    value={row_data_combi.row_data[head].value}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      control: (base) => ({ ...base, border: "none" }),
                      indicatorSeparator: (base) => ({
                        ...base,
                        backgroundColor: "none",
                      }),
                    }}
                    className="no-radius border-none"
                  />
                  <span className="text-danger">
                    {row_data_combi.row_data[head].error}
                  </span>
                  {/* <span className="text-danger">{row_data_combi.row_data[head].linked_error}</span> */}
                </>
              ) : row_data_combi.row_data[head].all_data.type ==
                "Multi Select" ? (
                <>
                  <Select
                    isMulti
                    id={props.index}
                    onChange={(data_value, actionMeta) => {
                      console.log("Multi Select data_value :", data_value);
                      console.log("Multi Select actionMeta :", actionMeta);
                      let send_data = {
                        head: head,
                        index: props.index,
                        value: data_value,
                        action: actionMeta,
                        selection_type: "single",
                      };
                      props.OnMultiSelectChange(send_data);
                    }}
                    options={row_data_combi.row_data[head].all_data.children}
                    tabSelectsValue={false}
                    value={row_data_combi.row_data[head].value}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      control: (base) => ({ ...base, border: "none" }),
                      indicatorSeparator: (base) => ({
                        ...base,
                        backgroundColor: "none",
                      }),
                    }}
                    className="no-radius border-none"
                  />
                  <span className="text-danger">
                    {row_data_combi.row_data[head].error}
                  </span>
                  {/* <span className="text-danger">{row_data_combi.row_data[head].linked_error}</span> */}
                </>
              ) : null}
            </td>
          )
        ) : row_data_combi.row_data[head].type == "variant_data" ? (
          <td key={j} className="col-4 col-md-3">
            {capitalize(row_data_combi.row_data[head].name)}
          </td>
        ) : (
          <td key={j} className="col-4 col-md-3">
            {row_data_combi.row_data[head].all_data.type == "Text Field" ? (
              row_data_combi.row_data[head].all_data.hasOwnProperty(
                "linked"
              ) ? (
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Fill the field"
                    value={row_data_combi.row_data[head].value}
                    onChange={(event) => {
                      event.preventDefault();
                      console.log("Text box onChange :", event.target.value);
                      let send_data = {
                        head: head,
                        index: props.index,
                        value: event.target.value,
                      };
                      onTextChange(send_data);
                    }}
                  />
                  <div className="input-group-text p-0">
                    {row_data_combi.row_data[head].all_data.linked.type ==
                    "Select" ? (
                      <Select
                        id={props.index}
                        onChange={(data_value, actionMeta) => {
                          // console.log(
                          //   "Select Single linked data_value :",
                          //   data_value
                          // );
                          // console.log(
                          //   "Select Single linked actionMeta :",
                          //   actionMeta
                          // );
                          let send_data = {
                            head: head,
                            index: props.index,
                            value: data_value,
                            action: actionMeta,
                            selection_type: "single",
                          };
                          props.OnLinkedSelectChange(send_data);
                        }}
                        options={
                          row_data_combi.row_data[head].all_data.linked.children
                        }
                        tabSelectsValue={false}
                        value={row_data_combi.row_data[head].linked_value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    ) : (
                      <Select
                        id={props.index}
                        isMulti
                        onChange={(data_value, actionMeta) => {
                          // console.log(
                          //   "Select multi linked data_value :",
                          //   data_value
                          // );
                          // console.log(
                          //   "Select multi linked actionMeta :",
                          //   actionMeta
                          // );
                          let send_data = {
                            head: head,
                            index: props.index,
                            value: data_value,
                            action: actionMeta,
                            selection_type: "multi",
                          };
                          props.OnLinkedSelectChange(send_data);
                        }}
                        options={
                          row_data_combi.row_data[head].all_data.linked.children
                        }
                        value={row_data_combi.row_data[head].linked_value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        className="no-radius border-none"
                      />
                    )}
                  </div>
                  <span className="text-danger">
                    {row_data_combi.row_data[head].error}
                  </span>
                  <span className="text-danger">
                    {row_data_combi.row_data[head].linked_error}
                  </span>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    className="form-control"
                    value={row_data_combi.row_data[head].value}
                    placeholder="Fill the field"
                    onChange={(event) => {
                      event.preventDefault();
                      console.log("Text box onChange :", event.target.value);
                      let send_data = {
                        head: head,
                        index: props.index,
                        value: event.target.value,
                      };
                      onTextChange(send_data);
                    }}
                  />
                  <span className="text-danger">
                    {row_data_combi.row_data[head].error}
                  </span>
                </>
              )
            ) : row_data_combi.row_data[head].all_data.type == "Textarea" ? (
              <>
                <textarea
                  type="text"
                  placeholder="Fill the field"
                  className="form-control"
                  value={row_data_combi.row_data[head].value}
                  style={{ height: 60 }}
                  onChange={(event) => {
                    console.log("text area onChange :", event);
                    event.preventDefault();
                    console.log("TextArea box onChange :", event.target.value);
                    let send_data = {
                      head: head,
                      index: props.index,
                      value: event.target.value,
                    };
                    onTextAreaChange(send_data);
                  }}
                />
                <span className="text-danger">
                  {row_data_combi.row_data[head].error}
                </span>
              </>
            ) : row_data_combi.row_data[head].all_data.type == "Select" ? (
              <>
                <Select
                  id={props.index}
                  onChange={(data_value, actionMeta) => {
                    console.log("Select data_value :", data_value);
                    console.log("Select actionMeta :", actionMeta);
                    let send_data = {
                      head: head,
                      index: props.index,
                      value: data_value,
                      action: actionMeta,
                      selection_type: "single",
                    };
                    props.OnSelectChange(send_data);
                  }}
                  options={row_data_combi.row_data[head].all_data.children}
                  tabSelectsValue={false}
                  value={row_data_combi.row_data[head].value}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base) => ({ ...base, border: "none" }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      backgroundColor: "none",
                    }),
                  }}
                  className="no-radius border-none"
                />
                <span className="text-danger">
                  {row_data_combi.row_data[head].error}
                </span>
                {/* <span className="text-danger">{row_data_combi.row_data[head].linked_error}</span> */}
              </>
            ) : row_data_combi.row_data[head].all_data.type ==
              "Multi Select" ? (
              <>
                <Select
                  isMulti
                  id={props.index}
                  onChange={(data_value, actionMeta) => {
                    console.log("Multi Select data_value :", data_value);
                    console.log("Multi Select actionMeta :", actionMeta);
                    let send_data = {
                      head: head,
                      index: props.index,
                      value: data_value,
                      action: actionMeta,
                      selection_type: "single",
                    };
                    props.OnMultiSelectChange(send_data);
                  }}
                  options={row_data_combi.row_data[head].all_data.children}
                  tabSelectsValue={false}
                  value={row_data_combi.row_data[head].value}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base) => ({ ...base, border: "none" }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      backgroundColor: "none",
                    }),
                  }}
                  className="no-radius border-none"
                />
                <span className="text-danger">
                  {row_data_combi.row_data[head].error}
                </span>
                {/* <span className="text-danger">{row_data_combi.row_data[head].linked_error}</span> */}
              </>
            ) : null}
          </td>
        )
      )}

      {props.product_addon.length > 0 ? (
        // data.addon_value ? (
        //   // state.expanded ? (
        //   //   <td
        //   //     className="cursor col-2 center"
        //   //     onClick={(e) => {
        //   //       // toggleExpander(e)
        //   //       onAddAddon()
        //   //     }}
        //   //   >
        //   //     <Entypo name="eye-with-line" size={22} color="#999" />
        //   //   </td>
        //   // ) : (
        //   <td className="col-2 center">
        //     {/* remove_addon */}
        //     <div
        //       className="cursor p-3"
        //       onClick={() => {
        //         onAddonDelete()
        //       }}
        //     >
        //       <AntDesign name="closecircleo" size={20} color="#999" />
        //     </div>

        //     <div
        //       className="cursor p-3"
        //       onClick={(e) => {
        //         // toggleExpander(e)
        //         // onAddAddon()
        //         // onViewAddon()
        //         props.onEditAddon(props.index, state.checked)
        //       }}
        //     >
        //       <Entypo name="eye" size={22} color="#999" />
        //     </div>
        //   </td>
        // ) : (
        //   // )
        //   <td
        //     className="cursor col-2 center"
        //     onClick={() => {
        //       onAddAddon()
        //     }}
        //   >
        //     <MaterialCommunityIcons
        //       name="plus-circle-outline"
        //       size={22}
        //       color="#777"
        //     />
        //   </td>
        // )
        // new

        data.addon_value ? (
          <td className="col-2 center">
            <div
              className="cursor p-3"
              onClick={(e) => {
                props.onEditAddon(props.index, state.checked);
              }}
            >
              <img
                src={require("../../assets/icon/maximize.png")}
                className="addon_icons"
              />
              {/* <Entypo name="eye" size={22} color="#999" /> */}
            </div>
          </td>
        ) : (
          <td
            className="cursor col-2 center"
            onClick={() => {
              onAddAddon();
            }}
          >
            <img
              src={require("../../assets/icon/add-item.png")}
              className="addon_icons"
            />

            {/* <MaterialCommunityIcons
              name="plus-circle-outline"
              size={22}
              color="#777"
            /> */}
          </td>
        )
      ) : null}

      {true ? (
        // <td
        //   className="col-2 center"
        //   onClick={(e) => {
        //     MrpToggleExpander(e)
        //   }}
        // >
        //   {mrp_state.expanded ? (
        //     <div className="cursor ps-3">
        //       <Entypo name="eye-with-line" size={20} color="#999" />
        //     </div>
        //   ) : (
        //     <div>
        //       {/* <div
        //         className="cursor ps-3"
        //         onClick={(e) => {
        //           MrpToggleExpander(e)
        //         }}
        //       >
        //         <Entypo name="eye" size={20} color="#999" />
        //       </div> */}

        //       <button
        //         className="btn btn-sm btn-light"
        //         onClick={(e) => {
        //           onMrp(e)
        //         }}
        //       >
        //         onMrp
        //       </button>
        //     </div>
        //   )}
        // </td>
        // new code
        <td className="col-2 center">
          <div>
            <button
              className="btn btn-sm "
              onClick={(e) => {
                onMrp(e);
              }}
            >
              Add / Edit Mrp
            </button>
          </div>
        </td>
      ) : null}

      {show_delete ? (
        <td
          className="cursor col-2 center"
          onClick={() => {
            onDelete();
          }}
        >
          {/* <MaterialIcons
            name="delete-outline"
            size={20}
            color="#777"
            onClick={() => {
              onDelete();
            }}
          /> */}
          <AntDesign name="delete" size={20} color="#777" />
        </td>
      ) : null}
    </tr>,
    // state.expanded && (
    //   <tr className="expandable d-flex" key="tr-expander">
    //     <td className="col-12">
    //       <div className="expand-hight d-flex align-items-center col-12">
    //         <div className="">
    //           {state.checked ? (
    //             <CheckboxTree
    //               expandOnClick
    //               nodes={props.product_addon}
    //               // disabled
    //               checked={state.checked}
    //               expanded={state.addonexpanded}
    //               onCheck={(checked, targetNode) => {
    //                 onAddonChange(checked, targetNode)
    //               }}
    //               icons={{
    //                 check: <i className="fas fa-check-square" />,
    //                 uncheck: <span className="fas fa-square" />,
    //                 halfCheck: <span className="fas fa-minus-square" />,
    //                 expandClose: <span className="fas fa-plus" />,
    //                 expandOpen: <span className="fas fa-minus" />,
    //                 expandAll: <span className="fas fa-minus-square" />,
    //                 collapseAll: <span className="fas fa-minus-square" />,
    //                 parentClose: <span className="fas fa-folder" />,
    //                 parentOpen: <span className="fas fa-folder-open" />,
    //                 leaf: <span className="fas fa-file" />,
    //               }}
    //               onExpand={(addonexpanded) => {
    //                 SetState((prevValue: any) => {
    //                   prevValue.addonexpanded = addonexpanded
    //                   return { ...prevValue }
    //                 })
    //                 console.log('addonecpanedd : ', addonexpanded)
    //               }}
    //             />
    //           ) : (
    //             <CheckboxTree
    //               expandOnClick
    //               nodes={props.product_addon}
    //               disabled
    //               checked={state.checked}
    //               expanded={state.addonexpanded}
    //               onCheck={(checked, targetNode) => {
    //                 onAddonChange(checked, targetNode)
    //               }}
    //               icons={{
    //                 check: <i className="fas fa-check-square" />,
    //                 uncheck: <span className="fas fa-square" />,
    //                 halfCheck: <span className="fas fa-minus-square" />,
    //                 expandClose: <span className="fas fa-plus" />,
    //                 expandOpen: <span className="fas fa-minus" />,
    //                 expandAll: <span className="fas fa-minus-square" />,
    //                 collapseAll: <span className="fas fa-minus-square" />,
    //                 parentClose: <span className="fas fa-folder" />,
    //                 parentOpen: <span className="fas fa-folder-open" />,
    //                 leaf: <span className="fas fa-file" />,
    //               }}
    //               onExpand={(addonexpanded) => {
    //                 SetState((prevValue: any) => {
    //                   prevValue.addonexpanded = addonexpanded
    //                   return { ...prevValue }
    //                 })
    //                 console.log('addonecpanedd : ', addonexpanded)
    //               }}
    //             />
    //           )}
    //         </div>
    //         <div className="ms-auto">
    //           <button
    //             onClick={() => {
    //               props.onEditAddon(props.index, state.checked)
    //               // onEditAddon();
    //             }}
    //             className="btn btn-outline-secondary"
    //           >
    //             <MaterialCommunityIcons
    //               name="folder-edit-outline"
    //               size={18}
    //               color="black"
    //             />{' '}
    //             Edit
    //           </button>
    //         </div>
    //       </div>
    //     </td>
    //   </tr>
    // ),
    // mrp_state.expanded && (
    //   <tr className="expandable" key="tr-expander">
    //     <td colSpan={12}>
    //       {/* ref="expanderBody" */}
    //       <div className="expand-hight row">
    //         <div className="col-12">
    //           {mrp_state.is_edit ? (
    //             <div className="d-flex align-items-center pb-3">
    //               <button
    //                 onClick={() => {
    //                   // onEditAddon();
    //                   SetMrpState((prevValue: any) => {
    //                     prevValue.field_data.push({
    //                       selected_country: {},
    //                       country: '',
    //                       state_data: [],
    //                       selected_state: {},
    //                       state: '',
    //                       selected_currency: {},
    //                       currency: '',
    //                       price: '',
    //                       selected_date: '',
    //                       activation_date: '',
    //                     })

    //                     return { ...prevValue }
    //                   })
    //                 }}
    //                 className="btn btn-secondary-outline border-none btn-sm"
    //               >
    //                 <MaterialCommunityIcons
    //                   name="folder-edit-outline"
    //                   size={18}
    //                   color="black"
    //                 />{' '}
    //                 <span className="text-dark ps-1">Add One More Row</span>
    //               </button>
    //               <div className="ms-auto">
    //                 <button
    //                   onClick={() => {
    //                     // onEditAddon();
    //                     SetMrpState((prevValue: any) => {
    //                       prevValue.field_data.map((ele) => {
    //                         prevValue.mrp_data.push(ele)
    //                       })
    //                       console.log('after push :', prevValue.mrp_data)
    //                       prevValue.field_data = [
    //                         {
    //                           selected_country: {},
    //                           country: '',
    //                           state_data: [],
    //                           selected_state: {},
    //                           state: '',
    //                           selected_currency: {},
    //                           currency: '',
    //                           price: '',
    //                           selected_date: '',
    //                           activation_date: '',
    //                         },
    //                       ]
    //                       prevValue.is_edit = false
    //                       props.mrp_on_save(prevValue, props.index)
    //                       return { ...prevValue }
    //                     })
    //                   }}
    //                   className="btn btn-secondary-outline border-none btn-sm me-3"
    //                 >
    //                   <MaterialCommunityIcons
    //                     name="check-underline"
    //                     size={16}
    //                     color="#297ad0"
    //                   />
    //                   <span className="text-primary ps-1">Save</span>
    //                 </button>
    //                 <button
    //                   onClick={() => {
    //                     // onEditAddon();
    //                     SetMrpState((prevValue: any) => {
    //                       prevValue.is_edit = false
    //                       return { ...prevValue }
    //                     })
    //                   }}
    //                   className="btn btn-secondary-outline border-none btn-sm"
    //                 >
    //                   <Ionicons name="md-close" size={18} color="#333" />
    //                   <span className="text-dark ps-1">Cancel</span>
    //                 </button>
    //               </div>
    //             </div>
    //           ) : null}
    //         </div>
    //         <div className="col-12">
    //           {!mrp_state.is_edit ? (
    //             <div className="">
    //               <div className="d-flex align-items-center border-bottom pb-2">
    //                 <p className="text-dark fw-bold mb-0"> MRP Data</p>

    //                 <div className="ms-auto">
    //                   <button
    //                     onClick={() => {
    //                       // onEditAddon();
    //                       SetMrpState((prevValue: any) => {
    //                         prevValue.is_edit = true
    //                         return { ...prevValue }
    //                       })
    //                     }}
    //                     className="btn btn-outline-secondary btn-sm"
    //                   >
    //                     <span className="pe-1">
    //                       <AntDesign name="edit" size={18} color="black" />
    //                     </span>
    //                     Edit
    //                   </button>
    //                 </div>
    //               </div>
    //               {mrp_state.mrp_data.length > 0 ? (
    //                 <div className="table-responsive">
    //                   <table className="table table-hover">
    //                     <thead>
    //                       <tr>
    //                         <th scope="col">#</th>
    //                         <th scope="col">Country</th>
    //                         <th scope="col">State</th>
    //                         <th scope="col">Currency</th>
    //                         <th scope="col">Price</th>
    //                         <th scope="col">Activation Date</th>
    //                         <th scope="col">Action</th>
    //                       </tr>
    //                     </thead>
    //                     <tbody>
    //                       {mrp_state.mrp_data.map(
    //                         (field_ele: any, field_index: any) => (
    //                           <tr key={field_index}>
    //                             <th scope="row" className="text-gray">
    //                               {field_index + 1}
    //                             </th>
    //                             <td className="text-gray">
    //                               {field_ele.selected_country.name}
    //                             </td>
    //                             {field_ele.hasOwnProperty('state') ? (
    //                               <td className="text-gray">
    //                                 {field_ele.selected_state.name}
    //                               </td>
    //                             ) : (
    //                               <td className="text-gray">-</td>
    //                             )}
    //                             <td className="text-gray">
    //                               {field_ele.selected_currency.name} -{' '}
    //                               {field_ele.selected_currency.symbol}
    //                             </td>
    //                             <td className="text-gray">{field_ele.price}</td>
    //                             <td className="text-gray">
    //                               {field_ele.activation_date}
    //                             </td>
    //                             <td>
    //                               <button
    //                                 className="btn btn-secondary btn-sm"
    //                                 onClick={() => {
    //                                   SetMrpState((prevValue: any) => {
    //                                     prevValue.mrp_data.splice(
    //                                       field_index,
    //                                       1,
    //                                     )
    //                                     props.mrp_on_save(
    //                                       prevValue,
    //                                       props.index,
    //                                     )
    //                                     return { ...prevValue }
    //                                   })
    //                                 }}
    //                               >
    //                                 Remove
    //                               </button>
    //                             </td>
    //                           </tr>
    //                         ),
    //                       )}
    //                     </tbody>
    //                   </table>
    //                 </div>
    //               ) : (
    //                 <p className="text-center pt-2">No Mrp Data</p>
    //               )}
    //             </div>
    //           ) : (
    //             <div className="">
    //               {mrp_state.field_data.length > 0
    //                 ? mrp_state.field_data.map(
    //                     (field: any, field_index: any) => (
    //                       <div
    //                         className="row mb-2 align-items-end"
    //                         key={field_index}
    //                       >
    //                         {/* Country */}
    //                         <div className="col-md-3 mb-2">
    //                           <label className="form-label">Country</label>
    //                           <Select
    //                             components={animatedComponents}
    //                             placeholder="Select Country"
    //                             className="basic-multi-select"
    //                             classNamePrefix="select"
    //                             options={context.app_data.countries}
    //                             value={field.selected_country}
    //                             menuPortalTarget={document.body}
    //                             styles={{
    //                               menuPortal: (base) => ({
    //                                 ...base,
    //                                 zIndex: 9999,
    //                               }),
    //                             }}
    //                             onChange={(e) => {
    //                               console.log(' Country :', e)
    //                               SetMrpState((prevValue: any) => {
    //                                 prevValue.field_data[
    //                                   field_index
    //                                 ].selected_country = e
    //                                 prevValue.field_data[field_index].country =
    //                                   e.id
    //                                 if (e.id == '99') {
    //                                   get_state(e, field_index)
    //                                 }
    //                                 return { ...prevValue }
    //                               })
    //                             }}
    //                           />
    //                         </div>
    //                         {/* State */}
    //                         {field.selected_country.id == '99' ? (
    //                           <div className="col-md-3 mb-2">
    //                             <label className="form-label">State</label>
    //                             <Select
    //                               components={animatedComponents}
    //                               placeholder="Select State"
    //                               className="basic-multi-select"
    //                               classNamePrefix="select"
    //                               options={field.state_data}
    //                               value={field.selected_state}
    //                               menuPortalTarget={document.body}
    //                               styles={{
    //                                 menuPortal: (base) => ({
    //                                   ...base,
    //                                   zIndex: 9999,
    //                                 }),
    //                               }}
    //                               onChange={(e) => {
    //                                 console.log(' state :', e)

    //                                 SetMrpState((prevValue: any) => {
    //                                   prevValue.field_data[
    //                                     field_index
    //                                   ].selected_state = e
    //                                   prevValue.field_data[field_index].state =
    //                                     e.id
    //                                   return { ...prevValue }
    //                                 })
    //                               }}
    //                             />
    //                           </div>
    //                         ) : null}
    //                         {/* Price */}
    //                         <div className="col-md-3 mb-2">
    //                           <label className="form-label">Price</label>
    //                           <div className="input-group">
    //                             <div className="input-group-text p-0">
    //                               <Select
    //                                 className="no-radius border-none"
    //                                 components={animatedComponents}
    //                                 placeholder="Select Currency"
    //                                 classNamePrefix="select"
    //                                 options={context.app_data.currencies}
    //                                 value={field.selected_currency}
    //                                 menuPortalTarget={document.body}
    //                                 styles={{
    //                                   menuPortal: (base) => ({
    //                                     ...base,
    //                                     zIndex: 9999,
    //                                   }),
    //                                   control: (base) => ({
    //                                     ...base,
    //                                     border: 'none',
    //                                   }),
    //                                   indicatorSeparator: (base) => ({
    //                                     ...base,
    //                                     backgroundColor: 'none',
    //                                   }),
    //                                 }}
    //                                 onChange={(e) => {
    //                                   console.log(' Currency :', e)
    //                                   SetMrpState((prevValue: any) => {
    //                                     prevValue.field_data[
    //                                       field_index
    //                                     ].selected_currency = e
    //                                     prevValue.field_data[
    //                                       field_index
    //                                     ].currency = e.id
    //                                     return { ...prevValue }
    //                                   })
    //                                 }}
    //                               />
    //                             </div>
    //                             <input
    //                               type="text"
    //                               className="form-control"
    //                               placeholder="Price"
    //                               value={field.price}
    //                               //   disabled={read_only}
    //                               onChange={(e) => {
    //                                 console.log(' Price :', e.target.value)
    //                                 SetMrpState((prevValue: any) => {
    //                                   prevValue.field_data[field_index].price =
    //                                     e.target.value
    //                                   return { ...prevValue }
    //                                 })
    //                               }}
    //                             />
    //                           </div>
    //                         </div>
    //                         {/* Activation Date */}
    //                         <div className="col-md-2 mb-2">
    //                           <label className="form-label">
    //                             Activation Date
    //                           </label>
    //                           <DatePicker
    //                             selected={field.selected_date}
    //                             dateFormat="dd/MM/yyyy"
    //                             onSelect={(date) => {
    //                               let date_value = moment(date).format(
    //                                 'DD-MM-yyyy',
    //                               )
    //                               SetMrpState((prevValue: any) => {
    //                                 prevValue.field_data[
    //                                   field_index
    //                                 ].selected_date = date
    //                                 prevValue.field_data[
    //                                   field_index
    //                                 ].activation_date = date_value
    //                                 return { ...prevValue }
    //                               })
    //                             }}
    //                             onChange={(date) => {
    //                               let date_value = moment(date).format(
    //                                 'DD-MM-yyyy',
    //                               )
    //                               SetMrpState((prevValue: any) => {
    //                                 prevValue.field_data[
    //                                   field_index
    //                                 ].selected_date = date
    //                                 prevValue.field_data[
    //                                   field_index
    //                                 ].activation_date = date_value
    //                                 return { ...prevValue }
    //                               })
    //                             }}
    //                           />
    //                         </div>
    //                         <div className="col-md-1 mb-2 ms-auto">
    //                           {field_index > 0 ? (
    //                             <button
    //                               className="btn btn-secondary-outline border-none btn-sm"
    //                               onClick={() => {
    //                                 console.log('Remove')
    //                                 SetMrpState((prevValue: any) => {
    //                                   prevValue.field_data.splice(
    //                                     field_index,
    //                                     1,
    //                                   )
    //                                   return { ...prevValue }
    //                                 })
    //                               }}
    //                             >
    //                               <MaterialCommunityIcons
    //                                 name="delete-outline"
    //                                 size={16}
    //                                 color="black"
    //                               />
    //                               {/* Remove */}
    //                             </button>
    //                           ) : null}

    //                           {/* <button
    //                             onClick={() => {
    //                               console.log("field_data :", field_data);
    //                             }}
    //                           >
    //                             Check
    //                           </button> */}
    //                         </div>
    //                       </div>
    //                     ),
    //                   )
    //                 : null}
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </td>
    //   </tr>
    // ),
  ];
}
