import React, { useState, useEffect, useContext, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DataContext from "../../utils/Context/DataContext";
import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome5,
} from "@expo/vector-icons";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ModalCategory from "../Products/ModalCategory";

let myModal = {};
const animatedComponents = makeAnimated();

function ColumnNode(props) {
  const [lines, setLines] = useState([{ index: "", id: "" }]);
  const [c_data, set_c_data] = useState([props.data]);

  return lines.map((m, index) => (
    <ColumnChild
      key={index}
      col_index={index}
      data={c_data[index]}
      pstate={{ lines, setLines }}
      cstate={{ c_data, set_c_data }}
      onChildSelect={(data) => {
        props.onSelect(data);
      }}
    />
  ));
}

function ColumnChild(props) {
  const { lines, setLines } = props.pstate;
  const { c_data, set_c_data } = props.cstate;
  const count = props.col_index + 1;
  const [active, SetActive] = useState(null);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="scroll-row border-right">
      {props.data.map((element, index) => (
        <div key={index}>
          <div
            className="catbut "
            onClick={(event) => {
              event.preventDefault();
              props.onChildSelect(element);

              if (element.hasOwnProperty("children")) {
                if (count < lines.length) {
                  let line_data = {
                    index: index,
                    id: element.id,
                    name: element.name,
                  };
                  let c_value_data = element.children;
                  let data_a = [...lines];
                  let data_b = [...c_data];
                  data_a.splice(count);
                  data_b.splice(count);
                  data_a.push(line_data);
                  data_b.push(c_value_data);
                  setLines(data_a);
                  set_c_data(data_b);
                } else if (count == lines.length) {
                  setLines([
                    ...lines,
                    { index: index, id: element.id, name: element.name },
                  ]);
                  set_c_data([...c_data, element.children]);
                }
                SetActive(element.id);
              } else {
                if (count < lines.length) {
                  let data_a = [...lines];
                  let data_b = [...c_data];
                  data_a.splice(count);
                  data_b.splice(count);
                  setLines(data_a);
                  set_c_data(data_b);
                }
                SetActive(element.id);
              }

              console.log("clicked c_data :", c_data);
              console.log("clicked element :", element);
              console.log("clicked lines :", lines);
            }}
          >
            <div
              className={
                element.id == active
                  ? "cursor px-2 py-3 active_cat"
                  : "cursor px-2 py-3 cat_hover"
              }
            >
              <div className="row align-items-center">
                <div className="col-2 pe-0">
                  <img
                    src={require("../../assets/icon/cookie.png")}
                    className="cat_img"
                  />
                </div>
                <p className="col-8 mb-0 ps-2 ">
                  {capitalizeFirstLetter(element.name)}
                </p>
                <span className="col-2 ps-0 ms-auto">
                  {element.hasOwnProperty("children") ? (
                    <MaterialIcons
                      name="arrow-forward-ios"
                      size={11}
                      color={element.id == active ? "#000" : "#777"}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function UpdateVariantDetailModal(props: any) {
  const context = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());
  const [save_data, SetSaveData] = useState({});
  const [view_data, SetViewData] = useState("main");
  const [selected_category, SetSelectedCategory] = useState({});
  const [modal_category, SetModalCategory] = useState({
    is_open: false,
    data: {},
  });

  useEffect(() => {
    if (props.open) {
      console.log("UpdateVariantDetailModal On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ModalVariantDetailUpdate"),
        {}
      );
      process_data();
      SetViewData("main");
      SetSelectedCategory({
        label: props.edit_data.category,
        value: props.edit_data.category_id,
      });
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    console.log("UpdateVariantDetailModal On mount :", props);
    let myModal1 = document.getElementById("ModalVariantDetailUpdate");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      // SetSaveData({});
      props.close(close_data);
    });
  }, []);

  function process_data() {
    // SetSaveData(props.edit_data)
    SetSaveData((prevValue: any) => {
      prevValue = props.edit_data;
      if (props.edit_data.hasOwnProperty("update_request_basic")) {
        if (Object.keys(props.edit_data.update_request_basic).length > 0) {
          prevValue.name = props.edit_data.update_request_basic.name;
          prevValue.description =
            props.edit_data.update_request_basic.description;
          prevValue.full_description =
            props.edit_data.update_request_basic.full_description;
        }
      }
      if (props.edit_data.hasOwnProperty("update_request_additional")) {
        if (Object.keys(props.edit_data.update_request_additional).length > 0) {
          prevValue.category =
            props.edit_data.update_request_additional.category;
          prevValue.category_id =
            props.edit_data.update_request_additional.category_id;
          prevValue.gst = props.edit_data.update_request_additional.gst;
          prevValue.gtin = props.edit_data.update_request_additional.gtin;
          prevValue.height = props.edit_data.update_request_additional.height;
          prevValue.height_unit =
            props.edit_data.update_request_additional.height_unit;
          prevValue.hsn = props.edit_data.update_request_additional.hsn;
          prevValue.isbn = props.edit_data.update_request_additional.isbn;
          prevValue.jan = props.edit_data.update_request_additional.jan;
          prevValue.length = props.edit_data.update_request_additional.length;
          prevValue.length_unit =
            props.edit_data.update_request_additional.length_unit;
          prevValue.max_handling_time =
            props.edit_data.update_request_additional.max_handling_time;
          prevValue.min_handling_time =
            props.edit_data.update_request_additional.min_handling_time;
          prevValue.mpn = props.edit_data.update_request_additional.mpn;
          prevValue.orgin_country =
            props.edit_data.update_request_additional.orgin_country;
          prevValue.orgin_country_id =
            props.edit_data.update_request_additional.orgin_country_id;
          prevValue.package_breadth =
            props.edit_data.update_request_additional.package_breadth;
          prevValue.package_breadth_unit =
            props.edit_data.update_request_additional.package_breadth_unit;
          prevValue.package_height =
            props.edit_data.update_request_additional.package_height;
          prevValue.package_height_unit =
            props.edit_data.update_request_additional.package_height_unit;
          prevValue.package_length =
            props.edit_data.update_request_additional.package_length;
          prevValue.package_length_unit =
            props.edit_data.update_request_additional.package_length_unit;
          prevValue.package_length_unit_name =
            props.edit_data.update_request_additional.package_length_unit_name;
          prevValue.package_weight =
            props.edit_data.update_request_additional.package_weight;
          prevValue.package_weight_unit =
            props.edit_data.update_request_additional.package_weight_unit;
          prevValue.package_weight_unit_name =
            props.edit_data.update_request_additional.package_weight_unit_name;
          prevValue.product_brand =
            props.edit_data.update_request_additional.product_brand;
          prevValue.product_brand_id =
            props.edit_data.update_request_additional.product_brand_id;
          prevValue.upc = props.edit_data.update_request_additional.upc;
          prevValue.weight = props.edit_data.update_request_additional.weight;
          prevValue.weight_unit =
            props.edit_data.update_request_additional.weight_unit;
          prevValue.weight_unit_name =
            props.edit_data.update_request_additional.weight_unit_name;
          prevValue.width = props.edit_data.update_request_additional.width;
          prevValue.width_unit =
            props.edit_data.update_request_additional.width_unit;
        }
      }
      return { ...prevValue };
    });
  }

  async function update_details(get_data: any) {
    console.log("update_details get_data :",get_data)
    let product_data_value: any;
    // SetSaveData((prevalue) => {
    //   console.log("update_details SetSaveData :",prevalue);
    //   product_data_value = {
    //     name: prevalue.name,
    //     description: prevalue.description,
    //     full_description: prevalue.full_description,
    //     category_id: prevalue.category_id,
    //     orgin_country_id: prevalue.orgin_country_id,
    //     product_brand_id: prevalue.product_brand_id,
    //     hsn: prevalue.hsn,
    //     gst: prevalue.gst,
    //     gtin: prevalue.gtin,
    //     upc: prevalue.upc,
    //     ean: prevalue.ean,
    //     jan: prevalue.jan,
    //     isbn: prevalue.isbn,
    //     mpn: prevalue.mpn,
    //     min_handling_time: prevalue.min_handling_time,
    //     max_handling_time: prevalue.max_handling_time,
    //     length: prevalue.length,
    //     length_unit: prevalue.length_unit,
    //     width: prevalue.width,
    //     width_unit: prevalue.width_unit,
    //     height: prevalue.height,
    //     height_unit: prevalue.height_unit,
    //     weight: prevalue.weight,
    //     weight_unit: prevalue.weight_unit,
    //     package_length: prevalue.package_length,
    //     package_length_unit: prevalue.package_length_unit,
    //     package_breadth: prevalue.package_breadth,
    //     package_breadth_unit: prevalue.package_breadth_unit,
    //     package_height: prevalue.package_height,
    //     package_height_unit: prevalue.package_height_unit,
    //     package_weight: prevalue.package_weight,
    //     package_weight_unit: prevalue.package_weight_unit,
    //   };
    //   return { ...prevalue };
    // });
    product_data_value = {
      name: get_data.name,
      description: get_data.description,
      full_description: get_data.full_description,
      category_id: get_data.category_id,
      orgin_country_id: get_data.orgin_country_id,
      product_brand_id: get_data.product_brand_id,
      hsn: get_data.hsn,
      gst: get_data.gst,
      gtin: get_data.gtin,
      upc: get_data.upc,
      ean: get_data.ean,
      jan: get_data.jan,
      isbn: get_data.isbn,
      mpn: get_data.mpn,
      min_handling_time: get_data.min_handling_time,
      max_handling_time: get_data.max_handling_time,
      length: get_data.length,
      length_unit: get_data.length_unit,
      width: get_data.width,
      width_unit: get_data.width_unit,
      height: get_data.height,
      height_unit: get_data.height_unit,
      weight: get_data.weight,
      weight_unit: get_data.weight_unit,
      package_length: get_data.package_length,
      package_length_unit: get_data.package_length_unit,
      package_breadth: get_data.package_breadth,
      package_breadth_unit: get_data.package_breadth_unit,
      package_height: get_data.package_height,
      package_height_unit: get_data.package_height_unit,
      package_weight: get_data.package_weight,
      package_weight_unit: get_data.package_weight_unit,
    };
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        product_data: product_data_value,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    
    console.log("product_data_value :", product_data_value);
    console.log("/product/update_product_details pass_data :", pass_data);
    let response = await api("/product/update_product_details", pass_data);
    console.log("/product/update_product_details response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        let item = close_data;
        item.action = "save";
        SetCloseData(item);
        myModal.hide();
      } else {
        toast.error(response.response.message);
      }
    }
  }

  return (
    <div
      className="modal"
      id="ModalVariantDetailUpdate"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header align-items-center">
            {view_data == "main" ? (
              <h5 className="mb-0  modal-title" id="ModalLabel">
                Update Product Details
              </h5>
            ) : (
              <div className="">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons
                      name="arrow-back-outline"
                      size={20}
                      color="black"
                    />
                    {/* Back */}
                  </button>
                  <h5 className="mb-0  modal-title" id="ModalLabel">
                    Choose Category
                  </h5>
                </div>

                <div className="d-flex p-2 pb-0 align-items-center">
                  <p className="pe-2 mb-0">Selected Category</p>
                  <MaterialCommunityIcons
                    name="chevron-double-right"
                    size={16}
                    color="#444"
                  />
                  {selected_category.name != "" ? (
                    <p className="text-black mb-0 ps-2">
                      {selected_category.name}
                    </p>
                  ) : null}
                </div>
              </div>
            )}

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-3 border-top overflowY-modal">
            {view_data == "main" ? (
              <div className="row">
                {/* Name */}
                <div className="col-12 p-3">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.name}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.name = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Product Description */}
                <div className="col-md-6 p-3">
                  <label className="form-label">Product Description</label>
                  <textarea
                    className="form-control"
                    value={save_data.description}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.description = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Product Full Description */}
                <div className="col-md-6 p-3">
                  <label className="form-label">Product Full Description</label>
                  <textarea
                    className="form-control"
                    value={save_data.full_description}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.full_description = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Category */}
                <div
                  className="col-md-6 p-3"
                  onClick={() => {
                    // SetViewData('category')
                    SetModalCategory((prevValue: any) => {
                      prevValue.is_open = true;
                      return { ...prevValue };
                    });
                  }}
                >
                  <label className="form-label">Category</label>
                  <Select
                    isSearchable={false}
                    value={selected_category}
                    options={[]}
                    onChange={(e) => {
                      console.log("category_id on change e :", e);
                      // SetSaveData((prevValue: any) => {
                      //   prevValue.category_id = e.target.value
                      //   return { ...prevValue }
                      // })
                    }}
                  />
                </div>
                {/* Orgin Country */}
                <div className="col-md-6 p-3">
                  <label className="form-label">Orgin Country</label>
                  <Select
                    isSearchable={false}
                    value={props.option_data.countries?.find(
                      (ele) => ele.value == save_data.orgin_country_id
                    )}
                    options={props.option_data.countries}
                    onChange={(e) => {
                      console.log("orgin_country_id on change e:", e);
                      SetSaveData((prevValue: any) => {
                        prevValue.orgin_country_id = e.value;
                        return { ...prevValue };
                      });
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
                {/* Product Brand */}
                <div className="col-md-7 p-3">
                  <label className="form-label">Product Brand</label>
                  <Select
                    isSearchable={false}
                    value={props.option_data.product_brands?.find(
                      (ele) => ele.value == save_data.product_brand_id
                    )}
                    options={props.option_data.product_brands}
                    onChange={(e) => {
                      console.log("product_brand_id on change e:", e);
                      SetSaveData((prevValue: any) => {
                        prevValue.product_brand_id = e.value;
                        return { ...prevValue };
                      });
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
                {/* hsn */}
                <div className="col-md-5 p-3">
                  <label className="form-label">HSN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.hsn}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.hsn = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* gst */}
                <div className="col-md-4 p-3">
                  <label className="form-label">GST</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.gst}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.gst = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* gtin */}
                <div className="col-md-4 p-3">
                  <label className="form-label">GTIN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.gtin}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.gtin = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* upc */}
                <div className="col-md-4 p-3">
                  <label className="form-label">UPC</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.upc}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.upc = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* ean */}
                <div className="col-md-4 p-3">
                  <label className="form-label">EAN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.ean}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.ean = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* jan */}
                <div className="col-md-4 p-3">
                  <label className="form-label">JAN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.jan}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.jan = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* ISBN */}
                <div className="col-md-4 p-3">
                  <label className="form-label">ISBN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.isbn}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.isbn = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* MPN */}
                <div className="col-md-4 p-3">
                  <label className="form-label">MPN</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.mpn}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.mpn = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Min Handling Time */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Min Handling Time</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.min_handling_time}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.min_handling_time = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Max Handling Time */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Max Handling Time</label>
                  <input
                    className="form-control"
                    type="text"
                    value={save_data.max_handling_time}
                    onChange={(e) => {
                      SetSaveData((prevValue: any) => {
                        prevValue.max_handling_time = e.target.value;
                        return { ...prevValue };
                      });
                    }}
                  />
                </div>
                {/* Length / Length_Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Length</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.length}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.length = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.length_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.length_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Width / Width Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Width</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.width}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.width = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.width_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.width_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Height / Height Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Height</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.height}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.height = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.height_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.height_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Weight / Weight Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Weight</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.weight}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.weight = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.weight_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.weight?.find(
                          (ele) => ele.value == save_data.weight_unit
                        )}
                        options={props.option_data.weight}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Package Length / Package Length Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Package Length</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.package_length}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.package_length = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.package_length_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.package_length_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          // control: (base) => ({ ...base, border: 'none' }),
                          // indicatorSeparator: (base) => ({
                          //   ...base,
                          //   backgroundColor: 'none',
                          // }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Package Breadth / Package Breadth Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Package Breadth</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.package_breadth}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.package_breadth = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.package_breadth_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.package_breadth_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          // control: (base) => ({ ...base, border: 'none' }),
                          // indicatorSeparator: (base) => ({
                          //   ...base,
                          //   backgroundColor: 'none',
                          // }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Package Height / Package Height Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Package Height</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.package_height}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.package_height = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          SetSaveData((prevValue: any) => {
                            prevValue.package_height_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.dimensions?.find(
                          (ele) => ele.value == save_data.package_height_unit
                        )}
                        options={props.option_data.dimensions}
                        tabSelectsValue={false}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Package Weight / Package Weight Unit */}
                <div className="col-md-4 p-3">
                  <label className="form-label">Package Weight</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={save_data.package_weight}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => {
                        SetSaveData((prevValue: any) => {
                          prevValue.package_weight = e.target.value;
                          return { ...prevValue };
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      <Select
                        isSearchable={false}
                        onChange={(e) => {
                          console.log("e on change :", e);
                          SetSaveData((prevValue: any) => {
                            prevValue.package_weight_unit = e.value;
                            return { ...prevValue };
                          });
                        }}
                        value={props.option_data.weight?.find(
                          (ele) => ele.value == save_data.package_weight_unit
                        )}
                        options={props.option_data.weight}
                        tabSelectsValue={false}
                        // value={save_data.package_weight_unit}
                        menuPortalTarget={document.body}
                        placeholder="Unit"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({ ...base, border: "none" }),
                          indicatorSeparator: (base) => ({
                            ...base,
                            backgroundColor: "none",
                          }),
                        }}
                        className="no-radius border-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {view_data == "category" ? (
              <div className="">
                <div className="scroll">
                  <div className="scroll-flex mt-1">
                    {props.option_data.product_categories.length > 0 ? (
                      <ColumnNode
                        data={props.option_data.product_categories}
                        onSelect={(data) => {
                          console.log("category on select :", data);
                          data.label = data.name;
                          data.value = data.id;
                          SetSelectedCategory(data);
                          SetSaveData((prevValue: any) => {
                            prevValue.category_id = data.id;
                            console.log("SetSaveData prevValue :", prevValue);
                            return { ...prevValue };
                          });
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="modal-footer shadow ">
            {view_data == "main" ? (
              <div className="d-flex align-items-center w-100">
                <button
                  type="button"
                  className="btn btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    let item = close_data;
                    item.action = "close";
                    SetCloseData(item);
                    myModal.hide();
                  }}
                >
                  Close
                </button>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-primary btn-radius px-3 "
                    onClick={() => {
                      console.log("update save_data :",save_data);
                      console.log("update selected_category :",selected_category);
                      // SetSaveData((prevalue) => {
                      //   console.log("update SetSaveData :",prevalue)
                      //   update_details(prevalue);
                      //   // send_data = prevalue;
                      //   return { ...prevalue };
                      // });
                      update_details(save_data);
                      //   let item = close_data
                      //   item.action = 'save'
                      //   SetCloseData(item)
                      //   myModal.hide()
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}
            {view_data == "category" ? (
              <button
                type="button"
                className="btn btn-primary btn-radius px-3"
                onClick={() => {
                  SetViewData("main");
                }}
              >
                Ok
              </button>
            ) : null}
          </div>
        </div>
      </div>

      {modal_category.is_open ? (
        <ModalCategory
          open={modal_category.is_open}
          data={modal_category.data}
          close={(data: any) => {
            console.log("ModalCreateAddonItem on close :", data);
            SetModalCategory((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // SetBasicDetailsValue((prevValue: any) => {
              //   prevValue.selected_category = data.value;
              //   prevValue.selected_category_group = data.group_value;
              //   prevValue.category = data.value.id;
              //   return { ...prevValue };
              // });
              // SetBasicErrorState((prevValue: any) => {
              //   // { languageError, categoryError, group_nameError }
              //   prevValue.categoryError = "";
              //   return { ...prevValue };
              // });
              console.log("category on select :", data);
              SetSaveData((prevValue: any) => {
                prevValue.category_id = data.value.id;
                console.log("SetSaveData prevValue :", prevValue);
                return { ...prevValue };
                // return prevValue;
              });
              // SetSaveData((prevValue: any) => ({...prevValue,category_id:data.value.id}));
              let selected_category = data.value;
              selected_category.label = data.value.name;
              selected_category.value = data.value.id;
              // data.label = data.name;
              // data.value = data.id;
              SetSelectedCategory(selected_category);
              
            }
          }}
        />
      ) : null}
    </div>
  );
}
