import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  Entypo,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import Select from "react-select";
import makeAnimated, { Placeholder } from "react-select/animated";
import { MultiStepForm, Step } from "react-multi-form";
import ModalCategory from "../Products/ModalCategory";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ModalCreateSpecification from "../Specifications/ModalCreateSpecification";

import ModalCreateAddonGroup from "../AddonGroups/ModalCreateAddonGroup";

import ProductAccordion from "../../components/ProductAccordion/ProductAccordion";

import CreatableSelect from "react-select/creatable";
// import Select from "react-select";

import ModalReviewAddon from "./ModalReviewAddon";

import ModalAddGallery from "../Gallery/ModalAddGallery";
import toast from "react-hot-toast";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { AppHelper } from "../../utils/Helper";

const animatedComponents = makeAnimated();
let state_data_value = [];
let today_date = new Date();


export default function AddProductVariant(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  // let state_data_value = context.app_data.states;
  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: "1",
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
    spec: [],
    mrp: [1, 2, 3, 4],
    addon_groups: [],
    category_id: "",
  });

  // init data
  const [countries_data, SetCountriesData] = useState([]);
  const [languages_data, SetLanguagesData] = useState([]);
  const [product_languages_data, SetProductLanguagesData] = useState([]);
  const [product_brands_data, SetProductBrandsData] = useState([]);
  const [product_categories_data, SetProductCategoriesData] = useState([]);
  const [currencies_data, SetCurrenciessData] = useState([]);
  const [product_currencies_data, SetProductCurrenciesData] = useState([]);
  const [active_step, SetActiveStep] = useState(1);

  const [category_value, SetCategoryValue] = useState({
    selected_category: {},
    category: "",
  });
  // pre spec data
  const [pre_spec_data, SetPreSpecData] = useState([]);
  const [modal_specification, SetModalSpecification] = useState({
    is_open: false,
    category: "",
  });
  //account addon
  const [account_addon_group, SetAccountAddonGroup] = useState([]);
  const [modal_addon, SetModalAddon] = useState({
    is_open: false,
    category: "",
  });
  // review
  const [avail_prod_name, SetAvailProdName] = useState(false);
  const [combination_row, SetCombinationRow] = useState([]);
  const [combination_head, SetCombinationHead] = useState([]);
  const [combination_data, SetCombinationData] = useState([]);
  const [row_generated, SetRowGenerated] = useState(false);
  ///////

  //Basic Details
  const [basic_details, SetBasicDetails] = useState({
    selected_country: "",
    country: "",
    selected_language: {
      code: "en",
      id: "40",
      label: "English",
      name: "English",
      native_name: "English",
      value: "40",
    },
    language: "40",
    selected_category: "",
    selected_category_group: [],
    category: "",
    selected_brand: "",
    brand: "",
    name: "",
    description: "",
    selected_image: [],
  });

  // spec value
  const [common_data, SetCommonData] = useState([]);
  const [variant_data, SetVariantData] = useState([]);
  const [varying_data, SetVaryingData] = useState([]);
  const [init_spec_data, SetInitSpecData] = useState([]);
  const [spec_type_options, SetSpecTypeOptions] = useState([]);
  const [show_varying, SetShowVarying] = useState(false);
  const [specification_data, SetSpecificationData] = useState({
    common_data: [],
    variant_data: [],
    varying_data: [],
  });

  ///Common Details
  const [common_details, SetCommonDetails] = useState({
    common_data: [],
  });

  // Variant Details
  const [variant_details, SetVariantDetails] = useState({
    variant_data: [],
  });

  // Addon Details
  const [addon_details, SetAddonDetails] = useState({
    addon_data: [],
  });

  // Review Details
  const [review_details, SetReviewDetails] = useState({
    review_data: [],
  });

  //   Mrp Details
  const [mrp_details, SetMrpDetails] = useState({
    mrp_data: [
      {
        selected_country: {
          id: "99",
          iso: "IN",
          iso3: "IND",
          label: "India",
          name: "INDIA",
          nicename: "India",
          numcode: "356",
          phonecode: "91",
          value: "99",
        },
        country: '99',
        state_data: state_data_value,
        selected_state: {},
        state: "",
        selected_currency: {
          code: "INR",
          id: "1",
          label: "₹",
          name: "Indian Rupees",
          symbol: "₹",
          value: "1",
        },
        currency: "1",
        price: "",
        // selected_date: "",
        // activation_date: "",
        selected_date: today_date,
        activation_date: moment(today_date).format('DD-MM-yyyy'),
      },
    ],
  });
  ///////

  useEffect(() => {
    console.log("AddProductVariant On mount :", props);
    console.log("AddProductVariant On mount context :", context);
    get_add_product_init();
    get_state_value();
  }, []);

  async function get_state_value() {
    console.log("get_state");
    let pass_data = {
      get: {
        country: "99",
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let response = await api("/init/get_state", pass_data);
    console.log("/init/get_state response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        state_data_value = response.response.data;
        SetMrpDetails((prevalue: any) => {
          prevalue.mrp_data.map((ele: any) => {
            ele.state_data = response.response.data
          })
          return { ...prevalue }
        })
      }
    }
  }

  async function get_add_product_init() {
    let pass_data = {
      get: {},
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/create_init", pass_data);
    console.log("/product/create_init :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.data.hasOwnProperty("countries")) {
        SetCountriesData(res_data.response.data.countries);
      }
      if (res_data.response.data.hasOwnProperty("languages")) {
        SetLanguagesData(res_data.response.data.languages);
      }
      if (res_data.response.data.hasOwnProperty("product_languages")) {
        SetProductLanguagesData(res_data.response.data.product_languages);
      }
      if (res_data.response.data.hasOwnProperty("product_brands")) {
        SetProductBrandsData(res_data.response.data.product_brands);
      }
      if (res_data.response.data.hasOwnProperty("product_categories")) {
        SetProductCategoriesData(res_data.response.data.product_categories);
      }
      if (res_data.response.data.hasOwnProperty("currencies")) {
        SetCurrenciessData(res_data.response.data.currencies);
      }
      if (res_data.response.data.hasOwnProperty("product_currencies")) {
        SetProductCurrenciesData(res_data.response.data.product_currencies);
      }
    }
  }

  async function get_account_addon(filter_value) {
    // let pass_data = {
    //   get: {
    //     category_id: basic_details.selected_category.id,
    //   },
    // };
    // // if (context.app_data.app_from == "seller") {
    // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id;
    // } else {
    //   pass_data.get.m_id = id_value.m_id;
    // }
    // let res_data = await api("/product/get_addon_groups", pass_data);
    // console.log("/product/get_addon_groups :", res_data);
    // if (res_data.status_code == 200) {
    //   if (res_data.response.hasOwnProperty("data")) {
    //     SetAccountAddonGroup(res_data.response.data);
    //   }
    // }

    let pass_data = {
      get: {
        page: filter_value.page_no,
        count: filter_value.count.value,
        sort: filter_value.sort.value,
        search: filter_value.search,
        spec: filter_value.spec,
        mrp: encodeURI(JSON.stringify(filter_value.mrp)),
        addon_groups: filter_value.addon_groups,
        category_id: basic_details.selected_category.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/get_all_addon_groups", pass_data);
    console.log("/product/get_all_addon_groups :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("addon")) {
        SetAccountAddonGroup(res_data.response.addon);
      }
    }
  }

  function onNext(data: any) {
    if (data.from == "basic_details") {
      console.log("onNext basic_details :", data);
      SetBasicDetails((prevValue: any) => {
        prevValue.selected_country = data.data.selected_country;
        prevValue.country = data.data.country;
        prevValue.selected_language = data.data.selected_language;
        prevValue.language = data.data.language;
        prevValue.selected_category = data.data.selected_category;
        prevValue.category = data.data.category;
        prevValue.selected_brand = data.data.selected_brand;
        prevValue.brand = data.data.brand;
        prevValue.name = data.data.name;
        prevValue.description = data.data.description;
        prevValue.selected_image = data.data.selected_image;
        prevValue.selected_category_group = data.data.selected_category_group;
        return { ...prevValue };
      });

      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      get_init_pre_specifications();
      get_account_addon(filter_data);
    }
    if (data.from == "specifiaction_data") {
      SetSpecificationData((prevValue: any) => {
        prevValue.common_data = data.data.common_data;
        prevValue.variant_data = data.data.variant_data;
        prevValue.varying_data = data.data.varying_data;
        return { ...prevValue };
      });
      SetCommonDetails((prevValue: any) => {
        prevValue.common_data = data.data.common_data;
        return { ...prevValue };
      });
      SetVariantDetails((prevValue: any) => {
        prevValue.variant_data = data.data.variant_data;
        return { ...prevValue };
      });
      let step_value;
      if (data.data.common_data.length > 0) {
        step_value = parseInt(data.active_step) + 1;
      } else {
        step_value = parseInt(data.active_step) + 2;
      }
      SetActiveStep(step_value);

      //   step_value = parseInt(data.active_step) + 1;
      //   SetActiveStep(step_value);
    }
    if (data.from == "common_data") {
      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      SetCommonDetails((prevValue: any) => {
        prevValue.common_data = data.data;
        return { ...prevValue };
      });
    }
    if (data.from == "variant_data") {
      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      SetVariantDetails((prevValue: any) => {
        prevValue.variant_data = data.data;
        return { ...prevValue };
      });
    }
    if (data.from == "addon_data") {
      let step_value = parseInt(data.active_step) + 1;
      SetActiveStep(step_value);
      generate_product_variants(avail_prod_name);
    }
  }

  function onPrev(data) {
    if (data.from == "specifiaction_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "specifiaction_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "common_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "variant_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "addon_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "review_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
    if (data.from == "mrp_data") {
      let step_value = parseInt(data.active_step) - 1;
      SetActiveStep(step_value);
    }
  }

  const BasicDetailsComponent = (child_props) => {
    const [basic_details_value, SetBasicDetailsValue] = useState(
      child_props.basic_data
    );
    const [modal_category, SetModalCategory] = useState({
      is_open: false,
      data: {},
    });
    const [modal_image, SetModalImage] = useState({
      is_open: false,
      selected_images: [],
    });

    const [basic_error_state, SetBasicErrorState] = useState({});

    function onImgSelect() {
      SetModalImage((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        // prevValue.selected_images = data.selected_img;
        return { ...prevValue };
      });
    }

    function validate(data) {
      console.log("validate :", data);
      let languageError = "";
      let categoryError = "";
      let group_nameError = "";

      if (Object.keys(data.selected_language).length == 0) {
        languageError = "Language is required";
      }

      if (Object.keys(data.selected_category).length == 0) {
        categoryError = "Category is required";
      }

      if (!data.name) {
        group_nameError = "Group Name is required";
      }

      if (languageError || categoryError || group_nameError) {
        SetBasicErrorState({ languageError, categoryError, group_nameError });
        return false;
      }
      return true;
    }

    function get_category_value() {
      let category_value = "";
      basic_details_value.selected_category_group.map(
        (ele: any, index: any) => {
          // if (basic_details_value.selected_category_group.length - 1 == index) {
          //   category_value = category_value + ele.name;
          // } else {
          //   category_value = category_value + ele.name + " » ";
          //   // ➤
          // }
          if (basic_details_value.selected_category_group.length - 1 == index) {
            category_value = category_value + ele.name;
          }
        }
      );
      return category_value;
    }

    return (
      <div className=" mb-5">
        <div className="d-flex mb-3 align-items-center">
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Basic Details</h6>
          </div>
          {/* next button */}
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              // let data = {
              //   from: 'basic_details',
              //   active_step: child_props.active_step,
              //   data: basic_details_value,
              // }
              // child_props.onNext(data)
              if (validate(basic_details_value)) {
                console.log("basic_validate ok");
                let data = {
                  from: "basic_details",
                  active_step: child_props.active_step,
                  data: basic_details_value,
                };
                child_props.onNext(data);
              } else {
                console.log("basic_validate not ok");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="d-sm-block d-md-none">
          <h6>Basic Details</h6>
        </div>
        <div className="row p-3">
          {/* languagee */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Language <span className="text-red">*</span>
            </label>
            <Select
              components={animatedComponents}
              placeholder="Select Language"
              className="basic-multi-select"
              classNamePrefix="select"
              options={context.app_data.languages}
              value={basic_details_value.selected_language}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(e) => {
                console.log(" Language :", e);
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_language = e;
                  prevValue.language = e.id;
                  return { ...prevValue };
                });
                SetBasicErrorState((prevValue: any) => {
                  // { languageError, categoryError, group_nameError }
                  prevValue.languageError = "";
                  return { ...prevValue };
                });
              }}
            />
            <span className="text-danger small pt-1 ps-1">
              {basic_error_state.languageError}
            </span>
          </div>
          {/* category */}
          <div
            className="col-md-6 mb-3"
            onClick={() => {
              // console.log('basic_details_value :',basic_details_value)
              SetModalCategory((prevValue: any) => {
                prevValue.is_open = true;
                return { ...prevValue };
              });
            }}
          >
            <label className="form-label">
              Category <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Category"
              // value={basic_details_value.selected_category.name}
              value={get_category_value()}
              onChange={(e) => { }}
              onFocus={() => {
                SetModalCategory((prevValue: any) => {
                  prevValue.is_open = true;
                  return { ...prevValue };
                });
              }}
            />
            <span className="text-danger small pt-1 ps-1">
              {basic_error_state.categoryError}
            </span>
          </div>
          {/* <p className="text-dark fw-bold pt-2">Group Details</p> */}
          <div className="col-md-6 md-border-right">
            {/* Product Name */}
            <div className="mb-3">
              <label className="form-label">
                Product Name <span className="text-red">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Product Name"
                value={basic_details_value.name}
                onChange={(e) => {
                  SetBasicDetailsValue((prevValue: any) => {
                    prevValue.name = e.target.value;
                    return { ...prevValue };
                  });
                  SetBasicErrorState((prevValue: any) => {
                    // { languageError, categoryError, group_nameError }
                    if (e.target.value == "") {
                      prevValue.group_nameError = "Product Name is required";
                    } else {
                      prevValue.group_nameError = "";
                    }
                    return { ...prevValue };
                  });
                }}
              />
              <span className="text-danger small pt-1 ps-1">
                {basic_error_state.group_nameError}
              </span>
            </div>
            {/* Description */}
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Description"
                value={basic_details_value.description}
                onChange={(e) => {
                  SetBasicDetailsValue((prevValue: any) => {
                    prevValue.description = e.target.value;
                    return { ...prevValue };
                  });
                }}
              />
            </div>
          </div>

          <div
            className={
              basic_details_value.selected_image.length > 0
                ? "col-md-5 ps-2"
                : "col-md-5 center ps-2"
            }
          >
            {basic_details_value.selected_image.length > 0 ? (
              <div className="">
                <div className="row py-0 px-2 overflow-img-scroll">
                  {basic_details_value.selected_image.map(
                    (img_src, img_index) => (
                      <div className="col-4 p-1" key={img_index}>
                        <img
                          src={AppHelper.imageSrc(
                            context.app_data.image_base_url,
                            img_src.url,
                            {
                              format: "webp",
                              width: 320,
                            }
                          )}
                          // src={
                          //   "https://cdn.1superapp.com/images/320/" +
                          //   img_src.url
                          // }
                          className="pre_img"
                        />
                      </div>
                    )
                  )}
                </div>
                <div
                  className="btn-page center cursor mt-3 mb-3"
                  onClick={() => {
                    onImgSelect(basic_details_value.selected_image);
                  }}
                >
                  <MaterialCommunityIcons
                    name="image-plus"
                    size={18}
                    color="gray"
                  />
                  <p className="ps-2 mb-0 text-darkgray fw-bold">
                    Add more images
                  </p>
                </div>
              </div>
            ) : (
              <div
                className="center cursor py-2"
                onClick={() => {
                  onImgSelect(modal_image.selected_images);
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <MaterialCommunityIcons
                      name="image-plus"
                      size={22}
                      color="gray"
                    />
                    <h6 className="ps-2 mb-0 text-darkgray">Add Images</h6>
                  </div>
                  <div className="mt-3">
                    <small className="">
                      <ul className="ps-0">
                        <li className="pb-1">Add your product images</li>
                        <li className="pb-1">No image file larger then 5MB</li>
                      </ul>
                    </small>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {modal_category.is_open ? (
          <ModalCategory
            open={modal_category.is_open}
            data={modal_category.data}
            close={(data: any) => {
              console.log("ModalCreateAddonItem on close :", data);
              SetModalCategory((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                // SetBasicDetails((prevValue: any) => {
                //   prevValue.selected_category = data.value;
                //   prevValue.category = data.value.id;
                //   return { ...prevValue };
                // });
                // SetCategoryValue((prevValue: any) => {
                //   prevValue.selected_category = data.value;
                //   prevValue.category = data.value.id;
                //   return { ...prevValue };
                // });
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_category = data.value;
                  prevValue.selected_category_group = data.group_value;
                  prevValue.category = data.value.id;
                  return { ...prevValue };
                });
                SetBasicErrorState((prevValue: any) => {
                  // { languageError, categoryError, group_nameError }
                  prevValue.categoryError = "";
                  return { ...prevValue };
                });
              }
            }}
          />
        ) : null}
        {modal_image.is_open ? (
          <ModalAddGallery
            open={modal_image.is_open}
            type={modal_image.type}
            data={modal_image.data}
            selected={modal_image.selected_images}
            from={"add_product"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalImage((prevValue: any) => {
                prevValue.is_open = false;
                if (data.action == "save") {
                  prevValue.selected_images = data.value;
                }
                return { ...prevValue };
              });
              if (data.action == "save") {
                SetBasicDetailsValue((prevValue: any) => {
                  prevValue.selected_image = data.value;
                  return { ...prevValue };
                });
              }

              // if (data.action == "save") {
              //   console.log("ModalAddGallery on close save :", modal_image);
              //   SetCombiData((prevValue: any) => {
              //     prevValue[modal_image.index].images = data.value;
              //     return [...prevValue];
              //   });
              // }
            }}
          />
        ) : null}
      </div>
    );
  };

  const SpecificationDataComponent = (child_props) => {
    const [expanded, SetExpanded] = useState("panel1");

    function checkbox_on_change(get_value: any) {
      SetInitSpecData((prevValue: any) => {
        if (get_value.event.target.checked) {
          prevValue[get_value.index].checked = true;
          if (get_value.from == "common_data") {
            prevValue[get_value.index].data_type = "common_data";
          }
          if (get_value.from == "varriant_data") {
            prevValue[get_value.index].data_type = "varriant_data";
          }
          if (get_value.from == "varying_data") {
            prevValue[get_value.index].data_type = "varying_data";
          }
        } else {
          prevValue[get_value.index].checked = false;
          prevValue[get_value.index].data_type = "";
        }
        return [...prevValue];
      });
    }

    async function process_next() {
      let next_pass_data = {};
      let common_data_value = [];
      let variant_data_value = [];
      let varying_data_value = [];
      let template_data = [];

      let spec_data_with_child = init_spec_data;

      let pass_data = {
        get: {
          category_id: basic_details.selected_category.id,
        },
        post: {
          specification: init_spec_data,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("init_specifications pass_data :", pass_data);
      let res_data = await api("/product/init_specifications", pass_data);
      console.log("/product/init_specifications response  :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.hasOwnProperty("data")) {
          spec_data_with_child.map((ele: any) => {
            res_data.response.data.map((data: any) => {
              if (
                ele.data_type == data.data_type &&
                ele.name == data.name &&
                ele.slug == data.slug &&
                ele.source_type == data.source_type &&
                ele.type == data.type
              ) {
                if (data.hasOwnProperty("linked")) {
                  ele.linked = data.linked;
                }
                if (data.hasOwnProperty("children")) {
                  ele.children = data.children;
                }
              }
            });
          });
        }
      }

      spec_data_with_child.map((ele: any, index: any) => {
        if (ele.checked === true) {
          if (ele.source_type == "templates") {
            let data = {
              from: ele.data_type,
              value: {
                name: ele.name,
                type: ele.type,
              },
            };
            if (ele.hasOwnProperty("children")) {
              let child_value = [];
              ele.children.map((c_ele: any, c_index: any) => {
                child_value.push({ name: c_ele.label });
              });
              data.value.children = child_value;
            }
            template_data.push(data);
          } else {
            if (ele.data_type == "common_data") {
              ele.error = "";
              if (ele.hasOwnProperty("linked")) {
                ele.linked_error = "";
              }
              common_data_value.push(ele);
            }
            if (ele.data_type == "varriant_data") {
              if (!Array.isArray(ele.value)) {
                ele.value = [];
              }
              ele.error = "";
              variant_data_value.push(ele);
            }
            if (ele.data_type == "varying_data") {
              ele.error = "";
              varying_data_value.push(ele);
            }
          }
        }
      });

      if (template_data.length > 0) {
        let spec_data = [];
        template_data.map((element: any) => {
          spec_data.push(element.value);
        });
        let pass_data = {
          get: {},
          post: {
            category_id: basic_details.selected_category.id,
            specification_data: spec_data,
            location: "create_product",
          },
        };
        // if (context.app_data.app_from == 'seller') {
        if (id_value.hasOwnProperty("brand_id")) {
          pass_data.get.brand = id_value.brand_id;
        } else {
          pass_data.get.m_id = id_value.m_id;
        }

        let res_data = await api("/product/add_pre_specification", pass_data);
        if (res_data.status_code == 201) {
          if (res_data.response.hasOwnProperty("data")) {
            res_data.response.data.map((ele: any, index: any) => {
              ele.checked = true;
              if (template_data[index].from == "common_data") {
                common_data_value.push(res_element);
              }
              if (template_data[index].from == "variant_data") {
                variant_data_value.push(res_element);
              }
              if (template_data[index].from == "varying_data") {
                varying_data_value.push(res_element);
              }
            });
          }
        }
      }

      //   next_pass_data.common_data = common_data_value;
      next_pass_data.common_data = varying_data_value;
      next_pass_data.variant_data = variant_data_value;
      next_pass_data.varying_data = varying_data_value;

      let data = {
        from: "specifiaction_data",
        active_step: child_props.active_step,
        data: next_pass_data,
      };
      console.log("specifiaction_data on next data :", data);
      child_props.onNext(data);
    }

    return (
      <div className=" mb-5">
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "specifiaction_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className=" w-100 d-md-block d-sm-none text-center">
            <h6>Specification Data</h6>
          </div>
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              let req_ele = [];
              init_spec_data.map((ele: any, index: any) => {
                if (ele.required) {
                  req_ele.push(ele);
                }
              });

              if (req_ele.every((ele) => ele.checked == true)) {
                console.log("All required Checked");
                process_next();
              } else {
                toast.error("Please select the required fields");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="d-flex py-2 align-items-center">
          <div className="d-sm-block d-md-none">
            <h6>Specification Data</h6>
          </div>
          <div
            className="d-flex align-items-center text-primary fw-bold ms-auto cursor"
            onClick={() => {
              SetModalSpecification({
                is_open: true,
                category: basic_details.selected_category,
              });
            }}
          >
            <div className="me-1">
              <Entypo name="plus" size={16} color="#297ad0" />
            </div>
            Add New
          </div>
        </div>
        <div className="pt-4 row ">
          <p className="fs-7 fw-bold text-dark">Recommended</p>
          {init_spec_data.some((ss_ele) => ss_ele.recommended == true) ? (
            init_spec_data.map((data, index) =>
              data.hasOwnProperty("addon") ? null : data.recommended ? (
                <div className="col-md-4 pb-2" key={index}>
                  {/* <label className="">
                      {data.name}
                      {data.required ? "*" : null}
                    </label> */}
                  <div className="d-flex">
                    <label className="p-2 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={data.data_type == "varying_data"}
                        onChange={(e) => {
                          let pass_value = {
                            from: "varying_data",
                            index: index,
                            ele: data,
                            event: e,
                          };
                          checkbox_on_change(pass_value);
                        }}
                      />
                      {data.name}
                      {data.required ? "*" : null}
                      {/* <small>Varying Data</small> */}
                    </label>
                  </div>
                </div>
              ) : null
            )
          ) : (
            <div className="center p-2">
              <hr className="w-30px" />
            </div>
          )}
        </div>
        <div className="row pt-3">
          <p className="fs-7 fw-bold text-dark">Others</p>
          {init_spec_data.map((data, index) =>
            data.hasOwnProperty("addon") ? null : !data.recommended ? (
              <div className="col-md-4 pb-2" key={index}>
                {/* <label className="">
                      {data.name}
                      {data.required ? "*" : null}
                    </label> */}
                <div className="d-flex">
                  <label className="p-2 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={data.data_type == "varying_data"}
                      onChange={(e) => {
                        let pass_value = {
                          from: "varying_data",
                          index: index,
                          ele: data,
                          event: e,
                        };
                        checkbox_on_change(pass_value);
                      }}
                    />
                    {data.name}
                    {data.required ? "*" : null}
                    {/* <small>Varying Data</small> */}
                  </label>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    );
  };

  async function get_init_pre_specifications() {
    let pass_data = {
      get: {
        category_id: basic_details.selected_category.id,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("pass_data :", pass_data);
    let res_data = await api("/product/init_specifications", pass_data);
    console.log("/product/init_specifications response  :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("data")) {
        SetPreSpecData(res_data.response.data);
        SetCommonData(res_data.response.data);
        SetVariantData(res_data.response.data);
        SetVaryingData(res_data.response.data);
        SetInitSpecData(res_data.response.data);
        SetSpecTypeOptions(res_data.response.options.type);
      }
      if (res_data.response.hasOwnProperty("options")) {
        SetSpecTypeOptions(res_data.response.options.type);
      }
    }
  }

  const CommonDataComponent = (child_props) => {
    // const [common_data, SetCommonData] = useState(child_props.common_data)

    const [common_data, SetCommonData] = useState([]);

    useEffect(() => {
      // if (child_props.common_data) {
      //   validate(child_props.common_data)
      // }
      // validate(child_props.common_data)
      if (child_props.active_step == 3) {
        console.log("CommonDataComponent active_step == 3 :", child_props);
        SetCommonData(child_props.common_data);
      }
    }, [child_props]);

    useEffect(() => {
      if (common_data.length > 0) {
        console.log("CommonDataComponent common_data on change :", common_data);
      }
    }, [common_data]);

    function on_select(data, actionMeta, index, ele) {
      console.log("on_select data :", data);
      console.log("on_select actionMeta :", actionMeta);
      console.log("on_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }

      if (actionMeta.action == "create-option") {
        let pass_value = {
          created_data: data,
          ele: ele,
          index: index,
        };
        add_spec_item(pass_value);
        // SetCommonData((prevValue: any) => {
        //   prevValue[index].value = data;
        //   return [...prevValue];
        // });
      }

      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = [];
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
    }

    async function add_spec_item(get_value: any) {
      let pass_data = {
        get: {},
        post: {
          specification: [
            {
              id: get_value.ele.id,
              items: [{ name: get_value.created_data.value }],
            },
          ],
          init: 1,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("add_spec_item create_specification pass_data :", pass_data);
      let res_data = await api("/product/create_specification", pass_data);
      console.log("add_spec_item create_specification res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success("Specification Item Created");
          if (res_data.response.hasOwnProperty("data")) {
            SetCommonData((prevValue: any) => {
              prevValue[get_value.index].children =
                res_data.response.data[0].children;
              // prevValue[index].value = get_value.created_data;
              validate(prevValue, get_Value.index, "one");

              return [...prevValue];
            });
          }
        }
      }
    }

    function on_multi_select(data, actionMeta, index) {
      console.log("on_multi_select data :", data);
      console.log("on_multi_select actionMeta :", actionMeta);
      console.log("on_multi_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }
      if (actionMeta.action == "create-option") {
        let pass_value = {
          created_data: data,
          ele: ele,
        };
        add_spec_item(pass_value);
        // SetCommonData((prevValue: any) => {
        //   prevValue[index].value = data;
        //   return [...prevValue];
        // });
      }
      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }
      if (actionMeta.action == "remove-value") {
        SetCommonData((prevValue: any) => {
          prevValue[index].value = data;
          validate(prevValue, index, "one");
          return [...prevValue];
        });
      }
    }

    function linked_on_select(data, actionMeta, index) {
      console.log("linked_on_select data :", data);
      console.log("linked_on_select actionMeta :", actionMeta);
      console.log("linked_on_select index :", index);
      if (actionMeta.action == "select-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = data;
          prevValue[index].linked_value = data;
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }

      if (actionMeta.action == "create-option") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = data;
          prevValue[index].linked_value = data;
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }

      if (actionMeta.action == "clear") {
        SetCommonData((prevValue: any) => {
          prevValue[index].linked.value = {};
          prevValue[index].linked_value = {};
          validate(prevValue, index, "one");

          return [...prevValue];
        });
      }
    }

    function linked_on_multi_select(data, actionMeta, index) {
      console.log("linked_on_multi_select data :", data);
      console.log("linked_on_multi_select actionMeta :", actionMeta);
      console.log("linked_on_multi_select index :", index);
      SetCommonData((prevValue: any) => {
        prevValue[index].linked.value = data;
        prevValue[index].linked_value = data;

        validate(prevValue, index, "one");

        return [...prevValue];
      });
    }

    function validate(data, index_value, type_value) {
      let common_data_value = data;
      common_data_value.map((ele: any, e_index: any) => {
        if (type_value == "all") {
          if (ele.type == "Textarea") {
            if (!ele.value) {
              ele.error = "Text Area Value Required";
            } else {
              ele.error = "";
            }
          }
          if (ele.type == "Text Field") {
            if (ele.hasOwnProperty("linked")) {
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (!ele.value) {
                ele.error = "Text Field Value Required";
              } else {
                ele.error = "";
              }
            }
          }
          if (ele.type == "Select") {
            if (ele.hasOwnProperty("linked")) {
              if (Object.keys(ele.value).length == 0) {
                ele.error = "Select Value Required";
              } else {
                ele.error = "";
              }
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (Object.keys(ele.value).length == 0) {
                ele.error = "Select Value Required";
              } else {
                ele.error = "";
              }
            }
          }
          if (ele.type == "Multi Select") {
            if (ele.hasOwnProperty("linked")) {
              if (ele.linked.type == "Select") {
                if (Object.keys(ele.linked.value).length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
              if (ele.linked.type == "Multi Select") {
                if (ele.linked.value.length == 0) {
                  ele.linked_error = "Unit Value Required";
                } else {
                  ele.linked_error = "";
                }
              }
            } else {
              if (ele.value.length == 0) {
                ele.error = "Multi Select Value Required";
              } else {
                ele.error = "";
              }
            }
          }
        }

        if (type_value == "one") {
          if (e_index == index_value) {
            if (ele.type == "Textarea") {
              if (!ele.value) {
                ele.error = "Text Area Value Required";
              } else {
                ele.error = "";
              }
            }
            if (ele.type == "Text Field") {
              if (ele.hasOwnProperty("linked")) {
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (!ele.value) {
                  ele.error = "Text Field Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
            if (ele.type == "Select") {
              if (ele.hasOwnProperty("linked")) {
                if (Object.keys(ele.value).length == 0) {
                  ele.error = "Select Value Required";
                } else {
                  ele.error = "";
                }
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (Object.keys(ele.value).length == 0) {
                  ele.error = "Select Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
            if (ele.type == "Multi Select") {
              if (ele.hasOwnProperty("linked")) {
                if (ele.linked.type == "Select") {
                  if (Object.keys(ele.linked.value).length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
                if (ele.linked.type == "Multi Select") {
                  if (ele.linked.value.length == 0) {
                    ele.linked_error = "Unit Value Required";
                  } else {
                    ele.linked_error = "";
                  }
                }
              } else {
                if (ele.value.length == 0) {
                  ele.error = "Multi Select Value Required";
                } else {
                  ele.error = "";
                }
              }
            }
          }
        }
      });
      console.log("common_data_value :", common_data_value);
      SetCommonData((prevValue: any) => {
        prevValue = common_data_value;
        return [...prevValue];
      });
      if (common_data_value.some((ele) => ele.error)) {
        return false;
      }
      return true;
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex mb-3 align-items-center">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "common_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Common Data</h6>
          </div>
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              // console.log('common_data :', common_data)
              // let data = {
              //   from: 'common_data',
              //   active_step: child_props.active_step,
              //   data: common_data,
              // }
              // child_props.onNext(data)
              console.log("common_data :", common_data);

              if (validate(common_data, 0, "all")) {
                console.log("Validation ok");
                let data = {
                  from: "common_data",
                  active_step: child_props.active_step,
                  data: common_data,
                };
                child_props.onNext(data);
              } else {
                console.log("Validation not ok");
              }
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="py-2 mb-2 d-md-none d-sm-block">
          <h6>Common Data</h6>
        </div>
        <div className="row p-3">
          {common_data.map((ele: any, index: any) => (
            <div className="pt-3 col-lg-4 col-md-6" key={index}>
              <label className="form-label">{ele.name}</label>
              {ele.type == "Text Field" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={ele.value}
                      className="form-control"
                      placeholder="Enter here..."
                      onChange={(e) => {
                        console.log(
                          " on change common e text field :",
                          e.target.value
                        );
                        console.log(" ele.linked :", ele.linked);
                        SetCommonData((prevValue: any) => {
                          prevValue[index].value = e.target.value;
                          validate(prevValue, index, "one");

                          return [...prevValue];
                        });
                      }}
                    />
                    <div className="input-group-text p-0">
                      {ele.linked.type == "Select" ? (
                        <Select
                          onChange={(data_value, actionMeta) => {
                            linked_on_select(data_value, actionMeta, index);
                            console.log(
                              "ele linked child :",
                              ele.linked.children
                            );
                            console.log("ele linked value :", ele.linked.value);
                          }}
                          options={ele.linked.children}
                          tabSelectsValue={false}
                          value={ele.linked.value}
                          menuPortalTarget={document.body}
                          placeholder="Select..."
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({ ...base, border: "none" }),
                            indicatorSeparator: (base) => ({
                              ...base,
                              backgroundColor: "none",
                            }),
                          }}
                          className="no-radius border-none"
                        />
                      ) : (
                        <Select
                          isMulti
                          onChange={(data_value, actionMeta) => {
                            linked_on_multi_select(
                              data_value,
                              actionMeta,
                              index
                            );
                          }}
                          options={ele.linked.children}
                          value={ele.linked.value}
                          menuPortalTarget={document.body}
                          placeholder="Select..."
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          className="no-radius border-none"
                        />
                      )}
                    </div>
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <>
                    <input
                      type="text"
                      value={ele.value}
                      className="form-control"
                      placeholder="Enter here..."
                      onChange={(e) => {
                        console.log(
                          " on change common e text field :",
                          e.target.value
                        );
                        SetCommonData((prevValue: any) => {
                          prevValue[index].value = e.target.value;
                          validate(prevValue, index, "one");
                          return [...prevValue];
                        });
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </>
                )
              ) : null}

              {ele.type == "Textarea" ? (
                <>
                  <textarea
                    value={ele.value}
                    className="form-control"
                    placeholder="Enter here..."
                    onChange={(e) => {
                      console.log(
                        " on change common e Textarea :",
                        e.target.value
                      );
                      SetCommonData((prevValue: any) => {
                        prevValue[index].value = e.target.value;
                        validate(prevValue, index, "one");
                        return [...prevValue];
                      });
                    }}
                  />
                  <span className="text-danger">{ele.error}</span>
                </>
              ) : null}

              {ele.type == "Select" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="d-flex">
                    <div className="">
                      <CreatableSelect
                        isClearable
                        value={ele.value}
                        onChange={(data_value, actionMeta) => {
                          on_select(data_value, actionMeta, index, ele);
                        }}
                        options={ele.children}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        placeholder="Select..."
                      />
                    </div>
                    {ele.linked.type == "Select" ? (
                      <Select
                        onChange={(data_value, actionMeta) => {
                          linked_on_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        placeholder="Select..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        isMulti
                        onChange={(data_value, actionMeta) => {
                          linked_on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        placeholder="Select..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <div className="">
                    {ele.slug == "product_brand" ||
                      ele.slug == "country_of_orgin" ? (
                      <>
                        <Select
                          isClearable
                          value={ele.value}
                          onChange={(data_value, actionMeta) => {
                            console.log("on select ele :", ele);
                            on_select(data_value, actionMeta, index, ele);
                          }}
                          options={ele.children}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <span className="text-danger">{ele.error}</span>
                      </>
                    ) : (
                      <>
                        <CreatableSelect
                          isClearable
                          value={ele.value}
                          onChange={(data_value, actionMeta) => {
                            console.log("on select ele :", ele);
                            on_select(data_value, actionMeta, index, ele);
                          }}
                          options={ele.children}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <span className="text-danger">{ele.error}</span>
                      </>
                    )}
                  </div>
                )
              ) : null}

              {ele.type == "Multi Select" ? (
                ele.hasOwnProperty("linked") ? (
                  <div className="d-flex">
                    <div className="">
                      <CreatableSelect
                        isClearable
                        isMulti
                        value={ele.value}
                        onChange={(data_value, actionMeta) => {
                          on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.children}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                    {ele.linked.type == "Select" ? (
                      <Select
                        onChange={(data_value, actionMeta) => {
                          linked_on_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        isMulti
                        onChange={(data_value, actionMeta) => {
                          linked_on_multi_select(data_value, actionMeta, index);
                        }}
                        options={ele.linked.children}
                        value={ele.linked.value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                    <span className="text-danger">{ele.error}</span>
                    <span className="text-danger">{ele.linked_error}</span>
                  </div>
                ) : (
                  <div className="">
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={ele.value}
                      onChange={(data_value, actionMeta) => {
                        on_multi_select(data_value, actionMeta, index);
                      }}
                      options={ele.children}
                      menuPortalTarget={document.body}
                      placeholder="Select..."
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <span className="text-danger">{ele.error}</span>
                  </div>
                )
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const VariantDataComponent = (child_props) => {
    const [variant_data, SetVariantData] = useState(child_props.variant_data);

    function variant_on_select(data, actionMeta, index, ele) {
      if (actionMeta.action == "create-option") {
        // create_child(ele.id, actionMeta.option.value, index);
        let pass_value = {
          created_data: data,
          ele: ele,
          index: index,
        };
        add_spec_item(pass_value);
      } else {
        SetVariantData((prevValue: any) => {
          prevValue[index].value = data;
          return [...prevValue];
        });
      }
    }

    async function create_child(group_id_value, spec_item_value, index) {
      let pass_data = {
        get: {},
        post: {
          specification_group_id: group_id_value,
          specification_item: spec_item_value,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("create_child pass_data :", pass_data);
      let res_data = await api(
        "/product/add_pre_specification_item",
        pass_data
      );
      console.log("res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.hasOwnProperty("id")) {
          SetVariantData((prevValue: any) => {
            let child_value = prevValue[index].children;
            child_value.push(res_data.response);
            prevValue[index].children = child_value;
            return [...prevValue];
          });
        }
      }
    }

    async function add_spec_item(get_value: any) {
      console.log("add_spec_item Get value :", get_value);
      let created_options = {
        name: "",
      };
      get_value.created_data.map((ele: any) => {
        if (ele.label == ele.value) {
          created_options.name = ele.label;
        }
      });
      let pass_data = {
        get: {},
        post: {
          specification: [
            {
              id: get_value.ele.id,
              items: [{ name: created_options.name }],
            },
          ],
          init: 1,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("add_spec_item create_specification pass_data :", pass_data);
      let res_data = await api("/product/create_specification", pass_data);
      console.log("add_spec_item create_specification res_data :", res_data);
      if (res_data.status_code == 200) {
        if (res_data.response.status) {
          toast.success("Specification Item Created");
          if (res_data.response.hasOwnProperty("data")) {
            SetVariantData((prevValue: any) => {
              prevValue[get_value.index].children =
                res_data.response.data[0].children;
              return [...prevValue];
            });
          }
        }
      }
    }

    function on_next() {
      console.log("variant data :", variant_data);
      let data = {
        active_step: child_props.active_step,
        from: "variant_data",
        data: variant_data,
      };
      child_props.onNext(data);
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "variant_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Variant Data</h6>
          </div>
          <button
            className="btn btn-primary ms-auto"
            onClick={() => {
              // let data = {
              //   from: "variant_data",
              //   active_step: child_props.active_step,
              // };
              // child_props.onNext(data);
              console.log("varriant data");
              on_next();
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        <div className="d-md-none d-sm-block py-2 mb-2">
          <h6>Variant Data</h6>
        </div>
        <div className="row">
          <div className="">
            {variant_data.map((ele: any, index: any) => (
              <div className="pt-3" key={index}>
                <div className="pb-3 row">
                  <label className="col-7">{ele.name}</label>

                  <div className="col-md-5">
                    <label className="cursor end mb-0">
                      <input
                        type="checkbox"
                        className="me-2"
                        onClick={(event) => {
                          if (event.target.checked) {
                            SetVariantData((prevValue: any) => {
                              prevValue[index].value =
                                prevValue[index].children;
                              return [...prevValue];
                            });
                          } else {
                            SetVariantData((prevValue: any) => {
                              prevValue[index].value = [];
                              return [...prevValue];
                            });
                          }

                          console.log("select all :", variant_data);
                        }}
                      />
                      Select all
                    </label>
                  </div>
                </div>
                {ele.hasOwnProperty("strict") ? (
                  ele.strict ? (
                    <CreatableSelect
                      isMulti
                      onChange={(data_value, actionMeta) => {
                        variant_on_select(data_value, actionMeta, index, ele);
                        console.log("strict data_value :", data_value);
                      }}
                      options={ele.children}
                      value={ele.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  ) : (
                    <CreatableSelect
                      isMulti
                      onChange={(data_value, actionMeta) => {
                        variant_on_select(data_value, actionMeta, index, ele);
                        console.log("strict else data_value :", data_value);
                      }}
                      options={ele.children}
                      value={ele.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )
                ) : (
                  <CreatableSelect
                    isMulti
                    onChange={(data_value, actionMeta) => {
                      variant_on_select(data_value, actionMeta, index, ele);
                      console.log("no strict else data_value :", data_value);
                    }}
                    options={ele.children}
                    value={ele.value}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const AddonDataComponent = (child_props) => {
    function on_next_addon() {
      console.log("addon data :", addon_details);
      let data = {
        active_step: child_props.active_step,
        from: "addon_data",
      };
      child_props.onNext(data);
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "addon_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Addon Data</h6>
          </div>
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              // let data = {
              //   from: "variant_data",
              //   active_step: child_props.active_step,
              // };
              // child_props.onNext(data);
              console.log("Addon data :", addon_details.addon_data);
              on_next_addon();
            }}
          >
            <span className="pe-2 text-primary">Next</span>
            <FontAwesome5 name="angle-double-right" size={14} color="#0053ad" />
          </button>
        </div>
        {/* header */}
        <div className="d-flex align-items-center py-2 mb-2">
          <div className="d-md-none d-sm-block">
            <h6>Addon Data</h6>
          </div>
          <div
            className="text-primary cursor ms-auto fw-bold"
            onClick={() => {
              SetModalAddon({
                is_open: true,
                category: basic_details.selected_category,
              });
              console.log("Add Addon");
            }}
          >
            Add addons
          </div>
        </div>
        {/* Body */}
        <div className="row px-1">
          {addon_details.addon_data.map((addon_ele: any, addon_index: any) => (
            <div className="col-md-6  p-2" key={addon_index}>
              <div className="p-3 pb-0 border radius">
                <div className="d-flex">
                  <div className="">
                    <h6>{addon_ele.label}</h6>
                  </div>
                  <div className="ms-auto cursor">
                    <p
                      onClick={() => {
                        SetAddonDetails((prevValue: any) => {
                          prevValue.addon_data.splice(addon_index, 1);
                          return { ...prevValue };
                        });
                      }}
                    >
                      Remove
                    </p>
                  </div>
                </div>

                {addon_ele.is_dependent ? (
                  <div className="row">
                    {addon_ele.children.map((child, i) => (
                      <div key={i} className="col-12 col-md-6">
                        <p className="mt-2 text-dark font-weight-bold">
                          {child.name}
                        </p>
                        <div>
                          <ul className="p-0">
                            {child.varriant.map((v_ele, v_index) => (
                              <li key={v_index} className="">
                                {v_ele.label} ➤
                                {v_ele.hasOwnProperty("mrp") &&
                                  Array.isArray(v_ele.mrp)
                                  ? v_ele.mrp.map(
                                    (mrp_ele: any, mrp_index: any) => (
                                      <span className="pl-2" key={mrp_index}>
                                        # {mrp_ele.price}
                                      </span>
                                    )
                                  )
                                  : null}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    {addon_ele.children.map((child, i) => (
                      <div className="col-12 col-md-6" key={i}>
                        <ul className="p-0">
                          <li className="">
                            {child.name} ➤
                            <div className="pt-2">
                              {child.mrp.map((mrp_ele: any, mrp_index: any) => (
                                <div className="" key={mrp_index}>
                                  <p className="mb-2">
                                    Country : {mrp_ele.country}
                                  </p>
                                  {mrp_ele.hasOwnProperty("state") ? (
                                    <p className="mb-2">
                                      State : {mrp_ele.state}
                                    </p>
                                  ) : null}
                                  <p className="mb-2">MRP : {mrp_ele.price}</p>
                                </div>
                              ))}
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ReviewComponent = (child_props) => {
    const [combi_data, SetCombiData] = useState(
      child_props.combination_data_value
    );
    const [modal_image, SetModalImage] = useState({
      is_open: false,
      index: "",
      selected_images: [],
    });
    const [modal_review_addon, SetModalReviewAddon] = useState({
      is_open: false,
      index: "",
      data: "",
    });

    function row_on_delete(index) {
      console.log("ReviewComponent row_on_delete index :", index);
      SetCombiData((prevValue: any) => {
        prevValue = prevValue.filter((_, i) => i != index);
        return [...prevValue];
      });
    }

    function on_add_addon(index) {
      console.log("ReviewComponent on_add_addon index :", index);
      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.data = {
          type: "add",
          addon: child_props.CustomAddonData,
        };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    function on_addon_delete(index) {
      console.log("ReviewComponent on_addon_delete :", index);

      SetCombiData((prevValue: any) => {
        prevValue[index].addon_value = false;
        prevValue[index].addon = "";
        console.log(
          "ReviewComponent on_addon_delete SetCombiData prevValue :",
          prevValue
        );

        return [...prevValue];
      });
    }

    function on_edit_addon(index, checked_value) {
      console.log("ReviewComponent on_edit_addon index :", index);
      console.log(
        "ReviewComponent on_edit_addon checked_value :",
        checked_value
      );
      // var dependant_addon_value = [];
      // addon_details.addon_data
      //   .filter((addon_element) => addon_element.dependent == true)
      //   .map((addon_filter_element) => {
      //     dependant_addon_value.push(addon_filter_element);
      //   });

      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        prevValue.data = {
          type: "edit",
          addon: child_props.CustomAddonData,
          checked: checked_value,
        };
        prevValue.index = index;
        return { ...prevValue };
      });
    }

    function on_remove_addon(index, row_data) {
      console.log("ReviewComponent on_remove_addon index :", index);
      console.log("ReviewComponent on_remove_addon row_data :", row_data);
      SetCombiData((prevValue: any) => {
        prevValue[index] = row_data;
        return [...prevValue];
      });
    }

    function on_addon_change(index, row_data) {
      console.log("ReviewComponent on_addon_change index :", index);
      console.log("ReviewComponent on_addon_change row_data :", row_data);
      SetCombiData((prevValue: any) => {
        prevValue[index] = row_data;
        return [...prevValue];
      });
    }

    function on_image_select(data) {
      console.log("ReviewComponent on_image_select data :", data);
      SetModalImage((prevValue: any) => {
        prevValue.is_open = !prevValue.is_open;
        prevValue.index = data.index;
        prevValue.selected_images = data.selected_img;
        return { ...prevValue };
      });
    }

    function on_text_box_change(data_value1, type) {
      console.log("ReviewComponent on_text_box_change :", data_value1);
      // let index = data_value1.send_data.index;
      // let head = data_value1.send_data.head;
      // let value = data_value1.send_data.value;
      if (type == "textbox") {
        SetCombiData((prevValue: any) => {
          prevValue[data_value1.index].row_data[data_value1.head].value =
            data_value1.value;
          console.log(
            "SetCombiData prevValue on_text_box_change !data_value1.have_link textbox :",
            prevValue
          );
          return [...prevValue];
        });
      } else {
        SetCombiData((prevValue: any) => {
          prevValue[data_value1.index].row_data[data_value1.head].value =
            data_value1.value;
          console.log(
            "SetCombiData prevValue on_text_box_change textarea :",
            prevValue
          );
          return [...prevValue];
        });
      }
    }

    function addon_modal_on_submit(pass_data) {
      console.log(
        "Review Addon modal on submit review page pass_data :",
        pass_data
      );
      console.log(
        "CustomAddonData on submit review page :",
        addon_details.addon_data
      );
      let add_data = addon_details.addon_data;
      let sa_data = [];
      let a1_data = [];
      pass_data.addon.map((p_a_ele, p_a_index) => {
        let parent = {};
        add_data.map((element, index) => {
          parent = {
            label: element.label,
            value: element.value,
            dependent_id: element.dependent_id,
            is_dependent: element.is_dependent,
            children: [],
          };
          let found = element.children.find((child) => child.id == p_a_ele);
          if (found) {
            parent.children.push(found);
            sa_data.push(parent);
          }
        });
      });

      sa_data.map((element, index) => {
        if (a1_data) {
          let found = a1_data.find((ele) => ele.value == element.value);
          if (found) {
            let index = a1_data.findIndex((a1) => a1.value == found.value);
            a1_data[index].children.push(element.children[0]);
          } else {
            a1_data.push(element);
          }
        } else {
          a1_data.push(element);
        }
      });
      console.log("a1_data :", a1_data);
      SetModalReviewAddon((prevValue: any) => {
        prevValue.is_open = false;
        return { ...prevValue };
      });
      SetCombiData((prevValue: any) => {
        prevValue[pass_data.index].addon_value = true;
        prevValue[pass_data.index].checked = pass_data.addon;
        prevValue[pass_data.index].addon = a1_data;
        console.log("SetCombiData prevValue :", prevValue);
        return [...prevValue];
      });
    }

    function save_mrp_data(data: any, index: any) {
      console.log("save_mrp_data data :", data);
      console.log("save_mrp_data index :", index);

      SetCombiData((prevValue: any) => {
        prevValue[index].mrp = data.mrp_data;
        console.log(
          "ReviewComponent save_mrp_data SetCombiData prevValue :",
          prevValue
        );
        return [...prevValue];
      });
    }

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "review_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none text-center">
            <h6>Products Review</h6>
          </div>
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "review_data",
                active_step: child_props.active_step,
                data: combi_data,
              };
              child_props.onSubmit(data);
            }}
          >
            <MaterialCommunityIcons
              name="checkbox-marked-circle-outline"
              size={16}
              color="#0053ad"
            />
            <span className="ps-2 text-primary">Submit</span>
          </button>
        </div>
        {/* header */}
        <div className="py-3">
          <div className="d-md-none d-sm-block">
            <h6>Products Review</h6>
          </div>
          <p>
            <small>Check product details</small>
          </p>
        </div>
        <div className="row">
          {/* Table */}
          <div className="mt-3 table-responsive text-nowrap">
            <table className="table table-hover w-100 pt-3">
              <thead>
                <tr>
                  <th>Default</th>
                  {combination_head.map((head, i) => (
                    <th key={i}>{head}</th>
                  ))}
                  {child_props.CustomAddonData.length > 0 ? (
                    <th>Addon</th>
                  ) : null}
                  {true ? <th>Mrp</th> : null}
                  {combi_data.length > 1 ? <th>Action</th> : null}
                </tr>
              </thead>
              <tbody>
                {/* {combination_row.length == 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <em>Loading...</em>
                    </td>
                  </tr>
                ) : ( */}
                {combi_data.map((row, i) => (
                  <ProductAccordion
                    key={i}
                    index={i}
                    data={row}
                    header={combination_head}
                    onDefaultChange={(data) => {
                      console.log("default on change");
                      SetCombinationData((prevValue: any) => {
                        prevValue.map((pre_ele: any, pre_index: any) => {
                          if (pre_index == data.index) {
                            pre_ele.default = true;
                          } else {
                            pre_ele.default = false;
                          }
                        });
                        return [...prevValue];
                      });
                    }}
                    onDelete={(data) => {
                      row_on_delete(data);
                    }}
                    onAddAddon={(data) => {
                      on_add_addon(data);
                    }}
                    onEditAddon={(index, checked_value) => {
                      on_edit_addon(index, checked_value);
                    }}
                    onRemoveAddon={(data) => {
                      on_remove_addon(data);
                    }}
                    onAddonChange={(data) => {
                      on_addon_change(data);
                    }}
                    onAddonDelete={(data) => {
                      on_addon_delete(data);
                    }}
                    onImgSelect={(data) => {
                      on_image_select(data);
                    }}
                    onTextChange={(data) => {
                      console.log("index :", i);
                      console.log("row :", row);

                      on_text_box_change(data, "textbox");
                    }}
                    OnTextAreaChange={(data) => {
                      on_text_box_change(data, "textarea");
                    }}
                    mrp_on_save={(data, index) => {
                      console.log("mrp_on save data:", data);
                      console.log("mrp_on save index:", index);
                      save_mrp_data(data, index);
                    }}
                    OnLinkedSelectChange={(data) => {
                      console.log("OnLinkedSelectChange data :", data);
                      const new_combi_data = [...combi_data];
                      new_combi_data[data.index].row_data[
                        data.head
                      ].all_data.linked.value = data.value;
                      new_combi_data[data.index].row_data[
                        data.head
                      ].linked_value = data.value;
                      SetCombiData(new_combi_data);
                    }}
                    OnSelectChange={(data) => {
                      console.log("OnSelectChange data :", data);
                      const new_combi_data = [...combi_data];
                      new_combi_data[data.index].row_data[data.head].value =
                        data.value;
                      SetCombiData(new_combi_data);
                    }}
                    OnMultiSelectChange={(data) => {
                      console.log("OnMultiSelectChange data :", data);
                      const new_combi_data = [...combi_data];
                      new_combi_data[data.index].row_data[data.head].value =
                        data.value;
                      SetCombiData(new_combi_data);
                    }}
                    product_addon={child_props.CustomAddonData}
                    avail_prod_name={avail_prod_name}
                    show_delete={combi_data.length > 1 ? true : false}
                  />
                ))}
                {/* )} */}
              </tbody>
            </table>
          </div>
        </div>

        {modal_image.is_open ? (
          <ModalAddGallery
            open={modal_image.is_open}
            type={modal_image.type}
            data={modal_image.data}
            selected={modal_image.selected_images}
            from={"add_product"}
            close={(data: any) => {
              console.log("ModalAddGallery on close :", data);
              SetModalImage((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                console.log("ModalAddGallery on close save :", modal_image);
                SetCombiData((prevValue: any) => {
                  prevValue[modal_image.index].images = data.value;
                  return [...prevValue];
                });
              }
            }}
          />
        ) : null}

        {modal_review_addon.is_open ? (
          <ModalReviewAddon
            open={modal_review_addon.is_open}
            index={modal_review_addon.index}
            data={modal_review_addon.data}
            addon_data={addon_details.addon_data}
            close={(data) => {
              console.log("ModalReviewAddon close :", data);
              SetModalReviewAddon((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                console.log("ModalReviewAddon close save:", data);

                addon_modal_on_submit(data.value);
              }
            }}
          />
        ) : null}
      </div>
    );
  };

  const MrpComponent = (child_props) => {
    const [mrp_data, SetMrpData] = useState(child_props.mrp_data);

    async function get_state(c_data: any, index: any) {
      console.log("get_state");
      let pass_data = {
        get: {
          country: c_data.id,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("pass_data :", pass_data);
      let response = await api("/init/get_state", pass_data);
      console.log("/init/get_state response :", response);
      SetMrpData((prevValue: any) => {
        if (response.status_code == 200) {
          if (response.response.hasOwnProperty("data")) {
            prevValue[index].state_data = response.response.data;
          }
        }
        return [...prevValue];
      });
    }

    const DatepickerInput = ({ ...props }) => (
      <input type="text" {...props} readOnly />
    );

    return (
      <div className="mb-5">
        {/* Button */}
        <div className="d-flex align-items-center mb-3">
          <button
            className="btn btn-light bg-fff border btn-page align-items-center"
            onClick={() => {
              let data = {
                from: "mrp_data",
                active_step: child_props.active_step,
              };
              child_props.onPrev(data);
            }}
          >
            <FontAwesome5 name="angle-double-left" size={14} color="#0053ad" />
            <span className="ps-2 text-primary">Prev</span>
          </button>
          <div className="w-100 d-md-block d-sm-none">
            <p className="text-dark fw-bold center mb-0">Mrp Details</p>
          </div>
          <button
            className="btn btn-light bg-fff border ms-auto btn-page align-items-center"
            onClick={() => {
              console.log("Mrp data :", mrp_data);
              let data = {
                active_step: child_props.active_step,
                from: "mrp_data",
                data: mrp_data,
              };
              // SetMrpDetails((prevValue: any) => {
              //   prevValue.mrp_data = mrp_data;
              //   return { ...prevValue };
              // });
              child_props.onSubmit(data);
            }}
          >
            <MaterialCommunityIcons
              name="checkbox-marked-circle-outline"
              size={16}
              color="#0053ad"
            />
            <span className="ps-2 text-primary">Submit</span>
          </button>
        </div>
        <div className="d-flex align-items-center py-2 mb-2">
          <div className="d-md-none d-sm-block">
            <p className="text-dark fw-bold mb-0 ">Mrp Details</p>
          </div>
        </div>
        <div className="p-2">
          {mrp_data.map((ele: any, index: any) => (
            <div className="row py-2 cursor-hover" key={index}>
              {/* Country */}
              <div className="col-md-3 mb-2">
                <label className="form-label">Country</label>
                <Select
                  components={animatedComponents}
                  placeholder="Select Country"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.countries}
                  value={ele.selected_country}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onChange={(e) => {
                    console.log(" Country :", e);
                    SetMrpData((prevValue: any) => {
                      prevValue[index].selected_country = e;
                      prevValue[index].country = e.id;
                      prevValue[index].selected_state = {};
                      prevValue[index].state = "";
                      // if (e.id == "99") {
                      //   get_state(e, index);
                      // }
                      return [...prevValue];
                    });
                  }}
                />
              </div>
              {/* State */}
              {ele.selected_country.id == "99" ? (
                <div className="col-md-3 mb-2">
                  <label className="form-label">State</label>
                  <Select
                    components={animatedComponents}
                    placeholder="Select State"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={ele.state_data}
                    value={ele.selected_state}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    onChange={(e) => {
                      console.log(" state :", e);

                      SetMrpData((prevValue: any) => {
                        prevValue[index].selected_state = e;
                        prevValue[index].state = e.id;
                        return [...prevValue];
                      });
                    }}
                  />
                </div>
              ) : null}

              {/* Price */}
              <div className="col-md-3 mb-2">
                <label className="form-label">Price</label>
                <div className="input-group">
                  <div className="input-group-text p-0">
                    <Select
                      className="no-radius border-none basic-multi-select"
                      components={animatedComponents}
                      placeholder="Select Currency"
                      isSearchable={false}
                      classNamePrefix="select"
                      options={context.app_data.currencies}
                      value={ele.selected_currency}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (base) => ({ ...base, border: "none" }),
                        indicatorSeparator: (base) => ({
                          ...base,
                          backgroundColor: "none",
                        }),
                      }}
                      onChange={(e) => {
                        console.log(" Currency :", e);
                        SetMrpData((prevValue: any) => {
                          prevValue[index].selected_currency = e;
                          prevValue[index].currency = e.id;
                          return [...prevValue];
                        });
                      }}
                    />
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Price"
                    value={ele.price}
                    //   disabled={read_only}
                    onChange={(e) => {
                      console.log(" Price :", e.target.value);
                      SetMrpData((prevValue: any) => {
                        prevValue[index].price = e.target.value;
                        return [...prevValue];
                      });
                    }}
                  />
                </div>
              </div>

              {/* Activation Date */}
              <div className="col-md-2 mb-2">
                <label className="form-label">Activation Date</label>
                <DatePicker
                  selected={ele.selected_date}
                  dateFormat="dd/MM/yyyy"
                  onSelect={(date) => {
                    let date_value = moment(date).format("DD-MM-yyyy");
                    SetMrpData((prevValue: any) => {
                      prevValue[index].selected_date = date;
                      prevValue[index].activation_date = date_value;
                      return [...prevValue];
                    });
                  }}
                  onChange={(date) => {
                    let date_value = moment(date).format("DD-MM-yyyy");
                    SetMrpData((prevValue: any) => {
                      prevValue[index].selected_date = date;
                      prevValue[index].activation_date = date_value;
                      return [...prevValue];
                    });
                  }}
                  customInput={<DatepickerInput />}
                />
              </div>
              <div className="col-md-1 ms-auto center">
                {index > 0 ? (
                  <button
                    className="btn btn-secondary-outline border-none btn-sm"
                    onClick={() => {
                      console.log("Remove");
                      SetMrpData((prevValue: any) => {
                        prevValue.splice(index, 1);
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="delete-outline"
                      size={16}
                      color="black"
                    />
                    {/* Remove */}
                  </button>
                ) : null}
              </div>
            </div>
          ))}

          <div className="center py-2 mt-2">
            <button
              className="btn btn-page center text-primary fw-bold"
              onClick={() => {
                console.log("Add More Mrp");
                SetMrpData((prevValue: any) => {
                  let push_data = {
                    selected_country: {
                      id: "99",
                      iso: "IN",
                      iso3: "IND",
                      label: "India",
                      name: "INDIA",
                      nicename: "India",
                      numcode: "356",
                      phonecode: "91",
                      value: "99",
                    },
                    country: '99',
                    state_data: state_data_value,
                    selected_state: {},
                    state: '',
                    selected_currency: {
                      code: "INR",
                      id: "1",
                      label: "₹",
                      name: "Indian Rupees",
                      symbol: "₹",
                      value: "1",
                    },
                    currency: "1",
                    price: '',
                    // selected_date: '',
                    // activation_date: '',
                    selected_date: today_date,
                    activation_date: moment(today_date).format('DD-MM-yyyy'),
                  };
                  prevValue.push(push_data);
                  return [...prevValue];
                });
              }}
            >
              <Ionicons name="add" size={16} color="#297ad0" />
              <span className="ps-1">Add More</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  function generate_product_variants(avail_prod_name_value) {
    let addon_data = addon_details.addon_data;
    let variant_data = variant_details.variant_data;
    let varying_data = specification_data.varying_data;
    let common_data = specification_data.common_data;
    console.log("varying_data : ", varying_data);
    console.log("variant_data : ", variant_data);
    console.log("common_data on spec : ", common_data);

    // let avail_prod_name = avail_prod_name_value;
    console.log("avail_prod_name before :", avail_prod_name_value);
    // Getting Product Brand
    let brand_name = "";
    common_data.map((c_ele: any, c_index: any) => {
      if (c_ele.name == "Product Brand") {
        brand_name = c_ele.value.label;
      }
    });
    if (variant_data.length > 0) {
      // let prod_available = varying_data.filter(
      //   (a_data) => a_data.name === "Product Name"
      // );
      // console.log("prod_available :", prod_available);
      // if (prod_available.length > 0) {
      //   SetAvailProdName(true);
      // }
      SetAvailProdName(true);
      varying_data.push({
        name: "Product Name",
        checked: true,
        data_type: "varying_data",
        disabled: false,
        slug: "name",
        source_type: "product_details",
        type: "Text Field",
        value: "",
      });

      /**Generating Combination */
      const form_data = [];
      const res = [];
      const combination_row_data = [];
      const combinations = [];

      /**Combination Row Start */
      variant_data.forEach((v_data) => {
        let child_data = [];
        v_data.value.forEach((children) => {
          children.parent_id = v_data.id;
          children.parent_name = v_data.name;
          child_data.push(children);
        });
        form_data.push(child_data);
      });
      console.log("form data :", form_data);
      if (form_data.length > 0) {
        console.log("form_data.length :", form_data.length);
        let max = form_data.length - 1;
        let combination_value_data = combination_provider(
          [],
          0,
          form_data,
          res,
          max
        );
        console.log("combination_value_data : ", combination_value_data);
        combination_value_data.forEach((children) => {
          var result = {};
          for (var i = 0; i < children.length; i++) {
            result["Image"] = "";
            console.log("avail_prod_name_value :", avail_prod_name_value);
            if (!avail_prod_name_value) {
              result["Product Name"] = basic_details.name;
            }
            let data = {
              all_data: children[i],
              name: children[i].label,
              type: "variant_data",
            };
            // result[children[i].parent_name] = children[i].label;
            result[children[i].parent_name] = data;
          }
          for (var j = 0; j < varying_data.length; j++) {
            let data = {
              all_data: varying_data[j],
              name: varying_data[j].name,
              type: "varying_data",
            };
            // result[varying_data[j].name] = varying_data[j].name;
            result[varying_data[j].name] = data;
          }
          console.log("result : ", result);
          combination_row_data.push(result);
        });
        console.log("combination_row_data : ", combination_row_data);
        SetCombinationRow(combination_row_data);
      }
      /**Combination Row End */

      /**Combination Head Start */
      var col = [];
      for (var i = 0; i < combination_row_data.length; i++) {
        let pro_value = brand_name + " " + basic_details.name;

        for (var key in combination_row_data[i]) {
          if (col.indexOf(key) === -1) {
            col.push(key);
          }
          console.log("col key check key with:", combination_row_data[i][key]);
          console.log("col key com[i] :", combination_row_data[i]);
          console.log("col key key :", key);

          if (key != "Product Name" && key != "Image") {
            if (combination_row_data[i][key].type == "variant_data") {
              pro_value = pro_value + " " + combination_row_data[i][key].name;
            }
          }
          // if (key == "Product Name") {
          combination_row_data[i]["Product Name"].all_data.value = pro_value;
          combination_row_data[i]["Product Name"].value = pro_value;
          // }
        }
      }
      console.log("col Head : ", col);
      SetCombinationHead(col);
      /**Combination Head End */

      /**Combination Data Start*/
      var sample_combi = {};
      combination_row_data.forEach((element, index) => {
        let parent = [];
        let child = [];

        for (var key in element) {
          if (key != "Image") {
            variant_data.forEach((variant_data_element) => {
              if (variant_data_element.name === key) {
                parent.push({
                  id: variant_data_element.id,
                  name: variant_data_element.name,
                });
                variant_data_element.value.forEach((children_value) => {
                  if (children_value.name == element[key]) {
                    child.push({
                      id: children_value.value,
                      name: children_value.label,
                    });
                  }
                });
              }
            });
          }
        }
        sample_combi["images"] = [];
        sample_combi["default"] = index == 0 ? true : false;
        sample_combi["row_data"] = element;
        sample_combi["parent_data"] = parent;
        sample_combi["child_data"] = child;
        sample_combi["addon"] = [];
        sample_combi["checked"] = [];

        sample_combi["addon_value"] = false;

        combinations.push(sample_combi);
        sample_combi = {};
      });
      console.log("combinations on multi :", combinations);
      SetCombinationData(combinations);
      SetRowGenerated(true);
      /**Combination Data End*/
    } else {
      console.log("combination_data variant_data is 0");
      // let prod_available = varying_data.filter(
      //   (a_data) => a_data.name === "Product Name"
      // );
      // console.log("prod_available :", prod_available);
      // if (prod_available.length > 0) {
      //   SetAvailProdName(true);
      // }
      let brand_name = "";
      common_data.map((c_ele: any, c_index: any) => {
        if (c_ele.name == "Product Brand") {
          brand_name = c_ele.value.label;
        }
      });

      SetAvailProdName(true);
      varying_data.push({
        name: "Product Name",
        checked: true,
        data_type: "varying_data",
        disabled: false,
        slug: "name",
        source_type: "product_details",
        type: "Text Field",
        value: brand_name + " " + basic_details.name,
      });
      let combinations = [];
      // combi row start
      const combination_row_data = [];
      var result = {};
      for (var j = 0; j < varying_data.length; j++) {
        if (varying_data[j].name == "Product Name") {
          let data = {
            all_data: varying_data[j],
            name: varying_data[j].name,
            type: "varying_data",
            value: brand_name + " " + basic_details.name,
          };
          result[varying_data[j].name] = data;
        } else {
          let data = {
            all_data: varying_data[j],
            name: varying_data[j].name,
            type: "varying_data",
          };
          result[varying_data[j].name] = data;
        }
        // let data = {
        //   all_data: varying_data[j],
        //   name: varying_data[j].name,
        //   type: "varying_data",
        // };
        // result[varying_data[j].name] = data;
      }
      combination_row_data.push(result);
      console.log("combination_row_data on as :", combination_row_data);
      // combi row end
      // col start
      var col = ["Image", "Product Name"];
      for (var i = 0; i < combination_row_data.length; i++) {
        for (var key in combination_row_data[i]) {
          if (col.indexOf(key) === -1) {
            col.push(key);
          }
        }
      }
      console.log("col :", col);
      //col end
      let sample_combi = {
        default: true,
        images: [],
        row_data: {
          Image: "",
          // "Product Name": basic_details.name,
        },
        parent_data: [],
        child_data: [],
        addon: [],
        checked: [],
        addon_value: false,
      };
      // combination_row_data.map((ele: any) => {

      //   sample_combi.row_data[];
      // });
      for (var i = 0; i < combination_row_data.length; i++) {
        for (var key in combination_row_data[i]) {
          // if (col.indexOf(key) === -1) {
          //   col.push(key);
          // }
          sample_combi.row_data[key] = combination_row_data[i][key];
        }
      }
      combinations.push(sample_combi);
      SetCombinationData(combinations);
      SetCombinationHead(col);
      SetRowGenerated(true);
      console.log("combination_data :", combinations);
      // stopped
    }
  }

  function combination_provider(arr, i, form_data, res, max) {
    for (let j = 0, l = form_data[i].length; j < l; j++) {
      let copy = arr.slice(0);
      copy.push(form_data[i][j]);
      if (i == max) {
        res.push(copy);
      } else {
        combination_provider(copy, i + 1, form_data, res, max);
      }
    }
    return res;
  }

  async function create_variant(product_data_value) {
    let pass_data = {
      get: {},
      post: {
        product_data: product_data_value,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    let res_data = await api("/product/create_variant", pass_data);
    console.log("/product/create_variant response :", res_data);
    if (res_data.status_code == 201) {
      console.log("product created");
      window.history.back();
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar py-3">
        <div className="d-flex align-items-center">
          <div
            className="cursor"
            onClick={() => {
              window.history.back();
            }}
          >
            <Ionicons name="arrow-back-sharp" size={19} color="#222" />
          </div>
          <div className="col-md-4 col-12 ps-2">
            <h5 className=" mb-0">Create Product</h5>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="row card m-3 pt-3">
        <MultiStepForm activeStep={active_step} accentColor={"#1675e0"}>
          {/* basic */}
          <Step>
            <BasicDetailsComponent
              onNext={onNext}
              active_step={active_step}
              basic_data={basic_details}
            />
          </Step>
          {/* specification */}
          <Step>
            <SpecificationDataComponent
              onNext={onNext}
              active_step={active_step}
              onPrev={onPrev}
            />
          </Step>
          {/* common */}
          <Step>
            <CommonDataComponent
              onNext={onNext}
              active_step={active_step}
              common_data={common_details.common_data}
              onPrev={onPrev}
            />
          </Step>
          {/* variant */}
          {/* <Step>
            <VariantDataComponent
              onNext={onNext}
              active_step={active_step}
              variant_data={variant_details.variant_data}
              onPrev={onPrev}
            />
          </Step> */}
          {/* addon */}
          {/* <Step>
            <AddonDataComponent
              onNext={onNext}
              active_step={active_step}
              account_addon_group={account_addon_group}
              onPrev={onPrev}
            />
          </Step> */}
          {/* review */}
          {/* <Step>
            <ReviewComponent
              active_step={active_step}
              onPrev={onPrev}
              CustomAddonData={addon_details.addon_data}
              onPrev={onPrev}
              combination_data_value={combination_data}
              onSubmit={(data) => {
                console.log("ReviewComponent onSubmit :", data);

                // SetCombinationData(data.data);
                let basic_details_value = basic_details;
                let image_array = basic_details_value.selected_image;
                basic_details_value.images = [];
                // basic_details_value.selected_image.map((ele: any) => {
                //   basic_details_value.images.push(ele.id);
                // });
                let submit_data = {
                  // basic_data: basic_details,
                  basic_data: basic_details_value,
                  images: image_array,
                  common_data: specification_data.common_data,
                  addon_data: addon_details.addon_data,
                  variant_data: variant_details.variant_data,
                  combination_data: data.data,
                };

                console.log("Submit Data :", submit_data);

                create_product(submit_data);
              }}
            />
          </Step> */}
          {/* Mrp */}
          <Step>
            <MrpComponent
              active_step={active_step}
              //   CustomAddonData={addon_details.addon_data}
              onPrev={onPrev}
              //   combination_data_value={combination_data}
              mrp_data={mrp_details.mrp_data}
              onSubmit={(data) => {
                console.log("MrpComponent onSubmit :", data);

                SetMrpDetails((prevValue: any) => {
                  prevValue.mrp_data = data.data;
                  return { ...prevValue };
                });
                let basic_details_value = basic_details;
                let image_array = basic_details_value.selected_image;
                basic_details_value.images = [];
                let submit_data = {
                  // basic_data: basic_details,
                  basic_data: basic_details_value,
                  images: image_array,
                  specification_data: specification_data.common_data,
                  //   addon_data: addon_details.addon_data,
                  //   variant_data: variant_details.variant_data,
                  //   combination_data: data.data,
                  mrp: data.data,
                };

                console.log("Submit Data :", submit_data);

                create_variant(submit_data);
              }}
            />
          </Step>
        </MultiStepForm>
      </div>

      <div className="">
        <ModalCreateSpecification
          open={modal_specification.is_open}
          type="create"
          data={""}
          all_data={pre_spec_data}
          category={modal_specification.category}
          spec_type_options={spec_type_options}
          add_type="multiple"
          from="add_product"
          close={(data: any) => {
            console.log("ModalCreateSpecification on close :", data);
            SetModalSpecification((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              get_init_pre_specifications();
            }
          }}
        />

        <ModalCreateAddonGroup
          open={modal_addon.is_open}
          type={modal_addon.type}
          data={""}
          all_data={account_addon_group}
          addon_data={addon_details.addon_data}
          category={modal_addon.category}
          from="add_product"
          variant_data={variant_details.variant_data}
          close={(data: any) => {
            console.log("ModalCreateAddonGroup on close :", data);
            SetModalAddon((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });
            if (data.action == "save") {
              // get_addon_items();
              SetAddonDetails((prevValue: any) => {
                prevValue.addon_data.push(data.data);
                return { ...prevValue };
              });
            }
          }}
        />
      </div>
    </div>
  );
}
