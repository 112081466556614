import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalCreateBrand from "./ModalCreateBrand";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import toast from "react-hot-toast";
import Select from "react-select";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import { AppHelper } from "../../utils/Helper";

const initial_value = {
  name: "",
  selected_country: {},
  logo: {},
};

export default function EditBrand(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const context = useContext(DataContext);

  const [country_option, SetCountryOption] = useState([]);
  const [save_data, SetSaveData] = useState(initial_value);
  const [modal_type, SetModalType] = useState("");
  const [read_only, SetReadOnly] = useState(false);
  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  const [error_state, SetErrorState] = useState({});

  useEffect(() => {
    console.log("Brand On mount :", props);
    console.log("Brand On mount context :", context);
  }, []);

  function validate() {
    let nameError = "";
    let countryError = "";

    if (!save_data.name) {
      nameError = "Name field is required";
    }
    if (Object.keys(save_data.selected_country).length == 0) {
      countryError = "Country field is required";
    }

    if (nameError || countryError) {
      SetErrorState({ nameError, countryError });
      return false;
    }
    return true;
  }

  async function create_brand() {
    console.log("create_breand save_data :", save_data);
    // if (save_data.name == '') {
    //   toast.error('Enter Brand name')
    // } else if (Object.keys(save_data.selected_country).length == 0) {
    //   toast.error('Select Country')
    // } else {
    if (validate()) {
      console.log("validate ok");
      let pass_data = {
        get: {},
        post: {
          name: save_data.name,
          country: save_data.selected_country.value,
          logo:
            !Object.keys(save_data.logo).length > 0 ? "" : save_data.logo.id,
          // language: "",
        },
      };

      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("Pass_data", pass_data);
      let res_data = await api("/brand/create", pass_data);
      console.log("/brand/create response :", res_data);

      if (res_data.status_code == 201) {
        if (res_data.response.hasOwnProperty("id")) {
          window.history.back();
        }
      }
    } else {
      console.log("validate not ok");
    }

    // }
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  return (
    <div className="">
      {/* Header */}
      <div className="d-flex align-items-center head-bar">
        <button
          className="btn btn-sm px-2"
          onClick={() => {
            window.history.back();
          }}
        >
          <Ionicons name="ios-arrow-back" size={20} color="black" />
        </button>
        <h6 className="mb-0">Edit Brand</h6>
      </div>

      {/* Body */}

      <div className="p-3">
        <div className="card p-3">
          <p className="pt-2 mb-4 text-dark fw-bold">Brand Details</p>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Brand Name *</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Brand Name"
                  value={save_data.name}
                  disabled={read_only}
                  onChange={(e) => {
                    SetSaveData((prevValue) => {
                      prevValue.name = e.target.value;
                      return { ...prevValue };
                    });
                    SetErrorState((prevValue: any) => {
                      if (e.target.value == "") {
                        prevValue.nameError = "Name field is required";
                      } else {
                        prevValue.nameError = "";
                      }
                      return { ...prevValue };
                    });
                  }}
                />
                <span className="text-danger">{error_state.nameError}</span>
              </div>

              <div className="mb-3">
                <label className="form-label">Country *</label>

                <Select
                  options={context.app_data.countries}
                  value={save_data.selected_country}
                  isDisabled={read_only}
                  placeholder="Country"
                  onChange={(e) => {
                    SetSaveData((prevValue) => {
                      prevValue.selected_country = e;
                      return { ...prevValue };
                    });
                    SetErrorState((prevValue: any) => {
                      prevValue.countryError = "";
                      return { ...prevValue };
                    });
                  }}
                />
                <span className="text-danger">{error_state.countryError}</span>
              </div>
            </div>
            {/* logo */}
            <div className="col-md-6">
              <div className="form-group pt-2">
                <div className="d-flex align-items-center">
                  <label className="mb-0">
                    Logo
                    <small
                      className="ps-1"
                      style={{ color: "#bbb", letterSpacing: 0.5 }}
                    >
                      (Optional)
                    </small>
                  </label>

                  {Object.keys(save_data.logo).length > 0 ? (
                    <div className="ms-auto">
                      <button
                        className="btn btn-sm text-primary fw-bold  px-3 cursor"
                        onClick={() => {
                          onImgSelect(modal_image.selected_images);
                        }}
                      >
                        Change
                      </button>
                    </div>
                  ) : null}
                </div>

                {Object.keys(save_data.logo).length > 0 ? (
                  <div className="center cursor py-2">
                    <div className="d-flex align-items-center">
                      <img
                        src={AppHelper.imageSrc(
                          context.app_data.image_base_url,
                          save_data.logo.url,
                          {
                            format: "webp",
                            width: 320,
                          }
                        )}
                        // src={
                        //   context.app_data.image_base_url +
                        //   context.app_data.image_sizes[0] +
                        //   "/" +
                        //   save_data.logo.url
                        // }
                        className="brand_img"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="center cursor py-2"
                    onClick={() => {
                      onImgSelect(modal_image.selected_images);
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <MaterialCommunityIcons
                          name="image-plus"
                          size={22}
                          color="gray"
                        />
                        <h6 className="ps-2 mb-0 text-darkgray">Add Images</h6>
                      </div>
                      <div className="mt-3">
                        <small className="">
                          <ul className="ps-0">
                            <li className="pb-1">Add your product images</li>
                            <li className="pb-1">
                              No image file larger then 5MB
                            </li>
                          </ul>
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="ms-auto mt-3">
            <button
              className="btn btn-primary btn-sm px-3"
              onClick={() => {
                // create_brand()
                console.log("Update");
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>

      {modal_image.is_open ? (
        <ModalAddGallery
          open={modal_image.is_open}
          type={modal_image.type}
          data={modal_image.data}
          selected={modal_image.selected_images}
          from={"create_brand"}
          close={(data: any) => {
            console.log("ModalAddGallery on close :", data);
            SetModalImage((prevValue: any) => {
              prevValue.is_open = false;
              if (data.action == "save") {
                prevValue.selected_images = data.value;
              }
              return { ...prevValue };
            });
            if (data.action == "save") {
              SetSaveData((prevValue: any) => {
                prevValue.logo = data.value[0];
                return { ...prevValue };
              });
            }
          }}
        />
      ) : null}
    </div>
  );
}
