import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import InfiniteScroll from "react-infinite-scroll-component";
import toast from "react-hot-toast";
import { AppHelper } from "../../utils/Helper";

export default function Products(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [product_data, SetProductData] = useState([]);
  const [size_data, SetSizeData] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const [is_checked, SetIsChecked] = useState(false);
  const [is_all_checked, SetIsAllChecked] = useState(false);
  const [search_data, SetSearchData] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [sort_by, SetSortBy] = useState("desc");
  const [next_page, SetNextPage] = useState(false);
  const [timer, SetTimer] = useState(null);

  const WAIT_INTERVAL = 3000;

  useEffect(() => {
    console.log("Products On mount :", props);
    console.log("Products On mount context :", context);
    let pass_value = {
      search_data: search_data,
      page_no: page_no,
      sort: sort_by,
      fetch_more: false,
    };
    // get_products(pass_value);
  }, []);

  useEffect(() => {
    if (search_data == "") {
      let pass_value = {
        search_data: search_data,
        page_no: page_no,
        sort: sort_by,
        fetch_more: false,
      };
      get_products(pass_value);
    } else {
      const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search_data]);

  async function get_products(pass_value) {
    let pass_data = {
      get: {
        sort: pass_value.sort,
        page: pass_value.page_no,
      },
    };
    if (pass_value.search_data != "") {
      pass_data.get.search = pass_value.search_data;
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/all", pass_data);
    console.log("/product/all :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("image_sizes")) {
        SetSizeData(res_data.response.image_sizes);
      }
      if (res_data.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(res_data.response.image_base_url);
      }
      if (res_data.response.hasOwnProperty("data")) {
        let product_data_value = res_data.response.data;
        product_data_value.map((ele: any) => {
          ele.checked = false;
        });
        if (pass_value.fetch_more) {
          SetProductData((prevValue: any) => {
            product_data_value.map((ele: any) => {
              prevValue.push(ele);
            });
            if (prevValue.every((v_ele) => v_ele.checked == true)) {
              SetIsAllChecked(true);
            } else {
              SetIsAllChecked(false);
            }
            return [...prevValue];
          });
        } else {
          if (product_data_value.every((v_ele) => v_ele.checked == true)) {
            SetIsAllChecked(true);
          } else {
            SetIsAllChecked(false);
          }
          SetProductData(product_data_value);
        }
      }
      if (res_data.response.hasOwnProperty("next_page")) {
        SetNextPage(res_data.response.next_page);
      }
    }
  }

  async function delete_product_group(data, product_data_value, delete_type) {
    console.log("delete_product_group data :", data);
    console.log("product_data_value :", product_data_value);

    let pass_data = {
      get: {},
      post: {},
    };
    let selected_group_data = [];
    product_data_value.map((ele: any) => {
      if (ele.checked) {
        selected_group_data.push(ele.id);
      }
    });
    console.log("selected_group_data :", selected_group_data);
    let url_path = "";
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    if (data.have_option) {
      if (data.options.is_checked) {
        pass_data.post.product_groups = selected_group_data;
        pass_data.post.delete_variants = 1;
        url_path = "/product/delete_variant";
      } else {
        pass_data.post.products = selected_group_data;
        url_path = "/product/delete_product_group";
      }
    }

    console.log("delete_product_group pass_data :", pass_data);
    console.log("delete_product_group url_path :", url_path);
    let res_data = await api(url_path, pass_data);
    console.log(url_path + ":", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.status) {
        toast.success("Product Deleted");
        SetPageNo((prevValue: any) => {
          prevValue = 1;
          let pass_value = {
            search_data: search_data,
            page_no: prevValue,
            sort: sort_by,
            fetch_more: false,
          };
          get_products(pass_value);
          return prevValue;
        });
      } else {
        toast.error(res_data.response.message);
      }
    }
  }

  function triggerItemChange(value) {
    SetPageNo((prevValue: any) => {
      prevValue = 1;
      let pass_value = {
        search_data: value,
        page_no: prevValue,
        sort: sort_by,
        fetch_more: false,
      };
      get_products(pass_value);
      return prevValue;
    });
  }

  return (
    <div className="">
      {/* Header */}
      <div className="row align-items-center head-bar">
        <div className="col-6 col-md-8 sm-mb-2">
          <h5 className="  mb-0">Groups List</h5>
        </div>

        {/* <div className="col-3 col-md-2 ps-0 ">
          <Link
            to={
              // context.app_data.app_from == "seller"
              id_value.hasOwnProperty("brand_id")
                ? "/seller/" + id_value.brand_id + "/generate"
                : "/manufacturer/" + id_value.m_id + "/generate"
            }
          >
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                console.log("Generate Products with Group new");
              }}
            >
              Generate Product
            </button>
          </Link>
        </div> */}

        <div className="col-md-2 col-6 ps-0 end">
          <Link
            to={
              // context.app_data.app_from == 'seller'
              id_value.hasOwnProperty("brand_id")
                ? "/seller/" + id_value.brand_id + "/group/add"
                : "/manufacturer/" + id_value.m_id + "/group/add"
            }
          >
            <button
              className="btn btn-primary btn-radius btn-sm px-3"
              onClick={() => {
                console.log("Products Group new");
              }}
            >
              Create New
            </button>
          </Link>
        </div>
      </div>

      <div className="fixed-sub-header">
        <div className=" row align-items-center head-bar">
          <div className="col-md-5 col-12 d-flex ps-0">
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
                onChange={(e) => {
                  SetSearchData((prevValue: any) => {
                    prevValue = e.target.value;
                    return prevValue;
                  });
                }}
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={16} color="#ccc" />
              </div>
            </div>
          </div>
          
          <div className="col-12 col-md-7 end sm-mt-3">
            <div className="end">
              {!is_checked ? (
                <div className="pe-3">
                  {/* select_all */}
                  <button
                    className="btn btn-sm "
                    onClick={() => {
                      SetProductData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = true;
                        });
                        SetIsChecked(true);
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          SetIsAllChecked(true);
                        } else {
                          SetIsAllChecked(false);
                        }
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-marked-circle-outline"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Select All</span>
                  </button>
                </div>
              ) : (
                <div>
                  {!is_all_checked ? (
                    // {/* select_all */}
                    <button
                      className="btn btn-sm pe-3 border-right"
                      onClick={() => {
                        SetProductData((prevValue: any) => {
                          prevValue.map((ele) => {
                            ele.checked = true;
                          });
                          SetIsChecked(true);
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            SetIsAllChecked(true);
                          } else {
                            SetIsAllChecked(false);
                          }
                          return [...prevValue];
                        });
                      }}
                    >
                      <MaterialCommunityIcons
                        name="checkbox-marked-circle-outline"
                        size={16}
                        color="#777"
                      />
                      <span className="ps-1 text-gray">Select All</span>
                    </button>
                  ) : null}

                  {/* unselect_all */}
                  <button
                    className="btn btn-sm px-3 border-right"
                    onClick={() => {
                      SetProductData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = false;
                        });
                        SetIsChecked(false);
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          SetIsAllChecked(true);
                        } else {
                          SetIsAllChecked(false);
                        }
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="progress-close"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Unselect All</span>
                  </button>
                  {/* delete */}
                  <button
                    className="btn btn-sm ps-3"
                    onClick={() => {
                      SetModalDelete((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.delete_type = "multiple";
                        prevValue.data = {
                          title: "Delete Product",
                          body: "Are you sure? want to delete product",
                          data: product_data,
                        };
                        prevValue.have_option = true;
                        prevValue.options = {
                          title: "Also Delete Variant",
                          type: "checkbox",
                          is_checked: false,
                        };
                        return { ...prevValue };
                      });
                      console.log("ProductData :", product_data);
                    }}
                  >
                    <MaterialCommunityIcons name="delete-outline" size={16} color="#777" />
                    <span className="ps-1 text-gray">Delete</span>
                  </button>
                </div>
              )}

              {/* <div className="dropdown ms-2">
                <button
                  className="btn btn-sm border-prime d-flex dropdown-toggle cursor"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Ionicons name="ios-filter" size={18} color="#297ad0" />
                  <span className="ps-1">Filter</span>
                </button>
                <ul className="dropdown-menu p-2 pt-3">
                  <p className="fw-bold text-dark mb-1">Products by</p>
                  <div className="py-2 btn btn-sm mb-1">
                    <label className="form-label mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        // checked={filter_data.own_products}
                        // onChange={(e) => {
                        //   SetFilterData((prevValue: any) => {
                        //     prevValue.own_products = e.target.checked;
                        //     let pass_value = {
                        //       search_data: search_data,
                        //       page_no: page_no,
                        //       sort: sort_by,
                        //       fetch_more: false,
                        //       filter_data: prevValue,
                        //     };
                        //     get_variants(pass_value);
                        //     return { ...prevValue };
                        //   });
                        // }}
                      />
                      <span className="ps-2">Own Products</span>
                    </label>
                  </div>
                  <div className="py-2 btn btn-sm mb-1">
                    <label className="form-label mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        // checked={filter_data.others_products}
                        // onChange={(e) => {
                        //   SetFilterData((prevValue: any) => {
                        //     prevValue.others_products = e.target.checked;
                        //     let pass_value = {
                        //       search_data: search_data,
                        //       page_no: page_no,
                        //       sort: sort_by,
                        //       fetch_more: false,
                        //       filter_data: prevValue,
                        //     };
                        //     get_variants(pass_value);
                        //     return { ...prevValue };
                        //   });
                        // }}
                      />
                      <span className="ps-2">Other Products</span>
                    </label>
                  </div>
                  <div className="border-top pt-2">
                    <p className="fw-bold text-dark mb-1">Group by</p>
                    <div className="py-2 btn-sm mb-1 w-100 text-left">
                      <label className="form-label mb-0 p-0 w-100">
                        <input
                          type="radio"
                          className="form-radio-input"
                          name="group"
                        />
                        <span className="ps-2">Ascending Order</span>
                      </label>
                    </div>
                    <div className="py-2 btn-sm mb-1 w-100 text-left">
                      <label className="form-label mb-0 p-0 w-100">
                        <input
                          type="radio"
                          className="form-radio-input"
                          name="group"
                        />
                        <span className="ps-2">Descending Order</span>
                      </label>
                    </div>
                  </div>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Options */}

      {/* Body */}
      <div className="container px-sm-0 px-md-3">
        <div className="py-3 mb-3">
          {product_data.length > 0 ? (
            <InfiniteScroll
              dataLength={product_data.length}
              next={() => {
                SetPageNo((prevValue: any) => {
                  prevValue = prevValue + 1;
                  let pass_value = {
                    search_data: search_data,
                    page_no: prevValue,
                    sort: sort_by,
                    fetch_more: true,
                  };
                  get_products(pass_value);
                  return prevValue;
                });
              }}
              hasMore={next_page}
              // height={525}
              className=""
              loader={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              }
              endMessage={
                <div className="center pt-3 pb-5">
                  <hr className="w-25" />
                </div>
              }
            >
              <div className="row">
                {product_data.map((ele: any, index: any) => (
                  <div
                    className="col-md-6 mb-2"
                    key={index}
                    onClick={() => {
                      console.log("Product Detail :", ele);
                    }}
                  >
                    <div
                      className={
                        product_data[index].checked
                          ? "card p-3 card-shadow"
                          : "card p-3"
                      }
                    >
                      <div className="row p-0 pb-2">
                        <div className="col-11">
                          <label className="d-flex align-items-center cursor">
                            <input
                              type="checkbox"
                              className="cursor form-check-input-custom"
                              checked={ele.checked}
                              onChange={() => {
                                SetProductData((prevValue: any) => {
                                  prevValue[index].checked =
                                    !prevValue[index].checked;
                                  if (
                                    prevValue.some(
                                      (v_ele) => v_ele.checked == true
                                    )
                                  ) {
                                    SetIsChecked(true);
                                  } else {
                                    SetIsChecked(false);
                                  }

                                  if (
                                    prevValue.every(
                                      (v_ele) => v_ele.checked == true
                                    )
                                  ) {
                                    SetIsAllChecked(true);
                                  } else {
                                    SetIsAllChecked(false);
                                  }
                                  return [...prevValue];
                                });
                              }}
                            />
                            <p className="mb-0 double text-dark fw-bold ps-2">
                              {ele.name}
                            </p>
                          </label>
                        </div>
                        <div className="col-1 ps-0 text-end">
                          <div
                          // className={
                          //   ele.status == "Active"
                          //     ? "text-green mb-1"
                          //     : "mb-1"
                          // }
                          >
                            {ele.status == "Active" ? (
                              <FontAwesome
                                name="circle"
                                size={14}
                                color="#18b901"
                              />
                            ) : (
                              <FontAwesome
                                name="circle"
                                size={14}
                                color="red"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row p-0 py-2 border-bottom">
                        <Link
                          className="cursor"
                          to={
                            // context.app_data.app_from == 'seller'
                            id_value.hasOwnProperty("brand_id")
                              ? "/seller/" +
                              id_value.brand_id +
                              "/group/details/" +
                              ele.id
                              : "/manufacturer/" +
                              id_value.m_id +
                              "/group/details/" +
                              ele.id
                          }
                        >
                          <div className="row">
                            <div className="col-3 px-1">
                              {ele.hasOwnProperty("images") ? (
                                <img
                                  src={AppHelper.imageSrc(
                                    image_base_url,
                                    ele.images[0].url,
                                    {
                                      format: "webp",
                                      width: 320,
                                    }
                                  )}
                                  // src={
                                  //   image_base_url +
                                  //   size_data[0] +
                                  //   "/" +
                                  //   ele.images[0].url
                                  // } 
                                  className="product_img radius"
                                />
                              ) : (
                                <img
                                  src={require("../../assets/img/no-image.jpg")}
                                  className="product_img"
                                />
                              )}
                            </div>
                            <div className="col-9 ps-1">
                              <div className="row">
                                {/* <div className="col-12 pb-2">
                                  <small className="">
                                    Brand :{' '}
                                    <span className="ps-1 text-dark">
                                      Smart phone
                                    </span>
                                  </small>
                                </div> */}
                                <div className="col-6 pb-2">
                                  <small className="">
                                    Variants :{" "}
                                    <span className="ps-1 text-dark">
                                      {ele.total_variants}
                                    </span>
                                  </small>
                                </div>
                                <div className="col-6 pb-2">
                                  <small className="">
                                    Addons :{" "}
                                    <span className="ps-1 text-dark">
                                      {/* 10 */}
                                      {ele.total_addon_groups}
                                    </span>
                                  </small>
                                </div>
                                <div className="col-6 pb-2">
                                  <small className="">
                                    Category :{" "}
                                    <span className="ps-1 text-dark">
                                      {ele.category}
                                    </span>
                                  </small>
                                </div>
                                <div className="col-6 pb-2">
                                  <small className="">
                                    Addon Items :{" "}
                                    <span className="ps-1 text-dark">
                                      {/* 10 */}
                                      {ele.total_addon_items}
                                    </span>
                                  </small>
                                </div>
                                <div className="col-12 pb-2">
                                  <small className="">
                                    MRP :{" "}
                                    <span className="ps-1 text-dark">
                                      {/* ₹20000 - ₹22000 */}
                                      {ele.min_variant_mrp.currency_symbol +
                                        " " +
                                        ele.min_variant_mrp.price +
                                        "-" +
                                        ele.max_variant_mrp.currency_symbol +
                                        " " +
                                        ele.max_variant_mrp.price}
                                    </span>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <div className="col-1 text-end ps-0"></div>
                      </div>

                      <p className="pt-2 mb-0">{ele.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            // product_data.map((ele: any, index: any) => (
            //   <div
            //     className="col-md-6 mb-2 cursor"
            //     key={index}
            //     onClick={() => {
            //       console.log("Product Detail :", ele);
            //     }}
            //   >
            //     <div className="d-flex">
            //       <div className="">
            //         <input
            //           type="checkbox"
            //           checked={ele.checked}
            //           onChange={() => {
            //             SetProductData((prevValue: any) => {
            //               prevValue[index].checked =
            //                 !prevValue[index].checked;
            //               if (
            //                 prevValue.some((v_ele) => v_ele.checked == true)
            //               ) {
            //                 SetIsChecked(true);
            //               } else {
            //                 SetIsChecked(false);
            //               }
            //               prevValue.map((p_ele: any, p_index: any) => {});
            //               return [...prevValue];
            //             });
            //           }}
            //         />
            //       </div>
            //       <div className="">
            //         <Link
            //           to={
            //           //  context.app_data.app_from == "seller"
            //  id_value.hasOwnProperty("brand_id")
            //               ? "/seller/" +
            //                 id_value.brand_id +
            //                 "/group/details" +
            //                 ele.id
            //               : "/manufacturer/" +
            //                 id_value.m_id +
            //                 "/group/details/" +
            //                 ele.id
            //           }
            //         >
            //           <div className="card p-3">
            //             <div className="row">
            //               <div className="col-2 px-1">
            //                 <img
            //                   src={require("../../assets/img/mob.png")}
            //                   className="dropzone_img"
            //                 />
            //               </div>
            //               <div className="col-10 ps-0">
            //                 <div className="d-flex">
            //                   <p className="mb-1 double text-black">
            //                     {ele.name}
            //                   </p>
            //                   <div className="ms-auto">
            //                     <small
            //                       className={
            //                         ele.status == "Active"
            //                           ? "text-green mb-1"
            //                           : "mb-1"
            //                       }
            //                     >
            //                       {ele.status}
            //                     </small>
            //                   </div>
            //                 </div>
            //                 <small className="oneline">{ele.category}</small>
            //               </div>

            //               <p className="my-1">{ele.description}</p>
            //             </div>
            //           </div>
            //         </Link>
            //       </div>
            //     </div>
            //   </div>
            // ))
            <div className="bg-fff p-2">
              <div className="center bg-fff h-170 w-100 p-3">
                <div>
                  <img
                    src={require("../../assets/img/empty.png")}
                    className="product-img"
                  />
                  <p className="mt-3 fw-bold text-center">No Data</p>
                </div>
              </div>
            </div>
          )}

          {/* Modal */}
          <div className="">
            <ModalDelete
              open={modal_delete.is_open}
              data={modal_delete.data}
              delete_type={modal_delete.delete_type}
              have_option={modal_delete.have_option}
              options={modal_delete.options}
              close={(data) => {
                console.log("on close delete modal data:", data);
                console.log(
                  "on close delete modal product_data:",
                  product_data
                );

                SetModalDelete((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  // console.log("on save product_data :", product_data);
                  let product_data_value = data.data;
                  delete_product_group(
                    data,
                    product_data_value,
                    data.delete_type
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
