import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import DataContext from "../../utils/Context/DataContext";

let myModal = {};

const initial_value = {
  name: "",
  selected_country: {},
  logo: "",
};

export default function ModalCreateBrand(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const context = useContext(DataContext);

  const [id_value, SetIdValue] = useState(useParams());
  const [country_option, SetCountryOption] = useState([]);
  const [save_data, SetSaveData] = useState(initial_value);
  const [modal_type, SetModalType] = useState("");
  const [read_only, SetReadOnly] = useState(false);

  useEffect(() => {
    if (props.open) {
      console.log("BrandModal On Open :", props);
      myModal = new bootstrap.Modal(document.getElementById("BrandModal"), {});
      myModal.show();
      SetModalType(props.type);
      if (props.type == "create") {
        // get_countries();
      }
      if (props.type == "view") {
        SetReadOnly(true);
        SetSaveData((prevValue) => {
          prevValue.name = props.data.name;
          return { ...prevValue };
        });
      }
    }
  }, [props.open]);

  useEffect(() => {
    console.log("BrandModal On mount :", props);
    let myModal1 = document.getElementById("BrandModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetSaveData({
        name: "",
        selected_country: "",
        logo: "",
      });
      SetReadOnly(false);
      props.close(close_data);
    });
  }, []);

  async function get_countries() {
    let res_data = await api("/brand/countries");
    console.log("response :", res_data);
    if (res_data.response.hasOwnProperty("data")) {
      SetCountryOption(res_data.response.data);
    }
  }

  async function create_brand() {
    if (save_data.name == "") {
      toast.error("Enter Brand name");
    } else if (Object.keys(save_data.selected_country).length == 0) {
      toast.error("Select Country");
    } else {
      let pass_data = {
        get: {},
        post: {
          name: save_data.name,
          country: save_data.selected_country.value,
          logo: save_data.logo,
          // language: "",
        },
      };

      // if (context.app_data.app_from == "seller") {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }
      console.log("Pass_data", pass_data);
      let res_data = await api("/brand/create", pass_data);
      console.log("/brand/create response :", res_data);

      if (res_data.status_code == 201) {
        if (res_data.response.hasOwnProperty("id")) {
          let item = close_data;
          item.action = "save";
          SetCloseData(item);
          myModal.hide();
        }
      }
    }
  }

  return (
    <div
      className="modal"
      id="BrandModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {modal_type == "create"
                ? "Create Brand"
                : modal_type == "view"
                ? "View Brand"
                : null}
            </h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Brand Name</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Brand Name"
                  value={save_data.name}
                  disabled={read_only}
                  onChange={(e) => {
                    SetSaveData((prevValue) => {
                      prevValue.name = e.target.value;
                      return { ...prevValue };
                    });
                  }}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Country</label>

                <Select
                  options={context.app_data.countries}
                  value={save_data.selected_country}
                  isDisabled={read_only}
                  placeholder="Country"
                  onChange={(e) => {
                    SetSaveData((prevValue) => {
                      prevValue.selected_country = e;
                      return { ...prevValue };
                    });
                  }}
                />
              </div>

              <div className="form-group row  pt-2">
                <label className="col-md-3 " htmlFor="">
                  Logo
                  <small style={{ color: "#ccc", letterSpacing: 0.5 }}>
                    (Optional)
                  </small>
                </label>
                <div className="col-md-7 pt-2">
                  <div className="box cursor flex align-center">
                    <p>
                      Drog file here or <a>Browse</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary-outline"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                // SetSaveData(initial_value);
                myModal.hide();
              }}
            >
              Close
            </button>
            {modal_type == "create" ? (
              <button
                type="button"
                className="btn btn-primary-outline"
                onClick={() => {
                  console.log("save");
                  create_brand();
                }}
              >
                Submit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
