import React, { useState, useEffect, useContext, useRef } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  Feather,
  Entypo,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome5,
} from '@expo/vector-icons'

import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

let myModal = {}
const animatedComponents = makeAnimated()

export default function UpdateAddonModal(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [addon_groups, SetAddonGroups] = useState([])
  const [addon_index, SetAddonIndex] = useState('')
  const [spec_data, SetSpecData] = useState([])
  const [save_data, SetSaveData] = useState({
    is_dependent: false,
    dependent_id: '',
  })

  const Switch = ({ isOn, handleToggle, onColor, id_value }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={id_value}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={id_value}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    )
  }

  useEffect(() => {
    if (props.open) {
      console.log('UpdateAddonModal On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('ModalAddonUpdate'),
        {},
      )
      SetAddonGroups(props.addon_group_data)
      SetAddonIndex(props.addon_index_data)
      SetSpecData(props.specification_data)
      SetSaveData((prevValue: any) => {
        if (
          props.addon_group_data[props.addon_index_data].hasOwnProperty(
            'specification_id',
          )
        ) {
          prevValue.is_dependent = true
          prevValue.dependent_id =
            props.addon_group_data[props.addon_index_data].specification_id
        }
        return { ...prevValue }
      })
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('UpdateAddonModal On mount :', props)
    let myModal1 = document.getElementById('ModalAddonUpdate')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetAddonGroups([])
      SetAddonIndex('')
      SetSpecData([])
      SetSaveData((prevValue: any) => {
        prevValue.is_dependent = false
        prevValue.dependent_id = ''
        return { ...prevValue }
      })
      props.close(close_data)
    })
  }, [])

  async function update_addon() {
    let pass_data = {
      get: {},
      post: {
        addons: [
          {
            addon: addon_groups[addon_index].product_addon_group_id,
          },
        ],
      },
    }
    if (save_data.is_dependent) {
      pass_data.post.addons[0].specification = save_data.dependent_id
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log(
      '/product/update_product_addon_dependencies pass_data :',
      pass_data,
    )
    let response = await api(
      '/product/update_product_addon_dependencies',
      pass_data,
    )
    console.log(
      '/product/update_product_addon_dependencies response :',
      response,
    )
    if (response.status_code == 200) {
      if (response.response.status) {
        console.log('save status true')
        let item = close_data
        item.action = 'save'
        SetCloseData(item)
        myModal.hide()
      } else {
        toast.error(response.response.message)
      }
    }
  }

  return (
    <div
      className="modal"
      id="ModalAddonUpdate"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg modal-dialog-centered">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Update Addon Dependencies
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>

          {/* body */}
          <div className="modal-body p-3 border-top">
            <div className="row">
              <p className='text-dark fs-7 fw-bold'>{addon_groups[addon_index]?.name}</p>
              <div className="my-2 d-flex align-items-center">
                <label className="form-label pe-3 mb-0">Is Dependent</label>
                <Switch
                  id_value={'is_dependent'}
                  isOn={save_data.is_dependent}
                  onColor="#0053ad"
                  handleToggle={(e) => {
                    console.log('dependent on change :', e.target.checked)

                    SetSaveData((prevValue: any) => {
                      prevValue.is_dependent = e.target.checked
                      prevValue.dependent_id = ''
                      return { ...prevValue }
                    })
                  }}
                />
              </div>
              {save_data.is_dependent ? (
                <div className="mt-3">
                  <p className='text-dark'>Spec Data</p>
                  {spec_data.map((ele: any, index: any) => (
                    <label key={index} className=" me-4">
                      <input
                      className='me-2 form-check-input' 
                        type="radio"
                        checked={
                          ele.specifications_id == save_data.dependent_id
                            ? true
                            : false
                        }
                        onChange={() => {
                          SetSaveData((prevValue: any) => {
                            prevValue.dependent_id = ele.specifications_id
                            return { ...prevValue }
                          })
                        }}
                      />
                      {ele.name}
                    </label>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-primary btn-radius px-3 "
                onClick={() => {
                  console.log('update')
                  update_addon()
                  //   let item = close_data
                  //   item.action = 'save'
                  //   SetCloseData(item)
                  //   myModal.hide()
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
