import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'

const animatedComponents = makeAnimated()

let myModal = {}

const initial_value = {
  name: '',
  notes: '',
  mrp: [],
  custom_quantity: true,
}

export default function ModalCreateAddonItem(props: any) {
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [country_option, SetCountryOption] = useState([])
  const [save_data, SetSaveData] = useState(initial_value)
  const [modal_type, SetModalType] = useState('')
  const [read_only, SetReadOnly] = useState(false)

  const context = useContext(DataContext)
  const [mrp_data, SetMrpData] = useState([
    {
      selected_country: {},
      country: '',
      state_data: [],
      selected_state: {},
      state: '',
      selected_currency: {},
      currency: '',
      price: '',
      selected_date: '',
      activation_date: '',
    },
  ])
  const [add_mrp_clicked, SetAddMrpClicked] = useState(false)
  const [width, SetWidth] = React.useState(innerWidth)
  const [spec_data,SetSpecData] = useState([])
  const [error_state, SetErrorState] = useState({})

  useEffect(() => {
    if (props.open) {
      console.log('AddonItemModal On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('AddonItemModal'),
        {},
      )
      myModal.show()
      SetModalType(props.type)
      if (props.type == 'view' || props.type == 'edit') {
        if (props.type == 'view') {
          SetReadOnly(true)
          process_data()
        }

        if (props.type == 'edit') {
          SetReadOnly(false)
          // if(props.data.hasOwnProperty("update_request")){
          //   if(Object.keys(props.data.update_request).length > 0 ){
          //     process_edit_data()
          //   }else{
          //     process_data()
          //   }
          // }else{
          //   process_data()
          // }
          process_edit_data()

        }

      }else{
        get_specification();
      }
    }
  }, [props.open])

  useEffect(() => {
    console.log('AddonItemModal On mount :', props)
    let myModal1 = document.getElementById('AddonItemModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData({
        name: '',
        notes: '',
        mrp: [],
        custom_quantity: false,
      })
      SetReadOnly(false)
      SetModalType('')
      SetMrpData([])
      SetErrorState({})
      props.close(close_data)
    })
  }, [])

  function process_data(){
    SetSaveData((prevValue) => {
      prevValue.name = props.data.name
      prevValue.notes = props.data.notes
      prevValue.mrp = props.data.mrp
      prevValue.custom_quantity = props.data.custom_quantity
      return { ...prevValue }
    })

    // let spec_data = props.data.specifications;
    // spec_data.map((ele)=>{
    //   if(ele.hasOwnProperty("value")){
    //     ele.value.label = ele.value.name
    //   }
    // })
    // SetSpecData(spec_data)
    if(props.data.hasOwnProperty("specifications_updates")){
      let spec_data = props.data.specifications_updates;
      spec_data.map((ele)=>{
        if(ele.hasOwnProperty("value")){
          ele.value.label = ele.value.name
        }
      })
      SetSpecData(spec_data)
    }else{
      get_specification();
    }

    let mrp_value : any = []
    if (props.data.hasOwnProperty('mrp')) {
      props.data.mrp.map((ele: any) => {
        var date = ele.activation_date
        var newdate = date.split('-').reverse().join('-')
        let push_value = {
          selected_country: {
            label: ele.country,
            value: ele.country_id,
            name: ele.country,
            id: ele.country_id,
          },
          country: ele.country_id,
          state_data: [],
          selected_state: {
            label: ele.state,
            value: ele.state_id,
            name: ele.state,
            id: ele.state_id,
          },
          state: ele.state_id,
          selected_currency: {
            label: ele.currency_symbol,
            value: ele.currency_id,
            name: ele.currency,
            id: ele.currency_id,
            code: ele.currency_code,
            symbol: ele.currency_symbol,
          },
          currency: ele.currency_id,
          price: ele.price,
          selected_date: new Date(newdate),
          activation_date: ele.activation_date,
        }
        console.log('push_value :', push_value)
        mrp_value.push(push_value)
      })
    } else {
    }
    SetMrpData(mrp_value)
  }
  
  function process_edit_data(){
    if(Object.keys(props.data.update_request).length > 0 ){
      SetSaveData((prevValue) => {
        prevValue.name = props.data.update_request.name
        prevValue.notes = props.data.update_request.notes
        prevValue.mrp = props.data.mrp
        prevValue.custom_quantity = props.data.custom_quantity
        return { ...prevValue }
      })
    }else{
      SetSaveData((prevValue) => {
        prevValue.name = props.data.name
        prevValue.notes = props.data.notes
        prevValue.mrp = props.data.mrp
        prevValue.custom_quantity = props.data.custom_quantity
        return { ...prevValue }
      })
    }
    if(props.data.hasOwnProperty("specifications_updates")){
      let spec_data = props.data.specifications_updates;
      spec_data.map((ele)=>{
        if(ele.hasOwnProperty("value")){
          ele.value.label = ele.value.name
        }
      })
      SetSpecData(spec_data)
    }else{
      get_specification();
      // let spec_data = props.data.specifications;
      // spec_data.map((ele)=>{
      //   if(ele.hasOwnProperty("value")){
      //     ele.value.label = ele.value.name
      //   }
      // })
      // SetSpecData(spec_data)
    }
    let mrp_value = []
    if (props.data.hasOwnProperty('mrp')) {
      props.data.mrp.map((ele: any) => {
        var date = ele.activation_date
        var newdate = date.split('-').reverse().join('-')
        let push_value = {
          selected_country: {
            label: ele.country,
            value: ele.country_id,
            name: ele.country,
            id: ele.country_id,
          },
          country: ele.country_id,
          state_data: [],
          selected_state: {
            label: ele.state,
            value: ele.state_id,
            name: ele.state,
            id: ele.state_id,
          },
          state: ele.state_id,
          selected_currency: {
            label: ele.currency_symbol,
            value: ele.currency_id,
            name: ele.currency,
            id: ele.currency_id,
            code: ele.currency_code,
            symbol: ele.currency_symbol,
          },
          currency: ele.currency_id,
          price: ele.price,
          selected_date: new Date(newdate),
          activation_date: ele.activation_date,
        }
        console.log('push_value :', push_value)
        mrp_value.push(push_value)
      })
    } else {
    }
    SetMrpData(mrp_value)
  }

  function validate() {
    let nameError = ''
    let noteError = ''

    if (!save_data.name) {
      nameError = 'Name field is required'
    }
    // if (!save_data.notes) {
    //   noteError = 'Note field is required'
    // }

    // if (nameError || noteError) {
    //   SetErrorState({ nameError, noteError })
    //   return false
    // }

    if (nameError) {
      SetErrorState({ nameError })
      return false
    }
    
    return true
  }

  async function get_specification(){
    let pass_data : any = {
      get: {
        specifications_only : 1
      },
      post: {
        specification: [
          {
              "id": "115",
              "source_type": "specification",
              "data_type": "varying"
          }
        ],
      },
    }
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    let res_data : any = await api('/product/init_specifications', pass_data)
    console.log('/product/init_specifications :', res_data)
    if(res_data.status_code == 200){
      if(res_data.response.hasOwnProperty("data")){
        SetSpecData(res_data.response.data)
      }
    }
  }

  async function create_addon_item() {
    // if (save_data.name == '') {
    //   toast.error('Enter Addon Item Name')
    // } else if (save_data.notes == '') {
    //   toast.error('Enter Addon Item notes')
    // } else {
    if (validate()) {
      console.log('validate ok')
      let addon_value = [];
      let mrp_value = [];
      let spec_value = [];
      mrp_data.map((ele: any, index: any) => {
        let push_data = {
          country: ele.country,
          state: ele.state,
          currency: ele.currency,
          price: ele.price,
          activation_date: ele.activation_date,
        }
        mrp_value.push(push_data)
      })
      spec_data.map((ele:any,index:any)=>{
        let push_data : any = {}
        if(ele.type_id == 2){
          if(Object.keys(ele.value).length > 0 ){
            push_data.id = ele.id;
            push_data.value = ele.value.value;
            spec_value.push(push_data);
          }
        }
      })
      addon_value.push({
        name: save_data.name,
        notes: save_data.notes,
        custom_quantity: save_data.custom_quantity,
        mrp: mrp_value,
        specification_data : spec_value
      })
      let pass_data = {
        get: {},
        post: {
          addons: addon_value,
        },
      }
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id
      } else {
        pass_data.get.m_id = id_value.m_id
      }
      console.log('Pass_data', pass_data)
      let res_data = await api('/product/add_addon_items', pass_data)
      console.log('/product/add_addon_items :', res_data)

      if (res_data.status_code == 201) {
        toast.success('Addon Item Created')

        if (res_data.response.hasOwnProperty('data')) {
          let item = close_data
          item.action = 'save'
          SetCloseData(item)
          myModal.hide()
        }
      }
    } else {
      console.log('validate not ok')
    }
    // }
  }

  const Switch = ({ isOn, handleToggle, onColor }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={`react-switch-new`}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={`react-switch-new`}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    )
  }

  async function get_state(c_data: any, index: any) {
    console.log('get_state')
    let pass_data = {
      get: {
        country: c_data.id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)

    SetMrpData((prevValue: any) => {
      prevValue[index].selected_country = c_data
      prevValue[index].country = c_data.id
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty('data')) {
          prevValue[index].state_data = response.response.data
        }
      }
      return [...prevValue]
    })
  }

  function get_currency() {
    console.log('get_currency')
    let currencies_data = context.app_data.currencies
    // currencies_data.map((ele: any) => {
    //   let label_value = ele.label + "(" + ele.code + ")";
    //   ele.label = label_value;
    // });
    return currencies_data
  }

  async function update_addon_item_details() {
    if (validate()) {
      console.log('validate ok')
      let spec_value : any = [];
      spec_data.map((ele:any,index:any)=>{
        let push_data : any = {}
        if(ele.type_id == 2){
          if(Object.keys(ele.value).length > 0 ){
            push_data.id = ele.id;
            push_data.value = ele.value.value;
            spec_value.push(push_data);
          }
        }
      })
      let pass_data = {
        get: {},
        post: {
          addon: [
            {
              id: props.data.id,
              name: save_data.name,
              notes: save_data.notes,
              custom_quantity: save_data.custom_quantity,
              specification_data : spec_value
            },
          ],
        },
      }
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id
      } else {
        pass_data.get.m_id = id_value.m_id
      }
      console.log('pass_data :', pass_data)
      let response = await api('/product/update_addon_item_details', pass_data)
      console.log('/product/update_addon_item_details response :', response)
      if (response.status_code == 200) {
        if (response.response.status) {
          toast.success('Addon item updated successfully')
          let item = close_data
          item.action = 'save'
          SetCloseData(item)
          myModal.hide()
        }
      } else {
        toast.error(response.response.message)
      }
    } else {
      console.log('validate not ok')
    }
  }

  async function update_addon_item_mrp(mrp_value) {
    let addons_value = [
      {
        id: props.data.id,
        mrp: mrp_value,
      },
    ]
    let pass_data = {
      get: {},
      post: {
        addons: addons_value,
        return: 'addon',
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/add_addon_pricing', pass_data)
    console.log('/product/add_addon_pricing response :', response)
    if (response.status_code == 201) {
      if (response.response.hasOwnProperty('data')) {
        let mrp_value = []
        response.response.data[0].mrp.map((ele: any) => {
          var date = ele.activation_date
          var newdate = date.split('-').reverse().join('-')
          let push_value = {
            selected_country: {
              label: ele.country,
              value: ele.country_id,
              name: ele.country,
              id: ele.country_id,
            },
            country: ele.country_id,
            state_data: [],
            selected_state: {
              label: ele.state,
              value: ele.state_id,
              name: ele.state,
              id: ele.state_id,
            },
            state: ele.state_id,
            selected_currency: {
              label: ele.currency_symbol,
              value: ele.currency_id,
              name: ele.currency,
              id: ele.currency_id,
              code: ele.currency_code,
              symbol: ele.currency_symbol,
            },
            currency: ele.currency_id,
            price: ele.price,
            selected_date: new Date(newdate),
            activation_date: ele.activation_date,
          }
          console.log('push_value :', push_value)
          mrp_value.push(push_value)
        })
        SetMrpData(mrp_value)
        props.data.mrp = response.response.data[0].mrp
        SetAddMrpClicked(false)
      }
    } else {
      toast.error(response.response.message)
    }
  }

  async function remove_addon_item_mrp(mrp_value, index_value) {
    console.log('remove_addon_item_mrp mrp value :', mrp_value)
    let pass_data = {
      get: {},
      post: {
        item_mrp: [mrp_value],
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/product/delete_addon_mrp', pass_data)
    console.log('/product/delete_addon_mrp response :', response)

    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success('Mrp Removed successfully')
        SetMrpData((prevValue: any) => {
          prevValue.splice(index_value, 1)
          return [...prevValue]
        })
        SetAddMrpClicked(false)
      } else {
        toast.error(response.response.message)
      }
    }
  }

  return (
    <div
      className="modal"
      id="AddonItemModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {modal_type == 'create'
                ? 'Create Addon Item'
                : modal_type == 'view'
                ? 'View Addon Item'
                : modal_type == 'edit'
                ? 'Edit Addon Item'
                : null}
            </h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body">
            <div className="">
              <div className="row">
                {/* Addon Item Name */}
                <div className="col-md-6 mb-2">
                  <label className="form-label">Addon Item Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Addon Item Name"
                    value={save_data.name}
                    disabled={read_only}
                    onChange={(e) => {
                      SetSaveData((prevValue) => {
                        prevValue.name = e.target.value
                        return { ...prevValue }
                      })
                      SetErrorState((prevValue: any) => {
                        if (e.target.value == '') {
                          prevValue.nameError = 'Name field is required'
                        } else {
                          prevValue.nameError = ''
                        }
                        return { ...prevValue }
                      })
                    }}
                  />
                  <span className="text-danger">{error_state.nameError}</span>
                </div>
                {/* Addon Item Notes */}
                <div className="col-md-6 mb-2">
                  <label className="form-label">Addon Item Notes *</label>

                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Addon Item Notes"
                    value={save_data.notes}
                    disabled={read_only}
                    onChange={(e) => {
                      SetSaveData((prevValue) => {
                        prevValue.notes = e.target.value
                        return { ...prevValue }
                      })
                      SetErrorState((prevValue: any) => {
                        if (e.target.value == '') {
                          prevValue.noteError = 'Note field is required'
                        } else {
                          prevValue.noteError = ''
                        }
                        return { ...prevValue }
                      })
                    }}
                  />
                  <span className="text-danger">{error_state.noteError}</span>
                </div>
                {/* custom quantity */}
                <div className="col-md-6 mb-2">
                  <label className="form-label d-flex">
                    <p>Custom Quantity</p>
                    <Switch
                      style={{
                        background:
                          'rgb(13, 110, 253) none repeat scroll 0% 0%',
                      }}
                      isOn={save_data.custom_quantity}
                      onColor="#0053ad"
                      handleToggle={(e) => {
                        console.log('Switch on change :', e)
                        if (!read_only) {
                          SetSaveData((prevValue) => {
                            prevValue.custom_quantity = !prevValue.custom_quantity
                            return { ...prevValue }
                          })
                        }
                      }}
                    />
                  </label>
                </div>
                {spec_data.map((spec_ele:any,spec_index:any)=>(
                  <div className="col-md-6 mb-2">
                  <label className="form-label d-flex">
                    <p>{spec_ele.name}</p>
                    {spec_ele.type_id == 2 || spec_ele.type == 2 ? (
                      // select
                      <Select
                        components={animatedComponents}
                        placeholder="Select Option"
                        isDisabled={props.type == "view" ? true : false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={spec_ele.hasOwnProperty("children") ? spec_ele.children : spec_ele.options }
                        value={spec_ele.value}
                        onChange={(e) => {
                          SetSpecData((prevalue)=>{
                            prevalue.map((ele,index)=>{
                              if(index == spec_index){
                                ele.value = e
                              }
                            })
                            console.log("prevalue :",prevalue)
                            return [...prevalue]
                          })
                        }}
                      />
                    ) : null}
                  </label>
                </div>
                ))}
              </div>
              {/* <div className="">
                <div className="d-flex align-items-center pb-2 border-bottom">
                  <p className="text-dark fw-bold mb-0">MRP</p>
                  <div className="ms-auto">
                    {props.type != 'view' ? (
                      props.type == 'edit' ? (
                        add_mrp_clicked ? (
                          <p
                            className="mb-0 text-primary px-2 cursor"
                            onClick={() => {
                              SetMrpData((prevValue: any) => {
                                prevValue.splice(prevValue.length - 1, 1)
                                return [...prevValue]
                              })
                              SetAddMrpClicked(false)
                            }}
                          >
                            Cancel
                          </p>
                        ) : (
                          <p
                            className="mb-0 text-primary px-2 cursor"
                            onClick={() => {
                              SetMrpData((prevValue: any) => {
                                let push_value = {
                                  selected_country: {},
                                  country: '',
                                  state_data: [],
                                  selected_state: {},
                                  state: '',
                                  selected_currency: {},
                                  currency: '',
                                  price: '',
                                  activation_date: '',
                                }
                                prevValue.push(push_value)
                                return [...prevValue]
                              })
                              SetAddMrpClicked(true)
                            }}
                          >
                            + Add MRP
                          </p>
                        )
                      ) : (
                        <p
                          className="mb-0 text-primary px-2 cursor"
                          onClick={() => {
                            SetMrpData((prevValue: any) => {
                              let push_value = {
                                selected_country: {},
                                country: '',
                                state_data: [],
                                selected_state: {},
                                state: '',
                                selected_currency: {},
                                currency: '',
                                price: '',
                                activation_date: '',
                              }
                              prevValue.push(push_value)
                              return [...prevValue]
                            })
                            SetAddMrpClicked(true)
                          }}
                        >
                          + Add MRP
                        </p>
                      )
                    ) : null}
                  </div>
                </div>
                {mrp_data.map((ele: any, index: any, row: any) => (
                  <div className="p-2 mb-3 cursor-hover" key={index}>
                    <div className="row py-2 border-bottom">
                      //Country
                      <div className="col-md-3 mb-2">
                        <label className="form-label">Country</label>
                        <Select
                          components={animatedComponents}
                          placeholder="Select Country"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={context.app_data.countries}
                          value={ele.selected_country}
                          menuPortalTarget={document.body}
                          isDisabled={read_only}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            console.log(' Country :', e)

                            if (e.id != '99') {
                              SetMrpData((prevValue: any) => {
                                prevValue[index].selected_country = e
                                prevValue[index].country = e.id
                                return [...prevValue]
                              })
                            } else {
                              get_state(e, index)
                            }
                          }}
                        />
                      </div>
                      //State
                      {ele.selected_country.id == '99' ? (
                        <div className="col-md-3 mb-2">
                          <label className="form-label">State</label>
                          <Select
                            components={animatedComponents}
                            placeholder="Select State"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={ele.state_data}
                            value={ele.selected_state}
                            menuPortalTarget={document.body}
                            isDisabled={read_only}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            onChange={(e) => {
                              console.log(' state :', e)
                              SetMrpData((prevValue: any) => {
                                prevValue[index].selected_state = e
                                prevValue[index].state = e.id
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      ) : null}
                      //Price
                      <div className="col-md-3 mb-2">
                        <label className="form-label">Price</label>
                        <div className="input-group">
                          <div className="input-group-text p-0">
                            <Select
                              className="no-radius border-none"
                              components={animatedComponents}
                              placeholder="Select Currency"
                              classNamePrefix="select"
                              options={context.app_data.currencies}
                              value={ele.selected_currency}
                              menuPortalTarget={document.body}
                              isDisabled={read_only}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (base) => ({
                                  ...base,
                                  border: 'none',
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  backgroundColor: 'none',
                                }),
                              }}
                              onChange={(e) => {
                                console.log(' Currency :', e)

                                SetMrpData((prevValue: any) => {
                                  prevValue[index].selected_currency = e
                                  prevValue[index].currency = e.id
                                  return [...prevValue]
                                })
                              }}
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Price"
                            value={ele.price}
                            disabled={read_only}
                            onChange={(e) => {
                              console.log(' Price :', e.target.value)
                              SetMrpData((prevValue: any) => {
                                prevValue[index].price = e.target.value
                                return [...prevValue]
                              })
                            }}
                          />
                        </div>
                      </div>
                      //Activation Date
                      <div className="col-md-2 mb-2">
                        <label className="form-label">Activation Date</label>
                        <DatePicker
                          selected={ele.selected_date}
                          dateFormat="dd/MM/yyyy"
                          disabled={read_only}
                          onSelect={(date) => {
                            let date_value = moment(date).format('DD-MM-yyyy')
                            SetMrpData((prevValue: any) => {
                              prevValue[index].selected_date = date
                              prevValue[index].activation_date = date_value
                              return [...prevValue]
                            })
                          }}
                          onChange={(date) => {
                            console.log('date :', date)
                            let date_value = moment(date).format('DD-MM-yyyy')
                            SetMrpData((prevValue: any) => {
                              prevValue[index].selected_date = date
                              prevValue[index].activation_date = date_value
                              return [...prevValue]
                            })
                          }}
                        />
                      </div>
                      {props.type != 'view' ? (
                        props.type == 'edit' ? (
                          add_mrp_clicked ? (
                            index + 1 === row.length ? (
                              <div className="col-md-1  ms-auto center p-0">
                                <button
                                  className="btn btn-sm btn-primary center cursor"
                                  onClick={() => {
                                    console.log('update ok add mrp')
                                    update_addon_item_mrp(mrp_data)
                                  }}
                                >
                                  <Ionicons name="ios-checkmark-sharp" size={14} color="#fff" />
                                  <p className='text-white ps-1 mb-0'>Ok</p>
                                </button>
                              </div>
                            ) : null
                          ) : (
                            <div
                              className="col-md-1 ms-auto mt-2 p-0 center cursor"
                            >
                              <button className='btn btn-sm'
                                onClick={() => {
                                  console.log('mrp ele : ', ele)
                                  console.log(
                                    'props ele : ',
                                    props.data.mrp[index].id,
                                  )
                                  remove_addon_item_mrp(
                                    props.data.mrp[index].id,
                                    index,
                                  )
                                }}
                              >
                                <MaterialCommunityIcons
                                  name="delete-outline"
                                  size={16}
                                  color="black"
                                />
                                {width < 740 ? (
                                  <span className='text-dark ps-1'>Remove</span>
                                ) : null}
                              </button>

                            </div>
                          )
                        ) : (
                          <div
                            className="col-1 ms-auto mt-2 p-0 center cursor"
                            onClick={() => {
                              SetMrpData((prevValue: any) => {
                                prevValue.splice(index, 1)
                                return [...prevValue]
                              })
                            }}
                          >
                            <MaterialCommunityIcons
                              name="delete-outline"
                              size={16}
                              color="black"
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
          {/* Footer */}
          <div className="modal-footer shadow">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                // SetSaveData(initial_value);
                myModal.hide()
              }}
            >
              Close
            </button>
            <div className="ms-auto">
              {modal_type == 'create' ? (
                <button
                  type="button"
                  className="btn btn-primary btn-radius px-3"
                  onClick={() => {
                    console.log('save')
                    create_addon_item()
                  }}
                >
                  Submit
                </button>
              ) : null}
              {modal_type == 'edit' ? (
                !add_mrp_clicked ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-radius px-3"
                    onClick={() => {
                      console.log('update')
                      update_addon_item_details()
                    }}
                  >
                    Update
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
