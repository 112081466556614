import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";

import DataContext from "../../utils/Context/DataContext";
import Loader from "../../utils/Loader";
import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import ManufacturerSideMenu from "../ManufacturerSideMenu/ManufacturerSideMenu";
import ManufacturerHeader from "../ManufacturerHeader/ManufacturerHeader";
import ManufacturerFooter from "../ManufacturerFooter/ManufacturerFooter";

export default function ManufacturerLayout(props) {
  const context = useContext(DataContext);
  const location = useLocation();
  const [is_menu_open, SetIsMenuOpen] = useState(false);
  const [id_value, SetIdValue] = useState(useParams());
  // const [user_details, SetUserDetails] = useState({});
  // const [language_data, SetLanguageData] = useState([]);
  // const [country_data, SetCountryData] = useState([]);
  // const [currency_data, SetCurrencyData] = useState([]);

  useEffect(() => {
    console.log("ManufacturerLayout onmount context :", context);
    console.log("ManufacturerLayout onmount id_value :", id_value);

    get_init();
  }, []);

  async function get_init() {
    let pass_data = {
      get: {
        m_id: id_value.m_id,
      },
    };
    let response = await api("/init", pass_data);
    console.log("/init :", response);
    if (response.status_code == 200) {
      let user_details,
        languages,
        countries,
        states,
        currencies,
        image_sizes,
        image_base_url;
      if (response.response.hasOwnProperty("user_details")) {
        user_details = response.response.user_details;
      } else {
        user_details = {};
      }
      if (response.response.hasOwnProperty("languages")) {
        languages = response.response.languages;
      } else {
        languages = [];
      }
      if (response.response.hasOwnProperty("countries")) {
        countries = response.response.countries;
      } else {
        countries = [];
      }
      if (response.response.hasOwnProperty("states")) {
        states = response.response.states;
      } else {
        states = [];
      }
      if (response.response.hasOwnProperty("currencies")) {
        currencies = response.response.currencies;
      } else {
        currencies = [];
      }
      if (response.response.hasOwnProperty("image_sizes")) {
        image_sizes = response.response.image_sizes;
      } else {
        image_sizes = [];
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        image_base_url = response.response.image_base_url;
      } else {
        image_base_url = "";
      }
      context.SetAppData((prevValue: any) => {
        prevValue.user_details = user_details;
        prevValue.languages = languages;
        prevValue.countries = countries;
        prevValue.states = states;
        prevValue.currencies = currencies;
        prevValue.image_sizes = image_sizes;
        prevValue.image_base_url = image_base_url;
        prevValue.app_from = "manufacturer";
        return { ...prevValue };
      });
    }
  }

  function on_menu_click(data) {
    console.log("ManufacturerLayout on menu/back click :", data);
    SetIsMenuOpen(data);
  }

  return (
    <>
      <div className="page-body">
        <ManufacturerSideMenu
          active={is_menu_open}
          menu_click={(data) => {
            on_menu_click(data);
          }}
        />
        <div className="page-content">
          {!is_menu_open ? <ManufacturerHeader /> : null}
          <Outlet />
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              className: "",
              style: {
                border: "1px solid #171546",
                padding: "10px",
              },
              duration: 2500,
            }}
          />
        </div>
      </div>
      {!is_menu_open ? (
        <ManufacturerFooter
          menu_click={(data) => {
            on_menu_click(data);
          }}
          from="Manufacturer"
        />
      ) : null}
    </>
  );
}
