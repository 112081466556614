import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import ModalAddGallery from "./ModalAddGallery";
import {
  AntDesign,
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppHelper } from "../../utils/Helper";

export default function Gallery(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const [size_data, SetSizeData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [gallery_data, SetGalleryData] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const context = useContext(DataContext);
  const [modal_gallery, SetModalGallery] = useState({
    is_open: false,
    data: {},
    type: "add",
  });
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });

  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: 1,
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
    // spec: [],
    // mrp: [],
    // addon_groups: [],
    // category_id: '',
  });

  useEffect(() => {
    console.log("Gallery On mount :", props);
    console.log("Gallery On mount context :", context);
    // get_gallery(page_no)
  }, []);

  useEffect(() => {
    if (filter_data.search == "") {
      get_gallery(filter_data, false);
    } else {
      const timeOutId = setTimeout(
        () => triggerItemChange(filter_data.search),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [filter_data.search]);

  function triggerItemChange(value) {
    SetFilterData((prevValue: any) => {
      // prevValue.page_no = prevValue.page_no + 1
      prevValue.page_no = 1;
      get_gallery(prevValue, false);
      return { ...prevValue };
    });
  }

  async function get_gallery(filter_value, fetch_value) {
    let pass_data = {
      get: {
        page: filter_value.page_no,
        search: filter_value.search,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("get_gallery pass_data :", pass_data);
    let response = await api("/Imagegallery/get", pass_data);
    console.log("/Imagegallery/get response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("next_page")) {
        SetFilterData((prevValue: any) => {
          prevValue.next_page = response.response.next_page;
          return { ...prevValue };
        });
      }
      if (response.response.hasOwnProperty("sizes")) {
        SetSizeData(response.response.sizes);
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }
      if (response.response.hasOwnProperty("data")) {
        let gallery_value = response.response.data;
        // gallery_value.map((ele: any) => {
        //   ele.checked = false
        // })
        if (fetch_value) {
          SetGalleryData((prevValue: any) => {
            // console.log(
            //   'fetch_value true SetGalleryData prevValue :',
            //   prevValue,
            // )
            // response.response.data.map((ele: any) => {
            //   ele.checked = false
            //   prevValue.push(ele)
            // })
            // console.log(
            //   'fetch_value true SetGalleryData prevValue after push :',
            //   prevValue,
            // )
            // prevValue = response.response.data
            let old_data = prevValue,
              resp_data = response.response.data,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;
            // prevValue.push.apply(prevValue, response.response.data)
            SetFilterData((prevValue1: any) => {
              if (prevValue.every((v_ele) => v_ele.checked == true)) {
                prevValue1.is_all_checked = true;
              } else {
                prevValue1.is_all_checked = false;
              }
              return { ...prevValue1 };
            });
            return [...prevValue];
          });
        } else {
          SetFilterData((prevValue: any) => {
            if (gallery_value.every((v_ele) => v_ele.checked == true)) {
              prevValue.is_all_checked = true;
            } else {
              prevValue.is_all_checked = false;
            }
            return { ...prevValue };
          });
          SetGalleryData(response.response.data);
        }

        // SetGalleryData(response.response.data)
      }
    }
  }

  async function delete_image(pass_value, product_data_value, delete_type) {
    console.log("delete_image pass_value: ", pass_value);
    console.log("delete_image delete_type: ", delete_type);

    let pass_data = {
      get: {},
      post: {
        images: [],
      },
    };

    if (delete_type == "single") {
      pass_data.post.images.push(pass_value.data.id);
    } else {
      let push_value = [];
      console.log("gallery_data :", product_data_value);
      product_data_value.map((ele: any) => {
        if (ele.checked) {
          push_value.push(ele.id);
        }
      });
      pass_data.post.images = push_value;
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    console.log("delete_image pass_data: ", pass_data);
    let res_data = await api("/Imagegallery/delete", pass_data);
    console.log("/Imagegallery/delete response :", res_data);
    if (res_data.status_code == 200) {
      toast.success("Gallery Image Deleted");

      SetFilterData((prevValue1: any) => {
        prevValue1.is_checked = false;
        prevValue1.is_all_checked = false;
        return { ...prevValue1 };
      });

      get_gallery(filter_data, false);

      if (res_data.response.hasOwnProperty("data")) {
        SetGalleryData(res_data.response.data);
      } else {
        SetGalleryData([]);
      }
    } else {
      get_gallery(filter_data, false);
    }
  }

  return (
    <div className="">
      {/* Header */}

      <div className=" row align-items-center head-bar">
        <div className="col-md-10 col-7 sm-mb-2">
          <h5 className="  mb-0">Gallery</h5>
        </div>

        <div className="col-5 col-md-2 ps-0 text-end sm-mb-2">
          <button
            className="btn btn-primary btn-radius btn-sm px-3"
            onClick={() => {
              SetModalGallery((prevValue: any) => {
                prevValue.is_open = true;
                prevValue.type = "add";
                return { ...prevValue };
              });
            }}
          >
            Add
          </button>
        </div>
      </div>
      <div className="border-top fixed-sub-header">
        <div className=" row align-items-center head-bar">
          <div className="col-md-6 col-10 d-flex">
            {/* search */}
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
                onChange={(e) => {
                  // SetSearchData((prevValue: any) => {
                  //   prevValue = e.target.value
                  //   return prevValue
                  // })
                  SetFilterData((prevValue1: any) => {
                    prevValue1.search = e.target.value;
                    return { ...prevValue1 };
                  });
                }}
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={16} color="#ccc" />
              </div>
            </div>
          </div>
          {/* count */}
          {/* <div className="text-center col-md-1 col-2 ps-0">
            <select
              className="form-select"
              value={filter_data.count}
              onChange={() => {}}
            >
              {filter_data.count_option.map((ele: any) => (
                <option value={ele.value}>{ele.label}</option>
              ))}
            </select>
          </div> */}
          <div className="col-12 col-md-6 end sm-mt-3">
            {!filter_data.is_checked ? (
              <div className="">
                {/* Select All */}
                <button
                  className="btn btn-sm pe-2 "
                  onClick={() => {
                    SetGalleryData((prevValue: any) => {
                      prevValue.map((ele) => {
                        ele.checked = true;
                      });
                      SetFilterData((prevValue1: any) => {
                        prevValue1.is_checked = true;
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          prevValue1.is_all_checked = true;
                        } else {
                          prevValue1.is_all_checked = false;
                        }
                        return { ...prevValue1 };
                      });
                      return [...prevValue];
                    });
                  }}
                >
                  <MaterialCommunityIcons
                    name="checkbox-marked-circle-outline"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Select All</span>
                </button>
              </div>
            ) : (
              <div>
                {/* Select All */}
                {!filter_data.is_all_checked ? (
                  <button
                    className="btn btn-sm pe-2 border-right"
                    onClick={() => {
                      SetGalleryData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = true;
                        });
                        SetFilterData((prevValue1: any) => {
                          prevValue1.is_checked = true;
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            prevValue1.is_all_checked = true;
                          } else {
                            prevValue1.is_all_checked = false;
                          }
                          return { ...prevValue1 };
                        });
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-marked-circle-outline"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Select All</span>
                  </button>
                ) : null}
                {/* Un Select All */}
                <button
                  className="btn btn-sm px-2 border-right"
                  onClick={() => {
                    SetGalleryData((prevValue: any) => {
                      prevValue.map((ele) => {
                        ele.checked = false;
                      });
                      SetFilterData((prevValue1: any) => {
                        prevValue1.is_checked = false;
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          prevValue1.is_all_checked = true;
                        } else {
                          prevValue1.is_all_checked = false;
                        }
                        return { ...prevValue1 };
                      });
                      return [...prevValue];
                    });
                  }}
                >
                  <MaterialCommunityIcons
                      name="progress-close"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Unselect All</span>
                </button>
                {/* Delete */}
                <button
                  className="btn btn-sm "
                  onClick={() => {
                    SetModalDelete((prevValue: any) => {
                      prevValue.is_open = true;
                      prevValue.delete_type = "multiple";
                      prevValue.data = {
                        title: "Delete Gallery Images ",
                        body: "Are you sure? want to delete selected gallery image",
                        data: gallery_data,
                      };
                      prevValue.have_option = false;
                      prevValue.options = {};
                      return { ...prevValue };
                    });
                  }}
                >
                  <MaterialCommunityIcons
                    name="delete-outline"
                    size={16}
                    color="#777"
                  />
                  <span className="ps-1 text-gray">Delete</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="px-1">
        <div className="p-3 mb-3">
          <div className="row">
            {gallery_data.length > 0 ? (
              <InfiniteScroll
                dataLength={gallery_data.length}
                next={() => {
                  console.log("on next :");
                  SetFilterData((prevValue: any) => {
                    prevValue.page_no = prevValue.page_no + 1;
                    get_gallery(prevValue, true);
                    return { ...prevValue };
                  });
                }}
                hasMore={filter_data.next_page}
                className=""
                loader={
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
                endMessage={
                  <div className="center pt-5 pb-3">
                    <hr className="w-25" />
                  </div>
                }
              >
                <div className="row px-2">
                  {gallery_data.map((i_ele: any, i_index: any) => (
                    <div className="col-md-3 col-6 mb-2 px-1" key={i_index}>
                      <div
                        className={
                          gallery_data[i_index].checked
                            ? "card-border card p-1"
                            : "card p-1"
                        }
                      >
                        <label className="d-flex align-items-center cursor">
                          <div className="position-check ">
                            <input
                              className="form-check-input-custom"
                              type="checkbox"
                              checked={i_ele.checked}
                              onChange={() => {
                                SetGalleryData((prevValue: any) => {
                                  prevValue[i_index].checked =
                                    !prevValue[i_index].checked;

                                  SetFilterData((prevValue1: any) => {
                                    if (
                                      prevValue.some(
                                        (v_ele) => v_ele.checked == true
                                      )
                                    ) {
                                      prevValue1.is_checked = true;
                                    } else {
                                      prevValue1.is_checked = false;
                                    }

                                    if (
                                      prevValue.every(
                                        (v_ele) => v_ele.checked == true
                                      )
                                    ) {
                                      prevValue1.is_all_checked = true;
                                    } else {
                                      prevValue1.is_all_checked = false;
                                    }
                                    return { ...prevValue1 };
                                  });

                                  return [...prevValue];
                                });
                              }}
                            />
                          </div>
                          <img
                            src={AppHelper.imageSrc(image_base_url, i_ele.url, {
                              format: "webp",
                              width: 320,
                            })}
                            // src={
                            //   image_base_url + size_data[0] + "/" + i_ele.url
                            // }
                            className="gallery_img"
                          />
                        </label>
                        <div className="img_over_content_gallery">
                          <div className="d-flex align-items-center mt-1">
                            <p className="v-small oneline text-white mb-0 img-name">
                              {i_ele.name}
                            </p>

                            {/* <div className="dropdown cursor ms-auto">
                              <div
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Entypo
                                  name="dots-three-horizontal"
                                  size={18}
                                  color="white"
                                />
                              </div>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <div
                                    className="cursor dropdown-item"
                                    onClick={() => {
                                      console.log('view')
                                      SetModalGallery((prevValue: any) => {
                                        prevValue.is_open = true
                                        prevValue.type = 'view'
                                        prevValue.data = i_ele
                                        return { ...prevValue }
                                      })
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <Ionicons
                                      name="eye-outline"
                                      size={16}
                                      color="gray"
                                    />
                                    &nbsp; View
                                  </div>
                                </li>

                                <li>
                                  <div
                                    className="cursor dropdown-item"
                                    onClick={() => {
                                      console.log('delete')
                                      SetModalDelete((prevValue: any) => {
                                        prevValue.is_open = true
                                        prevValue.delete_type = 'single'
                                        prevValue.data = {
                                          title: 'Delete Image  ',
                                          body:
                                            'Are you sure? want to delete image',
                                          data: i_ele,
                                        }
                                        prevValue.have_option = false
                                        prevValue.options = {}
                                        return { ...prevValue }
                                      })
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <AntDesign
                                      name="delete"
                                      size={16}
                                      color="gray"
                                    />
                                    &nbsp; Delete
                                  </div>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="center h-170 w-100">
                <div>
                  <img
                    src={require("../../assets/img/svg/no_data_2.svg")}
                    className="product-img"
                  />
                  <p className="mt-3 fw-bold text-center">No Data</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalAddGallery
        open={modal_gallery.is_open}
        type={modal_gallery.type}
        data={modal_gallery.data}
        from={"gallery"}
        close={(data: any) => {
          console.log("ModalAddGallery on close :", data);
          SetModalGallery((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            SetFilterData((prevValue: any) => {
              prevValue.page_no = 1;
              get_gallery(prevValue, false);
              return { ...prevValue };
            });
          }
        }}
      />

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        delete_type={modal_delete.delete_type}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data) => {
          console.log("on close delete modal data:", data);
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            delete_image(data, data.data, data.delete_type);
          }
        }}
      />
    </div>
  );
}

{
  /* // <div className="">
    //   <div className="bg-fff py-2 px-4">
    //     <h5 className="  mb-1">Gallery</h5>
    //   </div>
    // </div> */
}
