import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import InfiniteScroll from "react-infinite-scroll-component";
import toast from "react-hot-toast";
import { AppHelper } from "../../utils/Helper";

export default function ProductsVariant(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());

  const [product_data, SetProductData] = useState([]);
  const [size_data, SetSizeData] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const [is_checked, SetIsChecked] = useState(false);
  const [is_all_checked, SetIsAllChecked] = useState(false);

  const [search_data, SetSearchData] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [sort_by, SetSortBy] = useState("desc");
  const [next_page, SetNextPage] = useState(false);
  const [timer, SetTimer] = useState(null);
  const [filter_data, SetFilterData] = useState({
    own_products: true,
    others_products: true,
  });
  const [filter_active, SetFilterActive] = useState(false);

  useEffect(() => {
    console.log("ProductsVariant On mount :", props);
    console.log("ProductsVariant On mount context :", context);
    let pass_value = {
      search_data: search_data,
      page_no: page_no,
      sort: sort_by,
      fetch_more: false,
      filter_data: filter_data,
    };
    // get_variants(pass_value);
  }, []);

  useEffect(() => {
    if (search_data == "") {
      let pass_value = {
        search_data: search_data,
        page_no: page_no,
        sort: sort_by,
        fetch_more: false,
        filter_data: filter_data,
      };
      get_variants(pass_value);
    } else {
      const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search_data]);

  async function get_variants(pass_value) {
    let pass_data = {
      get: {
        sort: pass_value.sort,
        page: pass_value.page_no,
        filter_products: encodeURI(
          JSON.stringify({
            own_products: pass_value.filter_data.own_products,
            others_products: pass_value.filter_data.others_products,
          })
        ),
      },
    };
    if (pass_value.search_data != "") {
      pass_data.get.search = pass_value.search_data;
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let res_data = await api("/product/all_variants", pass_data);
    console.log("/product/all_variants :", res_data);
    if (res_data.status_code == 200) {
      if (res_data.response.hasOwnProperty("image_sizes")) {
        SetSizeData(res_data.response.image_sizes);
      }
      if (res_data.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(res_data.response.image_base_url);
      }
      if (res_data.response.hasOwnProperty("data")) {
        let product_data_value = res_data.response.data;
        product_data_value.map((ele: any) => {
          ele.checked = false;
        });
        if (pass_value.fetch_more) {
          SetProductData((prevValue: any) => {
            product_data_value.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetProductData(product_data_value);
        }
      }
      if (res_data.response.hasOwnProperty("next_page")) {
        SetNextPage(res_data.response.next_page);
      }
    }
  }

  async function delete_product_group(data, product_data_value, delete_type) {
    console.log("delete_product_group data :", data);
    console.log("product_data_value :", product_data_value);

    let pass_data = {
      get: {},
      post: {},
    };
    let selected_group_data = [];
    product_data_value.map((ele: any) => {
      if (ele.checked) {
        selected_group_data.push(ele.id);
      }
    });
    console.log("selected_group_data :", selected_group_data);
    let url_path = "";
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    if (data.have_option) {
      if (data.options.is_checked) {
        pass_data.post.group_variants = selected_group_data;
        url_path = "/product/delete_variant";
      } else {
        pass_data.post.products = selected_group_data;
        url_path = "/product/delete_product_group";
      }
    } else {
      pass_data.post.variants = selected_group_data;
      url_path = "/product/delete_variant";
    }

    console.log("delete_product_variant pass_data :", pass_data);
    console.log("delete_product_variant url_path :", url_path);
    let res_data = await api(url_path, pass_data);
    console.log(url_path + ":", res_data);

    if (res_data.status_code == 200) {
      if (res_data.response.status) {
        toast.success("Variant Deleted");
        SetPageNo((prevValue: any) => {
          prevValue = 1;
          let pass_value = {
            search_data: search_data,
            page_no: prevValue,
            sort: sort_by,
            fetch_more: false,
            filter_data: filter_data,
          };
          get_variants(pass_value);
          return prevValue;
        });
      } else {
        toast.error(res_data.response.message);
      }
    }
  }

  function triggerItemChange(value) {
    SetPageNo((prevValue: any) => {
      prevValue = 1;
      let pass_value = {
        search_data: value,
        page_no: prevValue,
        sort: sort_by,
        fetch_more: false,
        filter_data: filter_data,
      };
      get_variants(pass_value);
      return prevValue;
    });
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar">
        <div className="col-7 col-md-10">
          <h5 className="  mb-0">Products</h5>
        </div>
        <div className="col-5 col-md-2 text-end ps-0">
          <Link
            to={
              // context.app_data.app_from == 'seller'
              id_value.hasOwnProperty("brand_id")
                ? "/seller/" + id_value.brand_id + "/product/add"
                : "/manufacturer/" + id_value.m_id + "/product/add"
            }
          >
            <button
              className="btn btn-primary btn-radius px-3 btn-sm"
              onClick={() => {
                console.log("Products Variant new");
              }}
            >
              Create New
            </button>
          </Link>
        </div>
      </div>

      {/* Sub Header */}
      <div className="fixed-sub-header">
        <div className="row align-items-center head-bar">
          {/* search */}
          <div className="col-md-5 col-12 ">
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here..."
                onChange={(e) => {
                  SetSearchData((prevValue: any) => {
                    prevValue = e.target.value;
                    return prevValue;
                  });
                }}
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={16} color="#ccc" />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-7 sm-mt-3 ">
            <div className="end">
              {!is_checked ? (
                <div className="pe-2 border-right">
                  {/* select_all */}
                  <button
                    className="btn btn-sm "
                    onClick={() => {
                      SetProductData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = true;
                        });
                        SetIsChecked(true);
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          SetIsAllChecked(true);
                        } else {
                          SetIsAllChecked(false);
                        }
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-marked-circle-outline"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Select All</span>
                  </button>
                </div>
              ) : (
                <div>
                  {!is_all_checked ? (
                    // {/* select_all */}
                    <button
                      className="btn btn-sm pe-2 border-right"
                      onClick={() => {
                        SetProductData((prevValue: any) => {
                          prevValue.map((ele) => {
                            ele.checked = true;
                          });
                          SetIsChecked(true);
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            SetIsAllChecked(true);
                          } else {
                            SetIsAllChecked(false);
                          }
                          return [...prevValue];
                        });
                      }}
                    >
                      <MaterialCommunityIcons
                        name="checkbox-marked-circle-outline"
                        size={16}
                        color="#777"
                      />
                      <span className="ps-1 text-gray">Select All</span>
                    </button>
                  ) : null}
                  {/* unselect_all */}
                  <button
                    className="btn btn-sm px-2 border-right"
                    onClick={() => {
                      SetProductData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = false;
                        });
                        SetIsChecked(false);
                        if (prevValue.every((v_ele) => v_ele.checked == true)) {
                          SetIsAllChecked(true);
                        } else {
                          SetIsAllChecked(false);
                        }
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="progress-close"
                      size={16}
                      color="#777"
                    />
                    <span className="ps-1 text-gray">Unselect All</span>
                  </button>
                  {/* delete */}
                  <button
                    className="btn btn-sm px-2 border-right"
                    onClick={() => {
                      SetModalDelete((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.delete_type = "multiple";

                        prevValue.data = {
                          title: "Delete Product",
                          body: "Are you sure? want to delete product",
                          data: product_data,
                        };
                        prevValue.have_option = false;
                        prevValue.options = {
                          title: "Also Delete Variant",
                          type: "checkbox",
                          is_checked: false,
                        };
                        return { ...prevValue };
                      });
                      console.log("ProductData :", product_data);
                    }}
                  >
                    <MaterialCommunityIcons
                      name="delete-outline" size={16} color="#777" />
                    <span className="ps-1 text-gray">Delete</span>
                  </button>
                </div>
              )}

              {/* Filter */}
              <div
                className="dropdown ps-2"
              // onChange={() => {
              //       SetFilterActive(!filter_active);
              //     }}
              >
                <button
                  className="btn btn-sm d-flex dropdown-toggle cursor"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <Ionicons name="ios-filter" size={18} color="#297ad0" /> */}
                  {/* <span className="ps-1">Filter</span> */}
                  <img src={require("../../assets/icon/filter.png")} className="logo-img" />
                </button>
                <ul
                  className="dropdown-menu p-2 pt-3"
                  aria-labelledby="dropdownMenu"
                >
                  <li>
                    <p className="fw-bold text-dark mb-1">Products by</p>
                    <div className="py-2 btn btn-sm mb-1">
                      <label className="form-label mb-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={filter_data.own_products}
                          onChange={(e) => {
                            SetFilterData((prevValue: any) => {
                              prevValue.own_products = e.target.checked;
                              SetPageNo(1);
                              let pass_value = {
                                search_data: search_data,
                                page_no: 1,
                                sort: sort_by,
                                fetch_more: false,
                                filter_data: prevValue,
                              };
                              get_variants(pass_value);
                              return { ...prevValue };
                            });
                          }}
                        />
                        <span className="ps-2">Own Products</span>
                      </label>
                    </div>
                    <div className="py-2 btn btn-sm mb-1">
                      <label className="form-label mb-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={filter_data.others_products}
                          onChange={(e) => {
                            SetFilterData((prevValue: any) => {
                              prevValue.others_products = e.target.checked;
                              SetPageNo(1);
                              let pass_value = {
                                search_data: search_data,
                                page_no: page_no,
                                sort: sort_by,
                                fetch_more: false,
                                filter_data: prevValue,
                              };
                              get_variants(pass_value);
                              return { ...prevValue };
                            });
                          }}
                        />
                        <span className="ps-2">Other Products</span>
                      </label>
                    </div>
                  </li>
                  <li className="border-top pt-2">
                    <p className="fw-bold text-dark mb-1">Group by</p>
                    <div className="py-2 btn-sm mb-1 w-100 text-left">
                      <label className="form-label mb-0 p-0 w-100">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="group"
                          checked={sort_by == "asc" ? true : false}
                          onChange={(e) => {
                            SetSortBy("asc");
                            SetPageNo(1);
                            let pass_value = {
                              search_data: search_data,
                              page_no: 1,
                              sort: "asc",
                              fetch_more: false,
                              filter_data: filter_data,
                            };
                            get_variants(pass_value);
                          }}
                        />
                        <span className="ps-2">Ascending Order</span>
                      </label>
                    </div>
                    <div className="py-2 btn-sm mb-1 w-100 text-left">
                      <label className="form-label mb-0 p-0 w-100">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="group"
                          checked={sort_by == "desc" ? true : false}
                          onChange={(e) => {
                            SetSortBy("desc");
                            SetPageNo(1);
                            let pass_value = {
                              search_data: search_data,
                              page_no: 1,
                              sort: "desc",
                              fetch_more: false,
                              filter_data: filter_data,
                            };
                            get_variants(pass_value);
                          }}
                        />
                        <span className="ps-2">Descending Order</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container px-sm-0 px-md-3 my-3">
        <div className=" mb-3">
          {product_data.length > 0 ? (
            <InfiniteScroll
              dataLength={product_data.length}
              next={() => {
                SetPageNo((prevValue: any) => {
                  prevValue = prevValue + 1;
                  let pass_value = {
                    search_data: search_data,
                    page_no: prevValue,
                    sort: sort_by,
                    fetch_more: true,
                    filter_data: filter_data,
                  };
                  get_variants(pass_value);
                  return prevValue;
                });
              }}
              hasMore={next_page}
              className=""
              loader={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              }
              endMessage={
                <p className="text-center pt-3">{/* <b>End Of Data</b> */}</p>
              }
            >
              <div className="row">
                {product_data.map((ele: any, index: any) => (
                  <div
                    className="col-md-6 mb-2 cursor"
                    key={index}
                    onClick={() => {
                      console.log("Product Detail :", ele);
                    }}
                  >
                    <div className="card p-3">
                      <div className="row ">
                        <div className="col-11">
                          <label className="d-flex cursor">
                            <input
                              type="checkbox"
                              className="cursor form-check-input-custom"
                              checked={ele.checked}
                              onChange={() => {
                                SetProductData((prevValue: any) => {
                                  prevValue[index].checked =
                                    !prevValue[index].checked;
                                  if (
                                    prevValue.some(
                                      (v_ele) => v_ele.checked == true
                                    )
                                  ) {
                                    SetIsChecked(true);
                                  } else {
                                    SetIsChecked(false);
                                  }
                                  return [...prevValue];
                                });
                              }}
                            />
                            <p className="ps-2 mb-0 fw-bold text-dark text-black">
                              {ele.name}
                            </p>
                          </label>
                        </div>
                        <div className="col-1 ps-0 text-end">
                          <div
                          // className={
                          //   ele.status == "Active"
                          //     ? "text-green mb-1"
                          //     : "mb-1"
                          // }
                          >
                            {ele.status == "Active" ? (
                              <FontAwesome
                                name="circle"
                                size={14}
                                color="#18b901"
                              />
                            ) : (
                              <FontAwesome
                                name="circle"
                                size={14}
                                color="red"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="p-0 py-2 border-bottom">
                        <div className="row">
                          <div className="col-3 px-1">
                            {/* <img
                                src={require('../../assets/img/mob.png')}
                                className="product_img"
                              /> */}
                            {ele.hasOwnProperty("images") ? (
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  ele.images[0].url,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={
                                //   image_base_url +
                                //   size_data[0] +
                                //   "/" +
                                //   ele.images[0].url
                                // }
                                className="product_img_cover radius"
                              />
                            ) : (
                              <img
                                src={require("../../assets/img/no-image.jpg")}
                                className="product_img radius"
                              />
                            )}
                          </div>
                          <div className="col-9 ps-1">
                            <Link
                              to={
                                // context.app_data.app_from == 'seller'
                                id_value.hasOwnProperty("brand_id")
                                  ? "/seller/" +
                                  id_value.brand_id +
                                  "/product/details/" +
                                  ele.id
                                  : "/manufacturer/" +
                                  id_value.m_id +
                                  "/product/details/" +
                                  ele.id
                              }
                            >
                              {ele.hasOwnProperty("brand") ? (
                                <div className="pb-2">
                                  <small className="">
                                    Brand :{" "}
                                    {/* <span className="ps-1 text-dark">
                                          Smart phone
                                        </span> */}
                                  </small>
                                </div>
                              ) : null}

                              <div className="pb-2">
                                <small className="">
                                  Category :{" "}
                                  <span className="ps-1 text-dark">
                                    {ele.category}
                                  </span>
                                </small>
                              </div>

                              {/* <div className="pb-2">
                                <small className="">
                                  Addon Items :{" "}
                                  <span className="ps-1 text-dark">
                                    {ele.total_addon_items}
                                  </span>
                                </small>
                              </div> */}


                            </Link>
                          </div>
                        </div>
                        <div className="row pt-2 align-items-center">
                          <div className="col-6 pb-2">
                            {/* <small className="">
                              Addons :{" "}
                              <span className="ps-1 text-dark">
                                {ele.total_addon_groups}
                              </span>
                            </small> */}
                          </div>

                          <div className="col-6 pb-2 text-end">
                            <small className="">
                              MRP :{" "}
                              {ele.min_variant_mrp.currency_symbol != null &&
                              ele.min_variant_mrp.price != 0 &&
                              ele.max_variant_mrp.currency_symbol != null &&
                              ele.max_variant_mrp.price != 0 ? (
                                <span className="ps-1 text-dark">
                                  {/* ₹20000 - ₹22000 */}
                                  {ele.min_variant_mrp.currency_symbol +
                                    " " +
                                    ele.min_variant_mrp.price +
                                    "-" +
                                    ele.max_variant_mrp.currency_symbol +
                                    " " +
                                    ele.max_variant_mrp.price}
                                </span>
                              ) : null}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <p className="mb-0 pt-2">{ele.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            // product_data.map((ele: any, index: any) => (
            //   <div
            //     className="col-md-6 mb-2 cursor"
            //     key={index}
            //     onClick={() => {
            //       console.log("Product Detail :", ele);
            //     }}
            //   >
            //     <div className="d-flex">
            //       <div className="">
            //         <input
            //           type="checkbox"
            //           checked={ele.checked}
            //           onChange={() => {
            //             SetProductData((prevValue: any) => {
            //               prevValue[index].checked =
            //                 !prevValue[index].checked;
            //               if (
            //                 prevValue.some((v_ele) => v_ele.checked == true)
            //               ) {
            //                 SetIsChecked(true);
            //               } else {
            //                 SetIsChecked(false);
            //               }
            //               prevValue.map((p_ele: any, p_index: any) => {});
            //               return [...prevValue];
            //             });
            //           }}
            //         />
            //       </div>
            //       <div className="">
            //         <Link
            //           to={
            //            // context.app_data.app_from == "seller"
            // id_value.hasOwnProperty("brand_id")
            //               ? "/seller/" +
            //                 id_value.brand_id +
            //                 "/group/details" +
            //                 ele.id
            //               : "/manufacturer/" +
            //                 id_value.m_id +
            //                 "/group/details/" +
            //                 ele.id
            //           }
            //         >
            //           <div className="card p-3">
            //             <div className="row">
            //               <div className="col-2 px-1">
            //                 <img
            //                   src={require("../../assets/img/mob.png")}
            //                   className="product_img"
            //                 />
            //               </div>
            //               <div className="col-10 ps-0">
            //                 <div className="d-flex">
            //                   <p className="mb-1 double text-black">
            //                     {ele.name}
            //                   </p>
            //                   <div className="ms-auto">
            //                     <small
            //                       className={
            //                         ele.status == "Active"
            //                           ? "text-green mb-1"
            //                           : "mb-1"
            //                       }
            //                     >
            //                       {ele.status}
            //                     </small>
            //                   </div>
            //                 </div>
            //                 <small className="oneline">{ele.category}</small>
            //               </div>

            //               <p className="my-1">{ele.description}</p>
            //             </div>
            //           </div>
            //         </Link>
            //       </div>
            //     </div>
            //   </div>
            // ))
            <div className="bg-fff">
              <div className="center h-170 w-100 p-3">
                <div>
                  <img
                    src={require("../../assets/img/empty.png")}
                    className="product-img"
                  />
                  <p className="mt-3 fw-bold text-center">No Data Found!</p>
                </div>
              </div>
            </div>
          )}
          {/* Pagination */}
          {/* <div className="pagination:container text-end mt-4">
            <div className="pagination:number arrow">
              <MaterialIcons
                name="keyboard-arrow-left"
                size={24}
                color="black"
              />
            </div>

            <div className="pagination:number">1</div>
            <div className="pagination:number">2</div>

            <div className="pagination:number pagination:active">3</div>

            <div className="pagination:number">..</div>

            <div className="pagination:number">20</div>

            <div className="pagination:number arrow">
              <MaterialIcons
                name="keyboard-arrow-right"
                size={24}
                color="black"
              />
            </div>
          </div> */}
          {/* Modal */}
          <div className="">
            <ModalDelete
              open={modal_delete.is_open}
              data={modal_delete.data}
              delete_type={modal_delete.delete_type}
              have_option={modal_delete.have_option}
              options={modal_delete.options}
              close={(data) => {
                console.log("on close delete modal data:", data);
                console.log(
                  "on close delete modal product_data:",
                  product_data
                );

                SetModalDelete((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  // console.log("on save product_data :", product_data);
                  let product_data_value = data.data;
                  delete_product_group(
                    data,
                    product_data_value,
                    data.delete_type
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
