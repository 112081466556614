import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  MaterialIcons,
} from "@expo/vector-icons";

const menu_initial_value = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
  },
  {
    id: 2,
    name: "Brand",
    path: "brand",
  },
  {
    id: 2,
    name: "Generate Product",
    path: "generate",
  },
  {
    id: 3,
    name: "Product Groups",
    path: "group/list",
  },
  {
    id: 4,
    name: "Products",
    path: "product/list",
  },
  {
    id: 5,
    name: "Gallery",
    path: "gallery",
  },
  {
    id: 6,
    name: "Addon Items",
    path: "addon_items",
  },
  {
    id: 7,
    name: "Addon Groups",
    path: "addon_groups",
  },
  {
    id: 8,
    name: "Specifications",
    path: "specifications",
  },
];

export default function SellerSideMenu(props) {
  const context = useContext(DataContext);

  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems] = useState(menu_initial_value);
  const [id_value, SetIdValue] = useState(useParams());
  const [view_data, SetViewData] = useState({});

  useEffect(() => {
    console.log("SellerSideMenu On mount :", props);
    console.log("SellerSideMenu On id_value :", id_value);
  }, []);

  useEffect(() => {
    console.log("SellerSideMenu On props.active :", props);
    SetIsActive(props.active);
  }, [props.active]);

  function dropdown_action(m_item, m_index) {
    let items = menu_items;
    items[m_index].is_open = !m_item.is_open;
    SetMenuItems([...items]);
  }

  function dropdown_close() {
    let items = menu_items;
    items.map((item) => {
      if (item.hasOwnProperty("is_open")) {
        item.is_open = false;
      }
    });
    SetMenuItems([...items]);
  }

  return (
    <div className={is_active ? "sidebar active" : "sidebar"}>
      <div className="d-lg-none d-flex">
        {/* <i className="fas fa-arrow-left px-3 py-2 text-white"></i> */}
        <div className="cursor">
          <img
            src={require("../../assets/icon/svg/log-out.svg")}
            className="p-2 cursor"
          />{" "}
          Logout
        </div>
        <div
          className="ms-auto pe-2"
          onClick={() => {
            props.menu_click(false);
          }}
        >
          <img
            src={require("../../assets/icon/svg/x.svg")}
            className="p-2 cursor"
          />
          close
        </div>
      </div>
     
      <div className="navbar-profile pt-sm-0 pt-md-3">
      <div className=" center pe-0">
        {/* {context.app_data.user_details.hasOwnProperty("dp_url") && context.app_data.user_details.dp_url != "" ?(
          <img
            src={context.app_data.user_details.dp_url}
            className="profile-sidemenu-img"
          />
        ):(
          <img
            src={require("../../assets/img/5.jpeg")}
            className="profile-sidemenu-img"
          />
        )} */}
          <h6>{context.app_data.account?.name}</h6>
        </div>
        {/* <p className="fs-8 fw-bolder pt-2">
          {context.app_data.user_details.name}
        </p> */}
        {/* <small>Seller</small> */}
      </div>
      <div className="sidebar-sticky ">
        <ul>
          {menu_items.map((m_item, m_index) =>
            m_item.hasOwnProperty("children") ? (
              <li className="sidebar-dropdown" key={m_index}>
                <a
                  className={
                    m_item.is_open
                      ? "sidebar-dropdown-btn active"
                      : "sidebar-dropdown-btn"
                  }
                  role="button"
                  onClick={() => {
                    dropdown_action(m_item, m_index);
                  }}
                >
                  <i className="fas fa-cogs"></i> {context.t(m_item.name)}
                  <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i>
                </a>
                <div className="sidebar-dropdown-menu">
                  <ul>
                    {m_item.children.map((m_c_item, m_c_index) => (
                      <li key={m_c_index}>
                        <NavLink
                          className="link-loader"
                          activeclassname="link-active"
                          to={m_c_item.path}
                          onClick={() => {
                            dropdown_close();
                            props.menu_click(false);
                          }}
                        >
                          <i className="fas fa-gifts"></i>{" "}
                          {/* {context.t(m_c_item.name)} */}
                          {m_c_item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ) : (
              <li key={m_index}>
                <NavLink
                  className="link-loader"
                  activeclassname="link-active"
                  to={m_item.path}
                  onClick={() => {
                    dropdown_close();
                    props.menu_click(false);
                  }}
                >
                  <img src={m_item.img} className="icon-img" />{" "}
                  {/* {context.t(m_item.name)} */}
                  {m_item.name}
                </NavLink>
              </li>
            )
          )}
          <li>
            <NavLink
              className="cursor ms-2 link-loader"
              // activeclassname="link-active"
              to={"/"}
              onClick={() => {
                window.location.href =
                  "https://sellers.1superapp.com/brand/" +
                  id_value.brand_id +
                  "/dashboard";
              }}
            >
              Go to Seller
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
