import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

const menu_initial_value = [
  {
    id: 1,
    name: "Dashboard",
    path: "dashboard",
    icon: "collage",

    // icon: <MaterialIcons name="dashboard" size={16} color="gray" />
  },
  {
    id: 2,
    name: "Brand",
    path: "brand",
    icon: "tag-text",
    // icon: <MaterialIcons name="stars" size={24} color="black" />,
  },
  {
    id: 2,
    name: "Generate Product",
    path: "generate",
    icon: "plus-box-multiple",
    // icon: <MaterialIcons name="add-to-photos" size={16} color="gray" />
  },
  {
    id: 3,
    name: "Product Groups",
    path: "group/list",
    icon: "group",
    // icon: <MaterialCommunityIcons name="select-group" size={16} color="gray" />
  },
  {
    id: 4,
    name: "Products",
    path: "product/list",
    icon: "cart",
    // icon: <Fontisto name="shopping-basket" size={16} color="gray" />
  },
  {
    id: 5,
    name: "Gallery",
    path: "gallery",
    icon: "image-multiple",
    // icon: <Ionicons name="ios-images" size={16} color="gray" />
  },
  {
    id: 6,
    name: "Addon Items",
    path: "addon_items",
    icon: "playlist-plus",
    // icon: <Entypo name="add-to-list" size={16} color="gray" />
  },
  {
    id: 7,
    name: "Addon Groups",
    path: "addon_groups",
    icon: "view-grid-plus",
    // icon: <MaterialIcons name="dashboard-customize" size={16} color="gray" />
  },
  {
    id: 8,
    name: "Specifications",
    path: "specifications",
    icon: "format-list-text",
    // icon: <MaterialCommunityIcons name="format-list-text" size={16} color="gray" />
  },
];

export default function ManufacturerSideMenu(props) {
  const context = useContext(DataContext);

  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems] = useState(menu_initial_value);
  const [id_value, SetIdValue] = useState(useParams());
  const [view_data, SetViewData] = useState({});
  const [active_nav, SetActiveNav] = useState(null);

  useEffect(() => {
    console.log("ManufacturerSideMenu On mount :", props);
    console.log("ManufacturerSideMenu On id_value :", id_value);
  }, []);

  useEffect(() => {
    console.log("ManufacturerSideMenu On props.active :", props);
    SetIsActive(props.active);
  }, [props.active]);

  function dropdown_action(m_item, m_index) {
    let items = menu_items;
    items[m_index].is_open = !m_item.is_open;
    SetMenuItems([...items]);
  }

  function dropdown_close() {
    let items = menu_items;
    items.map((item) => {
      if (item.hasOwnProperty("is_open")) {
        item.is_open = false;
      }
    });
    SetMenuItems([...items]);
  }

  return (
    <div className={is_active ? "sidebar active" : "sidebar"}>
      <div className="navbar-profile mt-sm-0 px-2">
        <div className="d-sm-block d-md-none p-0">
          <div
            className="cursor p-2 end"
            onClick={() => {
              props.menu_click(false);
            }}
          >
            <Ionicons name="close" size={20} color="black" />
          </div>
        </div>
        <div className=" center pe-0">
            {context.app_data.user_details.hasOwnProperty("dp_url") && context.app_data.user_details.dp_url != "" ?(
              <img
                src={context.app_data.user_details.dp_url}
                className="profile-sidemenu-img"
              />
            ):(
              <img
                src={require("../../assets/img/5.jpeg")}
                className="profile-sidemenu-img"
              />
            )}
        </div>
        <div className=" ps-2 pe-1 text-center">
          <p className="text-dark mb-2 pt-2 fw-bold fs-7">
            {context.app_data.user_details.name}
          </p>
          <small>Manufacturer</small>
        </div>
      </div>
      <div className="sidebar-sticky pt-4">
        <ul>
          {menu_items.map((m_item, m_index) =>
            m_item.hasOwnProperty("children") ? (
              <li className="sidebar-dropdown" key={m_index}>
                <a
                  className={
                    m_item.is_open
                      ? "sidebar-dropdown-btn active"
                      : "sidebar-dropdown-btn"
                  }
                  role="button"
                  onClick={() => {
                    dropdown_action(m_item, m_index);
                  }}
                >
                  <i className="fas fa-cogs"></i> {context.t(m_item.name)}
                  <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i>
                </a>
                <div className="sidebar-dropdown-menu">
                  <ul>
                    {m_item.children.map((m_c_item, m_c_index) => (
                      <li key={m_c_index}>
                        <NavLink
                          className="link-loader"
                          activeclassname="link-active"
                          to={m_c_item.path}
                          onClick={() => {
                            dropdown_close();
                            props.menu_click(false);
                          }}
                        >
                          <i className="fas fa-gifts"></i>{" "}
                          {/* {context.t(m_c_item.name)} */}
                          {m_c_item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ) : (
              <li key={m_index}>
                <NavLink
                  className="link-loader align-items-center d-flex"
                  activeclassname="link-active"
                  to={m_item.path}
                  onClick={() => {
                    dropdown_close();
                    props.menu_click(false);
                    SetActiveNav(m_index);
                  }}
                >
                  <MaterialCommunityIcons
                    name={m_item.icon}
                    size={17}
                    color={m_index == active_nav ? "#3872c8" : "#999"}
                  />

                  <span className="ps-2">{m_item.name}</span>
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>

      <div className="center w-auto-bottom p-2">
        <p className="pe-1 mb-0 extra-small">Powered by</p>
        <img src={require("../../assets/img/logo.png")} className="logo-img" />
        <p className="ps-1 mb-0 extra-small">Superapp</p>
      </div>
    </div>
  );
}
