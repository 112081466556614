import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
import Cookies from "js-cookie";

const animatedComponents = makeAnimated();

export default function SellerHeader(props) {
  const context = useContext(DataContext);

  useEffect(() => {
    console.log("SellerHeader On mount :", props);
    console.log("SellerHeader context :", context);
  }, []);

  async function get_locales(lang_value) {
    let pass_data = {
      get: {
        lang: lang_value.value,
      },
    };
    let response = await api("/locales", pass_data);
    console.log("locales :", response);

    if (response.response.hasOwnProperty("data")) {
      context.SetAppData((prevValue) => {
        prevValue.selected_language = lang_value;
        prevValue.language_data = response.response.data;
        return { ...prevValue };
      });
    }
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar fixed-top navbar-light navbar-custom ">
        <div className="row w-100 align-items-center">
          <div className="col-md-6">
            <h4 className="mb-0 fw-bold text-primary">
              {/* {context.t("sellers")} */}
              Products
            </h4>
          </div>

          <div className="col-md-6 pe-0 d-lg-block d-md-none text-end">
            <div className="sidebar-options dropdown">
              <div className="col-2">
                <Select
                  components={animatedComponents}
                  placeholder="Select Language"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={context.app_data.language_list}
                  value={context.app_data.selected_language}
                  onChange={(e) => {
                    console.log("SellerHeader Select :", e);
                    SetSelectedLanguage(e);
                    Cookies.set("lang", e.value);
                    get_locales(e);
                  }}
                />
              </div>
              <Link
                to="/"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Home"
              >
                <img
                  src={require("../../assets/icon/svg/home1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="fas fa-home"></i> */}
              </Link>

              <button
                type="button"
                className="remove-after dropdown-toggle"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown tooltip"
                aria-expanded="false"
                data-bs-placement="bottom"
                title="Notification"
              >
                <img
                  src={require("../../assets/icon/svg/bell1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="far fa-bell"></i> */}
              </button>
              <Link
                to=""
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Logout"
              >
                <img
                  src={require("../../assets/icon/svg/log-out1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="fas fa-sign-out-alt"></i>  */}
              </Link>
              <a
                className="d-lg-block d-sm-none d-md-none"
                href="https://1superapp.com/dashboard"
              >
                <img
                  src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
                  width="25"
                />
              </a>
              <div
                className="nav-notification p-3 dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="nav-notification-content">
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
