import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  AntDesign,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-checkbox-tree/src/less/react-checkbox-tree.less'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
const animatedComponents = makeAnimated()

let myModal = {}
let state_data_value : any = [];
let today_date = new Date();

export default function ModalReviewMrp(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [mrp_state, SetMrpState] = useState({
    expanded: false,
    is_edit: false,
    field_data: [
      {
        selected_country: {
          id: "99",
          iso: "IN",
          iso3: "IND",
          label: "India",
          name: "INDIA",
          nicename: "India",
          numcode: "356",
          phonecode: "91",
          value: "99",
        },
        country: '99',
        state_data: state_data_value,
        selected_state: {},
        state: '',
        selected_currency: {
          code: "INR",
          id: "1",
          label: "₹",
          name: "Indian Rupees",
          symbol: "₹",
          value: "1",
        },
        currency: "1",
        price: '',
        selected_date: today_date,
        activation_date: moment(today_date).format('DD-MM-yyyy'),
      },
    ],
    mrp_data: [],
  })
  const [width, SetWidth] = React.useState(innerWidth)

  // selected_country
  useEffect(() => {
    if (props.open) {
      console.log('ModalReviewMrp On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('ReviewMrpModal'),
        {},
      )
      SetMrpState((prevValue: any) => {
        //   prevValue.mrp_data = props.mrp_data_value
        let f_value = 
          {
            selected_country: {
              id: "99",
              iso: "IN",
              iso3: "IND",
              label: "India",
              name: "INDIA",
              nicename: "India",
              numcode: "356",
              phonecode: "91",
              value: "99",
            },
            country: '99',
            state_data: state_data_value,
            selected_state: {},
            state: '',
            selected_currency: {
              code: "INR",
              id: "1",
              label: "₹",
              name: "Indian Rupees",
              symbol: "₹",
              value: "1",
            },
            currency: "1",
            price: '',
            // selected_date: '',
            // activation_date: '',
            selected_date: today_date,
            activation_date: moment(today_date).format('DD-MM-yyyy'),
          }
        let m_value = props.mrp_data_value;
        if(m_value.length == 0){
          m_value.push(f_value);
        }
        prevValue.mrp_data = m_value
        prevValue.field_data = [
          {
            selected_country: {
              id: "99",
              iso: "IN",
              iso3: "IND",
              label: "India",
              name: "INDIA",
              nicename: "India",
              numcode: "356",
              phonecode: "91",
              value: "99",
            },
            country: '99',
            state_data: state_data_value,
            selected_state: {},
            state: '',
            selected_currency: {
              code: "INR",
              id: "1",
              label: "₹",
              name: "Indian Rupees",
              symbol: "₹",
              value: "1",
            },
            currency: "1",
            price: '',
            // selected_date: '',
            // activation_date: '',
            selected_date: today_date,
            activation_date: moment(today_date).format('DD-MM-yyyy'),
          },
        ]
        return { ...prevValue }
      })
      myModal.show()
      get_state_value();
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalReviewMrp On mount :', props)
    let myModal1 = document.getElementById('ReviewMrpModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      props.close(close_data)
    })
  }, [])

  async function get_state(c_data: any, index: any) {
    console.log('get_state')
    let pass_data = {
      get: {
        country: c_data.id,
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)
    SetMrpState((prevValue: any) => {
      if (response.status_code == 200) {
        if (response.response.hasOwnProperty('data')) {
          prevValue.field_data[index].state_data = response.response.data
        }
      }
      return { ...prevValue }
    })
  }

  async function get_state_value() {
    console.log('get_state_value')
    let pass_data = {
      get: {
        country: "99",
      },
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id
    } else {
      pass_data.get.m_id = id_value.m_id
    }
    console.log('pass_data :', pass_data)
    let response = await api('/init/get_state', pass_data)
    console.log('/init/get_state response :', response)
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty('data')) {
        state_data_value = response.response.data
        SetMrpState((prevalue:any)=>{
          prevalue.field_data.map((ele:any)=>{
            ele.state_data = response.response.data
          })
          prevalue.mrp_data.map((ele:any)=>{
            ele.state_data = response.response.data
          })
          return {...prevalue}
        })
      }
    }
  }

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  )

  return (
    <div
      className="modal"
      id="ReviewMrpModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down  modal-dialog-centered ">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Mrp Details
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body pb-5">
            {/* {!mrp_state.is_edit ? (
              <div className="">
                <div className="d-flex align-items-center border-bottom pb-2">
                  <p className="text-dark fw-bold mb-0"> MRP Data</p>

                  <div className="ms-auto">
                    <button
                      onClick={() => {
                        // onEditAddon();
                        SetMrpState((prevValue: any) => {
                          prevValue.is_edit = true
                          return { ...prevValue }
                        })
                      }}
                      className="btn btn-text text-primary btn-sm"
                    >
                      <span className="pe-1">
                        <AntDesign name="edit" size={18} color="#0053ad" />
                      </span>
                      Edit
                    </button>
                  </div>
                </div>
                {mrp_state.mrp_data.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Country</th>
                          <th scope="col">State</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Price</th>
                          <th scope="col">Activation Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mrp_state.mrp_data.map(
                          (field_ele: any, field_index: any) => (
                            <tr key={field_index}>
                              <th scope="row" className="text-gray">
                                {field_index + 1}
                              </th>
                              <td className="text-gray">
                                {field_ele.selected_country.name}
                              </td>
                              {field_ele.hasOwnProperty('state') ? (
                                <td className="text-gray">
                                  {field_ele.selected_state.nicename}
                                </td>
                              ) : (
                                <td className="text-gray">-</td>
                              )}
                              <td className="text-gray">
                                {field_ele.selected_currency.name} -{' '}
                                {field_ele.selected_currency.symbol}
                              </td>
                              <td className="text-gray">{field_ele.price}</td>
                              <td className="text-gray">
                                {field_ele.activation_date}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm"
                                  onClick={() => {
                                    SetMrpState((prevValue: any) => {
                                      prevValue.mrp_data.splice(field_index, 1)
                                      // props.mrp_on_save(prevValue, props.index);
                                      let pass_data = {
                                        mrp_data: prevValue.mrp_data,
                                        index: props.index_value,
                                      }
                                      props.on_remove(pass_data)
                                      return { ...prevValue }
                                    })
                                  }}
                                >
                                  <MaterialCommunityIcons
                                    name="delete-outline"
                                    size={18}
                                    color="black"
                                  />

                                  {width < 740 ? (
                                    <span className="ps-2">Remove </span>
                                  ) : null}
                                </button>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center pt-2">No Mrp Data</p>
                )}
              </div>
            ) : ( */}
              <div className="">
                {mrp_state.mrp_data.length > 0
                  ? 
                  mrp_state.mrp_data.map((field: any, field_index: any) => (
                      <div
                        className="row py-2 align-items-end border-bottom "
                        key={field_index}
                      >
                        {/* Country */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Country</label>
                          <Select
                            components={animatedComponents}
                            placeholder="Select Country"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={context.app_data.countries}
                            value={field.selected_country}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            onChange={(e) => {
                              console.log(' Country :', e)
                              SetMrpState((prevValue: any) => {
                                // prevValue.field_data[
                                //   field_index
                                // ].selected_country = e
                                // prevValue.field_data[field_index].country = e.id
                                // prevValue.field_data[
                                //   field_index
                                // ].selected_state = {}
                                // prevValue.field_data[field_index].state = ""

                                prevValue.mrp_data[
                                  field_index
                                ].selected_country = e
                                prevValue.mrp_data[field_index].country = e.id
                                prevValue.mrp_data[
                                  field_index
                                ].selected_state = {}
                                prevValue.mrp_data[field_index].state = ""
                                
                                // if (e.id == '99') {
                                //   get_state(e, field_index)
                                // }
                                return { ...prevValue }
                              })
                            }}
                          />
                        </div>
                        {/* State */}
                        {field.selected_country.id == '99' ? (
                          <div className="col-md-3 mb-2">
                            <label className="form-label">State</label>
                            <Select
                              components={animatedComponents}
                              placeholder="Select State"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={field.state_data}
                              value={field.selected_state}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              onChange={(e) => {
                                console.log(' state :', e)

                                SetMrpState((prevValue: any) => {
                                  // prevValue.field_data[
                                  //   field_index
                                  // ].selected_state = e
                                  // prevValue.field_data[field_index].state = e.id
                                  prevValue.mrp_data[
                                    field_index
                                  ].selected_state = e
                                  prevValue.mrp_data[field_index].state = e.id
                                  return { ...prevValue }
                                })
                              }}
                            />
                          </div>
                        ) : null}
                        {/* Price */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Price</label>
                          <div className="input-group">
                            <div className="input-group-text p-0">
                              <Select
                                className="no-radius border-none"
                                components={animatedComponents}
                                placeholder="Select Currency"
                                isSearchable={false}
                                classNamePrefix="select"
                                options={context.app_data.currencies}
                                value={field.selected_currency}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    border: 'none',
                                  }),
                                  indicatorSeparator: (base) => ({
                                    ...base,
                                    backgroundColor: 'none',
                                  }),
                                }}
                                onChange={(e) => {
                                  console.log(' Currency :', e)
                                  SetMrpState((prevValue: any) => {
                                    // prevValue.field_data[
                                    //   field_index
                                    // ].selected_currency = e
                                    // prevValue.field_data[field_index].currency =
                                    //   e.id
                                    prevValue.mrp_data[
                                      field_index
                                    ].selected_currency = e
                                    prevValue.mrp_data[field_index].currency =
                                      e.id
                                    return { ...prevValue }
                                  })
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Price"
                              value={field.price}
                              //   disabled={read_only}
                              onChange={(e) => {
                                console.log(' Price :', e.target.value)
                                SetMrpState((prevValue: any) => {
                                  // prevValue.field_data[field_index].price =
                                  //   e.target.value
                                  prevValue.mrp_data[field_index].price =
                                    e.target.value
                                  return { ...prevValue }
                                })
                              }}
                            />
                          </div>
                        </div>
                        {/* Activation Date */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Activation Date</label>
                          <DatePicker
                            selected={field.selected_date}
                            dateFormat="dd/MM/yyyy"
                            onSelect={(date : any) => {
                              console.log("date :",date)
                              let date_value = moment(date).format('DD-MM-yyyy')
                              SetMrpState((prevValue: any) => {
                                // prevValue.field_data[
                                //   field_index
                                // ].selected_date = date
                                // prevValue.field_data[
                                //   field_index
                                // ].activation_date = date_value
                                prevValue.mrp_data[
                                  field_index
                                ].selected_date = date
                                prevValue.mrp_data[
                                  field_index
                                ].activation_date = date_value
                                return { ...prevValue }
                              })
                            }}
                            onChange={(date) => {
                              let date_value = moment(date).format('DD-MM-yyyy')
                              SetMrpState((prevValue: any) => {
                                // prevValue.field_data[
                                //   field_index
                                // ].selected_date = date
                                // prevValue.field_data[
                                //   field_index
                                // ].activation_date = date_value
                                prevValue.mrp_data[
                                  field_index
                                ].selected_date = date
                                prevValue.mrp_data[
                                  field_index
                                ].activation_date = date_value
                                return { ...prevValue }
                              })
                            }}
                            customInput={<DatepickerInput />}
                          />
                        </div>
                        <div className="col-md-1 mb-2 ms-auto">
                          {/* {field_index > 0 ? ( */}
                            <button
                              className="btn btn-secondary-outline border-none btn-sm"
                              onClick={() => {
                                console.log('Remove')
                                SetMrpState((prevValue: any) => {
                                  // prevValue.field_data.splice(field_index, 1)
                                  prevValue.mrp_data.splice(field_index, 1)
                                  return { ...prevValue }
                                })
                              }}
                            >
                              <MaterialCommunityIcons
                                name="delete-outline"
                                size={16}
                                color="gray"
                              />
                              {/* Remove */}
                            </button>
                          {/* ) : null} */}

                          {/* <button
                                onClick={() => {
                                  console.log("field_data :", field_data);
                                }}
                              >
                                Check
                              </button> */}
                        </div>
                      </div>
                    ))
                   : null}
              </div>
            {/* )} */}

            {/* {mrp_state.is_edit ? ( */}
              <div className="center my-3">
                <button
                  className="btn btn-page btn-sm"
                  onClick={() => {
                    // onEditAddon();
                    SetMrpState((prevValue: any) => {
                      // prevValue.field_data.push({
                      prevValue.mrp_data.push({
                        selected_country: {
                          id: "99",
                          iso: "IN",
                          iso3: "IND",
                          label: "India",
                          name: "INDIA",
                          nicename: "India",
                          numcode: "356",
                          phonecode: "91",
                          value: "99",
                        },
                        country: '99',
                        state_data: state_data_value,
                        selected_state: {},
                        state: '',
                        selected_currency: {
                          code: "INR",
                          id: "1",
                          label: "₹",
                          name: "Indian Rupees",
                          symbol: "₹",
                          value: "1",
                        },
                        currency: "1",
                        price: '',
                        selected_date: today_date,
                        activation_date: moment(today_date).format('DD-MM-yyyy'),
                        // selected_date: '',
                        // activation_date: '',
                      })

                      return { ...prevValue }
                    })
                  }}
                >
                  <span className="pe-1">
                    <MaterialCommunityIcons
                      name="plus"
                      size={18}
                      color="black"
                    />
                  </span>
                  Add more
                </button>
              </div>
            {/* ) : null} */}
          </div>

          <div className="modal-footer shadow">
            {/* {mrp_state.is_edit ? (
              <div className="d-flex align-items-center w-100">
                <button
                  className="btn btn-sm btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    // onEditAddon();
                    SetMrpState((prevValue: any) => {
                      prevValue.is_edit = false
                      prevValue.field_data = []
                      return { ...prevValue }
                    })
                  }}
                >
                  Cancel
                </button>

                <div className="ms-auto">
                  <button
                    className="btn btn-sm btn-primary btn-radius px-3"
                    onClick={() => {
                      // onEditAddon();
                      SetMrpState((prevValue: any) => {
                        prevValue.field_data.map((ele) => {
                          prevValue.mrp_data.push(ele)
                        })
                        console.log('after push :', prevValue.mrp_data)
                        // let pass_data = {
                        //   mrp_data: prevValue.field_data,
                        //   index: props.index_value,
                        // }
                        // props.on_save(pass_data)
                        // console.log('after save ')
                        prevValue.field_data = [
                          {
                            selected_country: {},
                            country: '',
                            state_data: [],
                            selected_state: {},
                            state: '',
                            selected_currency: {},
                            currency: '',
                            price: '',
                            selected_date: '',
                            activation_date: '',
                          },
                        ]
                        prevValue.is_edit = false
                        // props.mrp_on_save(prevValue, props.index);
                        return { ...prevValue }
                      })
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : ( */}
              <div className="d-flex align-items-center w-100">
                <button
                  className="btn btn-sm btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    // onEditAddon();
                    // SetMrpState((prevValue: any) => {
                    //   prevValue.is_edit = false
                    //   prevValue.field_data = []
                    //   return { ...prevValue }
                    // })
                    let item = close_data
                      item.action = 'close'
                      SetCloseData(item)
                      // let pass_data = {
                      //   mrp_data: mrp_state.field_data,
                      //   index: props.index_value,
                      // }
                      // props.on_save(pass_data)
                      myModal.hide()
                  }}
                >
                  Cancel
                </button>
                <div className="ms-auto">
                  {/* <button
                    className="btn btn-sm btn-secondary-outline btn-radius px-3"
                    onClick={() => {
                      let item = close_data
                      item.action = 'close'
                      SetCloseData(item)
                      // let pass_data = {
                      //   mrp_data: mrp_state.field_data,
                      //   index: props.index_value,
                      // }
                      // props.on_save(pass_data)
                      myModal.hide()
                    }}
                  >
                    Close
                  </button> */}
                  <button
                    className="btn btn-sm btn-primary btn-radius px-3"
                    onClick={() => {
                      // onEditAddon();
                      // SetMrpState((prevValue: any) => {
                      //   prevValue.field_data.map((ele) => {
                      //     prevValue.mrp_data.push(ele)
                      //   })
                      //   console.log('after push :', prevValue.mrp_data)
                      //   // let pass_data = {
                      //   //   mrp_data: prevValue.field_data,
                      //   //   index: props.index_value,
                      //   // }
                      //   // props.on_save(pass_data)
                      //   // console.log('after save ')
                      //   prevValue.field_data = [
                      //     {
                      //       selected_country: {},
                      //       country: '',
                      //       state_data: [],
                      //       selected_state: {},
                      //       state: '',
                      //       selected_currency: {},
                      //       currency: '',
                      //       price: '',
                      //       selected_date: '',
                      //       activation_date: '',
                      //     },
                      //   ]
                      //   prevValue.is_edit = false
                      //   // props.mrp_on_save(prevValue, props.index);
                      //   return { ...prevValue }
                      // })
                      let item = close_data
                      item.action = 'close'
                      SetCloseData(item)
                      // let pass_data = {
                      //   mrp_data: mrp_state.field_data,
                      //   index: props.index_value,
                      // }
                      // props.on_save(pass_data)
                      myModal.hide()
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
             {/* )} */}
          </div>
        </div>
      </div>
    </div>
  )
}
