export const AppConfig = {
  base_url: {
    api: 'https://'+ window.location.hostname +'/app', // for live
    superapp: "https://",
  },
  user_agent: {
    superapp: {
      android: "",
      ios: "",
    },
  },
  // environment : "Development",
  environment : "Production",
};
